// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/tmp/build/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-i-18-n-redirect-js": () => import("/tmp/build/src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-about-index-jsx": () => import("/tmp/build/src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-iceberg-index-jsx": () => import("/tmp/build/src/pages/iceberg/index.jsx" /* webpackChunkName: "component---src-pages-iceberg-index-jsx" */),
  "component---src-pages-index-jsx": () => import("/tmp/build/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-story-conditions-jsx": () => import("/tmp/build/src/pages/story/conditions.jsx" /* webpackChunkName: "component---src-pages-story-conditions-jsx" */),
  "component---src-pages-story-employment-jsx": () => import("/tmp/build/src/pages/story/employment.jsx" /* webpackChunkName: "component---src-pages-story-employment-jsx" */),
  "component---src-pages-story-enterprises-jsx": () => import("/tmp/build/src/pages/story/enterprises.jsx" /* webpackChunkName: "component---src-pages-story-enterprises-jsx" */),
  "component---src-pages-story-equality-jsx": () => import("/tmp/build/src/pages/story/equality.jsx" /* webpackChunkName: "component---src-pages-story-equality-jsx" */),
  "component---src-pages-story-future-jsx": () => import("/tmp/build/src/pages/story/future.jsx" /* webpackChunkName: "component---src-pages-story-future-jsx" */),
  "component---src-pages-story-index-jsx": () => import("/tmp/build/src/pages/story/index.jsx" /* webpackChunkName: "component---src-pages-story-index-jsx" */),
  "component---src-pages-story-protection-jsx": () => import("/tmp/build/src/pages/story/protection.jsx" /* webpackChunkName: "component---src-pages-story-protection-jsx" */),
  "component---src-pages-story-rights-jsx": () => import("/tmp/build/src/pages/story/rights.jsx" /* webpackChunkName: "component---src-pages-story-rights-jsx" */),
  "component---src-pages-story-tripartism-jsx": () => import("/tmp/build/src/pages/story/tripartism.jsx" /* webpackChunkName: "component---src-pages-story-tripartism-jsx" */),
  "component---src-pages-story-work-jsx": () => import("/tmp/build/src/pages/story/work.jsx" /* webpackChunkName: "component---src-pages-story-work-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/build/.cache/data.json")


module.exports = { interface_loading: 'chargement',
  interface_headphones: 'de préférence avec des écouteurs',
  interface_title: 'Notre histoire, votre histoire',
  interface_share: 'partager',
  interface_fb: 'facebook',
  interface_twitter: 'twitter',
  interface_youtube: 'youtube',
  interface_on: 'marche',
  interface_off: 'arrêt',
  interface_start: 'appuyez sur la barre d\'espace',
  interface_scrollDown: 'Dérouler le menu',
  interface_description: 'L’Organisation internationale du Travail (OIT) célèbre 100 ans de progrès vers la justice sociale et de promotion du travail décent.',
  interface_news: 'Actualités et événements',
  interface_fraudAlert: 'Courriers frauduleux',
  interface_disclaimer: 'Déni de responsabilité',
  interface_privacyPolicy: 'Politique de confidentialité',
  interface_copyright: 'Droits d\'auteur et autorisations',
  interface_ilo: 'Organisation internationale du Travail (OIT)',
  interface_ilo100: 'OIT 100',
  interface_ourStoryDescription: 'Embarquez dans un voyage interactif dans le passé, le présent et l’avenir de l’Organisation internationale du Travail, la plus ancienne agence spécialisée des Nations Unies. L\'OIT fait progresser la justice sociale et favorise le travail décent depuis 1919.',
  interface_about: 'A propos',
  interface_credits: 'Crédits',
  interface_mobileTitle: 'Notre histoire, <br>votre histoire',
  interface_mobileStart: 'Appuyez pour démarrer',
  interface_html5VideoError: 'Votre navigateur n\'est pas compatible avec les vidéos HTML5.',
  interface_previous: 'Précédent',
  interface_next: 'Suivant',
  interface_seePhoto: 'Voir cette photo',
  interface_seeVideo: 'Voir cette video',
  interface_nextChapter: 'Chapitre suivant',
  interface_discoverChapter: 'Découvrez ce chapitre le',
  interface_noOptions: 'Aucun résultat',
  interface_learnMore: 'En savoir plus',
  home_title: 'Le centenaire de l’OIT',
  home_subtitle: 'Faire progresser la justice sociale, promouvoir le travail décent',
  home_news: 'Actualités et événements',
  home_ourStory: 'Notre histoire, votre histoire',
  home_newsSubtitle: 'Découvrez nos activités liées au centenaire',
  home_ourStorySubtitle: 'Explorez notre histoire et notre mission',
  home_ourStoryCta: 'En ligne!',
  home_newsCta: 'En savoir plus',
  home_description: 'En 2019, l’OIT va fêter son 100e anniversaire. Le centenaire sera l’occasion de célébrer les réalisations de l’OIT et de réaffirmer sa place d’organisation mondiale faisant autorité pour le monde du travail.',
  home_socialMedia: 'En 2019, @OITInfo fête son centenaire. 100 ans pour faire progresser la justice sociale et promouvoir le travail décent. #OIT100 https://www.ilo.org/100/fr/',
  home_pageTitle: 'Le centenaire de l’OIT',
  home_ourStoryCta2: 'EXPLOREZ',
  home_ilcTitle: '',
  intro_heading: 'Alors que l\'OIT célèbre son 100e anniversaire, embarquez dans un voyage à travers son passé, son présent et son avenir.',
  intro_title: 'Notre histoire, votre histoire',
  intro_buttonStart: 'Partez à la découverte',
  intro_buttonDates: 'Voir les dates de lancement',
  intro_tableCaption: 'Ecoutez les dates de lancement des chapitres de Notre histoire, votre histoire',
  intro_chapters: 'Chapitres',
  intro_launchDate: 'Dates de lancement',
  intro_aboutIntro: '<p>En 2019, l\'Organisation internationale du Travail célèbrera son centenaire au service de la justice sociale et promouvoir des conditions de travail décentes.</p>\n\n<p>Ce site Web propose aux utilisateurs de faire un voyage multimédia à travers le passé, le présent et l\'avenir de l\'organisation au moment où elle se prépare à relever les défis de demain.</p>\n\n<p>Pour en savoir plus sur la façon dont l\'OIT célèbre son centenaire, consultez la <a href="https://www.ilo.org/100/news/fr">page Actualités et événements du centenaire de l\'OIT.</a>.</p>',
  intro_aboutTitle: 'A propos',
  intro_aboutDescription: 'Le site web ILO100 commémore le centenaire consacré à la promotion de la justice sociale et au travail décent. Cela n\'aurait pas été possible sans l\'appui généreux de nos mandants et de nos partenaires.',
  intro_aboutResourcesTitle: 'Ressources additionnelles',
  intro_aboutCreditsTitle: 'Crédits',
  intro_aboutResTripartism1: '<a href="https://www.ilo.org/global/lang--fr/index.htm">Site web de l\'OIT</a>',
  intro_aboutResFuture1: '<a href="https://www.ilo.org/global/topics/future-of-work/WCMS_569890/lang--fr/index.htm">Commission mondiale sur l’avenir du travail</a>',
  intro_aboutResTripartism2: '<a href="https://www.ilo.org/global/about-the-ilo/history/lang--fr/index.htm">Histoire de l\'OIT</a>',
  intro_aboutCreditsH1: 'Histoire, photographie et vidéo',
  intro_aboutCreditsP1: 'Département de la communication de l\'OIT',
  intro_aboutCreditsH2: 'Autres photos et vidéos',
  intro_aboutCreditsH3: 'Direction créative et design',
  intro_aboutCreditsH4: 'Développement du site web',
  intro_aboutCreditsH5: 'Conception musicale et sonore',
  intro_aboutCreditsH6: 'Remerciements particuliers',
  intro_aboutCreditsP2: 'AFP • Bibliothèque FDR • Jessica Maida • Lisa Kristine • Bibliothèque du Congrès • Maxime Fossat • Unsplash • Wikicommons',
  intro_aboutCreditsP3: 'Département de la communication de l\'OIT<br>avec ElkanoData',
  intro_aboutCreditsP4: 'Département de la communication de l\'OIT<br>avec Squareball Digital',
  intro_aboutCreditsP5: 'ElkanoData<br>avec Extreme Music (maisons de disques)',
  intro_aboutCreditsP6: 'Le site web ILO100 commémore le centenaire consacré à la promotion de la justice sociale et au travail décent. Cela n\'aurait pas été possible sans l\'appui généreux de nos mandants et de nos partenaires.\n\n<br><br>\n\nBernice Antoine (Jeune militant)  •  Valentina Auneme (Réceptionniste d’hôtel)  •  Michelle Bachelet (Haut‑Commissaire des Nations unies aux droits de l’homme) •  Sreela Das Gupta (Spécialiste de la Diversité et de l’inclusion)  •  Carling Dumulot Sr. (Chef autochtone Aeta)  •  Zainab Ecumba (Travailleur domestique)  •  Apolonia Fontes (Retraité)  •  Ulf Gustafsson (Mineur et syndicaliste)  •  Zainab Ahmed Hamza (Créatrice de bijoux)  •  Alison Hart (Travailleur indépendant en ligne)  •  Shinichi Kondo (Directeur, Hôpital Kinrou)  •  Yukinori Kusaka (Professeur émérite, Université de Fukui)  •  Dao Thuy Ly (sans-emploi) •  Zeinab Mahmoud (Entrepreneur)  •  Marina Mejia (Entrepreneur)  •  Zin Mar Myint (Travailleuse d’une usine de prêt-à-porter)  •  Narufumi Suganuma (Professeur, Kochi Medical School)  •  Francis Phiri (Instructeur en langue des signes)  •  Vishani Ragobeer (Jeune militant)  •  Nicolás Gurría Sánchez (Étudiant)  •  Laurel Sprague (Conseillère spéciale, communauté, UNAIDS)  •  Aya Tanimura (réalisatrice)   •  Afonsu de Jesus Vidal (Retraité)•  Zhang Yanyan (Confédération des entreprises de Chine).\n\n<br><br>\n\nUn merci tout particulier à tous les collègues de l’OIT qui ont rendu ce site possible',
  intro_dateChap1: '9 janvier',
  intro_dateChap2: '16 janvier',
  intro_dateChap3: '30 janvier',
  intro_dateChap4: '6 février',
  intro_dateChap5: '13 février',
  intro_dateChap6: '21 février',
  intro_dateChap7: '27 février',
  intro_dateChap8: '13 mars',
  intro_dateChap9: '1 avril',
  intro_post: 'Embarquez dans un voyage interactif à travers le passé, le présent et l\'avenir de l\'@OIT-- tout en célébrant un siècle de militantisme pour la justice sociale et la promotion du travail décent.',
  intro_aboutResTripartism3: '<a href="https://www.ilo.org/actemp/lang--en/index.htm">Bureau for Employers\' Activities (ACTEMP)</a>',
  intro_aboutResRights1: '<a href="https://www.ilo.org/global/standards/introduction-to-international-labour-standards/conventions-and-recommendations/lang--fr/index.htm">Conventions et recommandations</a>',
  intro_aboutResRights2: '<a href="https://www.alliance87.org/">Alliance 8.7</a>',
  intro_aboutResRights3: '<a href="https://www.ilo.org/global/about-the-ilo/how-the-ilo-works/departments-and-offices/governance/fprw/lang--fr/index.htm">Service des Principes et droits fondamentaux au travail (FUNDAMENTALS) de l\'OIT</a>',
  intro_aboutResTripartism4: '<a href="https://www.ilo.org/actrav/lang--fr/index.htm">Bureau des Activités pour les Travailleurs (ACTRAV)</a>',
  intro_aboutResEquality1: '<a href="https://www.equalpayinternationalcoalition.org/">EPIC: the Equal Pay International Coalition</a>',
  intro_aboutResEquality2: '<a href="https://www.ilo.org/global/topics/indigenous-tribal/lang--fr/index.htm">Portail thématique sur les peuples indigènes et tribaux</a>',
  intro_aboutResEquality3: '<a href="https://www.ilo.org/global/topics/hiv-aids/lang--fr/index.htm">Portail thématique sur VIH et SIDA</a>',
  intro_aboutResEmployment1: '<a href="https://www.ilo.org/global/topics/youth-employment/databases-platforms/global-initiative-decent-jobs/lang--fr/index.htm">L’Initiative mondiale pour l’emploi décent des jeunes</a>',
  intro_aboutResEmployment2: '<a href="https://www.skillsforemployment.org/KSP/fr/index.htm">La plate-forme mondiale, publique-privée, de partage des connaissances sur les compétences au service de l\'emploi </a>',
  intro_aboutResEmployment3: '<a href="https://www.ilo.org/ilostat/faces/ilostat-home?locale=fr&_afrLoop=443228287466324#!%40%40%3Flocale%3Dfr%26_afrLoop%3D443228287466324%26_adf.ctrl-state%3D109upvcqd2_57">ILOSTAT: Statistiques du travail</a>',
  intro_aboutResProtection1: '<a href="https://www.social-protection.org/gimi/gess/Wspr.action?lang=FR">Donnés du Rapport mondial sur la protection sociale 2017-2019</a>',
  intro_aboutResWork1: '<a href="https://www.ilo.org/global/topics/labour-law/lang--fr/index.htm">Portail thématique sur le droit du travail</a>',
  intro_aboutResConditions1: '<a href="https://betterwork.org/">Better Work</a>',
  intro_aboutResConditions2: '<a href="https://www.ilo.org/wcmsp5/groups/public/---ed_dialogue/---lab_admin/documents/projectdocumentation/wcms_613287.pdf">Fonds Vision Zero</a>',
  intro_aboutResConditions3: '<a href="https://www.ilo.org/global/topics/wages/minimum-wages/lang--fr/index.htm">Guide pour les politiques en matière de salaire minimum de l\'OIT</a>',
  intro_aboutResEnterprises1: '<a href="https://www.ilo.org/empent/areas/business-helpdesk/lang--fr/index.htm">Helpdesk du BIT: Assistance aux entreprises sur les normes internationales du travail</a>',
  intro_aboutResEnterprises2: '<a href="https://www.ilo.org/empent/units/boosting-employment-through-small-enterprise-development/eese/lang--en/index.htm">Enabling Environment for Sustainable Enterprises (EESE)</a>',
  intro_aboutResEnterprises3: '<a href="http://www.knowaboutbusiness.org/">Know About Business (KAB)</a>',
  intro_aboutResEnterprises4: '<a href="http://mmw.itcilo.org/fr/home">“Assurer le Fonctionnement de la Microfinance” !</a>',
  intro_aboutResEnterprises5: '<a href="https://www.ilo.org/empent/areas/start-and-improve-your-business/lang--fr/index.htm">Gerez Mieux Votre Entreprise (GERME)</a>',
  intro_aboutResEnterprises6: '<a href="https://www.itcilo.org/en/areas-of-expertise/rural-development/my-coop-manging-your-agricultural-cooperative">My.COOP</a>',
  intro_aboutResEnterprises7: '<a href="https://www.ilo.org/empent/Projects/score/lang--fr/index.htm">Le programme SCORE</a>',
  intro_aboutResEnterprises8: '<a href="https://www.ilo.org/empent/areas/womens-entrepreneurship-development-wed/lang--fr/index.htm">Développement de l\'entrepreneuriat féminin (WED)</a>',
  intro_aboutResEnterprises9: '<a href="https://www.ilo.org/empent/areas/mne-declaration/lang--fr/index.htm">Déclaration de principes tripartite sur les entreprises multinationales et la politique sociale</a>',
  intro_aboutResFuture2: '<a href="https://www.ilo.org/global/topics/sdg-2030/lang--fr/index.htm">Travail décent et le Programme de développement durable pour 2030</a>',
  intro_aboutResEquality4: '<a href="https://www.ilo.org/global/topics/disability-and-work/lang--fr/index.htm">Portail thématique sur handicap et travail </a>',
  intro_aboutResEquality5: '<a href=" http://www.businessanddisability.org/">ILO Global Business and Disability Network</a>',
  tripartism_title: 'Jeter les bases de la justice sociale',
  tripartism_heading: 'Tripartisme et dialogue social',
  tripartism_chapter: 'chapitre 1',
  tripartism_ilo: 'Organisation internationale du Travail',
  tripartism_infoboxIlo: '<p>L’Organisation internationale du Travail est née à une époque troublée, de changements sociaux et technologiques rapides.</p>\n\n<p>Dans un monde qui tentait de se reconstruire et de rebondir après les ravages de la guerre, l’OIT a été fondée sur la conviction que la justice sociale est essentielle pour obtenir une paix universelle et durable.</p>',
  tripartism_socialJustice: 'Justice sociale',
  tripartism_keysQuote: '«Quand ces trois clés tournent ensemble, quand les gouvernements, les employeurs et les travailleurs peuvent se rencontrer, les portes s’ouvrent et la justice sociale progresse.»',
  tripartism_guyRyder: 'Guy Ryder, Directeur général de l’OIT',
  tripartism_calloutWorld1919: 'le monde en 1919',
  tripartism_infoWw1: '<p>Après la première guerre mondiale, les pays étaient en ruine, les populations traumatisées par la guerre et les économies nationales dévastées. Les frontières avaient été redessinées et ceux qui avaient travaillé jour et nuit à l’arrière du front revendiquaient avec force d’être entendus.</p>\n\n<p>Le siècle qui avait précédé la guerre avait connu des mutations socioéconomiques profondes en Europe et aux  Etats-Unis. L’industrialisation avait induit une croissance sans précédent du pouvoir économique des nations européennes, ainsi qu’une concurrence de plus en plus féroce entre elles, en raison notamment des bouleversements parfois déshumanisants qui se produisaient dans le monde du travail.</p>',
  tripartism_calloutVersailles: 'le traité de versailles',
  tripartism_infoVersailles: '<p>Après la Première Guerre mondiale, le mécontentement grandissant face aux très fortes inégalités et aux mauvaises conditions de travail avait mené l’Europe au seuil d’une révolution.</p>\n\n<p>Les travailleurs exigeaient que l’accord de paix comporte des mesures pour améliorer les conditions de travail, par l’intermédiaire de normes internationales du travail et par l’octroi de droits aux syndicats.</p>',
  tripartism_whatIsSocialJustice: 'La justice sociale, c’est quoi?',
  tripartism_infoUnrest: '<h3>«Un tel mécontentement que la paix et l’harmonie du monde sont mises en danger»</h3>\n\n\n<p>Les revendications étaient si insistantes et la situation sociale si explosive que l’une des principales préoccupations de la Conférence de la paix était que «…le mécontentement est si grand que la paix et l’harmonie sont mises en danger…»</p>\n\n<p>Cette déclaration, au début du préambule de la Constitution de l’OIT, accorde clairement à l’organisation un rôle majeur dans le maintien de la paix.</p>',
  tripartism_calloutIloCreated: 'L’OIT – qui rassemble des représentants des gouvernements, des travailleurs et des employeurs – a donc été fondée en 1919 dans la Partie XIII du Traité de paix de Versailles, qui a mis fin à la première guerre mondiale.',
  tripartism_quoteWildDreamBody: '«Je me souviens parfaitement qu’à ce moment-là, l’OIT était encore un rêve. Pour beaucoup de gens, c’était un rêve inaccessible. Avait-on déjà vu des gouvernements se réunir pour améliorer les normes du travail sur le plan international?» <br><br>«L’idée que les personnes directement concernées – les travailleurs et les employeurs des différents pays – puissent intervenir avec les gouvernements pour déterminer des normes du travail était encore plus folle.»',
  tripartism_quoteWildDreamCaption: 'Franklin Delano Roosevelt était Président des États-Unis d\'Amérique lorsqu\'ils ont rejoint l\'OIT en 1934.',
  tripartism_calloutWildDream: 'Pour beaucoup, c’était un rêve inaccessible.',
  tripartism_timeline1969Heading: 'L’OIT reçoit le Prix Nobel de la Paix pour son 50e anniversaire',
  tripartism_timeline1969Para: '«Peu d’organisations ont réussi, comme l’a fait l’OIT, à traduire en action l’idée morale fondamentale sur laquelle elle se fonde… Par son travail honnête et sans relâche, l’OIT a réussi à introduire des réformes supprimant les injustices les plus flagrantes dans un grand nombre de pays.» Mme Aase Lionaes, Présidente du Comité Nobel du Parlement norvégien',
  tripartism_timeline1919Heading: 'Rédaction de la Constitution de l’OIT',
  tripartism_timeline1919Para: 'De janvier à avril, la Commission du travail instituée par la Conférence de paix se réunit à Paris, puis à Versailles pour rédiger la Constitution de l’OIT. La première Conférence internationale du Travail, qui rassemble les délégations de 40 pays, se tient à Washington D.C., en octobre-novembre 1919.',
  tripartism_timeline1920Heading: 'Ouverture du Bureau international du Travail à Genève',
  tripartism_timeline1920Para: 'Le siège de l\'OIT s’installe à Genève, en Suisse, durant l’été 1920; le français Albert Thomas devient le premier Directeur du Bureau international du Travail, qui est le secrétariat permanent de l’Organisation.',
  tripartism_timeline1936Heading: 'Première Conférence régionale de l’OIT à Santiago, Chili',
  tripartism_timeline1944Heading: 'La Déclaration de Philadelphie',
  tripartism_timeline1946Heading: 'L’OIT rejoint l’Organisation des Nations Unies',
  tripartism_timeline1946Para: 'L’OIT devient la première agence spécialisée de l’Organisation des Nations Unies qui vient d’être constituée. L’OIT devient donc l’un des principaux fora de discussion tout au long de la guerre froide.',
  tripartism_timeline1947Heading: 'Première Conférence régionale asiatique de l’OIT à New Delhi, Inde',
  tripartism_timeline1947Para: 'Les délégués de 18 pays et territoires se sont rencontrés pour renforcer les activités de l’OIT en Asie, à un moment de grand bouleversement dans la région.',
  tripartism_timeline1964Heading: 'La déclaration contre l’Apartheid',
  tripartism_timeline1964Para: 'La Conférence internationale du Travail adopte à l’unanimité la Déclaration sur l’Apartheid qui condamne la politique raciale du gouvernement de l’Afrique du Sud. En 1990, Nelson Mandela participe à la CIT, et rend hommage à l’OIT pour sa contribution à la lutte contre l’apartheid.',
  tripartism_timeline2014Heading: 'Le Centre international de formation de l\'OIT à Turin, en Italie, célèbre son 50e anniversaire',
  tripartism_timeline1982Heading: 'L’OIT promeut la liberté syndicale en Europe orientale',
  tripartism_timeline1982Para: 'L’OIT appuie pleinement la légitimité de Solidarność en Pologne, où Lech Walesa mène une grève pour créer le premier syndicat indépendant et autonome du bloc de l’Est, et ouvre la porte à un éventuel retour de la démocratie.',
  tripartism_timeline1998Heading: 'La Déclaration de l’OIT relative aux principes et droits fondamentaux au travail',
  tripartism_timeline1998Para: 'Les pays membres de l’OIT s’engagent à respecter et à promouvoir les principes et droits relatifs à la liberté syndicale, au travail forcé, au travail des enfants et à la discrimination – qu’ils aient ou non ratifié les conventions sur ces sujets.',
  tripartism_timeline1999Heading: 'Lancement de l’Agenda du travail décent',
  tripartism_timeline1999Para: 'Le travail décent résume les aspirations des gens pour leur vie professionnelle. Les quatre piliers de l’Agenda sont la création d’emplois et d’entreprises, l’extension de la protection sociale, la promotion du dialogue social et la garantie des droits au travail. L’égalité des chances et de traitement pour toutes les femmes et tous les hommes est un élément transversal de ces quatre piliers.',
  tripartism_timeline2008Heading: 'Déclaration de la justice sociale pour une mondialisation équitable',
  tripartism_timeline2008Para: 'Cette Déclaration est destinée à promouvoir le travail décent grâce à une approche intégrée comportant quatre objectifs stratégiques: la création d’emplois, la protection sociale, le dialogue social, et les principes et droits fondamentaux au travail. Elle favorise le dialogue social et le tripartisme, qui sont les mécanismes les plus appropriés pour traduire le développement économique en progrès social, et le progrès social en développement économique.\n\nLa même année survient la crise financière mondiale, qui devient la pire crise économique depuis la grande dépression.',
  tripartism_timeline2009Heading: 'Le Pacte mondial pour l’emploi',
  tripartism_timeline2009Para: 'Face aux perspectives d’un chômage prolongé, de la pauvreté et des inégalités, la Conférence internationale du Travail adopte un Pacte mondial pour l’emploi visant à stimuler la reprise économique, la création d’emplois et à fournir une protection pour les travailleurs et leurs familles.',
  tripartism_timeline2015Heading: 'Le travail décent est au cœur des objectifs de développement durable',
  tripartism_timeline2015Para: 'Durant l’Assemblée générale de l’ONU en septembre 2015, les quatre piliers de l’Agenda du travail décent – les normes et les droits au travail, la création d’emplois et le développement des entreprises, la protection sociale et le dialogue social – sont devenus des parties intégrantes du Programme de développement durable à l’horizon 2030.',
  tripartism_timeline2019Heading: 'Centenaire de l’OIT – Dessiner les contours d’un avenir au service de tous',
  tripartism_timeline2019Para: 'L’Organisation internationale du Travail, la plus ancienne des agences spécialisées de l’ONU, célèbre son centenaire.',
  tripartism_infoLifeIndustry: '<h3>Dans les pays industrialisés, la vie était très différente de celle de maintenant</h3>\n\n<p>Le niveau et l’ampleur de la pauvreté, des inégalités et des discriminations étaient beaucoup plus importants. Il n’était pas rare de voir des enfants travailler dans des usines et dans les champs. Il y avait fréquemment des accidents et des morts sur les lieux de travail.</p>',
  tripartism_timeline1926Heading: 'Un système de surveillance pour les conventions de l’OIT',
  tripartism_timeline1926Para: 'La Commission d’experts pour l’application des conventions et des recommandations est instaurée pour rendre les pays responsables de l’application des conventions de l’OIT qu’ils ont ratifiées.',
  tripartism_timeline1929Heading: 'Début de la grande dépression',
  tripartism_timeline1929Para: 'Les travaux de l’OIT sont directement liés à la recherche de solutions à la grande dépression, la question la plus cruciale de l’époque.',
  tripartism_timeline1936Para: 'La première Conférence du travail des états américains membres réaffirme l’idée d’universalité – les actions de l’OIT doivent correspondre aux besoins de tous les peuples dans l’ensemble du monde, quels que soient les régimes sociaux ou économiques qui existent dans leurs pays.',
  tripartism_timeline1939Heading: 'Début de la deuxième guerre mondiale',
  tripartism_timeline1939Para: 'La Suisse étant entourée par les forces de l’Axe, le centre opérationnel de l\'OIT est temporairement transféré à Montréal, au Canada, en août 1940.',
  tripartism_timeline1944Para: 'L’OIT adopte la Déclaration de Philadelphie qui réaffirme que le travail n’est pas une marchandise, que la liberté d’expression et d’association est indispensable pour obtenir un progrès soutenu et que la pauvreté, partout où elle existe, constitue un danger pour la prospérité de toutes et de tous, ce qui renforce la pertinence de l’OIT pour relever les défis des années d’après-guerre.',
  tripartism_timeline1948Heading: 'Adoption de la convention fondamentale de l’OIT sur la liberté syndicale',
  tripartism_timeline1948Para: 'La convention (n° 87) sur la liberté syndicale et la protection du droit syndical établit le droit des travailleurs et des employeurs à constituer les organisations de leur choix et à s’y affilier sans autorisation préalable.',
  tripartism_timeline1949Heading: 'Adoption de la convention fondamentale de l’OIT sur la négociation collective',
  tripartism_timeline1949Para: 'La convention (n° 98) sur le droit d’organisation et de négociation collective déclare que les travailleurs doivent être protégés contre les actes de discrimination antisyndicale, et incorpore le droit à la négociation collective.',
  tripartism_timeline1952Heading: 'Le programme en faveur des indiens des Andes',
  tripartism_timeline1952Para: 'Lancement du premier programme de coopération technique à grande échelle dirigé par l’OIT en coopération avec d’autres agences de l’ONU. Il porte sur sept pays andins et a pour objectif d’améliorer les conditions socioéconomiques des peuples indigènes. Il dure jusqu’en 1972.',
  tripartism_timeline1955Heading: 'Première Conférence régionale européenne de l’OIT à Genève',
  tripartism_timeline1955Para: '«L’importance de cette conférence provient du fait qu’elle montre principalement que les pays d’Europe se déclarent maintenant prêts à chercher des solutions aux problèmes sociaux posés par l’intégration économique plus complète de ce continent.» David A. Morse, Directeur général du BIT',
  tripartism_timeline1960Heading: 'Première Conférence régionale africaine de l’OIT à Lagos, Nigéria',
  tripartism_timeline1960Para: 'La plus grande conférence régionale organisée par l’OIT se déroule dans un contexte spécial: l’accession à l’indépendance de nombreux pays africains, ce qui donne à cette conférence une grande résonnance internationale.',
  tripartism_timeline2014Para: 'Le CIF développe des activités de formation, d’apprentissage et de développement des capacités pour les gouvernements, les organisations d’employeurs et de travailleurs, et d’autres partenaires nationaux et internationaux pour favoriser le travail décent. Le Centre de Turin a formé plus de 80 000 personnes issues de 172 pays depuis son ouverture en 1964.',
  tripartism_timeline1976Heading: 'Adoption de la convention de l’OIT sur le tripartisme et les normes du travail',
  tripartism_timeline1976Para: 'La convention (n° 144) sur les consultations tripartites relatives aux normes internationales du travail stipule que les employeurs et les travailleurs seront paritairement représentés dans tous les organes par l’intermédiaire desquels sont organisées les consultations de l’OIT, et que ces consultations se tiendront au moins une fois par an.',
  tripartism_timeline1977Heading: 'Nouveau programme pour améliorer les conditions et le milieu de travail',
  tripartism_timeline1977Para: 'Le Programme international pour l’amélioration des conditions et du milieu de travail (PIACT) est destiné à promouvoir et à soutenir les actions visant à «humaniser le travail». Ce programme porte sur l’amélioration de la qualité de vie professionnelle grâce à la prévention des accidents du travail et des maladies professionnelles, et à «une meilleure prise en compte de l’humain dans les transferts de technologie».',
  tripartism_timeline1992Heading: 'Un nouveau programme pour éliminer le travail des enfants',
  tripartism_timeline1992Para: 'L’OIT lance le Programme international pour l’abolition du travail des enfants (IPEC), le programme le plus vaste jamais lancé pour éliminer le travail des enfants, qui d’après les statistiques de l’OIT, a contribué à retirer plus de 86 millions d’enfants des situations de travail dans lesquelles ils se trouvaient partout dans le monde.',
  tripartism_timeline2002Heading: 'Instauration de la Commission mondiale sur la dimension sociale de la mondialisation',
  tripartism_timeline2002Para: '26 personnalités marquantes – des chefs d’état, des dirigeants d’entreprise, des syndicalistes et des Prix Nobel – examinent le processus de mondialisation du point de vue des gens ordinaires. C’est la première tentative pour trouver  un terrain d’entente sur l’un des sujets les plus controversés de notre temps.',
  tripartism_quoteSocJusBody1: '«Surmonter la pauvreté, ce n’est pas une œuvre de charité, c’est un acte de justice.»',
  tripartism_quoteSocJusBody2: '«Tant qu’un grand nombre de gens ne se sentiront pas responsables du bien-être des autres, on ne pourra jamais atteindre la justice sociale.»',
  tripartism_quoteSocJusCaption1: 'Nelson Mandela, ancien président de l\'Afrique du Sud, lauréat du prix Nobel de la paix en 1993',
  tripartism_quoteSocJusCaption2: 'Helen Keller, auteure américaine et militante politique',
  tripartism_quoteSocJusBody3: '«Quand on retire aux gens leur droit de vivre dans la dignité, l’espoir d’avoir un avenir meilleur, pour contrôler leurs vies, quand on les prive de ce choix, on peut s’attendre à ce qu’ils se battent pour ces droits.»',
  tripartism_quoteSocJusCaption3: 'Reine Rania Al Abdullah de Jordanie',
  tripartism_quoteSocJusBody4: '«Un autre élément essentiel pour obtenir une paix universelle et durable, c’est la justice sociale.»',
  tripartism_quoteSocJusCaption4: 'Arthur Henderson, ancien dirigeant du parti travailliste de Grande-Bretagne, prix Nobel de la paix en 1934',
  tripartism_quoteSocJusBody5: '«Il ne peut y avoir de paix sans justice, il ne peut y avoir de justice sans équité, il ne peut y avoir d’équité sans développement, il ne peut y avoir de développement sans démocratie, il ne peut y avoir de démocratie sans respect de l’identité et de la valeur des cultures et des peuples.»',
  tripartism_quoteSocJusCaption5: 'Rigoberta Menchú, militante guatémaltèque des droits humains, lauréate du prix Nobel de la paix en 1992',
  tripartism_quoteSocJusBody6: '«L’éducation est indispensable pour devenir durablement autonome, l’éducation est un droit essentiel pour les autres droits de la vie en société. L’éducation est indispensable pour la justice sociale.»',
  tripartism_quoteSocJusCaption6: 'Kailash Satyarthi, militant indien des droits des enfants, prix Nobel de la paix en 2014',
  tripartism_infoFounders: '<h3>Les fondateurs de l’OIT</h3>\n\n<p>…étaient convaincus du lien essentiel entre la paix internationale et la justice sociale dans les pays. Ce lien était si important qu’il fallait absolument mettre en place une organisation traitant des questions liées au travail pour promouvoir et protéger la paix mondiale.</p>',
  tripartism_visSocJustTitle: 'Les clés de la justice sociale',
  tripartism_visSocJustHeading1: 'Les efforts de l’OIT pour atteindre la justice sociale',
  tripartism_visSocJustBody1: '<p>L’OIT est la seule organisation internationale où les gouvernements ne disposent pas d’un droit de vote exclusif. Pour élaborer ses normes et ses politiques, les représentants des travailleurs et des employeurs disposent du même nombre de voix que les gouvernements dans les processus de décision. Ce concept s’appelle le « tripartisme ».</p>\n\n<p>En 1919, la décision de donner à des représentants non gouvernementaux le droit de participer aux conférences internationales était une suggestion complètement radicale. En fait, même après la fondation de l’OIT, il faudra des années, voire des décennies pour que la plupart des pays pratiquent le tripartisme au niveau national.</p>\n\n<p>Ces dispositions uniques donnent à l’OIT l’avantage d’intégrer les connaissances du « monde réel » sur l’emploi et les questions du travail dans ses travaux.</p> \n\n<p>En s’inspirant de cet exemple fructueux, plus de 85% des états membres de l’OIT ont créé depuis des institutions tripartites pour traiter des questions liées au travail.</p>',
  tripartism_visSocJustHeading2: 'Le dialogue social',
  tripartism_visSocJustBody2: '<p>On appelle dialogue social le processus démocratique permettant  aux personnes qui seront concernées par les décisions relatives au monde du travail de participer à la prise de décision et à leur mise en œuvre.</p> \n\n<p>Il revêt différentes formes, comme les échanges d’informations, les consultations et les négociations entre travailleurs, employeurs et gouvernements. Il se fonde sur le droit à la liberté syndicale, qui permet aux travailleurs et aux employeurs de constituer les organisations de leur choix et de s’y affilier, le droit à la négociation collective des salaires et d’autres conditions d’emploi. Le dialogue social fonctionne mieux s’il se fonde sur la transparence, l’honnêteté, le respect mutuel et la compréhension.</p>\n\n<p>Sur le lieu de travail, le dialogue social peut améliorer les conditions de travail et contribuer à la performance économique, c’est un puissant moteur de stabilité, d’équité, de productivité et de croissance durable.</p>\n\n<p>Au niveau national, le dialogue social et le tripartisme font partie intégrante de la démocratie participative. Ils servent à garantir et à promouvoir les principes et droits fondamentaux au travail. Ils veillent à ce que les travailleurs reçoivent une part équitable de l’économie, à la sécurité des lieux de travail, et à ce que la mondialisation bénéficie à tous. Le dialogue social et le tripartisme sont essentiels au développement durable, à la justice sociale et à la paix.</p>',
  tripartism_visSocJustHeading3: 'Les organisations de travailleurs et d’employeurs',
  tripartism_visSocJustBody3: '<p>Mettre en place des relations sociales saines demeure un défi courant et formidable pour les organisations d’employeurs et les syndicats dans le monde.</p>\n\n<p>Comme le dialogue social est essentiel pour son travail, l’OIT contribue à améliorer l’efficacité de ces organisations pour qu’elles puissent mieux aider et représenter leurs membres.</p>\n\n<p>L’OIT travaille donc en étroite collaboration avec les représentants des employeurs et des travailleurs, ainsi qu’avec les représentants des gouvernements, qui siègent au Conseil d’administration du BIT et contribuent à déterminer son agenda.</p>\n\n<p>Grâce à la coopération sur le lieu de travail, l’OIT demeure pertinente en permanence dans le monde du travail et pour relever les défis toujours différents qu’elle affronte, comme l’introduction des nouvelles technologies, la promotion de l’innovation et la mise en place d’environnements de travail sains.</p>',
  tripartism_calloutDistinctHeading: 'L’OIT se distingue également des autres organisations intergouvernementales dans un autre domaine – parce qu’elle adopte des normes internationales du travail et supervise leur mise en œuvre.',
  tripartism_calloutDistinctBody: 'Ces traités internationaux définissent les actions à réaliser, et les principes à respecter par les pays qui les ratifient. C’est le principal moyen d’action et d’influence de l’OIT dans le monde du travail, qui contribue à créer des règles du jeu équitables permettant une concurrence loyale entre pays, entreprises et travailleurs.',
  tripartism_timeline1919Caption: '1ère Conférence internationale du Travail, Washington DC, Etats-Unis, 1919. © OIT',
  tripartism_timeline1920Caption: 'Albert Thomas, premier Directeur du BIT. © OIT',
  tripartism_timeline1926Caption: 'Le Conseil d’administration de l’OIT instaure en 1926 une commission technique, ensuite appelée la Commission d’experts sur l’application des Conventions et des Recommandations (CEACR), pour surveiller la mise en œuvre des normes internationales du travail. © OIT',
  tripartism_timeline1929Caption: 'Des chômeurs font la queue durant la grande dépression. New York, Etats-Unis, 1930. © Bibliothèque du Congrès',
  tripartism_timeline1936Caption: 'Première Conférence du Travail des états membres américains de l’OIT,  Santiago, Chili, 1936. © BIT',
  tripartism_timeline1939Caption: 'Un groupe de fonctionnaires du BIT et leurs épouses attendent pour monter à bord d’un bateau à Lisbonne, Portugal, en septembre 1940, alors que le BIT déménage au Canada durant la guerre. © BIT',
  tripartism_timeline1944Caption: 'Le Directeur du BIT Edward  Phelan (assis, à droite) signe la Déclaration historique de Philadelphie de l’OIT à la Maison Blanche, Washington DC, le 17 mai 1944, en présence du Président des Etats-Unis, Franklin Roosevelt (assis, à gauche). © BIT',
  tripartism_timeline1946Caption: 'Le Directeur du BIT Edward Phelan (à gauche) et le Secrétaire général des Nations Unies Trygve Lie (à droite) signent un accord selon lequel l’OIT devient la première agence spécialisée de l’ONU qui vient de naître. © BIT',
  tripartism_timeline1947Caption: 'Première Conférence régionale asiatique de l’OIT à New Delhi, Inde, 1947. © BIT',
  tripartism_timeline1948Caption: 'Le personnel de l’OIT à la Conférence internationale du Travail à San Francisco, Etats-Unis, 1948. © BIT',
  tripartism_timeline1949Caption: 'Edward Phelan (à gauche), 4e Directeur du BIT, et David Morse (à droite), 5e Directeur général du BIT. Genève, Suisse, 1949. © BIT',
  tripartism_timeline1952Caption: 'Programme en faveur des indiens des Andes, Pérou. © BIT',
  tripartism_timeline1955Caption: 'Max Petitpierre, Président de la Confédération Helvétique, fait un discours pour la première Conférence régionale européenne avec David Morse, 5e Directeur général du BIT (à gauche), et Roberto Ago, Président du Conseil d’administration du BIT (à droite). © BIT',
  tripartism_timeline1960Caption: 'David Morse, 5e Directeur général du BIT, arrive à Lagos, au Nigéria, pour la première Conférence régionale africaine. © BIT',
  tripartism_timeline1964Caption: 'L’OIT a été la première organisation internationale à imposer des sanctions à l’Afrique du Sud en raison de ses politiques d’apartheid. Plus de 30 ans après, à la 77e Conférence internationale du Travail, Nelson Mandela a remercié l’OIT pour son soutien à la lutte contre l’apartheid. 1990. © BIT',
  tripartism_timeline2014Caption: 'Chaque année, des personnes du monde entier viennent au Centre international de formation de l\'OIT à Turin pour participer à ses séminaires, ateliers et cours. © Davide Bellucca',
  tripartism_timeline1969Caption: 'Le Directeur général David Morse reçoit le prix Nobel de la paix au nom de l’OIT des mains d’Aase Lionaes, Présidente du Comité Nobel du Parlement norvégien, à l’université d’Oslo, le 10 décembre 1969. © BIT',
  tripartism_timeline1976Caption: 'Les délégués de la 61e session de la CIT dans le hall de l’assemblée de l’ONU, Genève, Suisse, 1976. © BIT',
  tripartism_timeline1977Caption: 'Affiches sur la sécurité et la santé au travail en Mauritanie. © BIT',
  tripartism_timeline1982Caption: 'L’OIT apporte son soutien au syndicat indépendant Solidarnosc, dirigé par Lech Walesa, qui deviendra plus tard Président de la Pologne. © BIT',
  tripartism_timeline1992Caption: 'L’OIT crée le Programme international pour l\'abolition du travail des enfants (IPEC). © BIT / E. Gianotti',
  tripartism_timeline1998Caption: 'Adoption de la Déclaration de l’OIT sur les principes et droits fondamentaux au travail à la 86e session de la CIT, Genève, Suisse. © BIT',
  tripartism_timeline1999Caption: 'L’OIT lance l’Agenda du travail décent. Des chômeurs à Harare, Zimbabwe, cherchent du travail. 7 juin 2001. © AFP / Alexander Joe',
  tripartism_timeline2002Caption: 'Commission mondiale sur la dimension sociale de la mondialisation. De gauche à droite, Benjamin William Mkapa, Président de Tanzanie, Tarja Halonen, Président de la République de Finlande et Juan Somavia, Directeur général du BIT. © BIT / M. Crozet',
  tripartism_timeline2008Caption: 'Session d’ouverture de la 97e CIT en 2008 à Genève, en Suisse, durant laquelle la Déclaration sur la justice sociale pour une mondialisation équitable a été adoptée. © BIT',
  tripartism_timeline2009Caption: 'Discours de Juan Somavia, Directeur général du BIT, durant la session d’ouverture du Sommet de l’OIT sur la crise mondiale de l’emploi. Genève, Suisse, 15 juin 2009. © BIT',
  tripartism_timeline2015Caption: 'Ban Ki-moon, le huitième Secrétaire général de l’ONU, fait un discours à la CIT. © BIT',
  tripartism_timeline2017Caption: 'Guy Ryder, Directeur général du BIT, fait un discours pour le lancement de la Commission mondiale sur l’avenir du travail. Genève, Suisse, 21 août 2017. © BIT / M. Crozet',
  tripartism_timeline2019Caption: 'Photo de groupe du bureau régional de l\'OIT pour l’Asie et le Pacifique célébrant l’approche du centenaire de l’OIT. © BIT',
  tripartism_visSocJustTripartism: 'Le tripartisme',
  tripartism_visSocJustGovernments: 'Les gouvernements',
  tripartism_visSocJustEmployers: 'Les employeurs',
  tripartism_visSocJustWorkers: 'Les travailleurs',
  tripartism_imageThreekeysCaption: 'Trois clés, représentant les gouvernements, les employeurs et les travailleurs, ouvrent les portes du nouveau siège de l’OIT à Genève, en Suisse, lors de l’inauguration du bâtiment en 1926. © BIT',
  tripartism_imageThreekeysAlt: 'Trois clés dans la serrure du portail de la Villa Rappard à Genève, en Suisse.',
  tripartism_imageGirlcottonCaption: 'Une jeune fille filant le coton dans une filature du Texas, Etats-Unis, 1913. © Bibliothèque du Congrès',
  tripartism_imageFactoriesCaption: 'L’industrie dans les années 1900. © BIT',
  tripartism_imageProtestCaption: 'Manifestations en  Europe, 1919. © British Pathé',
  tripartism_imageCommissionCaption: 'La Commission sur la législation internationale du Travail, réunie durant la Conférence de paix de Paris, février-mars 1919. La Commission a rédigé la constitution de l’OIT, qui fait partie du Traité de Versailles. © BIT',
  tripartism_imageCommissionAlt: 'Un groupe d’hommes debout sur un escalier à l’extérieur d’un bâtiment. Devant se trouve Samuel Gompers, le président de la Commission.',
  tripartism_imageInaugurationCaption: 'Inauguration du siège de l’OIT à Genève, Suisse, 1926. © BIT',
  tripartism_imageRooseveltCaption: 'Franklin Roosevelt, 32e Président des Etats-Unis. Les Etats-Unis ont rejoint l’OIT durant son mandat. © AFP (Source audio: Archives Nationales des Etats-Unis)',
  tripartism_imageFirstilcCaption: '1ère Conférence internationale du Travail, Washington DC, Etats-Unis, 1919. © BIT',
  tripartism_imageAlbertthomasCaption: 'Albert Thomas, premier Directeur du BIT. © BIT',
  tripartism_imageGbtwentysixCaption: 'Le Conseil d’administration de l’OIT instaure en 1926 une commission technique, ensuite appelée la Commission d’experts sur l’application des Conventions et des Recommandations (CEACR), pour surveiller la mise en œuvre des normes internationales du travail. © BIT',
  tripartism_imageGbtwentysixAlt: 'Un groupe de délégués assis dans une salle de réunion.',
  tripartism_imageUnemployedCaption: 'Des chômeurs font la queue durant la grande dépression. New York, Etats-Unis, 1930. © Bibliothèque du Congrès',
  tripartism_imageFirstchileCaption: 'Première Conférence du Travail des états membres américains de l’OIT,  Santiago, Chili, 1936. © BIT',
  tripartism_imageFirstchileAlt: 'Un groupe of délégués sortant du bâtiment. Devant marche Harold Butler, deuxième Directeur général du BIT.',
  tripartism_imageShiplisbonCaption: 'Un groupe de fonctionnaires du BIT et leurs épouses attendent pour monter à bord d’un bateau à Lisbonne, au Portugal, en septembre 1940, alors que le BIT déménage au Canada durant la guerre. © BIT',
  tripartism_imageShiplisbonAlt: 'Trois hommes et trois femmes face à la caméra devant un grand bateau portant le drapeau américain.',
  tripartism_imagePhelandofpCaption: 'Le Directeur du BIT Edward  Phelan (assis, à droite) signe la Déclaration historique de Philadelphie de l’OIT à la Maison Blanche, Washington DC, le 17 mai 1944, en présence du Président des Etats-Unis Franklin Roosevelt (assis, à gauche). © BIT',
  tripartism_imagePhelandofpAlt: 'Trois hommes, dont Roosevelt et Phelan, sont assis autour d’un bureau. Derrière eux, deux hommes sont debout, ainsi qu’une femme, Frances Perkins.',
  tripartism_imagePhelanlieCaption: 'Le Directeur du BIT Edward Phelan (à gauche) et le Secrétaire général des Nations Unies Trygve Lie (à droite) signent un accord selon lequel l’OIT devient la première agence spécialisée de l’ONU qui vient de naître. © BIT',
  tripartism_imagePhelanlieAlt: 'Deux hommes sont assis côte à côte à un bureau, et chacun signe l’accord.',
  tripartism_imageFirstasiaconfCaption: 'Première Conférence régionale asiatique de l’OIT à New Delhi, Inde, 1947. © BIT',
  tripartism_imageFirstasiaconfAlt: 'Les délégués de la Conférence régionale et Jawaharlal Nehru, le 1er premier Ministre de l’Inde, debout dans une salle.',
  tripartism_imageIlcsanfranCaption: 'Le personnel de l’OIT à la Conférence internationale du Travail à San Francisco, Etats-Unis, 1948. © BIT',
  tripartism_imageIlcsanfranAlt: 'Des hommes et des femmes debout côte à côte devant un immeuble sous un panneau indiquant «Conférence internationale du Travail».',
  tripartism_imagePhelanmorseCaption: 'Edward Phelan (à gauche), 4e Directeur du BIT, et David Morse (à droite), 5e Directeur général du BIT. Genève, Suisse, 1949. © BIT',
  tripartism_imagePhelanmorseAlt: 'Deux hommes sont debout et se regardent.',
  tripartism_imageAndeanCaption: 'Programme en faveur des indiens des Andes, Pérou. © BIT',
  tripartism_imageAndeanAlt: 'Des travailleurs indigènes et des experts de l’OIT sur un chantier à Chucuito, Pérou.',
  tripartism_imagePetitpierreCaption: 'Max Petitpierre, Président de la Confédération Helvétique, fait un discours pour la première Conférence régionale européenne avec David Morse, 5e Directeur général du BIT (à gauche), et Roberto Ago, Président du Conseil d’administration du BIT (à droite). © BIT',
  tripartism_imagePetitpierreAlt: 'Deux hommes sont assis derrière un bureau avec des micros. L’homme debout au centre parle au public.',
  tripartism_imageMorsenigeriaCaption: 'David Morse, 5e Directeur général du BIT, arrive à Lagos, au Nigéria, pour la première Conférence régionale africaine. © BIT',
  tripartism_imageMorsenigeriaAlt: 'David Morse et des délégués descendent de la passerelle de l’avion.',
  tripartism_imageMandelaCaption: 'L’OIT fut la première organisation internationale à imposer des sanctions à l’Afrique du Sud en raison de ses politiques d’apartheid. Plus de 30 ans après, lors de la 77e Conférence internationale du Travail, Nelson Mandela remercia l’OIT pour son soutien à la lutte contre l’apartheid. 1990. © BIT',
  tripartism_imageItcturinCaption: 'Session de formation au Centre international de formation de Turin, Italie. © BIT',
  tripartism_imageItcturinAlt: 'Vue d\'ensemble d\'une grande salle de réunion, où des dizaines de personnes sont assises autour de tables et écoutent un orateur qui est debout à l\'avant de la salle près d\'un grand écran.',
  tripartism_imageNobelprizeCaption: 'Le Directeur général  David Morse reçoit le prix Nobel de la paix au nom de l’OIT des mains d’Aase Lionaes, Présidente du Comité Nobel du Parlement norvégien, à l’université d’Oslo, le 10 décembre 1969. © BIT',
  tripartism_imageIlcseventysixCaption: 'Les délégués de la 61e session de la CIT dans le hall de l’assemblée de l’ONU à Genève, Suisse, 1976. © BIT',
  tripartism_imageIlcseventysixAlt: 'Des hommes et des femmes assis dans un grand hall d’assemblée.',
  tripartism_imagePosteroshCaption: 'Affiches sur la sécurité et la santé au travail en Mauritanie. © BIT',
  tripartism_imagePosteroshAlt: 'Deux personnes devant un tableau d’affichage qui indique «Accidents du travail».',
  tripartism_imageLechCaption: 'L’OIT apporte son soutien au syndicat indépendant Solidarnosc, dirigé par Lech Walesa, qui deviendra plus tard Président de la Pologne. © BIT',
  tripartism_imageIpecCaption: 'L’OIT crée le Programme international pour l\'abolition du travail des enfants (IPEC). © BIT / E. Gianotti',
  tripartism_imageIpecAlt: 'Une jeune fille avec un foulard est assise derrière un métier à tisser des tapis.',
  tripartism_imageAdoptfprwCaption: 'Adoption de la Déclaration de l’OIT sur les principes et droits fondamentaux au travail à la 86e session de la CIT à Genève, Suisse. © BIT',
  tripartism_imageAdoptfprwAlt: 'Le 8e Directeur général du BIT, Michel Hansenne, et d’autres délégués applaudissent au podium l’adoption de la Déclaration.',
  tripartism_imageZimbabweCaption: 'L’OIT lance l’Agenda du travail décent. Des chômeurs à Harare, Zimbabwe, cherchent du travail. 7 juin 2001. © AFP / Alexander Joe',
  tripartism_imageZimbabweAlt: 'Six hommes debout dans la rue près d’un poteau. Trois d’entre eux lisent un journal.',
  tripartism_imageWorldcomCaption: 'Commission mondiale sur la dimension sociale de la mondialisation. De gauche à droite, Benjamin William Mkapa, Président de Tanzanie, Tarja Halonen, Président de la République de Finlande et Juan Somavia, Directeur général du BIT. © BIT / M. Crozet',
  tripartism_imageWorldcomAlt: 'Trois personnes debout côte à côte devant une bannière, qui sourient.',
  tripartism_imageDeclarationsjCaption: 'Session d’ouverture de la 97e CIT en 2008 à Genève, en Suisse, durant laquelle la Déclaration sur la justice sociale pour une mondialisation équitable a  été adoptée. © BIT',
  tripartism_imageDeclarationsjAlt: 'Vue du hall de l’assemblée de l’ONU, rempli de délégués.',
  tripartism_imageSomaviajobsCaption: 'Discours de Juan Somavia, Directeur général du BIT, durant la session d’ouverture du Sommet de l’OIT sur la crise mondiale de l’emploi. Genève, Suisse, 15 juin 2009. © BIT',
  tripartism_imageSomaviajobsAlt: 'Un homme faisant un discours au podium dans le hall de l’assemblée de l’ONU avec le logo de l’OIT.',
  tripartism_imageBankimoonCaption: 'Ban Ki-moon, le huitième Secrétaire général de l’ONU, fait un discours à la CIT. © BIT',
  tripartism_imageBankimoonAlt: 'Un homme parle au podium. Derrière lui, il y a un drapeau avec le logo de l’OIT.',
  tripartism_imageRyderfowCaption: 'Guy Ryder, Directeur général du BIT, fait un discours pour le lancement de la Commission mondiale sur l’avenir du travail. Genève, Suisse, 21 août 2017. © BIT / M. Crozet',
  tripartism_imageRyderfowAlt: 'Un homme parle au podium sur lequel est écrit «Commission mondiale sur l’avenir du travail».',
  tripartism_imageAsiaofficeCaption: 'Photo de groupe du bureau régional de l\'OIT pour l’Asie et le Pacifique célébrant l’approche du centenaire de l’OIT. © OIT',
  tripartism_imageAsiaofficeAlt: 'Groupe d’hommes et de femmes qui portent tous un T-shirt bleu avec le logo de l’OIT. Les personnes du premier rang tiennent des pancartes avec le logo OIT 100.',
  rights_heading: 'Droits et normes du travail',
  rights_chapter: 'chapitre 2',
  rights_title: 'Vos droits au travail',
  rights_calloutRightsTitle: 'On peut tomber sur ce genre d’histoires dans n’importe quel pays du monde et leur point commun est qu’elles portent sur des violations de nos droits.',
  rights_calloutRightsBody: 'Le droit d’être un enfant et d’aller à l’école, le droit de s’affilier à un syndicat, le droit de créer une entreprise sans discrimination ou le droit de travailler en sécurité et en étant traité avec dignité – ces droits sont inscrits dans les normes internationales du travail.',
  rights_infoPathtodw: '<h3>Le chemin vers le travail décent</h3>\n\n<p>Le travail n’est pas une marchandise, un produit inanimé qui peut être négocié pour obtenir un profit maximum ou le prix le plus bas.</p>\n\n<p>Ce que nous appelons le travail décent est lié au respect de soi, au bien-être et au développement de la personne en tant qu’être humain. Les normes internationales du travail garantissent le droit de tendre vers le travail décent.</p>\n\n<p>Pour que le développement économique ait un sens, il doit inclure la création d’emplois et des conditions permettant aux personnes de travailler librement, de façon équitable, sûre et digne.</p>\n\n<p>Les normes internationales du travail sont là pour garantir que le principal objectif du développement soit d’améliorer le bien-être de tous.</p>',
  rights_infoLevelplayBody1: '<h3>Sur un pied d\'égalité</h3>\n\n<p>Pour atteindre l’objectif du travail décent dans une économie mondialisée, il faut agir au niveau international. C’est justement ce qu’a fait l’Organisation internationale du Travail depuis sa création grâce à un système d’instruments juridiques validés par les gouvernements, les employeurs et les travailleurs.</p>\n\n<p>Il peut s’agir de conventions qui sont des traités internationaux qui deviennent juridiquement contraignants une fois approuvés ou « ratifiés » par les gouvernements ou des recommandations, qui sont des directives non contraignantes.</p>\n\n<p>Les conventions et les recommandations de l’OIT établissent des niveaux minimaux de protection sur des thématiques spécifiques du travail, comme le travail des enfants, le travail forcé, l’égalité entre les femmes et les hommes, et bien d’autres sujets.</p>\n\n<p>Elles prennent en compte les nouvelles réalités et les défis rencontrés par les personnes qui travaillent dans un monde en mutation, et servent à créer des règles du jeu équitables pour que la concurrence entre les pays et les entreprises soit loyale.  Il est très probable qu’un grand nombre des lois de votre pays visant à garantir de bonnes conditions de travail sur votre lieu de travail aient été rédigées pour respecter les normes internationales du travail de l’OIT.</p>',
  rights_calloutDevstandards: 'L’élaboration des normes internationales du travail à l’OIT est un processus unique de coopération entre les gouvernements, les travailleurs et les employeurs du monde entier.',
  rights_infoRoadtolsBody1: '<h3>Le chemin pour aboutir à une norme du travail</h3>\n\n<p>Une fois qu’un problème a été identifié dans le monde du travail, le Conseil d’administration de l’OIT décide que la Conférence internationale du Travail discutera de cette question, à partir d’un rapport élaboré par le secrétariat et commenté par tous les Etats membres ainsi que les organisations de travailleurs et d’employeurs.</p>\n\n<p>A partir de cette discussion, le secrétariat de l\'OIT rédige une norme du travail qui fait l’objet de nouvelles discussions à la conférence suivante, est amendée le cas échéant, et proposée pour adoption. Il faut une majorité des deux tiers pour adopter une norme.</p>',
  rights_calloutSohowprobTitle: 'Comment identifie-t-on les problèmes qui se posent dans le monde du travail ?',
  rights_calloutSohowprobBody: 'Les gouvernements, les syndicats et les organisations d’employeurs, ainsi que la société civile, participent à ce processus d’identification des besoins de nouvelles normes internationales.',
  rights_infoGlobalmarchBody1: '<p>En 1998, une mobilisation citoyenne appelée La Marche mondiale contre le travail des enfants a rassemblé des ONG, des syndicats, des enseignants, et des enfants sous la direction de Kailash Satyarthi, pour exiger de mettre fin au travail des enfants.</p> \n\n<p>Ils ont traversé l’Afrique, l’Asie, l’Amérique du Nord, l’Amérique du Sud et l’Europe en marchant, pour aller à la Conférence internationale du Travail à Genève, leur destination finale.</p>\n\n<p>Cette marche était importante car à ce moment-là, l’OIT discutait d’une nouvelle convention éventuelle contre les pires formes de travail des enfants.</p>\n\n<p>L’impact de ces enfants en marche a été si fort qu’elle a contribué à ce que cette convention soit la plus rapidement ratifiée dans l’histoire de l’OIT.</p>',
  rights_calloutUniversalvalues: 'Nos valeurs universelles',
  rights_infoGbfourissues: '<p>Le Conseil d’administration de l’OIT a identifié quatre problèmes considérés comme des principes et droits fondamentaux sur le lieu de travail. Ils sont protégés par huit conventions « fondamentales » associées à des recommandations et des protocoles.</p>',
  rights_listFprw1: 'La liberté syndicale et le droit à la négociation collective',
  rights_listFprw2: 'L’élimination de toutes les formes de travail forcé',
  rights_listFprw3: 'L’abolition effective du travail des enfants',
  rights_listFprw4: 'L’élimination des discriminations vis-à-vis de l’emploi',
  rights_infoBasisfprw: '<p>Ces principes forment le socle de la Déclaration de l’OIT relative aux principes et droits fondamentaux au travail. Adoptée en 1998, cette déclaration engage les états membres à respecter et à promouvoir les principes et les droits appartenant à ces quatre catégories, qu’ils aient ou non ratifié les conventions qui s’y rapportent.</p>',
  rights_visConventionsTitle: 'NOS CONVENTIONS FONDAMENTALES',
  rights_infoSupervisoryBody: '<h3>Le système de contrôle de l’OIT</h3>\n\n<p>Les normes internationales du travail ne sont efficaces que si elles sont appliquées. Pour y parvenir, les conventions de l’OIT s’appuient sur un <mark id="rights_infoSupervisoryBody">système de contrôle unique au niveau international</mark>, qui permet de garantir que les pays appliquent les conventions qu’ils ratifient.</p>',
  rights_calloutYourrights: 'VOS DROITS au TRAVAIL',
  rights_infoAdoptionils: '<p>L’OIT, en adoptant des normes internationales du travail, a répondu par le passé et continue à répondre aujourd’hui à un nombre croissant de défis rencontrés par les travailleurs, les employeurs et les gouvernements dans l’économie mondialisée.</p>',
  rights_calloutManyideas: 'De nombreuses idées considérées maintenant comme une évidence - de l’égalité des chances à l’absence de discrimination sur le lieu de travail - ont d’abord fait l’objet de discussions au niveau international par l’OIT',
  rights_imageChildmechanicCaption: 'Un enfant mécanicien, La Paz, Bolivie. © OIT/M. Crozet',
  rights_imageDomesticCaption: 'Une travailleuse domestique crible le riz, Bengal, Inde. © OIT/S.J. Mohammad',
  rights_imageDomesticAlt: 'Une femme avec des habits colorés crible le riz par terre avec un bâton.',
  rights_imageChildmechanicAlt: 'Une photo à contre-jour d’un enfant tenant des outils pour réparer quelque chose sous une voiture. La voiture est montée sur un cric.',
  rights_imageIlc1998Caption: 'Conférence internationale du Travail, Genève, Suisse, 1998. © OIT',
  rights_imageIlc1998Alt: 'Photo fisheye du hall de l’assemblée de l’ONU rempli de délégués pour la CIT.',
  rights_imageGlobalmarchCaption: 'La marche mondiale contre le travail des enfants arrive au Palais des Nations, Genève, Suisse, 1998. © OIT',
  rights_imageAdoptfprwCaption: 'La Déclaration de l’OIT sur les principes et droits fondamentaux au travail est adoptée à la 86e session de la Conférence internationale du Travail en 1998. © OIT',
  rights_imageAdoptfprwAlt: 'Le 8e Directeur général, Michel Hansenne et d’autres délégués applaudissent au podium l’adoption de la déclaration.',
  rights_imageC182Caption: 'Enfant travaillant sur un port. © OIT/P. Hien',
  rights_imageChilddockAlt: 'Un garçon avec une douzaine de briques sur le dos marche sur une passerelle en bois reliant le petit bateau au port.',
  rights_imageC105Caption: 'Des travailleurs esclaves sauvés à Pará, Brésil, font la queue pour être identifiés. © OIT/J. Ripper',
  rights_imageRescuedAlt: 'Une main tient des petites photos des hommes qui ont été sauvés. Derrière, des hommes font la queue.',
  rights_imageC29Caption: 'Des travailleurs dans une briquèterie, Népal. © Lisa Kristine/Human Thread Foundation',
  rights_imageBrickyardAlt: 'Un travailleur avec quatre briques empilées sur la tête en ajoute deux de plus. Les travailleurs sont couverts de poussière orange provenant des briques.',
  rights_imageC111Caption: 'Des travailleuses agricoles Sindh font une manifestation pour leurs droits pour la Journée internationale de la femme au Pakistan. © OIT',
  rights_imageSindhAlt: 'Des femmes habillées de vêtements colorés sont assises sous une grande tente et tiennent des pancartes indiquant “Journée de la femme, zéro  discrimination”.',
  rights_imageC100Caption: 'Des femmes demandant l’égalité de rémunération pour la manifestation du 1er  mai des travailleurs, 1er  Mai, Paris. 2001 © AFP/M. Medina/AFP',
  rights_imageEqualpayAlt: 'Des femmes marchent dans les rues avec une grande bannière sur laquelle est écrit : “Egalité des salaires femmes/hommes maintenant”.',
  rights_imageC138Caption: 'Une enfant portant un gros fagot de branches sur sa tête. © OIT/A. Khan',
  rights_imageC98Caption: 'Des travailleurs et des étudiants manifestent contre la réforme des retraites. Annecy, France. © OIT/M. Crozet',
  rights_imagePensionreformAlt: 'Des gens marchent dans les rues. Deux hommes vêtus de gilets bleus portent des drapeaux bleus de la CFTC (Confédération française des travailleurs chrétiens).',
  rights_imageC87Caption: 'Réunion syndicale au Japon. © OIT',
  rights_imageUnionmeetAlt: 'Un homme avec le bras gauche levé est debout face à un groupe d’hommes assis sur des gradins.',
  rights_imageCasCaption: 'Commission de l’application de normes. 106e Session de la Conférence  internationale du Travail. Genève, Juin 2017. © OIT',
  rights_imageCasAlt: 'Une salle de conférence remplie de délégués. Des écrans montrent que la réunion porte sur la Commission de l’application des normes.',
  rights_imageEbelingCaption: 'L.E. Ebeling présente l’avis des employeurs des Etats-Unis sur la question de l’égalité de rémunération pour les femmes, à la 33e conférence générale de l’OIT en 1950. Cette discussion a abouti à l’adoption de la Convention sur l’égalité de rémunération en 1951. © OIT',
  rights_imageEbelingAlt: 'Une femme au visage austère est debout avec son bloc-notes et s’adresse au public.',
  rights_detailSupervisory1: '<h3>Comment l’OIT supervise l’application des normes</h3>\n\n<p>L’OIT a développé plusieurs moyens de superviser l’application des conventions et des recommandations.</p>\n\n<p>En cas de difficultés pour l’application des normes, l’OIT peut aider les pays à remédier à ses lacunes, notamment en fournissant une assistance technique.</p>',
  rights_detailSupervisory2Title: 'La Commission d’experts pour l’application des conventions et recommandations',
  rights_detailSupervisory2: '<h3>La Commission d’experts pour l’application des conventions et recommandations</h3>\n\n<p>Lorsqu’un pays a ratifié une convention, il est obligé de rendre régulièrement un rapport sur les mesures qu’il a prises pour mettre en œuvre cette convention. Les gouvernements doivent présenter leurs rapports aux organisations d’employeurs et de travailleurs nationales les plus représentatives, pour qu’elles apportent leurs commentaires.</p>',
  rights_detailSupervisory3Title: 'La Commission de l’application des normes de la Conférence',
  rights_detailSupervisory3: '<p>La Commission d’experts a été établie en 1926 pour apporter une évaluation technique impartiale de l’état de l’application des normes internationales du travail dans les États Membres de l’OIT.</p>\n\n<p>Elle est aujourd’hui composée de 20 juristes éminents nommés par le Conseil d’administration pour des mandats de trois ans. Ces experts viennent de différentes régions géographiques, différents systèmes juridiques et différentes cultures.</p>',
  rights_ilsShortNameC29: 'Travail forcé',
  rights_ilsShortNameC182: 'Les pires formes de travail des enfants',
  rights_ilsShortNameC105: 'Abolition du travail forcé',
  rights_ilsShortNameC111: 'Discrimination',
  rights_ilsShortNameC100: 'Egalité de rémunération',
  rights_ilsShortNameC138: 'Age minimum',
  rights_ilsShortNameC98: 'Organisation et négociation collective',
  rights_ilsShortNameC87: 'Liberté syndicale',
  rights_ilsBlurbC29: 'Les États Membres s’engagent à supprimer l’emploi du travail forcé ou obligatoire dans le plus bref délai possible, y compris la traite des êtres humains, et à assurer aux victimes une réparation efficace.',
  rights_ilsBlurbC182: 'Les États Membres s’engagent à prendre des mesures immédiates et efficaces pour éliminer les pires formes de travail des enfants, notamment toutes les formes d’esclavage comme la vente et la traite d’enfants; le recrutement d’enfants pour les utiliser dans des conflits armés; la prostitution des enfants; le recrutement des enfants pour des activités illicites, notamment la production et le trafic de stupéfiants; et les travaux susceptibles de nuire à la santé, la sécurité ou la moralité de l’enfant.',
  rights_ilsBlurbC105: 'Les États Membres s’engagent à supprimer toute forme de travail forcé ou obligatoire en tant que mesure de coercition politique ou de sanction à l’égard de personnes qui ont ou expriment leurs opinions politiques ; en tant que méthode de mobilisation et d’utilisation de la main-d’œuvre à des fins de développement économique ; en tant que mesure de discipline du travail ; en tant que punition pour avoir participé à des grèves ; ou en tant que mesure de discrimination raciale, sociale, nationale ou religieuse.',
  rights_ilsBlurbC111: 'Les États Membres s’engagent à appliquer une politique nationale visant à promouvoir l’égalité des chances et de traitement en matière d’emploi et de profession, afin d’éliminer toute discrimination fondée sur la race, la couleur, le sexe, la religion, l’opinion politique, l’ascendance nationale ou l’origine sociale, qui a pour effet de détruire ou d’altérer l’égalité des chances ou de traitement en matière d’emploi ou de profession.',
  rights_ilsBlurbC100: 'Tous les travailleurs seront rémunérés selon le principe de l’égalité de rémunération entre les hommes et les femmes pour un travail de valeur égale.',
  rights_ilsBlurbC138: 'Les États Membres s’engagent à assurer l’abolition effective du travail des enfants et à élever l’âge minimum d’admission à l’emploi à un niveau permettant aux adolescents d’atteindre le plus complet développement physique et mental, et conforme à l’âge auquel cesse la scolarité obligatoire. L’âge minimum d’admission à tout type d’emploi susceptible de compromettre la santé, la sécurité ou la moralité des adolescents (appelé travail dangereux) ne devra pas être inférieur à 18 ans.',
  rights_ilsBlurbC98: 'Les travailleurs ont droit à une protection contre les actes de discrimination antisyndicale en matière d’emploi.  Les négociations volontaires entre les employeurs ou les organisations d’employeurs et les syndicats en vue de régler les conditions d’emploi par des conventions collectives doivent être encouragées.',
  rights_ilsBlurbC87: 'Les travailleurs et les employeurs, sans distinction d’aucune sorte, ont le droit, sans autorisation préalable, de constituer des organisations de leur choix, ainsi que de s’affilier à ces organisations. Les États Membres s’engagent à prendre toutes les mesures nécessaires et appropriées en vue d’assurer aux travailleurs et aux employeurs le libre exercice du droit syndical.',
  rights_conventionsCountries: 'pays',
  rights_visConventionsSee: 'Voir les ratifications par pays',
  rights_visConventionsUpdate: 'Dernière actualisation:',
  rights_visConventionsSelect: 'Rechercher un pays',
  rights_visConventionsCountries: 'pays',
  rights_visConventionsRatified: 'Les pays qui l’ont ratifiée',
  rights_visConventionsNotRatified: 'Les pays qui ne l’ont pas encore ratifiée',
  rights_visConventionsRatifs: 'ont ratifié cette convention',
  rights_date: 'Découvrez ce chapitre le 16 janvier 2019',
  rights_visLatestUpdate: '',
  rights_visConventionsFundratif: 'conventions fondamentales ratifiées',
  rights_calloutManyIdeasSub: '... et à la suite de cela, sont devenues maintenant une réalité pour des millions de femmes et d’hommes.',
  rights_calloutConclusion2: 'En établissant de nouveaux droits et protections pour les travailleurs et les employeurs dans un environnement en mutation, l’OIT poursuit sa lutte pour un monde où règnent l’égalité et l’absence de discrimination.',
  rights_visConventionsC182: 'Convention 182',
  rights_visConventionsC29: 'Convention 29',
  rights_visConventionsP29: 'Protocole 29',
  rights_visConventionsC105: 'Convention 105',
  rights_visConventionsC111: 'Convention 111',
  rights_visConventionsC100: 'Convention 100',
  rights_visConventionsC138: 'Convention 138',
  rights_visConventionsC98: 'Convention 98',
  rights_visConventionsC87: 'Convention 87',
  rights_calloutConclusion1: 'La mission essentielle de l’OIT, qui est de s’efforcer d’assurer à tous un avenir meilleur dans le monde du travail, exige de comprendre et d’anticiper les facteurs qui contribuent aux changements affectant le monde du travail.',
  rights_infoSuperTest: '',
  rights_detailSupervisory4: '<h3>La Commission de l’application des normes de la Conférence</h3>\n\n<p>Le rapport annuel de la Commission d’experts est présenté à la Conférence internationale du Travail, où il est examiné par la Commission de l’application des normes de la Conférence.</p>\n\n<p>Cette commission est composée de délégués des gouvernements, des employeurs et des travailleurs, qui sélectionnent un certain nombre de cas pour discussion, à partir du rapport de la Commission d’experts.</p>',
  rights_detailSupervisory5: '<p>Les gouvernements qui figurent sur cette liste doivent répondre devant la Commission de la Conférence et fournir des informations sur la situation en cause.</p>\n\n<p>Très souvent, la Commission de la conférence rédige des recommandations adressées aux gouvernements afin qu’ils prennent des mesures spécifiques pour résoudre le problème, et propose l’aide de l’OIT aux gouvernements pour remédier à ces problèmes.</p>\n\n<p>C’est souvent par le biais de l’assistance technique, habituellement fournie par l’OIT pendant plusieurs années, que les violations des droits du travail prennent fin et que les travailleurs peuvent bénéficier de leurs droits et protections fondamentaux.</p>',
  employment_heading: 'L\'emploi',
  employment_chapter: 'chapitre 4',
  employment_title: 'Pas n’importe quel travail',
  employment_infoEmppromotBody: '<p>La promotion de l’emploi est au cœur du mandat de l’OIT. C’est l’un des piliers de l’Agenda du travail décent.</p>\n\n<p>La promotion de la création du plein emploi productif fait partie intégrante du travail de l’OIT depuis ses débuts. Car les emplois décents – et pas n’importe quels emplois – sont essentiels pour parvenir à la paix, la justice sociale, l\'inclusion sociale, le développement économique et l’épanouissement personnel.</p>',
  employment_quoteSantosBody: '«La justice sociale s’obtient par plus d’emplois, moins de pauvreté et moins d’inégalités. Le travail décent en est un pilier, et les politiques du travail ainsi qu’un développement économique plus équitable sont essentiels pour y parvenir.»',
  employment_quoteSantosCaption: 'Juan Manuel Santos, prix Nobel de la paix et ancien président de Colombie, à la 107e Conférence internationale du Travail, 2018.',
  employment_infoEndpovertyTitle: 'La fin de la pauvreté?',
  employment_infoEndpoverty: '<h3>La fin de la pauvreté ?</h3>\n\n<p>La pauvreté est la cause principale de nombreux problèmes auxquels le monde est confronté aujourd’hui. Les inégalités de revenus, le chômage et les privations peuvent susciter une aliénation qui contribue à l’instabilité, aux conflits et à l’extrémisme violent.</p>\n\n<p>A l’inverse, améliorer l’accès à des emplois de qualité permet d’accroître les revenus et de renforcer la cohésion sociale et l’équité dans les sociétés. Il est crucial d’avoir des emplois plus nombreux et de meilleure qualité pour créer un monde dans lequel nous aimerions tous vivre.</p>',
  employment_infoEndpovertyBody2: 'A l’inverse, améliorer l’accès à des emplois de qualité permet d’accroître les revenus et de renforcer la cohésion sociale et l’équité dans les sociétés. Il est crucial d’avoir des emplois plus nombreux et de meilleure qualité pour créer un monde dans lequel nous aimerions tous vivre.',
  employment_calloutWhatstandsTitle: 'Qu’est-ce qui empêche les gens d’avoir des emplois décents ?',
  employment_calloutWhatstandsBody: 'Explorez certains des défis rencontrés dans le monde par les femmes et les hommes à la recherche d\'emplois plus nombreux et de meilleure qualité.',
  employment_detailInequalityTitle: 'Les inégalités',
  employment_detailInequalityBody1: '<h3>Les inégalités</h3>\n\n<p>Les inégalités sur le marché du travail aboutissent à la pauvreté.  Il y a injustice lorsque les gens n’ont pas accès à l\'emploi ou n’ont pas les compétences requises pour en trouver un, et lorsque leurs droits au travail sont si peu protégés qu’ils ne gagnent pas suffisamment pour se nourrir ou nourrir leur famille. </p>\n\n<p>S\'ils arrivent à surmonter les inégalités qui mènent à l\'injustice, les gens peuvent utiliser leur potentiel pour gagner leur vie et sortir de la pauvreté. L’OIT aide les pays à surmonter les inégalités sur les marchés du travail et à trouver des moyens durables pour sortir de la pauvreté, notamment grâce à des programmes de subvention salariale.</p>',
  employment_detailInequalityBody2: '<p>Dans la Fédération de Russie, un partenariat public-privé unique avec l’entreprise russe LUKOIL fournit des subventions salariales aux employeurs locaux pour aider les jeunes à trouver un emploi.</p>\n\n<p>Subventionner les salaires des jeunes travailleurs donne à ces derniers de nouvelles opportunités et une expérience professionnelle, et montre aux employeurs que les jeunes travailleurs sont un atout intéressant pour leurs entreprises.</p>',
  employment_detailConflictTitle: 'Les conflits et les catastrophes',
  employment_detailConflictBody1: '<h3>Les conflits et les catastrophes</h3>\n\n<p>Près de 1,5 milliard de personnes vivent dans des situations de vulnérabilité et de violence armée, et 200 millions ont été victimes de catastrophes.  Les conflits et les catastrophes compromettent les moyens de subsistance et créent une pénurie d’emplois décents. Cela peut se traduire par une augmentation de la pauvreté, des inégalités et des troubles sociaux. </p>\n\n<p>L’OIT soutient des programmes permettant de créer des emplois décents pour les personnes touchées par un conflit ou une catastrophe. C’est un moyen de renforcer leur résilience et d’aider les pays à avancer vers la paix et la stabilité. </p>',
  employment_detailConflictBody2: '<p>En Colombie, le processus de paix a mis fin à un demi-siècle de conflit armé et l’OIT aide les communautés locales à développer le secteur du tourisme pour créer des emplois pour les plus vulnérables de la société: les jeunes et les femmes.</p>\n\n<p>En assurant un revenu aux familles des zones rurales, l’une des causes profondes du conflit – l’absence d’opportunités de travail et de croissance – cède la place à une paix stable et durable. </p>',
  employment_detailYouthempTitle: 'L\'emploi des jeunes',
  employment_detailYouthempBody1: '<h3>L\'emploi des jeunes</h3>\n\n<p>Selon les statistiques de l’OIT, 66 millions de jeunes n’ont pas d’emploi et plus de 145 millions de jeunes travailleurs vivent dans la pauvreté, malgré les progrès de l’éducation et les avantages qui en découlent ces dernières décennies.</p>\n\n<p>Nous sommes face à un avenir dans lequel les jeunes ont peu de possibilités de trouver un emploi décent, en dépit de leurs compétences et de leur volonté de travailler. </p>',
  employment_detailYouthempBody2: '<p>Investir dans l’emploi des jeunes signifie travailler ensemble pour que la création d’emplois devienne une priorité. Parallèlement, nous devons aider les jeunes à surmonter les obstacles qui leur sont spécifiques, comme la discrimination et le manque de compétences.</p>\n\n<p>L’OIT aide les pays à concevoir, mettre en œuvre et évaluer des politiques et des programmes favorisant l’emploi des jeunes pour que ces derniers aient plus de chances d’avoir un emploi décent.</p>',
  employment_detailRuralexodusTitle: 'L\'exode rural',
  employment_detailRuralexBody1: '<h3>L\'exode rural</h3>\n\n<p>Les prévisions montrent que 68% de la population mondiale vivra dans des zones urbaines en 2050, alors que des millions de personnes se déplacent à la recherche d\'un emploi. Cela risque  de laisser moins d’emplois et d’opportunités aux populations rurales.</p>\n\t\n<p>La promotion du développement des compétences et de l’esprit d’entreprise chez les femmes et les jeunes en milieu rural est un des moyens de leur garantir l\'accès à des emplois décents.</p>\n\n<p>L’OIT œuvre à la promotion des compétences dans le secteur rural et au sein des petites et moyennes entreprises.</p>',
  employment_detailRuralexBody2: '<p>Au Kenya, en République-Unie de Tanzanie et en Ouganda, plus de 2 900 femmes entrepreneurs ont été formées pour lancer leur propre entreprise. Les femmes ont non seulement déclaré que la formation avait renforcé leur confiance en elles-mêmes, mais une enquête de suivi a montré qu’une femme formée sur deux avait créé une nouvelle entreprise.</p>\n\n<p>Grâce au soutien de l’OIT, 6 300 femmes et sept coopératives de femmes en Ethiopie ont bénéficié d’une formation sur le crédit et la gestion d’entreprise, ce qui a considérablement augmenté leurs revenus et créé de nouveaux emplois.</p>',
  employment_detailInformalTitle: 'Le travail informel',
  employment_detailInformalBody1: '<h3>Le travail informel</h3>\n\n<p>L’économie informelle emploie plus de la moitié des travailleurs dans le monde. Cela signifie qu\'ils occupent des emplois occasionnels non protégés par la législation du travail. Ils n’ont aucun droit, ni voix et pas suffisamment de revenus pour échapper à la pauvreté.</p>\n\n<p>Les entreprises informelles ont généralement des taux de productivité faibles et n’offrent pas de travail décent. Cela entrave la croissance de ce genre d’entreprises et signifie qu’elles n’ont pas accès aux ressources généralement disponibles.</p>',
  employment_detailInformalBody2: '<p>L\'OIT soutient les travailleurs et les entreprises en leur fournissant les connaissances et les compétences nécessaires pour les aider à passer de l\'économie informelle à l\'économie formelle.</p>\n\n<p>Au Népal, après la catastrophe du tremblement de terre de 2015, l’OIT a contribué à la formation de guides touristiques et d’autres petites entreprises nécessaires à la reconstruction de l’industrie du tourisme.</p>\n\n<p>L’OIT a aidé les employeurs à fournir de meilleurs services à leurs clients tout en offrant également de meilleurs emplois. </p>',
  employment_detailMigrationTitle: 'Les migrations de main-d’œuvre',
  employment_detailMigrationBody1: '<h3>Les migrations de main-d’œuvre</h3>\n\n<p>D’après les dernières estimations de l’OIT, le nombre de travailleurs migrants dans le monde s\'élève à 150 millions sur 232 millions de migrants internationaux.</p>\n\n<p>Ils contribuent au développement économique, social et culturel de leur pays d’accueil. L’argent qu’ils envoient à leur famille et les compétences qu’ils acquièrent à l’étranger sont très précieuses pour leurs communautés et leur pays d’origine. Mais très souvent, leur contribution n’est pas reconnue. </p>',
  employment_detailMigrationBody2: '<p>En fait, de nombreux migrants sont vulnérables à l’exploitation et aux abus. Parmi les 21 millions de personnes actuellement réduites au travail forcé, 44% ont été piégées ou victimes de traite après avoir migré à l’intérieur ou à l’extérieur de leur pays à la recherche d’un travail. </p>\n\n<p>L’OIT aide à élaborer des politiques visant à optimiser les avantages des migrations de main-d’œuvre pour les migrants et pour les communautés qui les accueillent.</p>',
  employment_calloutDecentjobsTitle: 'Des emplois décents pour tous',
  employment_calloutDecentjobsBody: 'L’OIT a mené des décennies de recherches et de projets d’assistance dans plusieurs pays du monde, qui ont abouti à la mise en place de politiques de promotion et de création d’emplois décents.',
  employment_infoRightskillsTitle: 'Les bonnes compétences',
  employment_infoRightskills: '<h3>Les bonnes compétences</h3>\n\n<p>Alors que le monde du travail connaît des transformations rapides liées aux technologies, à la nature de la production, à l’emploi et au changement climatique, l’efficacité de la formation professionnelle est plus indispensable que jamais.</p>\n\n<p>L\'OIT est à l\'avant-garde des politiques et des systèmes de développement des compétences qui anticipent et forment à des compétences pour les emplois du futur.</p>',
  employment_infoRightskillsBody2: 'Il est important de souligner que l’OIT favorise l’inclusion des groupes défavorisés dans la société par le biais du développement de leurs compétences.',
  employment_infoInvestmentsTitle: 'Des investissements qui créent des emplois',
  employment_infoInvestments: '<h3>Des investissements qui créent des emplois</h3>\n\n<p>Des millions de personnes vivent dans des régions qui manquent d’infrastructures essentielles telles que les routes, les ponts, l’approvisionnement en eau, l’électricité ou l’Internet. Beaucoup n’ont pas accès aux services de base comme l’eau, la santé et l’éducation. </p>\n\n<p>Investir dans des infrastructures peut considérablement améliorer le niveau de vie et avoir un impact direct sur la qualité de vie des habitants. </p>\n\n<p>En utilisant la main-d’œuvre et les ressources locales pour construire les infrastructures indispensables, et en impliquant les communautés dans la planification, des emplois sont créés, les coûts sont réduits et l’industrie locale est favorisée. Les institutions locales sont renforcées et deviennent plus efficaces.  </p>\n\n<p>Cela a pour effet de réduire la pauvreté rurale et urbaine et d’améliorer le bien-être économique et social des personnes et des communautés.</p>',
  employment_infoInvestmentsBody2: 'Cela a pour effet de réduire la pauvreté rurale et urbaine et d’améliorer le bien-être économique et social des personnes et des communautés.',
  employment_infoStronginstTitle: 'Des institutions fortes',
  employment_infoStronginstBody: '<h3>Des institutions fortes</h3>\n\n<p>Dans un monde globalisé et de plus en plus compétitif, les marchés du travail fonctionnent mieux lorsque les institutions qui les régissent créent un environnement assurant aux travailleurs un revenu et une protection sociale et du travail, tout en donnant aux entreprises l’espace nécessaire pour prospérer. </p>\n\n<p>Le bon fonctionnement des marchés du travail nécessite des cadres politiques et des institutions solides.</p>',
  employment_calloutThisiswhy: 'Voilà pourquoi l’OIT aide les pays à mettre en place les institutions pertinentes et à élaborer et mettre en œuvre des politiques grâce au dialogue entre les représentants du gouvernement, des employeurs et des travailleurs.',
  employment_infoNepalBody1: '<p>Au Népal, par exemple, le programme d’information sur le marché du travail et des services d\'emploi de l’OIT (LIfE) aide les services publics de l’emploi du pays, qui jouent un rôle de plus en plus important dans le placement des travailleurs, à améliorer leur employabilité, remédier à l’inadéquation des compétences et améliorer les liens entre employeurs et travailleurs.</p>\n\n<p>Un des principaux avantages du programme est que les participants développent une expertise non seulement en acquérant des compétences techniques, personnelles et sociales, mais aussi grâce à la socialisation sur le lieu de travail. Pour les employeurs, la formation sur le lieu de travail permet également d’augmenter la productivité en remédiant aux  manques de compétences.</p>',
  employment_infoNepalBody2: 'Un des principaux avantages du programme est que les participants développent une expertise non seulement en acquérant des compétences techniques, personnelles et sociales, mais aussi grâce à la socialisation sur le lieu de travail. Pour les employeurs, la formation sur le lieu de travail permet également d’augmenter la productivité en remédiant aux  manques de compétences.',
  employment_calloutChallengesdwTitle: 'Les défis du travail décent sont complexes, les solutions le sont aussi.',
  employment_calloutChallengesdwBody: 'Avec ses 100 ans d’expérience, l’OIT est en position de leader pour créer un avenir du travail fondé sur les emplois décents.',
  employment_imageLocalyouthsCaption: 'Jeunes dans un atelier de formation à Madagascar. Un projet soutenu par l\'OIT a permis de lutter contre la violence urbaine en permettant aux jeunes d’apprendre un métier et d’abandonner une vie criminelle. © OIT Madagascar',
  employment_imageLocalyouthsAlt: 'Trois hommes et une femme en bleus de travail avec des casques jaunes, debout autour d’une table en bois sur laquelle une boite à outils est ouverte.',
  employment_imageWastepickCaption: 'Ramasseurs de déchets dans une décharge à Manille, Philippines. Les 3 000 personnes qui trient les ordures à Quezon sont maintenant syndiquées et représentent un modèle de gestion des déchets, recyclant près de 40% des déchets ménagers. © OIT/M. Rimando',
  employment_imageWastepickAlt: 'Un grand nombre d’hommes, de femmes et d’enfants ramassent des déchets sur une décharge.',
  employment_imageSlumsCaption: 'Bidonvilles à West Point, Monrovia, Libéria. Environ 75 000 personnes y vivent mais elles n’ont accès qu’à quatre toilettes publiques. La tuberculose et d\'autres maladies sont endémiques. © UNMIL/A.G. Farran',
  employment_imageSlumsAlt: 'Vue aérienne de maisons basses avec des toits en tôles ondulées rouillées près du rivage.',
  employment_imageTaclobanCaption: 'Ville de Tacloban aux Philippines après le super typhon Haiyan en novembre 2013. Environ 90% de la ville a été détruite. Les survivants ont qualifié la dévastation d’apocalyptique. © OIT/M. Crozet',
  employment_imageTaclobanAlt: 'Un avion vole au-dessus d’une zone pleine de décombres, de voitures détruites et d’arbres tombés.',
  employment_imageMigrantaddisCaption: 'Un travailleur migrant revenu au pays discute des opportunités d’emploi avec un employeur potentiel lors d\'une foire de l’emploi organisée par l’OIT, Addis Abeba, Ethiopie. © OIT/M. Tadesse',
  employment_imageMigrantaddisAlt: 'Deux hommes debout discutent au-dessus d’un bureau sur lequel il y a un ordinateur et des brochures.',
  employment_imageFarmersCaption: 'Agricultrices à Madagascar. L\'agriculture emploie la majorité de la population malgache, qui dispose d\'un équipement limité et d\'infrastructures médiocres et qui produit à peine de quoi nourrir sa famille. © OIT Madagascar',
  employment_imageFarmersAlt: 'Deux agricultrices se penchent pour ramasser des plants d’un champ de riz paddy.',
  employment_imageHandicraftCaption: 'Artisane basée à Katmandou (Népal), où l\'OIT soutient les femmes chefs d\'entreprise en partageant des pratiques commerciales qui renforcent leur sécurité et celle de leur famille, et qui modifient les idées reçues sur le genre et le rôle des femmes dans la société. © OIT/M. Crozet',
  employment_imageHandicraftAlt: 'Une femme avec un sac jaune à l’épaule tient dans ses mains une poupée en forme de paon bleu et vert.',
  employment_imageMigrantdatesCaption: 'Travailleur migrant récoltant des dattes, Bahrein. La proportion de migrants par rapport aux travailleurs locaux à Bahreïn est parmi les plus élevées au monde. © OIT',
  employment_imageMigrantdatesAlt: 'Un homme debout dans les branches ramasse des dattes rouges d’une branche qui en est pleine.',
  employment_imageApprenticeCaption: 'Asia a trouvé un emploi dans un hôtel à Zanzibar, en Tanzanie, après avoir suivi un cours de formation professionnelle de l\'OIT dans le secteur de l\'hôtellerie. © OIT Tanzanie',
  employment_imageApprenticeAlt: 'Une femme portant un hijab blanc regarde un écran d’ordinateur au bureau de réception.',
  employment_imageConstructionCaption: 'Travailleurs sur un chantier à La Paz, Bolivie. Selon l\'OCDE, l\'industrie de la construction emploie environ 7% de la main-d\'œuvre mondiale et devrait représenter 13% du PIB d\'ici 2020. © OIT',
  employment_imageConstructionAlt: 'Trois travailleurs du bâtiment sur un chantier avec des casques et des lunettes de protection.',
  employment_imageTraineesCaption: 'Stagiaires pour l’apprentissage en milieu de travail du programme LIfE de l’OIT au Népal. © Fédération des associations d\'artisanat du Népal (FHAN)',
  employment_imageTraineesAlt: 'Une femme avec un sac jaune à l’épaule tient dans ses mains une poupée en forme de paon bleu et vert.',
  employment_date: 'Découvrez ce chapitre le 6 février 2019',
  employment_detailConflictBody3: '<p>En fait, l’accord final de paix fait spécifiquement référence aux normes internationales du travail de l’OIT en tant que moyen de garantir le travail décent et les droits des travailleurs dans les zones rurales, dont l’absence était l\'une des principales causes des troubles.</p>',
  employment_detailMigrationBody3: '<p>En Jordanie, au Népal, aux Philippines et en Tunisie, l’OIT travaille en étroite collaboration avec les autres agences de l’ONU à la prévention de l’exploitation des travailleurs migrants en leur donnant accès à des informations fiables.</p>\n\n<p>Tout aussi important, l’OIT diffuse des informations sur le recrutement équitable par le biais des médias locaux et internationaux afin de sensibiliser les travailleurs migrants et les employeurs potentiels.</p>',
  protection_heading: 'Protection sociale',
  protection_chapter: 'chapitre 5',
  protection_title: 'Vous n’êtes pas seul',
  protection_calloutWhathappensTitle: 'Que se passe-t-il quand vous êtes malade, handicapé, enceinte ou âgé et que nous n’avez pas suffisamment de revenus ou pas de revenus du tout parce que vous ne pouvez pas travailler?',
  protection_calloutWhathappensBody: 'Comment pouvons-nous protéger les individus et leurs familles de ces risques et de la pauvreté tout au long de leur vie?',
  protection_infoFacesofspBody: '<h3>Les différentes facettes de la protection sociale</h3>\n\n<p>La protection sociale fait partie des droits de l’homme. Elle garantit à tous l’accès aux soins de santé et à la sécurité du revenu. Elle vous protège ainsi que votre famille en cas de maladie, de chômage, de blessure, lors de votre grossesse ou si vous êtes trop âgé pour travailler. Elle fournit une aide à votre famille s’il vous arrive quelque chose. </p>\n\n<p>La protection sociale vous donne la tranquillité d’esprit et l’espoir d’un avenir meilleur.</p>',
  protection_calloutEveryoneright: 'Tout le monde a droit à la sécurité sociale',
  protection_quoteEveryonerightBody: '«Toute personne a droit à un niveau de vie suffisant pour assurer sa santé, son bien-être et ceux de sa famille, notamment pour l’alimentation, l’habillement, le logement, les soins médicaux ainsi que pour les services sociaux nécessaires; elle a droit à la sécurité en cas de chômage, de maladie, d’invalidité, de veuvage, de vieillesse ou dans les autres cas de perte de ses moyens de subsistance par suite de circonstances indépendantes de sa volonté.»',
  protection_quoteEveryonerightCaption: 'Déclaration universelle des droits de l’homme, article 25(1).',
  protection_infoYourspBody: '<h3>Votre protection sociale</h3>\n\n<p> La protection sociale est un ensemble de politiques et de programmes visant à réduire et prévenir la pauvreté et la vulnérabilité durant votre vie. La protection sociale vous permet d’accéder à : </p>\n\n<ul>\n<li> Des prestations aux familles et à l’enfance</li>\n<li> La protection de la maternité</li>\n<li> Des prestations de chômage</li>\n<li> Des prestations en cas d’accident du travail ou de maladie professionnelle</li>\n<li> Des indemnités de maladie</li>\n<li> La protection de la santé</li>\n<li> Des pensions de vieillesse</li>\n<li> Des pensions d’invalidité</li>\n<li> Des pensions de survivants</li>\n</ul>',
  protection_calloutWhyneedsp: 'Pourquoi avez-vous besoin d’une protection sociale?',
  protection_infoEndpovertyBody: '<h3>La fin de la pauvreté</h3>\n\n<p>Selon les statistiques de l’OIT, 4 milliards de personnes dans le monde n’ont pas de protection sociale. Seulement 29% de la population mondiale bénéficie d’une couverture sociale suffisante et 55% n’a aucune couverture. </p>',
  protection_calloutUniversalsp: 'La protection sociale universelle est essentielle pour atteindre plusieurs des Objectifs de développement durable de l’ONU. Sans elle, il est impossible d’éradiquer la pauvreté, de réduire les inégalités ou de parvenir à l’égalité des sexes.',
  protection_infoTimeforactionBody: '<h3>Il est temps d’agir: la protection sociale universelle</h3>\n\n<p>Il est possible d’étendre la protection sociale aux 4 milliards de personnes dans le monde qui n’en bénéficient pas. L’OIT soutient les pays qui s’efforcent de créer ou d’améliorer leurs systèmes nationaux de protection sociale.</p>\n\n<p>Les normes et l’expertise de l’OIT sont essentielles pour aider les pays à développer leurs propres régimes nationaux de protection sociale.</p>',
  protection_calloutPasttenyears: 'Au cours des dix dernières années, l’OIT a contribué au développement de la protection sociale dans 136 pays, notamment à :',
  protection_listSpfloors1: 'Stratégies nationales<br> de protection sociale dans',
  protection_listSpfloors2: 'Protection<br> de la santé dans',
  protection_listSpfloors3: 'Prestations<br> à l’enfance dans',
  protection_listSpfloors4: 'Prestations<br> de maternité en',
  protection_listSpfloors5: 'Des régimes d’assurance<br> chômage dans',
  protection_listSpfloors6: 'Programmes publics<br> de l’emploi dans',
  protection_listSpfloors7: 'Pensions<br> de vieillesse dans',
  protection_vizColombiaTitle: 'Colombie',
  protection_vizColombiaBody: 'Définition de sa stratégie nationale de protection sociale',
  protection_vizPeruTitle: 'Pérou',
  protection_vizPeruBody: 'Etablissement d’un régime de pensions non contributif',
  protection_vizTogoTitle: 'Togo',
  protection_vizTogoBody: 'Mise en place d\'un régime national d\'assurance maladie couvrant 400 000 bénéficiaires',
  protection_vizCyprusTitle: 'Chypre',
  protection_vizCyprusBody: 'Réforme de son régime d’assistance sociale',
  protection_vizKazakhstanTitle: 'Kazakhstan',
  protection_vizKazakhstanBody: 'Ratification de la convention (n° 183) de l’OIT sur la protection de la maternité',
  protection_vizIndiaTitle: 'Inde',
  protection_vizIndiaBody: 'Extension du régime national d’assurance maladie',
  protection_vizCambodiaTitle: 'Cambodge',
  protection_vizCambodiaBody: 'Mise en place d’un régime national d’assurance maladie',
  protection_vizThailandTitle: 'Thaïlande',
  protection_vizThailandBody: 'Mise en place d’un régime universel de protection de la santé',
  protection_vizVietnamTitle: 'Vietnam',
  protection_vizVietnamBody: 'Extension de la couverture des pensions non contributives',
  protection_vizMozambiqueTitle: 'Mozambique',
  protection_vizMozambiqueBody: 'Définition de sa stratégie nationale de protection sociale',
  protection_vizJordanTitle: 'Jordanie, Bahreïn et Arabie saoudite',
  protection_vizJordanBody: 'Mise en place de régimes d’assurance chômage',
  protection_vizSouthafricaTitle: 'Afrique du Sud',
  protection_vizSouthafricaBody: 'Intensification du Programme élargi de travaux publics',
  protection_infoRoadaheadBody: '<h3>L’avenir</h3>\n\n<p> Les pays ont fait des progrès louables mais des milliards de personnes ne bénéficient toujours pas d’une protection sociale appropriée dans le monde. </p>\n\n<p>L’absence de protection sociale rend les gens vulnérables à la pauvreté, aux inégalités et à l’exclusion sociale durant leur vie. La solution est la protection sociale universelle. </p>',
  protection_calloutOnlywhen: 'C’est seulement lorsque cette aspiration deviendra réalité que nous aurons fait des progrès substantiels vers le développement durable pour tous.',
  protection_imageGarmentmedCaption: 'Centre médical dans une usine de prêt-à-porter en Jordanie. © OIT',
  protection_imageGarmentmedAlt: 'Un panneau sur une fenêtre indique «Centre médical». Derrière la fenêtre, on voit des patients et du personnel médical.',
  protection_imagePregnantCaption: 'Travailleuse enceinte dans une entreprise fabriquant des pièces pour l’industrie automobile à Hangzhou, Chine. © OIT',
  protection_imagePregnantAlt: 'Une femme enceinte, debout dans un couloir devant un bureau, tamponne une pile de petits papiers.',
  protection_imageNurseCaption: 'Une infirmière s’occupe d’un enfant dans un centre médical à Bagdad, Iraq. © OIT/Apex Image',
  protection_imageNurseAlt: 'Une infirmière lève la jambe droite d’un enfant allongé sur un lit. Une autre femme tient la main gauche de l’enfant.',
  protection_imageHomelessCaption: 'Un SDF fouille dans une poubelle à San Francisco, Etats-Unis. © OIT',
  protection_imageHomelessAlt: 'Dans la rue, un homme est penché sur une poubelle avec une main à l’intérieur. Il y a un tram derrière lui.',
  protection_imageVendorCaption: 'Vendeuse de fleurs au marché aux fleurs de Cuenca, Equateur. © OIT',
  protection_imageVendorAlt: 'Une femme est assise devant les bouquets de fleurs qu’elle vend.',
  protection_imageNursesnewCaption: 'Des infirmières s’occupent de nouveaux nés à la maternité de Princess Christian, à Freetown, Sierra Leone. © World Bank/D. Chavez',
  protection_imageNursesnewAlt: 'Deux infirmières en uniforme rose regardent trois nouveaux nés enveloppés dans des couvertures de couleur orange qui dorment dans un berceau.',
  protection_date: 'Découvrez ce chapitre le 13 février 2019',
  protection_countries: 'pays',
  protection_vizDominicanrepTitle: 'République Dominicaine',
  protection_vizDominicanrepBody: 'Ratification de la convention (n° 102) de l’OIT concernant la sécurité sociale (norme minimum)',
  protection_vizMoldovaTitle: 'République de Moldova',
  protection_vizMoldovaBody: 'Création d’accords bilatéraux de sécurité sociale pour couvrir les travailleurs moldaves migrants dans différents pays',
  protection_vizRwandaTitle: 'Rwanda',
  protection_vizRwandaBody: 'Mise en place d’un système d’indemnités de congé de maternité',
  protection_vizTimorlesteTitle: 'Timor-Leste',
  protection_vizTimorlesteBody: 'Création de son premier régime de sécurité sociale contributif',
  protection_country: 'pays',
  protection_countries1: 'pays',
  work_heading: 'Travail et gouvernance',
  work_chapter: 'chapitre 6',
  work_title: 'Des règles pour travailler',
  work_infoMostofusBody: '<p>Qu\'il s\'agisse d\'un bureau, d\'un marché, d\'une usine ou d\'un navire, beaucoup de gens considèrent sans doute leur lieu de travail simplement comme un endroit où ils gagnent leurs vies.</p>\n\n<p>Dans leur quotidien, peu de gens s’attardent sur les lois, règles et règlements qui sont si essentiels pour assurer la sécurité du travail et garantir des conditions de travail décentes pour tous.</p>\n\n<p>Mais avec tant de changements en cours - nouvelles technologies, contrats à court terme et travail en ligne, par exemple - les lois, règles, politiques et institutions qui sous-tendent notre monde du travail doivent  évoluer au même rythme que  les évolutions en cours afin de soutenir et protéger le travail décent.</p>',
  work_calloutRoleofilo: 'Alors, quel est le rôle de l’OIT dans tout ça ? Et en quoi ça vous concerne ?',
  work_infoLabourstdsBody: '<p>Les normes internationales du travail de l’OIT adoptées par les gouvernements au siècle dernier, ont mis en place un système complet de traités internationaux, retranscrits dans les législations et les politiques nationales, et dans le travail des institutions publiques et privées. </p>  \n\n<p>Ensemble, ces normes favorisent les emplois décents et productifs, où que vous travailliez, dans des conditions de liberté, d’équité, de sécurité et de dignité.</p>  \n\n<p>L’OIT travaille en étroite collaboration avec les gouvernements, les organisations d’employeurs et les syndicats pour veiller à ce que les lois et les réglementations qui ont une incidence sur votre vie professionnelle soient pertinentes et appliquées. </p> \n\n<p> L’organisation aide également les institutions publiques à mener des inspections pour veiller à l’application des législations du travail sur les lieux de travail comme le vôtre.</p>',
  work_infoKeepingyouBody: '<h3>Préserver votre sécurité au travail</h3>\n\n<p>La sécurité et santé au travail (SST) est un des éléments les plus essentiels sur le lieu de travail. Cela signifie protéger et favoriser la sécurité, la santé et le bien-être des travailleurs.</p>\n\n<p>L’évolution de la SST remonte à la révolution industrielle durant les années 1800, et est issue des préoccupations vis-à-vis du nombre d’hommes, de femmes et d’enfants blessés ou tués au travail.  A l’époque de la fondation de l’OIT en 1919, l’élaboration de normes internationales pour protéger la sécurité et la santé des travailleurs était devenue une priorité.</p>\n\n<p>Quatre des dix premières recommandations de l’OIT portaient sur des questions de sécurité et de santé au travail.  De nos jours, plus de 40 conventions et recommandations de l’OIT traitent spécifiquement de la sécurité et santé au travail. La convention n° 187 – l’une des plus récentes – a pour objectif de créer une culture globale de prévention pour arriver à une vraie réduction des décès, lésions et maladies liés au travail. </p>',
  work_calloutLawscompliedTitle: 'L’un des moyens qu’ont les gouvernements pour s’assurer du respect des législations du travail sur les lieux de travail est de vérifier leur application effective.',
  work_calloutLawscompliedBody: 'Vérifier leur application effective comprend l’inspection par des inspecteurs du travail des bureaux, des usines, des chantiers de construction et d’autres lieux de travail pour s’assurer que les conditions de travail sur ces lieux respectent les exigences des législations et des politiques du travail.',
  work_infoFrontlineBody: '<h3>Sur le front</h3>\n\n<p>Toutefois, les budgets des gouvernements dédiés à la vérification de l’application des normes n’ont pas suivi l’augmentation rapide du nombre de lieux de travail ni l’accélération du changement dans le monde du travail. Il est de plus en plus difficile pour les inspecteurs du travail d’inspecter tous les lieux de travail et de remédier totalement aux conditions de travail qu’ils constatent.</p>\n\n<p>L’OIT a toujours aidé les gouvernements à développer leurs systèmes d’inspection du travail, à moderniser la planification et les visites d’inspection en réponse aux mutations rapides qui nous affectent tous sur nos lieux de travail. </p>',
  work_calloutFourareas: 'L’OIT travaille également dans quatre domaines connexes pour veiller à la mise en œuvre correcte des législations du travail et au fonctionnement efficace des organes publics et privés du travail.',
  work_listFitforpurpose1Title: 'Adaptation à leur fonction',
  work_listFitforpurpose1Body: 'Renforcer l’efficacité des systèmes d’administration du travail',
  work_listLawmatters2Title: 'Les questions de législation',
  work_listLawmatters2Body: 'Apporter une assistance pour les réformes de la législation du travail',
  work_listWorkingtog3Title: 'La coopération, ça marche',
  work_listWorkingtog3Body: 'Le  tripartisme, le dialogue social et les relations professionnelles',
  work_listReaching4Title: 'Obtenir un consensus',
  work_listReaching4Body: 'Comment prévenir et régler les conflits du travail',
  work_detailFitforpurpose1: '<h3>Renforcer l’efficacité des systèmes d’administration du travail </h3>\n\n<p>Des ministères du travail aux agences de recrutement, il est important que les organismes publics ou privés chargés de la rédaction et de la mise en œuvre de la législation du travail d’un pays soient bien organisés, professionnels et efficaces, pour une bonne gouvernance du marché du travail.</p>',
  work_detailLawmatters1: '<h3>Apporter une assistance pour les réformes de la législation du travail</h3>  \n\n<p>La législation du travail doit s’adapter aux changements rapides qui transforment les lieux de travail, qui doivent respecter les normes de l’OIT et répondre aux objectifs du travail décent.</p>\n\n<p>Pour aider les états membres à évaluer, élaborer et réviser leur législation du travail et mettre en œuvre les conventions de l’OIT, l’organisation propose des expertises et une assistance techniques.</p>',
  work_detailWorkingtog1: '<h3>Le tripartisme, le dialogue social et les relations professionnelles</h3>  \n\n<p>Lorsque les gouvernements, les travailleurs et les employeurs s’attellent ensemble aux problèmes dans un système de relations professionnelles saines, cela permet d’améliorer les performances de l’économie et aboutit à une distribution plus équitable des profits et de la richesse. Cela peut se produire par le biais de :</p>\n<ul>\n<li>Négociations, consultations et échanges d’informations</li>\n<li>Négociation collective</li>\n<li>Prévention et résolution des conflits</li>\n<li>D’autres instruments du dialogue social, y compris les normes internationales du travail</li>\n</ul>',
  work_detailReaching1: '<h3>comment prévenir et régler les conflits du travail</h3>  \n\n<p>Dans la relation d’emploi, les plaintes et les conflits sont inévitables. </p>\n\n<p>Mais avant d’atteindre le stade du recours à la justice, il existe des moyens de prévenir et de régler les conflits du travail grâce au consensus.</p>',
  work_infoBrokenrulesBody: '<h3>Enfreindre la loi</h3>   \n\n<p>Même quand une législation est en place, il y a toujours des remises en cause de l’état de droit. </p> \n\n<p>Par exemple, 182 états membres sur 187 ont ratifié la convention sur les pires formes de travail des enfants, qui pourrait donc devenir la première convention de l’OIT à recevoir une ratification universelle. Et pourtant, malgré la baisse du nombre d’enfants qui travaillent au fil des années, il reste encore 152 millions d’enfants qui travaillent dans le monde. </p> \n\n<p>178 pays sur 187 ont ratifié la convention sur le travail forcé, mais d’après les statistiques de l’OIT,  25 millions de personnes sont toujours soumises au travail forcé. C’est une industrie qui génère 150 milliards de dollars des Etats-Unis de profits illégaux par an. </p>\n\n<p>L’OIT fait face à ces défis et à d’autres dans tous les secteurs, avec une série de projets et de programmes pour aider les gouvernements à appliquer la législation du travail. Cette aide comprend l’actualisation des conventions si nécessaire. </p>\n\n<p>En 2014 par exemple, pour répondre à l’émergence de nouvelles formes d’esclavage moderne, la Conférence internationale du Travail a adopté le Protocole sur le travail forcé – un addendum à la convention sur le travail forcé de 1930 – qui vise à intensifier les efforts pour éliminer toutes les formes de travail forcé, y compris la traite des êtres humains. </p>',
  work_infoTacklingBody: '<h3>S’attaquer aux intermédiaires</h3> \n\n<p>Face aux défis de l’économie moderne, les travailleurs se battent pour trouver des opportunités d’emploi et les services de recrutement sont plus importants que jamais.</p>\n\n<p>Si elles sont correctement régulées, les agences d’emploi publiques et privées peuvent jouer un rôle important pour mettre en relation les emplois disponibles et les travailleurs qui ont les qualifications adaptées.</p>\n\n<p>Dans le monde, on estime qu’il y a 244 millions de migrants internationaux et 740 millions de migrants internes. La plupart d’entre eux migrent à la recherche d’un emploi décent et d’un meilleur niveau de vie.</p>\n\n<p>Cependant, certains groupes vulnérables, notamment les migrants et les travailleurs peu qualifiés, sont la proie d’agences de l’emploi et d’intermédiaires de l’économie informelle peu scrupuleux, qui peuvent les piéger dans la traite des êtres humains ou dans des situations de travail forcé.  </p>',
  work_calloutTrappedflTitle: '25 millions de personnes sont piégées dans le travail forcé dans le monde. 44% d’entre elles ont migré à l’intérieur ou à l’extérieur de leur pays pour travailler.',
  work_calloutTrappedflBody: 'Les travailleurs migrants qui empruntent de l’argent à des tiers ont un risque accru d’être entraînés dans du travail forcé.',
  work_infoInresponseBody: '<p>Pour répondre à ces défis, l’OIT travaille en étroite collaboration avec les gouvernements, les organisations d’employeurs et les syndicats, le secteur privé et d’autres organisations internationales pour :</p>\n\n<ul>\n<li>Contribuer à la prévention de la traite des êtres humains et du travail forcé.</li>\n<li>Protéger les droits des travailleurs, notamment des travailleurs migrants, des pratiques abusives et frauduleuses durant leur processus de recrutement.</li>\n<li>Réduire le coût de la migration de main d’œuvre et améliorer les bénéfices pour les migrants et leurs familles, les pays d’origine et de destination, en matière de développement. </li>\n</ul>',
  work_calloutIloaimstoTitle: 'L’objectif de l’OIT est de diffuser les connaissances sur les pratiques efficaces de recrutement, améliorer les lois, les politiques et les mécanismes de contrôle, promouvoir les pratiques équitables au sein des entreprises, autonomiser les travailleurs et les protéger.',
  work_calloutIloaimstoBody: 'En utilisant les outils de gouvernance du monde du travail cités plus haut, l’OIT œuvre pour améliorer les conditions de travail dans le monde, et renforcer la lutte pour le travail décent pour tous.',
  work_imageNightshiftCaption: 'Travailleurs de l’équipe de nuit d’une usine de lychees à Tamatave, Madagascar. © OIT/M. Fossat',
  work_imageNightshiftAlt: 'Des travailleurs habillés de blanc avec des masques sanitaires, des bonnets et des gants bleus dans une usine ;  assis sur des bancs, ils trient les lychees sur un tapis roulant.',
  work_imageFishersCaption: 'Des pêcheurs apprennent des techniques de pisciculture respectueuses de l’environnement dans le cadre d’un programme d’emploi d’urgence AusAid de l’OIT, Philippines. © OIT/M. Crozet',
  work_imageFishersAlt: 'Deux pêcheurs debout sur un radeau essaient de jeter à l’eau un grand filet de pêche.',
  work_imageWomenwarCaption: 'Des travailleuses durant la guerre à l’usine 3 de la Royal Shell, Woolwich Arsenal, Royaume-Uni, Mai 1918. © Imperial War Museum',
  work_imageWomenwarAlt: 'Des femmes travaillent à la fabrication d’obus sur une table avec divers outils.',
  work_imageLabourinspCaption: 'Des inspecteurs du travail effectuent une inspection de la sécurité et la santé au travail dans un atelier à Amman, Jordanie. © OIT/G. Megevand',
  work_imageLabourinspAlt: 'Une inspectrice du travail avec un outil de contrôle dans sa main discute avec un travailleur assis devant une machine.',
  work_imageChildcarpetCaption: 'Une enfant tisseuse de tapis à Lahore, Pakistan. © OIT/M. Crozet',
  work_imageChildcarpetAlt: 'Une jeune fille assise derrière un métier à tisser des tapis attrape des faisceaux de fils devant elle.',
  work_imageMigrantCaption: 'Un travailleur migrant au Qatar. © Apex Image',
  work_imageMigrantAlt: 'Un homme en combinaison jaune répand de l’herbe sur le sol. On voit de grands immeubles en arrière-plan.',
  work_imageSyrianCaption: 'Un réfugié syrien travaille sur un chantier en Jordanie. © OIT/A. Al Sukhni',
  work_imageSyrianAlt: 'Un homme debout sur un échafaudage peint un motif complexe au plafond avec de la peinture jaune.',
  work_date: 'Découvrez ce chapitre le 20 février 2019',
  work_detailFitforpurpose2: '<p>L’OIT fournit des conseils et une assistance techniques sur la réforme des systèmes d’administration du travail, et des formations pour les fonctionnaires chargés des inspections du travail, des services de l’emploi, des prestations de protection sociale, du développement des entreprises, de la résolution des conflits, de l’élaboration des politiques et des législations du travail, de la collecte et de l’analyse des données, et du dialogue social.</p>',
  work_detailWorkingtog2: '<p>Ce dialogue social est essentiel pour atteindre et maintenir le travail décent.</p>\n\n<p>L’OIT facilite le dialogue social entre représentants des gouvernements, des employeurs et des travailleurs pour aider les états membres à mettre en place des relations professionnelles saines dans les pays pour une gouvernance efficace du monde du travail. </p>\n\n<p>L’OIT soutient ces efforts par la recherche, les programmes d’éducation et de formation, et aussi des plateformes de partage d’informations et de compétences. </p>',
  work_detailReaching2: '<p>Dans la conciliation et la médiation, un tiers apporte une assistance durant les négociations pour aider les parties à trouver un accord, notamment si les parties sont dans l’impasse.</p>\n\n<p>L’arbitrage, c’est lorsque un tiers, qui ne fait pas partie d’un tribunal, a le pouvoir de prendre une décision pour résoudre un conflit.</p>\n\n<p> La résolution des différents du travail par des tribunaux spécialisés, c’est lorsqu’un tribunal ordinaire ou spécialisé dans le droit du travail tranche un différend portant sur les droits et les obligations.  </p>',
  work_imageConsensusCaption: 'Des partenaires sociaux du secteur métallurgique négocient un accord pour éviter un conflit. Les négociations se tiennent dans les locaux de la Direction Générale de l’Inspection du travail et de la conciliation à Tunis, Tunisie. © OIT',
  work_imageWorkingtoCaption: 'Wided Bouchamaoui, présidente de la Confédération tunisienne de l\'industrie, du commerce et de l\'artisanat (UTICA) et membre du Quartet de dialogue national de Tunisie. Le prix Nobel de la paix a été décerné au Quatuor en 2015 "pour sa contribution décisive à l\'édification d\'une démocratie pluraliste en Tunisie à la suite de la révolution de Jasmin de 2011" © OIT',
  work_imageFitforpurposeCaption: 'Un centre de développement des ressources humaines et d\'emploi à Tianjin. Chine. Les centres de services pour l’emploi comme celui-ci aident à développer les connaissances et les compétences des travailleurs pour améliorer la compétitivité et la productivité, tout en favorisant l’inclusion sociale et le travail décent. © OIT',
  work_imageLawmattersCaption: 'Inspection du navire "Jolly Blu" au port de Gênes, en Italie. Le lieutenant Paolo Leone vérifie si le navire est conforme aux normes internationales relatives à la sécurité des navires, à la prévention de la pollution marine et aux conditions de travail et de vie décentes des gens de mer, telles que définies dans la Convention du travail maritime de l\'OIT, 2006. © OIT',
  equality_heading: 'Egalité et non-discrimination',
  equality_title: 'La liberté d’être soi-même',
  equality_chapter: 'chapitre 3',
  equality_calloutHundreds: 'Des centaines de millions de personnes subissent des discriminations au travail. C’est une violation d’un droit de l’homme élémentaire qui a d’importantes conséquences socioéconomiques.',
  equality_infoConsequencesTitle: 'Les conséquences de la discrimination',
  equality_infoConsequencesBody1: '<h3>Les conséquences de la discrimination</h3>\n\n<p>La discrimination existe dans tous les pays et tous les secteurs économiques du monde. Elle réduit les opportunités pour les personnes, gâche les talents et ralentit le progrès économique. Elle augmente les tensions et renforce les inégalités.</p>\n\n<p>La lutte contre les discriminations est un élément essentiel de l’Agenda du travail décent de l’OIT. Réussir dans ce domaine a des répercussions bien au-delà du lieu de travail: chez soi, à l’école et même dans la rue.</p>',
  equality_infoConsequencesBody2: 'La lutte contre les discriminations est un élément essentiel de l’Agenda du travail décent de l’OIT. Réussir dans ce domaine a des répercussions bien au-delà du lieu de travail: chez soi, à l’école et même dans la rue.',
  equality_infoDiscriminationTitle: 'Nous subissons tous des discriminations',
  equality_infoDiscrimination: '<h3>Nous subissons tous des discriminations</h3>\n\n<p>Les discriminations se fondent sur de multiples raisons, comme la race, le sexe, l’orientation sexuelle, le statut VIH, le handicap, l’âge et l’appartenance politique. Les peuples indigènes, les minorités ethniques, les groupes religieux, les femmes et les migrants sont souvent la cible de discrimination.  Elles sont également liées à la violence et au harcèlement des femmes et des hommes dans le monde du travail.</p>\n\n<p>Le harcèlement sexuel est largement répandu. Il renforce les stéréotypes sur les capacités et les aspirations des femmes et peut les inciter à sortir du marché du travail, détruire leur carrière et avoir des effets durables sur tous les aspects de leur vie.  </p>',
  equality_infoDiscriminationBody2: 'Nous subissons tous des discriminations',
  equality_infoResearchnineBody: '<p>Les recherches entreprises par l’OIT dans neuf pays de 2013 à 2016 confirment que les stéréotypes et les préjugés ont une incidence sur les perspectives d’emploi des personnes lesbiennes, homosexuelles, bisexuelles et transgenre. Elles sont confrontées à la discrimination, notamment à la violence et au harcèlement, à de nombreux niveaux. Elles sont souvent contraintes de cacher leur identité sexuelle.</p>',
  equality_infoDomino: '<h3>L’effet domino de la discrimination</h3>\n\n<p>La discrimination, qu’elle soit subtile ou ouverte, peut avoir une incidence sur la santé mentale d’une personne. Des décennies de recherche ont montré que lorsque des personnes sont injustement traitées ou travaillent longtemps dans un environnement hostile, elles courent un risque plus élevé de développer des troubles liés au stress, de l’anxiété et de la dépression. </p>\n\n<p>Lorsque la santé mentale d’une personne est affectée, cela peut produire un effet domino qui s’étend bien au-delà de la personne, à ses partenaires, à ses enfants et à ses collègues.</p>\n\n<p>La discrimination peut empêcher les gens de réaliser leurs rêves et leurs objectifs professionnels. Elle limite leur potentiel et coûte plus de 400 milliards de dollars aux économies en termes de perte de croissance mondiale du PIB.</p>',
  equality_infoDominoBody1: 'La discrimination, qu’elle soit subtile ou ouverte, peut avoir une incidence sur la santé mentale d’une personne. Des décennies de recherche ont montré que lorsque des personnes sont injustement traitées ou travaillent longtemps dans un environnement hostile, elles courent un risque plus élevé de développer des troubles liés au stress, de l’anxiété et de la dépression. Lorsque la santé mentale d’une personne est affectée, cela peut produire un effet domino qui s’étend bien au-delà de la personne, à ses partenaires, à ses enfants et à ses collègues.',
  equality_infoDominoBody2: 'La discrimination peut empêcher les gens de réaliser leurs rêves et leurs objectifs professionnels. Elle limite leur potentiel et coûte plus de 400 milliards de dollars aux économies en termes de perte de croissance mondiale du PIB.',
  equality_calloutPortraitsTitle: 'PORTRAITS DE L\'INÉGALITÉ',
  equality_calloutPortraitsBody: 'La discrimination n’est pas seulement mauvaise moralement: elle viole également les droits de l’homme et a des conséquences économiques négatives.',
  equality_infoBreakingexpectTitle: 'Briser les idées reçues sur les questions de genre',
  equality_infoBreakingexpectBody1: '<h3>Briser les idées reçues sur les questions de genre</h3>\n\n<p>Un rapport conjoint OIT-Gallup sur l’avenir des femmes et le travail "Towards a better future for women and work: Voices of women and men" donne pour la première fois un aperçu des attitudes et de la perception qu’ont les femmes et les hommes des femmes au travail. Ce sondage révèle que les avis des hommes sont très souvent similaires à ceux des femmes et montre qu\'au niveau mondial les femmes désirent disposer des mêmes opportunités de travail que les hommes.</p>\n\n<p> Ce sondage a été réalisé dans 142 pays et territoires, auprès de 149 000 adultes, et est représentatif de plus de 99% de la population mondiale adulte.</p>',
  equality_infoBreakingexpectBody2: 'Ce sondage a été réalisé dans 142 pays et territoires, auprès de 149 000 adultes, et est représentatif de plus de 99% de la population mondiale adulte.',
  equality_calloutDespiteprog: 'En dépit des progrès significatifs réalisés au siècle dernier, la probabilité de faire partie de la main-d’œuvre est beaucoup plus faible pour les femmes que pour les hommes.',
  equality_calloutAbetterlife: 'Une meilleure vie professionnelle pour tous',
  equality_infoIlsdiscrimination: '<p>Les normes du travail et les directives de l’OIT qui ont pour objectif de combattre les discriminations sont reconnues par les gouvernements, les travailleurs et les employeurs dans le monde entier.</p>\n\n<p>Alors que de plus en plus de pays ont adopté des lois de protection contre les discriminations et de promotion de l’égalité, les experts de l’OIT ont joué un rôle crucial dans ce domaine en proposant des conseils sur les changements législatifs.</p>',
  equality_calloutConclusion1: 'Un monde sans discrimination est possible, et nous avons tous un rôle à jouer pour y arriver.',
  equality_imageMigrantreturnCaption: 'Silhouette d\'une travailleuse migrante rapatriée qui a reçu un soutien psychosocial dans le cadre d\'un projet de réintégration soutenu par l\'OIT à Addis-Abeba (Éthiopie). Les travailleurs migrants sont souvent soumis à différentes formes de discrimination, de violence et de harcèlement. © OIT / A. Muluneh',
  equality_imageMigrantreturnAlt: 'Une femme est photographiée devant une fenêtre. La photo est rétro-éclairée et son visage n\'est pas visible.',
  equality_imageDisabledgarmentCaption: 'Travailleuse dans une usine de prêt-à-porter, Nguyen Thi Thanh Hieu. Dong Nai, Viet Nam. © OIT/A. Nguyen',
  equality_imageDisabledgarmentAlt: 'Une femme bossue est assise devant une machine à coudre dans une usine avec un petit bout de tissu entre les mains.',
  equality_imageTranswomenCaption: 'Femmes trans participent à un cours d\'assistante de cuisine au Brésil à l\'intention de lesbiennes, gays, bisexuels, transgenres et intersexués, enseigné par la chef argentine Paola Carosella. L’OIT encourage l’inclusion des personnes LGBTI sur le marché du travail formel. © OIT Brésil',
  equality_imageTranswomenAlt: 'Deux cheffes cuisinant avec des ingrédients disposés sur une table en acier dans une cuisine.',
  equality_visGenderBody: '<h3>Egalité des genres</h3>\n\n<p>L’égalité entre les femmes et les hommes renforce les économies, contribue à leur croissance sur le long terme. Un rapport de l’Institut  McKinsey Global a montré, par exemple, que progresser vers l’égalité des femmes permettrait au PIB mondial de croître de 12 000 milliards de dollars d’ici 2025.</p>\n\n<p>Tous les aspects des travaux de l’OIT reflètent la volonté de faire progresser l’égalité entre les femmes et les hommes, qui est inscrite dans sa Constitution et ses conventions fondamentales.</p>',
  equality_visLgbtBody: '<h3>LGBT</h3>\n\n<p>Une étude récente sur l’Inde réalisée par la Banque mondiale estime qu’en plus de la souffrance des personnes lesbiennes, homosexuelles, bisexuelles et transgenre (LGBT) en raison des discriminations qu’elles subissent, le coût économique de l’homophobie dans ce pays pourrait s’élever à 1,7% de perte de PIB par an.</p>\n\n<p>Le projet de l\'OIT "L\'identité de genre et l’orientation sexuelle: la promotion des droits, la diversité et l\'égalité dans le monde du travail (PRIDE)" mène des recherches sur les discriminations à l’encontre des travailleurs LGBT dans le monde et met en lumière les bonnes pratiques de promotion de leur inclusion sur les lieux de travail. Les pays où sont menées ces recherches sont l’Argentine, le Costa Rica, la France, la Hongrie, l’Inde, l’Indonésie, le Monténégro, l’Afrique du Sud et la Thaïlande.</p>',
  equality_visHivBody: '<h3>VIH et Sida</h3>\n\n<p>Les politiques et l’éducation relatives au VIH, ainsi que la promotion du dépistage volontaire et des services de conseil, réduisent la stigmatisation sur le lieu de travail et permettent aux salariés de connaître leur statut VIH tôt et d’être traités s’ils sont séropositifs. La santé des travailleurs s’améliore avec le traitement. Ils prennent moins de congés de maladie et deviennent plus productifs dans leur emploi. Cela permet aux personnes séropositives de continuer de travailler et de s’occuper de leur famille.</p>\n\n<p>L’initiative Conseil et dépistage volontaires du VIH  (VCT@WORK) a permis à 6 millions de travailleurs de connaître leur statut VIH et d’obtenir un traitement, en cas de nécessité. </p>\n\n<p>Des initiatives de ce type contribuent à réduire le nombre de personnes incapables de travailler en raison de leur séropositivité. En 2005, 350 000 personnes n’étaient pas en capacité de travailler en raison de leur séropositivité. On espère que ce chiffre passera à 40 000 personnes d’ici 2020, soit 85% de réduction pour les hommes et 93% pour les femmes. </p>',
  equality_visDisabilityBody: '<h3>Handicap</h3>\n\n<p>Un milliard de personnes, soit une personne sur sept dans le monde, a un handicap. Parmi elles, environ 800 millions sont en âge de travailler mais beaucoup sont exclues des lieux de travail. Cette situation les empêche de participer pleinement à la société et induit une perte de 3% à 7% du PIB mondial.</p> \n\n<p>Le réseau mondial Entreprises et handicap de l’OIT, qui comprend des multinationales employant plus de 4,2 millions de salariés, partage des connaissances et développe des moyens pour permettre aux entreprises et aux employeurs d’engager et de garder des personnes handicapées.</p>',
  equality_visIndigenousBody: '<h3>Les peuples autochtones</h3> \n\n<p>Les peuples autochtones représentent environ 5% de la population mondiale, mais 15% des pauvres. Ils sont souvent privés de leur terre, d’une éducation de qualité, et leurs voix ne sont pas entendues par les gouvernements. Améliorer leur participation au secteur des emplois formels pourrait sortir des millions d’entre eux, et notamment les femmes, de la pauvreté.</p>    \n\n<p>L’OIT s’est engagée sur les questions des peuples autochtones et tribaux depuis les années 1920. Elle est responsable de la Convention (n° 169) relatives aux peuples indigènes et tribaux, 1989, le seul traité international qui traite exclusivement de leurs droits.</p>',
  equality_visEthnicityBody: '<h3>L’appartenance ethnique</h3>\n\n<p>Si les hommes et les femmes issus de l’immigration avaient plus de pouvoir économique, les gouvernements bénéficieraient de recettes supplémentaires sous forme d’impôts sur le revenu ou d’impôts sur les sociétés. La recherche a par exemple montré qu’en France, mettre fin à la discrimination des personnes d’origine Nord-Africaine induirait une augmentation des recettes de 2,0% et réduirait les dépenses publiques de 0,5%.</p>\n\n<p>L’élimination de la discrimination fondée sur la race est au cœur des travaux de l’OIT depuis 1919 et est inscrite dans la Convention (n° 111) concernant la discrimination (emploi et profession). L’obligation de tous les Etats Membres de respecter, promouvoir et réaliser ce principe a été réaffirmée dans la Déclaration de l’OIT sur les principes et droits fondamentaux au travail, adoptée en 1998. </p>',
  equality_visMigrantBody: '<h3>Travailleurs migrants</h3>  \n\n<p>Les travailleurs migrants font partie des groupes de population les plus touchés par les ralentissements économiques et les premiers à faire l’objet de discrimination. Cependant, il existe un consensus chez les économistes pour dire que l’immigration a un petit impact positif sur les salaires et l’emploi car l’immigration entraîne une croissance de l’économie. </p>\n\n<p>L’Initiative de l’OIT sur le recrutement équitable permet de créer des possibilités de recrutement équitables pour les travailleurs migrants. La plateforme de conseils pour le recrutement de travailleurs migrants les protège des pratiques abusives d’emploi grâce aux avis des utilisateurs sur les agences de recrutement. </p>',
  equality_visDomesticBody: '<h3>Les travailleurs domestiques</h3>\n\n<p>Selon les statistiques de l’OIT, il y a 67 millions de travailleurs domestiques dans le monde. Ils s’occupent des familles, effectuent leur ménage et la cuisine, et représentent une part essentielle de l’économie des soins à la personne. Pourtant, seuls 10% d’entre eux bénéficient d’une protection au travail égale à celle des autres travailleurs. Ils gagnent généralement moins de la moitié des salaires moyens et sont responsables d’une partie significative de l’écart des salaires entre les hommes et les femmes au bas de l\'échelle du marché du travail. Les mauvaises conditions de travail que subissent beaucoup d’entre eux sont enracinées dans diverses formes de discrimination : souvent, le travail domestique n’est pas considéré comme un travail mais comme faisant naturellement partie des responsabilités des femmes.</p>\n\n<p>La Convention (n° 189) sur les travailleurs et travailleurs domestiques, 2011, est la seule norme internationale reconnue destinée à protéger les travailleurs domestiques dans le monde et à améliorer leurs conditions de vie et de travail.</p>',
  equality_visAgeBody: '<h3>Age</h3>\n\n<p>La faible participation des personnes âgées à la main-d’œuvre entraîne des coûts économiques considérables. En Australie, si la participation à la main-d’œuvre des travailleurs âgés de 55 ans et plus augmentait de 3%, le PIB augmenterait de 33 milliards de dollars des Etats-Unis, et le revenu national de 1,6%. Une augmentation de 5% de la participation de ce groupe à l’emploi rémunéré se traduirait par une augmentation du PIB de 48 milliards de dollars des Etats-Unis soit 2,4% du revenu national. </p>\n\n<p>L’OIT aborde la situation spécifique de ces travailleurs dans la Recommandation (n° 162) sur les travailleurs âgés, 1980. Cette dernière propose un cadre constructif aux pays pour offrir des conditions de travail décentes et productives aux travailleurs âgés. </p>',
  equality_visWomen1Body: 'Les préférences des femmes',
  equality_visWomen2Body: 'Les préférences des hommes',
  equality_visWomen3Body: '<p>70% des femmes et 66% des hommes interrogés préfèreraient que les femmes travaillent dans des emplois rémunérés – soit plus du double de ceux qui préfèrent que les femmes restent à la maison.</p>',
  equality_visWomen4Body: '<p>Au niveau mondial, les femmes préféreraient travailler dans des emplois rémunérés (29%) ou avoir la possibilité de travailler tout en prenant soin de leur famille (41%) d’après le rapport.</p>',
  equality_visWomen5Body: '<p>Seulement 27% des femmes veulent rester à la maison, et seulement 29% des hommes souhaitent la même chose.</p>',
  equality_visWomen6Body: '<p>Parmi les 70% de femmes qui voudraient travailler dans des emplois rémunérés, on trouve une majorité de femmes qui ne font pas partie de la main-d’œuvre.</p>',
  equality_visWomen7Body: '<p>Il convient de noter que tel est le cas dans toutes les régions du monde, y compris dans celles où la participation des femmes à la main-d’œuvre est traditionnellement faible, en particulier dans les États arabes.</p>',
  equality_visWomen8Body: 'Préfèrent que les femmes travaillent dans des emplois rémunérés',
  equality_visWomen9Body: 'Préfèrent que les femmes fassent les deux',
  equality_visWomen10Body: 'Préfèrent que les femmes restent à la maison pour faire les tâches ménagères et s’occuper de leur famille',
  equality_visWomen11Body: 'Ne savent pas ou refusent de répondre',
  equality_date: 'Découvrez ce chapitre le 30 janvier 2019',
  equality_visGapTitle: 'L\'écart entre les sexes dans la main-d’œuvre',
  equality_visGapSelect: 'Rechercher un pays',
  equality_visGapRatesLabel: 'Taux de participation à la main-d’œuvre (%)',
  equality_visGapWomen: 'Les femmes dans la main-d’œuvre',
  equality_visGapMen: 'Les hommes dans la main-d’œuvre',
  equality_visGapGap: 'ECART',
  equality_visGapNote: 'Données de 2019. Source : ILOSTAT – Estimations modélisées de l\'OIT.',
  equality_visGapDefinition: 'La MAIN-D’ŒUVRE fait référence à la somme de toutes les personnes en âge de travailler qui sont dans l’emploi ou au chômage. Le taux de participation à la main-d’œuvre représente le pourcentage de la main-d’œuvre par rapport à la population en âge de travailler.',
  equality_calloutPortraitsSub: 'Le coût des inégalités se chiffre en milliards de dollars, et prive les pays de richesses monétaires et de talents. On serait tous plus riches si tous les membres de la société avaient les mêmes opportunités.',
  equality_calloutDespiteProgSub: 'Le taux actuel de participation des femmes à la main-d’œuvre dans le monde est proche de 49%. Pour les hommes, il est de 75%.',
  equality_calloutConclusion2: 'L’égalité est un moyen de créer des emplois décents pour tous les hommes et toutes les femmes, et fait partie intégrante de tous les programmes d’emploi de l’OIT.',
  equality_infoWomenBody1: '<p>Lorsque les femmes travaillent, elles ont tendance à occuper des emplois peu qualifiés et peu rémunérés, souvent sous-évalués.</p>\n\n<p>Partout dans le monde, les femmes effectuent plus d’heures de travail si l’on ajoute les heures de travail non rémunérées aux heures de travail rémunérées. Les femmes effectuent 75% du travail non rémunéré de soins aux personnes dans le monde. L’OIT estime que 16,4 milliards d’heures sont consacrées par jour au travail de soins non rémunéré, soit l’équivalent de 9% du PIB mondial.</p>',
  equality_infoWomenBody2: 'Pour aider à résoudre ce problème, la Coalition internationale pour l\'Égalité salariale (EPIC), dirigée par l’OIT, ONU-Femmes et l’OCDE, s\'emploie à prendre des mesures concrètes avec les gouvernements, le secteur privé, la société civile et les universités pour accélérer la réduction de l’écart entre les salaires des hommes et des femmes et atteindre l’égalité de rémunération d’ici 2030.',
  equality_calloutPortraitsSubtitle: 'Explorez ces facettes du monde du travail pour en savoir plus.',
  equality_portrait0Detail1: '<h3>Carling, dirigeant autochtone</h3>\n\n<p>Carling Dumulot est un Aeta, un peuple autochtone vivant aux Philippines. Tout au long de sa vie, il a vu sa culture et les besoins de son peuple marginalisés et ignorés, même sur leurs terres ancestrales.</p>\n\n<p>En tant que dirigeant autochtone, son combat a consisté à obtenir la reconnaissance nécessaire pour aider son peuple à sortir de la pauvreté et à faire entendre sa voix pour les décisions qui affectent sa vie quotidienne.</p>',
  equality_portrait0Detail2: '<h3>Combattre la discrimination à l\'égard des peuples autochtones</h3> \n\n<p>Les peuples autochtones représentent environ 5% de la population mondiale, mais 15% des pauvres. Ils sont souvent privés de leur terre, d’une éducation de qualité, et leurs voix ne sont pas entendues par les gouvernements. Améliorer leur participation dans les emplois formels pourrait aider  des millions d’entre eux, et notamment les femmes, à sortir de la pauvreté.</p>    \n\n<p>L’OIT est engagée sur les questions des peuples autochtones et tribaux depuis les années 1920. Elle est responsable de la Convention (n° 169) relatives aux peuples indigènes et tribaux, 1989, le seul traité international qui traite exclusivement de leurs droits.</p>',
  equality_portrait0Caption: 'Carling Dumulot, dirigeant autochtone du peuple Aeta qui s\'est battu aux côtés de l\'OIT pour sensibiliser le public à la discrimination à laquelle les peuples autochtones sont confrontés aux Philippines et dans le monde. © OIT',
  equality_portrait1Detail1: '<h3>Sreela, spécialiste de la diversité et de l\'inclusion</h3>\n\n<p>Le monde de Sreela Das Gupta a changé il y a 15 ans quand on lui a diagnostiqué une maladie auto-immune qui l\'a laissée dans un fauteuil roulant. Vivre avec un handicap a changé la façon dont les gens la voient et les attentes qu’elle avait au travail.</p>',
  equality_portrait1Detail2: '<p>Cependant, elle considère qu’elle a de la chance parce que ses employeurs travaillent avec elle pour déterminer comment elle peut contribuer au mieux à son lieu de travail, ce qui est encore rare dans la plupart des pays du monde.</p>\n\n<p>L’encouragement d’autrui est donc devenu une force directrice dans sa vie. Elle s’efforce  d’aider les autres dans les domaines de l’inclusion des personnes handicapées, des droits de la personne, de l’autonomisation des femmes et de la santé maternelle et infantile depuis plus de deux décennies.</p>',
  equality_portrait1Caption: 'Sreela Das Gupta, qui se spécialise à aider les entreprises à être plus diversifiées et à inclure les personnes handicapées en milieu de travail. © OIT',
  equality_portrait2Detail1: '<h3>Abel, travailleur migrant</h3>\n\n<p>Des personnes comme Abel Torres Garcia jouent un rôle essentiel dans l\'approvisionnement alimentaire et le soutien à l\'économie des pays industrialisés.</p>\n\n<p>Pendant des années, il a fait un travail éreintant en plein soleil à récolter des patates douces, des tomates et d’autres légumes; un travail mal apprécié qui ne lui rapporte qu’un maigre salaire. Bien qu’il prenne des emplois dont la population locale ne veut généralement pas, il subit la discrimination presque tous les jours.</p>',
  equality_portrait2Detail2: '<h3>Combattre la discrimination envers les travailleurs migrants</h3>  \n\n<p>Les travailleurs migrants sont parmi les plus touchés par les ralentissements économiques et les premiers à faire l’objet de discrimination. Les économistes s’entendent pour dire que l’immigration a un impact faible mais positif sur les salaires et l’emploi car elle favorise une croissance de l’économie.</p>',
  equality_portrait2Caption: 'Abel Torres Garcia, un travailleur agricole migrant mexicain basé en Caroline du Nord (États-Unis), est quotidiennement victime de discrimination, alors qu\'il travaille légalement dans un secteur qui contribue 84 milliards de dollars à l\'économie de cet état. © Marc McAndrews',
  equality_portrait3Detail1: '<h3>Blanca, travailleuse âgée</h3>\n\n<p>Blanca Ester Castillo, âgée de 77 ans, a été confrontée à la pauvreté et à l\'exclusion tout au long de sa vie. Alors qu’elle a pris soin de 14 enfants et 11 petits-enfants, à mesure qu’elle vieillissait, elle s’est vue confrontée à une discrimination croissante dans sa recherche d’emploi.</p>\n\n<p>Elle fait partie des millions de femmes et d\'hommes qui sont les principaux soutiens du ménage, mais qui doivent faire face à une lutte acharnée pour continuer à subvenir aux besoins de leur famille à en vieillissant.</p>',
  equality_portrait3Detail2: '<h3>Combattre la discrimination envers les personnes âgées</h3>\n\n<p>La faible participation des personnes âgées à la main-d’œuvre entraîne des coûts économiques considérables. En Australie, si la participation à la main-d’œuvre des travailleurs âgés de 55 ans et plus augmentait de 3%, le PIB augmenterait de 33 milliards de dollars des Etats-Unis, et le revenu national de 1,6%.</p>',
  equality_portrait3Caption: 'Blanca Ester Castillo, âgée de 77 ans, qui a récemment pris sa retraite,  avait été victime de discrimination dans sa recherche d\'emploi, peu d\'employeurs souhaitant embaucher une femme de son âge. © OIT',
  equality_visGapWorld: 'Monde',
  equality_portrait4Detail1: '<h3>Aya, réalisatrice primée</h3>\n\n<p>Aya Tanimura est une réalisatrice primée de vidéoclips, de télévision et de films. Son travail a amassé plus d’un milliard de vues cumulées en ligne, grâce à ses concepts très beaux et originaux conçus pour de grandes marques, des sociétés et des artistes tels que Katy Perry.</p>',
  equality_portrait4Detail2: '<p>Pourtant, Tanimura estime que la discrimination et les obstacles rencontrés par les femmes travaillant dans la production cinématographique et télévisuelle ont limité le travail qu’elle a pu accomplir. Il lui est arrivé que d’autres sur le plateau aient supposé qu’elle était une assistante des projets qu’elle dirige.</p>\n\n<p>Sur les 100 films qui ont généré le plus de revenus en 2018, 4% seulement ont été réalisés par des femmes.</p>',
  equality_portrait4Caption: 'Aya Tanimura, une réalisatrice australo-japonaise qui a été victime de discrimination sur son lieu de travail parce qu’elle est une femme. © Lauren Crew',
  equality_portrait5Detail1: '<h3>Emma, activiste des droits des lesbiennes</h3>\n\n<p>Emma Chacón A. est une survivante de harcèlement au travail. En 2014, lorsque d’autres sur son lieu de travail ont appris qu’elle était activiste pour les droits des lesbiennes, ses responsabilités au travail ont été réduites sans préavis et elle a dû endurer les commentaires homophobes de ses collègues.</p>\n\n<p>Plus tard, elle a été mutée contre son gré dans un autre lieu de travail.</p>',
  equality_portrait5Detail2: '<p>Au début, elle n\'arrivait pas à croire ce qui lui arrivait car, en tant que lesbienne, elle s\'était toujours sentie autonomisée. Son traitement au travail, cependant, l’a amené à se poser des questions sur elle-même, sur sa valeur et sur son travail.</p>\n\n<p>Il lui a fallu plusieurs années pour se remettre et se sentir à nouveau appréciée et valorisée au travail. Aujourd\'hui, elle est fière de soutenir les personnes qui ont vécu des situations similaires et de les aider à surmonter ces situations.</p>',
  equality_portrait5Caption: 'Emma Chacón A., une survivante de harcèlement et de discrimination sur son lieu de travail en raison de son activisme en faveur des droits des lesbiennes. © Emma Chacón A.',
  equality_portrait6Detail1: '<h3>Laurel, activiste pour les personnes vivant avec le VIH et les droits de l’homme</h3>\n\n<p>En 1991, Laurel Sprague a été diagnostiquée positive à l’âge de 20 ans. Très rapidement, elle est tombée malade, et beaucoup de ses amis de son réseau de soutien étaient aussi mourants.</p>\n\n<p>Presque immédiatement, elle a été victime de stigmatisation et de discrimination. Elle ne pouvait rien faire pour mettre fin à ce qui lui arrivait, mais elle a pu faire de son mieux pour être là pour ceux qui étaient dans la même situation qu’elle.</p>\n\n<p>Aujourd’hui, elle est une activiste luttant pour mettre fin à la discrimination, la criminalisation et les atteintes aux droits de l\'homme subies par des personnes vivant avec le VIH.</p>',
  equality_portrait6Detail2: '<h3>Combattre la discrimination à l\'égard des personnes atteintes du VIH</h3>\n\n<p>Les politiques et l’éducation sur les lieux de travail relatives au VIH, ainsi que la promotion du dépistage volontaire et des services de conseil, réduisent la stigmatisation sur le lieu de travail et permettent aux salariés de connaître leur statut VIH tôt et d’être traités s’ils sont séropositifs.</p>\n\n<p>La santé des travailleurs s’améliore avec le traitement. Ils prennent moins de congés de maladie et deviennent plus productifs dans leur emploi. Cela permet aux personnes séropositives de continuer de travailler et de s’occuper de leur famille.</p>',
  equality_portrait6Caption: 'Laurel Sprague, une activiste luttant pour mettre fin à la discrimination, la criminalisation et les atteintes aux droits de l\'homme subies par des personnes vivant avec le VIH © OIT',
  equality_portrait7Detail1: '<h3>Zainab, employée domestique</h3>\n\n<p>Zainab Ecumba a été harcelée durant des années. « Ils me traitaient comme un objet », dit-elle. « Mes employeurs soulevaient mes vêtements, ou, lorsque je dormais, ils s’amusaient souvent à venir me retirer la couverture. »</p>\n\n<p>Les employées domestiques sont vulnérables au harcèlement, car elles travaillent derrière des portes fermées où personne ne veille sur elles. Les étrangers croyaient rarement Zainab lorsqu’elle leur racontait son vécu.</p>',
  equality_portrait7Detail2: '<h3>Combattre la discrimination envers les travailleurs domestiques</h3>\n\n<p>Selon les statistiques de l’OIT, il y a 67 millions de travailleurs domestiques dans le monde. Ils s’occupent des familles, effectuent leur ménage et la cuisine, et représentent une part essentielle de l’économie des soins à la personne. Pourtant, seuls 10% d’entre eux bénéficient d’une protection au travail égale à celle des autres travailleurs.</p>\n\n<p>Ils gagnent généralement moins de la moitié du salaire moyen et sont responsables d’une partie significative de l’écart des salaires entre les hommes et les femmes au bas de l\'échelle du marché du travail.</p>',
  equality_portrait7Caption: 'Zainab Ecumba, employée domestique, victime de harcèlement, de violence et de discrimination dans les maisons de certains de ses employeurs durant des années. © Zainab Ecumba',
  equality_portrait8Detail1: '<h3>Leroy, entraîneur de football</h3>\n\n<p>Leroy Rosenior a été joueur de football professionnel dans les années 1980 et 1990. « La discrimination raciale était horrible » : dit-il. « Vous connaissez cette phrase : "Des bâtons et des pierres peuvent me briser les os, mais les mots ne me feront jamais de mal" ?  Eh bien, je crois que j\'ai déjà eu des fractures dans la moitié de mes os, mais la douleur physique disparaît rapidement. Les mots, cependant, sont demeurés en moi. »</p>\n\n<p>Ces dernières années, Leroy a travaillé avec Show Racism the Red Card pour sensibiliser les gens concernant la problématique du racisme dans le football et dans la société.  En mars 2019, il sera décoré du prestigieux Ordre de l’Empire Britannique (MBE). Il espère que le MBE attirera l\'attention sur le travail de l\'organisation et soulignera la nécessité de lutter contre la discrimination raciale dans le football et dans tous les lieux de travail.</p>',
  equality_portrait8Detail2: '<h3>Combattre la discrimination raciale</h3>\n\n<p>Si les hommes et les femmes victimes de discrimination raciale avaient plus de pouvoir économique, les gouvernements bénéficieraient de recettes supplémentaires sous forme d\'impôts sur le revenu et d\'impôts sur les sociétés.</p>\n\n<p>Par exemple, des recherches ont montré qu\'en France, le fait de mettre fin à la discrimination à l\'égard des personnes d\'ascendance nord-africaine entraînerait une augmentation de 2,0 % des recettes et une réduction de 0,5 % des dépenses publiques.</p>',
  equality_portrait8Caption: 'Leroy Rosenior, footballeur professionnel dans les années 80 et 90, a été victime de discrimination raciale sur le terrain et en dehors. © Show Racism the Red Card',
  equality_portrait1Detail3: '<h3>Combattre la discrimination à l\'égard des personnes handicapées</h3>\n\n<p>Un milliard de personnes, soit une personne sur sept dans le monde, a un handicap. Parmi elles, environ 800 millions sont en âge de travailler mais beaucoup sont exclues des lieux de travail.</p>',
  equality_portrait1Detail4: '<p>Cette situation les empêche de participer pleinement à la société et induit une perte de 3% à 7% du PIB mondial.</p> \n\n<p>Le réseau mondial Entreprises et handicap de l’OIT, qui comprend des multinationales employant plus de 4,2 millions de salariés, partage des connaissances et développe des moyens pour permettre aux entreprises et aux employeurs d’engager et de garder des personnes handicapées.</p>',
  equality_portrait2Detail3: '<p>L’Initiative de recrutement équitable de l’OIT vise à maximiser la protection des travailleurs migrants pendant le processus de recrutement</p>\n\n<p>La plateforme de conseils en recrutement aide à protéger les travailleurs migrants des pratiques abusives en matière d’emploi grâce aux avis d’utilisateurs sur les agences de recrutement.</p>',
  equality_portrait3Detail3: '<p>Une augmentation de 5% de la participation de ce groupe à l’emploi rémunéré se traduirait par une augmentation du PIB de 48 milliards de dollars des Etats-Unis soit 2,4% du revenu national.</p>\n\n<p>La Recommandation (n° 162) de l’OIT sur les travailleurs âgés, 1980, propose un cadre constructif que les pays peuvent suivre afin d’offrir des conditions de travail décentes et productives aux travailleurs âgés.</p>',
  equality_portrait4Detail3: '<h3>Combattre la discrimination des genres</h3>\n\n<p>L’égalité entre les femmes et les hommes renforce les économies, contribuant à leur croissance sur le long terme. Un rapport de l’Institut McKinsey Global a montré que progresser vers l’égalité des femmes permettrait au PIB mondial de croître de 12 000 milliards de dollars d’ici 2025.</p>\n\n<p>Tous les aspects du travail de l’OIT reflètent la volonté de faire progresser l’égalité entre les femmes et les hommes, qui est inscrite dans sa constitution et ses conventions fondamentales.</p>',
  equality_portrait5Detail3: '<h3>Combattre la discrimination vis-à-vis des personnes LGBTI</h3>\n\n<p>Tout pays peut bénéficier d’une meilleure défense des droits des lesbiennes, gays, bisexuels, transgenres et intersexués (LGTBI).</p>\n\n<p>Par exemple, une récente étude de la Banque mondiale en Inde estime qu\'en plus de la douleur et des souffrances subies par la communauté LGBT causées par la discrimination, l\'homophobie pourrait couter au pays la perte de jusqu’à 1,7% du PIB par an.</p>',
  equality_portrait5Detail4: '<p>Le projet de l\'OIT intitulé "L’identité de genre et l’orientation sexuelle: la promotion des droits, la diversité et l’égalité dans le monde du travail" (PRIDE) mène des recherches sur les discriminations à l’encontre des travailleurs LGBTI dans le monde et met en lumière les bonnes pratiques favorisant leur intégration sur le lieu de travail.</p> \n\n<p>Les pays où sont menées ces recherches sont l’Argentine, l’Afrique du Sud, le Costa Rica, la France, la Hongrie, l’Inde, l’Indonésie, le Monténégro, et la Thaïlande.</p>',
  equality_portrait6Detail3: '<p>L’initiative Conseil et dépistage volontaires du VIH (VCT@WORK) a permis à 6 millions de travailleurs de connaître leur statut VIH et d’obtenir un traitement, s’il y a lieu.</p>\n\n<p>Des initiatives de ce type contribuent à réduire le nombre de personnes incapables de travailler en raison de leur séropositivité. En 2005, 350 000 personnes n’étaient pas en mesure de travailler en raison de leur séropositivité. On espère que ce chiffre passera à 40 000 personnes d’ici 2020, soit 85% de réduction pour les hommes et 93% pour les femmes.</p>',
  equality_portrait7Detail3: '<p>Les mauvaises conditions de travail que subissent beaucoup d’entre eux sont enracinées dans diverses formes de discrimination : souvent, le travail domestique n’est pas considéré comme un travail mais comme faisant naturellement partie des responsabilités des femmes.</p>\n\n<p>La Convention (n° 189) sur les travailleuses et travailleurs domestiques, 2011, est la seule norme internationalement reconnue destinée à protéger les travailleurs domestiques à travers le monde et à améliorer leurs conditions de vie et de travail.</p>',
  equality_portrait8Detail3: '<p>L\'élimination de la discrimination raciale est au cœur de l\'action de l\'OIT depuis 1919 et est inscrite dans la convention (Nº 111) concernant la discrimination (emploi et profession), 1958.</p>\n\n<p>L\'obligation pour tous les États membres de respecter, promouvoir et mettre en œuvre ce principe a été réaffirmé dans la Déclaration de l\'OIT relative aux principes et droits fondamentaux au travail, adoptée en 1998.</p>',
  enterprises_heading: 'Entreprises durables',
  enterprises_chapter: 'chapitre 8',
  enterprises_title: 'Des entreprises pour nous tous et pour la planète',
  enterprises_date: 'Découvrez ce chapitre le 13 mars 2019',
  enterprises_infoAroundtheworld: '<p>Dans le monde, il y a une demande croissante d’entreprises motivées par plus que le simple profit. Les entreprises et les consommateurs cherchent de plus en plus des produits et des services qui ont un impact positif sur leur communauté, l’environnement et la société. </p>\n\n<p>On trouve parmi les entreprises durables des petites, moyennes, grandes entreprises et des multinationales, des coopératives, des entreprises «vertes» et des entreprises sociales qui trouvent un équilibre entre leurs propres besoins et intérêts et ceux des gens qui travaillent pour eux et de la société au sens large.</p>\n\n<p>La promotion de ce type d’entreprises est au cœur de l’Agenda du travail décent de l’OIT, qui s’articule sur la création d’emplois, les droits au travail, la protection sociale et le dialogue social. L’Agenda du travail décent est lié aux objectifs mondiaux des Nations Unies, les Objectifs de développement durable, qui placent les emplois décents au centre des politiques économiques et du développement. Ce type de développement profite à tout le monde maintenant et à l’avenir, sans nuire à l’environnement.<p>',
  enterprises_infoTherightenviro: '<h3>Le bon environnement pour les bonnes entreprises</h3>\n\n<p>Les entreprises durables ne peuvent pas exister dans le vide. Il faut un environnement approprié au niveau régional, national et local pour induire l’innovation et la croissance permettant d’obtenir des emplois plus nombreux et de meilleure qualité . L’OIT s’implique à toutes les étapes de cette transition.</p>\n\n<p>L’OIT aide les parties prenantes, les individus, les entreprises et les institutions nationales à créer un environnement permettant aux entreprises de toute taille et de toute forme d’atteindre les Objectifs de développement durable grâce à son programme «Un environnement favorable aux entreprises durables».</p>',
  enterprises_infoInclusive: '<h3>Des lieux de travail inclusifs, responsables et durables</h3>\n\n<p>Au cœur du travail de l’OIT se trouve l’idée que le tripartisme – qui rassemble gouvernements, représentants des employeurs et des travailleurs pour obtenir un consensus – est essentiel pour créer des entreprises qui travaillent pour les gens et la planète. </p>\n\n<p>La Déclaration de principes tripartite sur les entreprises multinationales et la politique sociale de l’OIT, appelée la Déclaration sur les EMN, est le seul document juridique mondial qui donne des conseils spécifiques à ces entreprises sur la politique sociale et les pratiques inclusives, responsables et durables au travail.</p>\n\n<p>Cette déclaration, élaborée et adoptée par les gouvernements, les employeurs et les travailleurs du monde entier, vise à encourager les contributions positives que les multinationales peuvent apporter au progrès économique et social et à la réalisation du travail décent pour tous. Elle cherche également à minimiser et à résoudre les difficultés que peuvent rencontrer les entreprises au cours de ce processus. </p>\n\n<p><mark id="enterprises_infoInclusive">L’OIT aide les gouvernements, les entreprises et les entrepreneurs à utiliser les conseils fournis par la Déclaration sur les EMN.</mark></p>',
  enterprises_detailInclusive1: '<h3>Le travail décent et les jeux olympiques</h3>\n\n<p>L’OIT a créé un partenariat unique avec le comité d’organisation des Jeux olympiques et paralympiques de Tokyo 2020, et utilise les directives de la déclaration sur les multinationales pour promouvoir les pratiques socialement responsables au travail auprès des entreprises qui contribuent aux Jeux olympiques de 2020.</p>',
  enterprises_infoEntrepreneurship: '<h3>Des outils pour l’entreprenariat afin de réussir</h3>\n\n<p>L’OIT aide les personnes et les organisations à obtenir, améliorer et préserver les compétences et les connaissances dont ils ont besoin pour développer des entreprises durables. </p>\n\n<p>Explorez deux de nos programmes:</p>\n\n<ul>\n<li>Gérez mieux votre entreprise (GERME)</li>\n<li>Tout savoir sur l’entreprise</li>\n</ul>',
  enterprises_visStartyourbusHeading: 'Créer votre entreprise',
  enterprises_visKnowyourbusHeading: 'Connaître votre entreprise',
  enterprises_visKnowyourbusBody: 'Que voulez-vous savoir sur les entreprises?',
  enterprises_visStartyourbusBody: 'L’un des plus importants programmes de formation à la gestion des entreprises dans le monde',
  enterprises_visStartyourbusDetail1: '<h3>L’un des plus importants programmes de formation à la gestion des entreprises dans le monde</h3>\n\n<p>Avec un réseau de plus de 65 000 formateurs dans plus de 100 pays et plus de 15 millions d’entrepreneurs formés, le programme Gérez mieux votre entreprise (GERME) est l’un des plus grands programmes de formation des entrepreneurs dans le monde aujourd’hui.</p>',
  enterprises_visKnowyourbusDetail1: '<h3>Que voulez-vous savoir sur les entreprises?</h3>\n\n<p>Pour encourager l’esprit d’entreprise chez les jeunes, le programme Tout savoir sur l’entreprise aide les jeunes femmes et les jeunes gens de 15 à 24 ans à mieux comprendre le monde des affaires. Il les sensibilise aux opportunités et aux défis de l’entreprenariat et de l’emploi indépendant. </p>',
  enterprises_infoHelpingbus: '<h3>Aider les entreprises à réussir</h3>\n\n<p>L’OIT fournit des formations et des conseils aux entreprises existantes pour les aider à adopter des pratiques responsables, durables et inclusives.</p>\n\n<p>Nos programmes couvrent tous les aspects, des conseils pour améliorer la productivité et les conditions de travail aux services de soutien pour les femmes qui souhaitent créer et gérer leur entreprise. Ces programmes comprennent: </p>\n\n<ul>\n<li>My.COOP</li>\n<li>Des entreprises durables, compétitives et responsables (SCORE)</li>\n<li>Le développement de l’entreprenariat des femmes (WED)</li>\n</ul>',
  enterprises_visAgriCoopHeading: 'Coopératives agricoles',
  enterprises_visAgriCoopDetail1: '<h3>My.COOP</h3>\n\n<p>Dans les coopératives agricoles, les agriculteurs mettent en commun leurs ressources dans certains domaines d’activité pour réduire les coûts et bénéficier d’économies d’échelle. My.COOP est un programme de formation de l’OIT destiné aux dirigeants de coopératives agricoles potentielles ou existantes. </p>',
  enterprises_visResponsibleHeading: 'Des entreprises responsables',
  enterprises_visResponsibleDetail1: '<h3>Des entreprises durables, compétitives et responsables (SCORE)</h3>\n\n<p>Destinée aux petites et moyennes entreprises des marchés émergents dans les chaines d’approvisionnement mondiales et locales, cette formation et ces conseils en entreprise permettent d’améliorer la productivité et les conditions de travail. SCORE couvre des domaines tels que la coopération sur le lieu de travail, la gestion de la qualité, et la sécurité et la santé au travail. </p>',
  enterprises_visWomenentrepHeading: 'L’entreprenariat des femmes',
  enterprises_visWomenentrepDetail1: '<h3>Le développement de l’entreprenariat des femmes (WED) </h3>\n\n<p>Le programme WED de l’OIT propose des formations et des services de soutien aux femmes pour les aider à s’autonomiser. Ce programme travaille avec les gouvernements, les organisations d’employeurs et de travailleurs et les fournisseurs de services afin d\'améliorer leurs services et les diffuser auprès des femmes.  </p>',
  enterprises_infoAddresslegal: '<h3>Répondre aux défis juridiques</h3>\n\n<p>Même les entreprises les mieux intentionnées peuvent avoir du mal à aligner leurs activités sur les normes internationales du travail et à établir de bonnes relations professionnelles. </p>\n\n<p>C’est là qu’intervient le service d’assistance aux entreprises sur les normes internationales du travail de l’OIT. Il s’agit d’un outil juridique confidentiel destiné aux dirigeants et aux travailleurs d’une entreprise pour leur donner des informations et des conseils sur un large éventail de sujets liés au travail, provenant tous de la Déclaration sur les entreprises multinationales. </p>\n\n<p>Les entreprises ont ainsi bénéficié d’un soutien pour lutter contre le travail des enfants, le travail forcé, la discrimination et les inégalités sur le lieu de travail, et améliorer la sécurité et la santé au travail, pour ne citer que quelques exemples. </p>',
  enterprises_infoRethinking: '<h3>Repenser notre façon de faire des affaires</h3>\n\n<p>Les défis d’aujourd’hui – le chômage, l’exclusion, les inégalités, les chocs économiques, la pauvreté et le réchauffement climatique – nous obligent à repenser notre façon de faire des affaires.</p>\n\n<p>L’émergence des entreprises sociales – entreprises aux objectifs économiques et sociaux explicites, souvent liés à l’environnement, dans le cadre d’une économie sociale et solidaire croissante – est l’un des résultats de ce processus.</p>\n\n<p>Contrairement aux entreprises à but lucratif, ces entreprises sont souvent issues d’associations et de coopératives locales, d’associations de commerce équitable et d’ONG. Beaucoup sont bénévoles et motivées par une cause particulière telle que les droits des femmes, ce qui renforce leur influence dans l’élaboration des politiques dans le monde. </p>',
  enterprises_calloutIntheeu: 'Dans l’Union européenne, environ 10% du nombre total des entreprises et 6% de l’emploi total appartiennent à l’économie sociale et solidaire; beaucoup sont des coopératives.',
  enterprises_visWhyrcoops: 'Pourquoi les coopératives sont-elles importantes?',
  enterprises_visWhyrcoopsDetail1: '<h3>Coopérer pour un travail décent</h3>\n\n<p>Les coopératives sont un pilier essentiel de l’économie sociale et solidaire. Elles sont uniques parmi les entreprises durables car la propriété y est collective et le pouvoir contrôlé démocratiquement par leurs membres. </p>\n\n<p>Il existe des coopératives dans tous les secteurs de l’économie; elles génèrent au moins 279 millions d’emplois dans le monde. </p>',
  enterprises_visWhyrcoopsDetail2: '<p>Comme le reconnaît la Recommandation (n° 193) de l’OIT sur la promotion des coopératives, 2002, les coopératives détenues par leurs membres et démocratiquement contrôlées contribuent à créer des emplois de qualité, à mobiliser des ressources et à créer des investissements, y compris pour les populations défavorisées. </p>\n\n<p>L’ONU estime que les coopératives renforcent la sécurité des moyens de subsistance de 3 milliards de personnes grâce aux services d\'alimentation, de protection sociale, d’énergie, de santé et financiers qu’elles fournissent.</p>\n\n<p>De nos jours, les coopératives représentent de 3 à 3,5% du PIB mondial, les 2 000 plus grandes coopératives dans 56 pays réalisant un chiffre d’affaires total de plus de 2,5 milliards de dollars des Etats-Unis. </p>',
  enterprises_calloutCooperatives: 'Les coopératives contribuent à l\'objectif général de l’OIT de créer un travail décent pour tous par le biais d’emplois productifs, de la protection sociale et du respect des droits. Elles proposent également un forum au cours duquel les gens peuvent exprimer leurs préoccupations, s’organiser et participer aux décisions qui ont une incidence sur leurs vies.',
  enterprises_calloutTheilosupports: 'L’OIT soutient l’économie sociale et solidaire avec des actions de sensibilisation, de partenariat, de formation et de conseil politique.',
  enterprises_calloutPositiveimpact: 'L’impact positif des entreprises durables est un élément essentiel à prendre en considération au moment où l’OIT se tourne vers l’avenir du travail.',
  enterprises_calloutAfuture: 'Un avenir dans lequel nous voudrions tous vivre.',
  enterprises_imageNuragroCaption: 'Des travailleurs de l’association Nuragro en Colombie reçoivent une formation de l’OIT. © OIT',
  enterprises_imageNuragroAlt: 'Trois travailleurs sur une exploitation agricole déplacent des plantes.',
  enterprises_imageLakumbaCaption: 'Travailleuse de l’association La Kumba, Colombie, lors d\'une formation de l’OIT. © OIT',
  enterprises_imageLakumbaAlt: 'Une femme tient un panier plein de fraises dans une serre.',
  enterprises_imageTechnicianCaption: 'Un technicien sur un site de production d’une multinationale fabriquant des détergents pour la lessive au Caire, Egypte. © OIT/M. Crozet',
  enterprises_imageTechnicianAlt: 'Un homme en uniforme bleu avec un masque et des lunettes de protection dans une usine pleine de machines.',
  enterprises_imageSiybCaption: 'Formation GERME, Burundi. © OIT',
  enterprises_imageSiybAlt: 'Une formatrice tient un dessin d\'un vendeur de fruits avec un client.',
  enterprises_imageKabCaption: 'Participants à une formation du programme Tout savoir sur l’entreprise en Syrie. © OIT',
  enterprises_imageKabAlt: 'Trois stagiaires hommes et deux stagiaires femmes sont assis autour d’un bureau et discutent du contenu de leur classeur.',
  enterprises_imageKhouryCaption: 'La créatrice de vêtements, Lara Khoury, dans son studio à Beyrouth, Liban. © OIT/J. Saad',
  enterprises_imageKhouryAlt: 'Une femme accoudée à une table dessine sur une feuille de papier, un assortiment de tissus et des magazines autour d’elle.',
  enterprises_imageMycoopCaption: 'Formation My.Coop, Mongolie. © OIT',
  enterprises_imageMycoopAlt: 'Quatre stagiaires hommes et une stagiaire femme sont assis autour d’un bureau et écrivent sur une grande feuille de papier les éléments à présenter.',
  enterprises_imageScoreCaption: 'Travailleurs d’une usine d’emballage d’orange dans le cadre du programme SCORE de l\'OIT, Pérou. © OIT',
  enterprises_imageScoreAlt: 'Quatre travailleurs avec des gilets de protection blancs et des casquettes trient des oranges sur un tapis roulant.',
  enterprises_imageWedCaption: 'Josepha participe au programme Développement de l’entreprenariat des femmes (WED) de l’OIT et au programme d’autonomisation économique en Tanzanie. © OIT/D. Debru',
  enterprises_imageWedAlt: 'Une femme parle à une autre personne de l’autre côté de son bureau.',
  enterprises_imageChildhuachipaCaption: 'Enfant travailleur dans une briqueterie, Huachipa, Pérou. © OIT/E. Gianotti',
  enterprises_imageChildhuachipaAlt: 'Enfant aux vêtements sales et aux mains pleines de boue.',
  enterprises_imageWomencoopCaption: 'Femmes membres d’une coopérative à Dar Es Salaam, Tanzanie. © OIT/M. Crozet',
  enterprises_imageWomencoopAlt: 'Deux femmes dans une grande coopérative d’élevage de poulets tiennent des poules blanches dans leurs mains.',
  enterprises_imageFarmerseedsCaption: 'Des agriculteurs achètent des semences à une coopérative à Kaolack, Sénégal © Banque mondiale/D. Van Leggelo-Padilla',
  enterprises_imageFarmerseedsAlt: 'Deux hommes debout devant des piles de sacs de semences discutent avec un papier et une calculatrice dans les mains.',
  enterprises_imagePalestinianCaption: 'La formatrice palestinienne Eman Abu Medeen au Centre pour enfants "Children\'s Land" financé par l’OIT à Um Al-Nasser, un village bédouin  dans le Nord de la bande de Gaza. © S. Jadallah',
  enterprises_imagePalestinianAlt: 'Une femme debout à côté de poupées et de jouets en bois faits à la main.',
  enterprises_visStartyourbusDetail2: '<p>GERME possède quatre programmes de formation qui répondent à toutes les étapes de développement d’une entreprise: de la création de votre idée d’entreprise au démarrage, à l’amélioration et au développement de votre entreprise. Il couvre tout, de l’élaboration d’un plan d’entreprise à la gestion des stocks, la commercialisation, le calcul des coûts, les registres et la gestion des ressources humaines.</p>',
  enterprises_visKnowyourbusDetail2: '<p>Au cours de la dernière décennie, le programme Tout savoir sur l’entreprise a formé plus de 14 000 enseignants et plus de 3 millions de jeunes aux compétences essentielles pour l’entreprenariat, comme la communication, la négociation et la planification. </p>\n\n<p>Les matériels pédagogiques de ce programme ont été utilisés dans l’enseignement secondaire public et privé, dans les institutions de formation professionnelle et technique, et même dans l’éducation supérieure. Le programme Tout savoir sur l’entreprise, présent dans 56 pays du monde, fait maintenant partie des programmes scolaires nationaux de 18 de ces pays. </p>',
  enterprises_visAgriCoopDetail2: '<p>My.COOP répond aux nombreux défis rencontrés par les dirigeants de coopératives. Ce programme se fonde sur l’idée qu’une meilleure gestion se traduira par une augmentation de la qualité et de l’efficacité des services de la coopérative. Il fournit des orientations sur la façon de satisfaire les membres, les débouchés commerciaux et les considérations sociales.</p>',
  enterprises_visWomenentrepDetail2: '<p>Au cours des 15 dernières années, le programme WED de l’OIT a formé plus 1,5 million de femmes à la gestion d’entreprises et a équipé plus de 75 prestataires de services afin de mieux répondre aux besoins des femmes. Il a également réalisé 25 évaluations de pays pour aider les partenaires sociaux à mettre en œuvre des plans d’action visant à améliorer l’environnement pour permettre le développement de l’esprit d’entreprise chez les femmes.</p>',
  enterprises_visResponsibleDetail2: '<p>Plus de 1 400 petites et moyennes entreprises dans le monde ont bénéficié de la formation SCORE depuis son lancement. Cela représente plus de 300 000 travailleurs qui ont bénéficié d’améliorations dans leur entreprise, notamment d’efforts pour développer une culture du respect, de la confiance et de la communication sur le lieu de travail. </p>\n\n<p>De plus, la formation SCORE a amélioré la productivité des entreprises participantes de 50% et affiche un taux de satisfaction de 88%. </p>',
  enterprises_imageTokyoCaption: 'Guy Ryder, Directeur général de l\'OIT, et Toshiro Muto, Directeur général des Jeux olympiques et paralympiques de Tokyo 2020. © Uta Mukuo',
  enterprises_detailInclusive2: '<p>Au Pakistan, l’OIT a créé un partenariat avec le gouvernement et les organisations d’employeurs et de travailleurs pour instaurer des pratiques d’entreprises durables afin d’éliminer le travail des enfants  de l’industrie des ballons de football à Sialkot, fournisseur des grandes marques mondiales. </p>',
  enterprises_visWhyrcoopsDetail3: '<p>Les banques coopératives ont été un moteur essentiel de la reprise économique après la crise financière mondiale; les banques coopératives européennes servent plus de 215 millions de clients et détiennent une part de marché moyenne  d\'environ 20%. </p>\n\n<p>Depuis l’adoption de la recommandation n° 193, près de 100 pays ont réexaminé, révisé ou adopté des politiques ou des lois sur les coopératives. Cela a contribué à modifier la perception des coopératives pour voir en elles une forme d’entreprise viable.  </p>',
  conditions_heading: 'Conditions de travail',
  conditions_chapter: 'chapitre 7',
  conditions_title: 'Comment ça a été au boulot aujourd’hui ?',
  conditions_date: 'Découvrez ce chapitre le 27 février 2019',
  conditions_visTimeTitle: 'La durée de travail dans le monde',
  conditions_visTimeSelect: 'Rechercher un pays',
  conditions_visTimeSharesLabel: 'Pourcentage de salariés',
  conditions_visTimeColour1: 'Travaillant <strong>moins de 35 heures</strong> par semaine',
  conditions_visTimeColour2: 'Travaillant de <strong>35 à 48 heures</strong> par semaine',
  conditions_visTimeColour3: 'Travaillant <strong>plus de 48 heures</strong> par semaine',
  conditions_visTimeNote: 'Données de 2018 ou dernières données disponibles. Source : ILOSTAT',
  conditions_infoWhenwego: '<p>Quand on va travailler, on espère gagner suffisamment d’argent pour vivre bien, acheter de quoi manger, payer ses factures, et faire face à des situations difficiles comme une maladie ou un nouvel enfant. </p>\n\n<p>On espère avoir suffisamment de temps libre pour se détendre et passer du temps en famille, et même qu’il reste assez d’argent pour profiter des petits plaisirs de la vie.</p>\n\n<p>Quand on arrive sur son lieu de travail, on espère être en sécurité, ne pas y mourir ni avoir une lésion ou une maladie qui changera notre vie et nous rendra incapable de joindre les deux bouts ou nous plongera dans la pauvreté.</p>\n\n<p>En d’autres termes, on espère avoir des conditions de travail décentes.</p>',
  conditions_calloutTheseshouldnot: 'Ça ne devrait pas seulement être des attentes. Ce sont des droits.',
  conditions_calloutManymillions: 'Des millions d’entre nous bénéficient de ces droits, grâce aux conventions de l’OIT qui ont été adoptées année après année par les états membres, et qui portent sur la sécurité et la santé au travail, le temps de travail, le salaire minimum et la protection de la maternité, entre autres. Mais des millions de personnes n’en bénéficient pas.',
  conditions_infoSafetyinworkplace: '<h3>La sécurité sur le lieu de travail</h3> \n\n<p>D’après les statistiques de l’OIT, il y a tous les ans, 2,78 millions de personnes qui meurent des suites d’une exposition à des dangers pour la sécurité et la santé au travail : soit plus de 5 personnes qui meurent par minute ! </p>\n\n<p>374 millions d’autres travailleurs subissent des accidents du travail non mortels chaque année. </p>\n\n<p>En plus de la souffrance causée par les accidents du travail, ces derniers ont un coût économique important pour les travailleurs et leurs familles, les employeurs et les économies dans leur ensemble. On a calculé que les lésions mortelles et non mortelles, les affections et les maladies liées au travail coûtent environ 4% du PIB mondial. </p>',
  conditions_infoIloflagship: '<p>Le programme phare de l’OIT « L’initiative mondiale pour une action préventive dans le domaine de la sécurité et de la santé du travail » aide les états membres à développer les capacités nécessaires pour améliorer la sécurité et la santé au travail dans leur pays. </p>\n\n<p>Nous travaillons dans plus de 140 pays pour :</p>\n\n<ul>\n<li><mark id="conditions_infoIloflagship">Encourager les investissements publics et privés dans la prévention des décès, des lésions et des maladies liés au travail.</mark></li>\n<li>Former les inspecteurs du travail et diffuser les bonnes pratiques auprès des employeurs et des travailleurs pour améliorer la sécurité et la santé sur leurs lieux de travail.</li>\n<li>Développer des législations efficaces et créer des systèmes de collecte des données sur la sécurité et la santé.</li>\n<li>Intégrer la sécurité et la santé dans l’éducation et la formation des compétences pour les jeunes travailleurs.<li>\n</ul>',
  conditions_infoHowfaryourmoney: '<h3>Jusqu’où allez-vous avec votre argent ?</h3>\n\n<p>Des millions de personnes luttent pour joindre les deux bouts. Avoir un salaire minimum peut les empêcher de plonger dans la pauvreté. Le salaire minimum peut également aider à sortir de la pauvreté et réduire les inégalités entre les femmes et les hommes. Il complète et renforce les politiques de négociation collective, utilisées pour déterminer les conditions d’emploi et de travail. </p>\n\n<p>Aujourd\'hui, plus de 90% des états membres de l’OIT ont une forme de salaire minimum. L’OIT collabore avec les gouvernements pour veiller à ce que le salaire minimum soit fixé à un niveau qui prenne en compte les besoins des travailleurs et de leurs familles, et ceux de l’économie dans son ensemble. </p>\n\n<p>Il s’agit d’un des domaines essentiels de l’action de l’OIT, inscrit dans des conventions comme la convention (n° 131) sur la fixation des salaires minima, 1970.  </p>',
  conditions_detailVisionzero1: '<h3>Le Fonds Vision zéro</h3>\n\n<p>En 2013, 1 137 travailleurs ont été tués au Bangladesh, dans une usine de vêtements dans le complexe du Rana Plaza. Suite à cette catastrophe, les dirigeants du G7 ont mis en place un nouveau fonds mondial, appelé le « Fonds Vision zéro » pour financer l’amélioration de la sécurité et la santé au travail dans les chaines d’approvisionnement mondiales.</p>',
  conditions_infoLimitinghours: '<h3>Limiter la durée excessive du travail </h3>\n\n<p>Les préoccupations sur la durée excessive du travail ont influencé la toute première convention de l’OIT adoptée en 1919, qui limitait la durée passée par les travailleurs dans leur emploi par jour et par semaine. </p>\n\n<p>Aujourd’hui, les normes de l’OIT sur la durée de travail sont au cœur de la relation d’emploi. </p>\n\n<p>Ces normes portent sur des sujets comme le nombre d’heures de travail, les périodes de repos nécessaires, et même le congé annuel. </p>\n\n<p>En outre, les normes de l’OIT contribuent à l’équilibre entre vie professionnelle et vie personnelle et jouent un rôle essentiel pour encourager les entreprises à préserver la santé et la productivité de leurs travailleurs. </p>',
  conditions_vis1994: 'en 1994',
  conditions_infoJobparents: '<h3>La sécurité de l’emploi pour les parents</h3>\n\n<p>Les travailleurs qui ont des enfants peuvent voir leur sécurité de l’emploi menacée. </p>\n\n<p>L’OIT reconnaît la protection de la maternité comme un droit fondamental au travail, essentiel pour promouvoir la santé et le bien-être des mères et de leurs enfants. Elle permet d’atteindre l’égalité entre les hommes et les femmes et fait progresser le travail décent pour les femmes et pour les hommes.</p>\n\n<p>Presque tous les pays ont adopté des lois sur la protection de la maternité au travail. Les mesures de soutien aux travailleurs qui ont des responsabilités familiales, y compris les pères, sont en augmentation dans le monde.</p>',
  conditions_vis38Percent: '<p class="bubble-percent">38%</p>',
  conditions_visCountriesmaternity: 'des pays accordaient au moins 14 semaines de congé de maternité',
  conditions_vis2013: 'en  2013',
  conditions_vis51Percent: '<p class="bubble-percent">51%</p>',
  conditions_calloutHoweverimprove: 'Toutefois, pour améliorer les conditions de travail de tous les travailleurs, il faut qu’un plus grand nombre de pays ratifient les conventions de l’OIT sur la protection de la maternité',
  conditions_vis34: '34',
  conditions_visRatified183: 'pays ont ratifié la convention de la protection de la maternité de l’OIT au 1er janvier 2019',
  conditions_visSeeratifications: 'Voir les ratifications',
  conditions_infoNewways: '<h3>De nouvelles façons de travailler</h3>\n\n<p>On voit apparaitre maintenant de nouvelles façons de travailler avec les innovations technologiques, et les modifications de la structure traditionnelle du travail.</p>\n\n<p>Elles comprennent le temps partiel, le travail temporaire, le travail intérimaire, le travail au contrat – lorsque des personnes travaillent sur des projets à court terme en dehors des relations traditionnelles salarié-employeur. On les appelle les emplois atypiques, qui posent des problèmes au niveau des conditions de travail.</p>',
  conditions_carouselIsyourjob: 'Votre emploi est-il atypique ?',
  conditions_carouselYesBody1: 'Oui, si vous êtes embauché seulement pour une durée limitée',
  conditions_carouselYesCaption1: 'On appelle ça l’emploi temporaire et c’est un emploi atypique : il comprend les contrats à durée déterminée, les contrats relatifs à un projet ou une tâche, le travail saisonnier et le travail occasionnel, y compris le travail journalier.',
  conditions_carouselYesBody2: 'Oui, si vous travaillez moins d’heures que les gens qui ont un emploi à plein temps comparable',
  conditions_carouselYesCaption2: 'C’est l’emploi à temps partiel et c’est un emploi atypique. Il permet à des travailleurs de combiner le travail et l’éducation de leurs enfants, les études ou la formation. Toutefois, le nombre d’heures de travail peut être très réduit et imprévisible, comme pour les contrats de zéro heures, où l’employeur n’est pas obligé de fournir un minimum d’heures de travail.',
  conditions_carouselYesBody3: 'Oui, si vous êtes payé par une entreprise mais que vous travaillez pour une autre',
  conditions_carouselYesCaption3: 'On appelle ça les relations d’emploi multipartites et c’est un emploi atypique. Cela se produit souvent lorsqu’un travailleur est payé par une entreprise – une agence d’intérim ou un sous-traitant – mais qu’il réalise son travail pour une autre entreprise sur le lieu de travail de cette dernière. Il n’y a généralement pas de relations d’emploi entre le travailleur et l’entreprise à laquelle il fournit ses services.',
  conditions_carouselYesBody4: 'Oui si vous êtes un prestataire indépendant mais que vous êtes supervisé comme si vous étiez un salarié',
  conditions_carouselYesCaption4: 'Cela s’appelle des relations d’emploi déguisées et c’est un emploi atypique. Lorsque les travailleurs sont surveillés par des superviseurs comme des salariés, alors qu’ils sont engagés en tant que prestataires indépendants, la vraie nature de la relation de travail est cachée pour éviter de respecter les lois du travail.',
  conditions_carouselYesBody5: 'Oui, si vous êtes indépendant juridiquement, mais économiquement dépendant',
  conditions_carouselYesCaption5: 'Cela s’appelle l’emploi non salarié dépendant, et c’est une zone grise. Ce sont les situations où un travailleur fournit des services à une entreprise dans le cadre d’un contrat commercial, alors que les revenus de ce travailleur dépendent très fortement d’un très petit nombre de clients.',
  conditions_calloutNonstdemp: 'L’emploi atypique est très répandu dans les pays industrialisés comme dans les pays en développement, dans tous les secteurs économiques et toutes les professions.',
  conditions_callout150Countries: 'Dans plus de 150 pays, 11% des travailleurs du secteur privé sont des employés temporaires. Les femmes représentent moins de 40% du total des salariés, mais elles représentent 57% des salariés à temps partiel.',
  conditions_infoNonstdjobs: '<p>Les emplois atypiques permettent d’accéder plus facilement au marché du travail, et potentiellement d’avoir un meilleur équilibre entre vie professionnelle et vie personnelle. Cependant, les travailleurs dans des emplois atypiques peuvent être exclus de la protection sociale et de la sécurité sociale et avoir moins d’accès à la formation et aux opportunités de développement de carrière.</p>\n\n<p>Ils participent aussi moins aux négociations collectives et ont un risque plus élevé de discrimination.</p>\n\n<p>Pour de nombreux travailleurs, les contrats à durée déterminée, en fonction d’un projet, saisonniers, temporaires ou le travail à la demande sont associés à une plus grande insécurité, qui touche beaucoup plus les femmes.</p>\n\n<p>Toutefois, que vous soyez dans un emploi normal ou un emploi atypique, tous les emplois devraient être décents.</p>',
  conditions_infoSourcing: '<h3>Des approvisionnements dans le monde entier, des ventes dans le monde entier</h3>\n\n<p>La mondialisation a beaucoup complexifié le monde de l’entreprise et du commerce. La plupart des entreprises s’approvisionnent maintenant dans le monde entier, vendent dans le monde entier, ou sont en concurrence avec une entreprise qui le fait.</p>\n\n<p>Cela signifie que les entreprises traitent maintenant avec un réseau de fournisseurs, de fabricants, d’entrepôts, de centres de distribution, de grossistes et de détaillants. </p>\n\n<p>On appelle ça une chaine d’approvisionnement mondiale et c’est l’une des évolutions majeures du monde du travail ces 50 dernières années.</p>\n\n<p>L’une des questions principales qui découle de l’émergence de ces chaines d’approvisionnement mondiales est celle des conditions de travail tout au long de la chaine. </p>',
  conditions_infoInmanycountries: '<p>Dans beaucoup de pays, les chaines d’approvisionnement mondiales créent de l’emploi et des opportunités de développement socioéconomique.</p>\n\n<p>Lorsque les employeurs respectent le droit du travail et les normes internationales du travail, cela renforce le travail décent, améliore la productivité et la croissance et permet aux travailleurs d’avoir des emplois qui nécessitent plus de compétences, avec de meilleures conditions et de meilleurs salaires. </p>',
  conditions_calloutButpoor: 'Mais les mauvaises conditions de travail et de relations d’emploi dans certaines chaines d’approvisionnement mondiales peuvent avoir des conséquences catastrophiques.',
  conditions_infoCollapseranaplaza: '<p>1 500 personnes sont décédées dans l’effondrement du bâtiment du Rana Plaza en 2013, et dans les incendies d’usines au Pakistan et au Bangladesh en 2012, ce qui a suscité un nouvel appel en faveur d’une action mondiale pour obtenir des conditions de travail décentes dans les chaines d’approvisionnement mondiales.</p>\n\n<p><mark id="conditions_infoCollapseranaplaza">L’OIT est en première ligne de ce combat. </mark></p>\n\n<p>Suite à la catastrophe du Rana Plaza, plus de 800 000 travailleurs Bangladais ont été formés aux mesures de base de sécurité et de santé, et les victimes blessées ont obtenu un soutien. Près de 300 survivants ont été formés à des compétences nécessaires pour subvenir à leurs besoins, plus de 3 000 ont bénéficié d’une orientation professionnelle, et 66 ont reçu une aide psychosociale.</p>',
  conditions_detailBetterwork1: '<h3>Better Work</h3>\n\n<p>Better Work est une collaboration entre l’OIT et la Société financière internationale (SFI), membre du groupe de la Banque mondiale, dont l’objectif est d’améliorer les conditions de travail dans l’industrie de l’habillement et promouvoir le respect des droits du travail des travailleurs, tout en renforçant la compétitivité des entreprises de l’habillement. Better Work rassemble toutes les parties prenantes du secteur de l’habillement pour y arriver. </p>',
  conditions_infoChallengesarise: '<p>Les défis surgissent lorsque des entreprises prennent des décisions qui ont une incidence sur les conditions de travail sans être directement responsables de ceux qui effectuent le travail. </p>\n\n<p>Les sous-traitants peuvent ne pas respecter la législation du travail, ce qui se traduit dans certains cas par de l’emploi informel, de mauvaises conditions de travail, voire du travail forcé et du travail des enfants. </p>\n\n<p>Comme l’approvisionnement des biens et des services hors des frontières rend le contrôle difficile, tous les gouvernements ne sont pas en mesure de faire face aux transformations rapides induites par l’exposition à l’économie mondiale et aux chaines d’approvisionnement mondiales. </p>',
  conditions_calloutSolution: 'La solution : promouvoir les normes internationales du travail, un meilleur contrôle des lois existantes et le renforcement du dialogue social.',
  conditions_calloutThroughitswork: 'Grâce à son travail dans ce domaine, l’OIT est aux avant-postes pour aider à implanter le travail décent dans les chaines d’approvisionnement mondiales.',
  conditions_infoTheilohelps: '<p>L’Organisation aide ses états membres à améliorer la sécurité et la santé au travail, instaurer des salaires minima et élaborer des lois pour améliorer les conditions de travail. </p>\n\n<p>Par le biais de la formation, du partage des connaissances et du travail avec des partenaires du secteur public et du secteur privé, l’OIT a montré qu’il est possible d’améliorer les conditions de vie de millions de travailleurs des chaines d’approvisionnement mondiales. </p>',
  conditions_calloutPromotingsafe: 'Promouvoir des conditions de travail sûres et productives est au cœur du travail de l’OIT depuis un siècle.',
  conditions_calloutFromthefactories: 'Des usines de la révolution industrielle aux défis suscités par l’économie numérique et les chaines d’approvisionnement mondiales, l’instauration de conditions de travail décentes continue d’être une priorité et une voie permettant aux entreprises du futur de devenir durables.',
  conditions_captionVietnam: 'Un jeune ouvrier du bâtiment sans équipement de sécurité approprié, Hanoi, Viet Nam. © OIT / M. Fossat',
  conditions_imageConstructionAlt: 'Jambes d\'un ouvrier avec des sandales debout sur les barres d\'un échafaudage au-dessus du sol.',
  conditions_imageFactoryCaption: 'Un employé d\'une usine de fabrication de fromage. Montevideo, Uruguay. © OIT / M. Fossat',
  conditions_imageFactoryAlt: 'Un homme vêtu d\'une combinaison blanche, d\'un masque, de gants et d\'un tablier industriel tenant un tuyau d\'arrosage pour nettoyer le sol.',
  conditions_imageMadagascarCaption: 'Les travailleurs exigent des salaires plus élevés et de meilleures conditions de travail lors d\'une grève de chat sauvage dans une usine de confection à Antananarivo, à Madagascar. © OIT / M. Fossat',
  conditions_imageMadagascarAlt: 'Un couloir d\'usine rempli de travailleuses. L\'un d\'entre eux tient une pancarte en carton indiquant «augmentez nos salaires».',
  conditions_imagePlatformCaption: 'Une femme qui travaille à la maison avec son enfant. © iStock',
  conditions_imagePlatformAlt: 'Une femme à lunettes assise à une table de cuisine et utilisant un ordinateur. Un bébé est assis sur une chaise haute à côté d\'elle.',
  conditions_imageCateringCaption: 'Une femme travaille dans les services de restauration, États-Unis. © J. Maida',
  conditions_imageCateringAlt: 'Une femme habillée professionnellement tenant un grand plateau plein de bouchées.',
  conditions_captionGarmentHaiti: 'Ouvriers d\'usine de vêtement en Haïti. © OIT',
  conditions_imageHaitiAlt: 'Une vue aérienne d\'une usine remplie de machines à confectionner et de tissus de différentes couleurs.',
  conditions_captionGarmentBang: 'Ouvriers d\'usine de vêtement au Bangladesh. © OIT',
  conditions_imageBangladeshAlt: 'Femmes portant des hijabs verts et des masques hygiéniques assis à une longue table et utilisant des machines à coudre.',
  conditions_captionMyanmarSafety: 'Un jeune ouvrier du bâtiment travaille pieds nus à Yangon, au Myanmar. © OIT / M. Fossat',
  conditions_imageMyanmarAlt: 'Un ouvrier travaillant sur un échafaudage. Une bannière qui dit «la sécurité d\'abord» est sur la clôture.',
  conditions_imageVisionCaption: 'Au Myanmar, le projet Vision Zero Fund vise à améliorer la sécurité et la santé au travail. © OIT',
  conditions_imageVisionAlt: 'Une femme dans un chapeau de soleil traditionnel assis dans le champ à côté d\'un tas de gingembre.',
  conditions_imageBetterworkCaption: 'Ouvrier dans une usine Better Work au Vietnam. Les usines de Better Work ont reçu des commandes plus importantes, un meilleur accès aux acheteurs et des prix plus élevés des principaux acheteurs au fil du temps, grâce à leur participation au programme. © OIT',
  conditions_imageBetterworkAlt: 'Une travailleuse d’usine de confection au Vietnam sourit en travaillant.',
  conditions_detailBetterwork2: '<p>Grâce à leur participation au programme Better Work, les usines ont progressivement amélioré leurs conditions de travail, leur productivité et leur rentabilité. </p>\n\n<p>Actuellement, le programme est actif dans 1 450 usines de sept pays, qui emploient plus d’1,9 million de travailleurs. En plus de ses conseils aux usines, Better Work collabore avec les gouvernements pour améliorer la législation du travail, et avec les marques internationales pour veiller à la pérennité des progrès.</p>\n\n<p>L’objectif de ce programme est que l’industrie de l’habillement sorte des millions de personnes de la pauvreté en leur fournissant un travail décent, en autonomisant les femmes, en dirigeant la compétitivité des entreprises et en favorisant une croissance économique inclusive.</p>',
  conditions_detailVisionzero2: '<p>L’OIT joue un rôle essentiel dans la mise en œuvre du Fonds Vision zéro, en administrant le fonds et en utilisant toute l’étendue de son expertise et son expérience pour développer les connaissances, les stratégies et les outils destinés aux gouvernements, aux employeurs et aux travailleurs pour améliorer la sécurité et la santé dans les chaines d’approvisionnement mondiales.</p>\n\n<p>Au Myanmar, l’OIT a donné aux cultivateurs de gingembre l’accès à des meilleures informations sur l’utilisation sûre des produits chimiques agricoles, et contribue à fournir des solutions fondées sur le marché pour améliorer leurs conditions de travail. </p>',
  future_heading: 'L\'avenir du travail',
  future_chapter: 'chapitre 9',
  future_title: 'Regarder vers l’avenir',
  future_date: 'Découvrez ce chapitre le 29 mars 2019',
  future_infoLookingtowards: '<p>Le monde du travail connaît une mutation profonde. La mondialisation et les changements technologiques créent de nouvelles voies vers la prospérité, mais bouleversent aussi les modes de travail existants.</p>',
  future_visIceberg1: '<p>Les progrès numériques et technologiques – y compris les technologies de l’information et de la communication – créent de nouvelles opportunités pour les travailleurs et les entreprises.</p>\n\n<p style="font-weight: 600;">Mais ce n’est que la partie émergée de l’iceberg.</p>',
  future_visIceberg2: '<p>Le changement climatique, l’évolution démographique, les migrations et les changements dans l’organisation du travail vont concerner toutes les sociétés, les organisations, les travailleurs et les entreprises. </p>\n\n<p>La demande va changer pour certains emplois, d’autres emplois vont disparaître, et beaucoup ne ressembleront plus à ce que nous connaissons aujourd’hui.</p>',
  future_visIceberg3: '<p>La direction de ces mutations et  leurs effets sur le travail dépendra de nos politiques et de nos actions, surtout  si nous cherchons à façonner l’avenir du travail que nous voulons.</p>',
  future_visQuestions1: 'D’où viendront les <span class="emphasis">emplois du futur</span> et à quoi ressembleront-ils?',
  future_visQuestions2: 'Que réserve l\'avenir pour les <span class="emphasis">jeunes</span> et de quelles compétences auront-ils besoin?',
  future_visQuestions3: 'Comment nous assurer que toutes les modalités de travail seront <span class="emphasis">décentes</span>?',
  future_visQuestions4: 'Comment garantir que la technologie augmente les <span class="emphasis">capacités humaines</span> au lieu de les réduire?',
  future_calloutIloidentified: 'L’OIT a identifié des secteurs clés qui créeront probablement les futurs emplois.',
  future_calloutExplorethem: 'Explorez-les ici pour découvrir les opportunités émergentes:',
  future_visGreenjobsHeading: 'Les emplois verts',
  future_visGreenjobsDetail1: '<h3>Les emplois verts</h3>\n\n<p>Les emplois verts sont l’une des nouvelles formes de travail de ces 20 dernières années. Leur objectif est de préserver, restaurer et contribuer à l’environnement.</p>\n\n<p>On les trouve dans des secteurs très variés comme la fabrication manufacturière, la construction, la mode, les énergies renouvelables, l’électronique et la gestion des déchets.</p>',
  future_visGreenjobsDetail2: '<p>Les emplois verts contribuent à:</p>\n\n<ul>\n<li>améliorer l’efficience énergétique</li>\n<li>limiter les émissions de gaz à effet de serre</li>\n<li>réduire les déchets et la pollution</li>\n<li>protéger et restaurer les écosystèmes</li>\n<li>faciliter l’adaptation au changement climatique</li>\n</ul>\n\n<p>L’OIT estime que la transition vers une économie verte pourrait engendrer jusqu’à 60 millions d’emplois supplémentaires dans les 15 prochaines années. </p>',
  future_visCareeconomyHeading: 'L’économie des soins à la personne',
  future_visCareeconomyDetail1: '<h3>L’économie des soins à la personne</h3>\n\n<p>Le travail des soins à la personne comprend les services de soins de santé, la garde d’enfants, l’éducation préscolaire, le handicap et les soins de longue durée, les soins aux personnes âgées et bien plus encore.</p>\n\n<p>Avec les changements démographiques dans le monde et le vieillissement des populations dans une grande partie du monde développé, l’économie des soins à la personne détient l’un des potentiels les plus élevés de création d’emplois à l’avenir. </p>',
  future_visGigeconomyHeading: 'La «gig economy»',
  future_visGigeconomyDetail1: '<h3>La «gig economy»</h3> \n\n<p>Les innovations technologiques ouvrent de nouvelles opportunités pour travailler à distance et en ligne. Les emplois de la «gig economy», où les travailleurs ont de multiples emplois à court terme ou des contrats en ligne avec plusieurs employeurs, plutôt que la traditionnelle relation unique employeur/employé plus permanente, offrent aux travailleurs la possibilité de gagner un revenu et aux entreprises celle d’organiser le travail de manière différente.</p>',
  future_visGigeconomyDetail2: '<p>Des travailleurs qui seraient normalement exclus du marché du travail – par exemple en raison d’un handicap, de responsabilités familiales ou d’une maladie – ont plus d’opportunités de travailler. Toutefois, les recherches de l’OIT montrent que de nombreux travailleurs collaboratifs gagnent moins que le salaire minimum.</p>\n\n<p>L’insécurité dans laquelle vivent ces travailleurs est préoccupante, ainsi que leurs conditions de travail. L’OIT dirige des recherches sur bon nombre des problèmes soulevés par ces nouvelles formes de travail:</p>\n\n<ul>\n<li>Quel est le statut d’emploi de ces travailleurs collaboratifs en ligne et des travailleurs à la demande, qui sont en concurrence pour accomplir ponctuellement des tâches pour des employeurs?</li>\n</ul>',
  future_visRuraleconomyHeading: 'L’économie rurale',
  future_visRuraleconomyDetail1: '<h3>L’économie rurale</h3>\n\n<p>Le développement rural figure à l’agenda de l’OIT depuis ses débuts. Depuis 1919, l’OIT a adopté plus de 30 normes internationales du travail directement pertinentes pour l’agriculture et le développement rural, portant sur les risques au travail, l’emploi, la protection sociale et le dialogue social.</p>\n\n<p>L’économie rurale détient un grand potentiel de création d’emplois décents productifs, et contribue au développement durable et à la croissance économique. </p>',
  future_visRuraleconomyDetail2: '<p>De plus, comme près de 80% des pauvres vivent dans des zones rurales, toute amélioration des conditions de vie des populations rurales aura un énorme impact sur la lutte contre la pauvreté d’ici 2030 – date fixée pour atteindre les Objectifs de développement durable des Nations Unies. </p>\n\n<p>L’agriculture demeurera le plus grand secteur dans de nombreuses zones rurales. Il est probable que les populations rurales vont également bénéficier du développement de l’économie du tourisme et des loisirs.</p>',
  future_visGlobalsupplyHeading: 'Les chaines d’approvisionnement mondiales',
  future_visGlobalsupplyDetail1: '<h3>Les chaines d’approvisionnement mondiales</h3>\n\n<p>La production et la distribution de biens et de services se sont beaucoup complexifiées. Autrefois, les entreprises concentraient leur production vers des pays ou des régions spécifiques. Maintenant, elle s’étend à des réseaux transnationaux pour maximiser les profits et minimiser les déchets. </p>\n\n<p>Les chaines d’approvisionnement mondiales ont transformé l’économie mondiale ces 30 dernières années. Elles ont été un moteur de croissance et de création d’emplois, surtout dans le monde en développement. </p>',
  future_visGlobalsupplyDetail2: '<p>Les emplois dans les chaînes d\'approvisionnement mondiales sont aussi variés que les pays dans lesquels ils se situent, et ont permis à de plus en plus de personnes de trouver un emploi. </p>\n\n<p>Un grand nombre de ces emplois peuvent être remplacés par des machines, notamment dans l’industrie légère, mais les recherches de l’OIT montrent qu’actuellement, cela ne semble pas avoir d’intérêt économique dans les régions en développement en raison du coût relativement élevé des machines, du faible coût de la main-d’œuvre et de la capacité limitée d’absorption des nouvelles technologies. Mais qu’en sera-t-il à l’avenir?</p>',
  future_visRobotageHeading: 'L’ère des robots',
  future_visRobotageDetail1: '<h3>L’ère des robots</h3>\n\n<p>Les progrès de la spécialisation et de la sophistication de la technologie accélèrent le rythme de l’automatisation au travail et la demande de robots industriels. L’automatisation sur les lieux de travail va de 10% à 60% et entraîne inévitablement des pertes d’emploi. </p>\n\n<p>Il y a toutefois un espoir dans cette perspective sombre – relatif à la transformation des emplois et à la réorientation des compétences des travailleurs.</p>',
  future_visRobotageDetail2: '<p>Les systèmes robotiques doivent être reliés en eux, entretenus et mis à jour. Cela va probablement créer de nouveaux emplois, notamment pour ceux qui ont des connaissances en sciences, technologie, ingénierie et mathématiques (STEM).</p>\n\n<p>De plus, les développeurs de logiciels et les programmeurs seront potentiellement l’équivalent des cols bleus à l’avenir. Dans les économies développées d’aujourd’hui, le secteur manufacturier compte 14 robots – nécessitant tous un entretien régulier – pour 1000 employés. Ce nombre va croître.  </p>',
  future_calloutBrighterfuture: 'Un avenir meilleur',
  future_infoSkillsweneed: '<h3>Les compétences dont nous aurons besoin</h3>\n\n<p>L’évolution du monde du travail nécessitera de nouvelles compétences et mettra l’accent sur l’apprentissage tout au long de la vie.  L’automatisation et la robotisation, par exemple, augmenteront la demande de compétences techniques dans la résolution des problèmes et l’innovation. </p>\n\n<p>La demande de compétences non techniques accroîtra également, telles que la capacité à communiquer efficacement, à travailler en équipe, à trouver des solutions créatives aux problèmes, à la socialisation et l’adaptation aux changements. Ces compétences seront très prisées. Le résultat:</p>\n\n<ul>\n<li>L’éducation et les systèmes de formation devront fournir à la main-d’œuvre les compétences qui seront nécessaires à l’avenir.</li>\n<li>Des mesures politiques devront renforcer les liens entre les établissements de formation et les entreprises.</li>\n<li>Nous aurons besoin de trouver un équilibre entre assurer la qualité de la formation et répondre à la demande de programmes de formation plus souples et plus courts.</li>\n</ul>',
  future_infoOpportunity: '<h3>Réduire l’écart entre les hommes et les femmes</h3>\n\n<p>L’évolution du monde du travail offre l’occasion de supprimer les obstacles structurels à l’égalité entre les hommes et les femmes et de s’attaquer aux inégalités sur les lieux de travail et dans nos sociétés. </p>\n\n<p>Les femmes fournissent la grande majorité des soins et du travail ménager non rémunérés dans le monde, ce qui entrave souvent leur participation à la main-d’œuvre. </p>\n\n<p>Formaliser le travail de soins à la personne représente donc une excellente occasion pour permettre à plus de femmes d’entrer dans la main-d’œuvre. L’OIT travaille dans 80 pays pour y réussir.</p>\n\n<p>En plus de ces efforts,  plusieurs projets de l’OIT aident les femmes à accéder à une éducation de qualité et à des formations professionnelles pour améliorer leurs chances de trouver des emplois décents. </p>',
  future_visWomen: 'ne font pas partie de la main-d’œuvre car elles effectuent des activités de soins non rémunérées',
  future_visMen: 'ne font pas partie de la main-d’œuvre car ils  effectuent des activités de soins non rémunérées',
  future_quoteKofiBody: '«L’égalité des genres est plus qu’un objectif en soi. C’est une condition préalable pour relever le défi de la réduction de la pauvreté, promouvoir le développement durable et établir la bonne gouvernance.»',
  future_quoteKofiCaption: 'Kofi Annan, ancien secrétaire général des Nations Unies et lauréat du prix Nobel de la paix',
  future_infoNewforms: '<h3>De nouveaux types d’entreprises</h3>\n\n<p>Les entreprises sont le moteur de nos économies, les générateurs de prospérité et les créateurs d’emploi. Cependant, on craint de plus en plus que l’importance accordée à la rétribution des investisseurs et des créanciers ne se fasse au détriment de l’environnement et du bien-être des travailleurs.</p>\n\n<p>L’essor des formes alternatives d’entreprises montre que ces dernières peuvent jouer un rôle essentiel dans la réalisation des Objectifs de développement durable des Nations Unies en aidant des millions de personnes à sortir de la pauvreté. </p>',
  future_infoThesebusinesses: '<p>Ces types d’entreprises incluent des coopératives de travailleurs, des mutuelles et des entreprises sociales qui répondent aux besoins des citoyens et des communautés locales. D’après les recherches de l’OIT:</p>\n\n<ul>\n<li>Ces entreprises sont moins susceptibles de délocaliser leurs activités de production, et elles créent et préservent des emplois.</li>\n<li>Elles sont souvent les premières à régénérer les communautés locales et à secourir les entreprises au bord de la faillite. </li>\n<li>Elles sont efficaces pour mettre en commun les ressources des micro-entreprises et des travailleurs indépendants.</li>\n</ul>\n\n<p>La Recommandation (n° 193) de l’OIT sur la promotion des coopératives fournit des directives pour leur développement.</p>',
  future_infoModernizing: '<h3>Moderniser les systèmes de protection sociale</h3>\n\n<p>La demande de protection sociale va probablement croître avec l’augmentation des inégalités et le changement du rôle du travail dans la vie des gens.</p>\n\n<p>Les pertes d’emploi vont entraîner un plus grand besoin d’assurance chômage. L\'inadéquation entre les compétences des travailleurs et celles dont l\'économie aura besoin à l\'avenir fera pression sur les programmes d\'emploi publics. L’allongement de l’espérance de vie pèsera sur les régimes de pensions de vieillesse. </p> \n\n<p>Pour faire face à ces défis, certaines innovations récentes dans les pays développés et en développement montrent que les systèmes de protection sociale peuvent s’adapter et se rapprocher de la couverture universelle.</p>\n\n<p>Certains pays ont par exemple introduit des mécanismes de protection sociale pour les travailleurs indépendants et informels, y compris les travailleurs des plateformes numériques.</p> \n\n<p>Les communications numériques et les procédures automatisées sont utilisées pour rendre les procédures administratives plus efficaces et plus économiques.</p>',
  future_infoHumancentred: '<h3>Un agenda axé sur l’humain</h3>\n\n<p>Afin de préparer les cent prochaines années, l’OIT a instauré la Commission mondiale sur l’avenir du travail, composée de chefs d’état, d’éminents chercheurs et des représentants des principales organisations de travailleurs et d’employeurs. </p>\n\n<p>Cette commission a entrepris un examen approfondi de l’avenir du travail en vue de proposer une base analytique permettant d’atteindre la justice sociale au 21ème siècle. Elle appelle à mettre en place un agenda axé sur l’humain qui renforce le contrat social en plaçant les gens et leur travail au cœur des politiques socioéconomiques et des pratiques des entreprises. </p>\n\n<p>Cet agenda repose sur trois piliers d’action, qui, une fois combinés, favoriseront la croissance, l’équité et la durabilité pour les générations actuelles et futures:</p>\n\n<ul>\n<li>Augmenter l’investissement dans les capacités des personnes</li>\n<li>Augmenter les investissements dans les institutions du travail</li>\n<li>Augmenter l’investissement dans le travail décent et durable </li>\n</ul>',
  future_infoFuturewewant: '<h3>L’avenir que nous voulons</h3>\n\n<p>Depuis sa fondation au siècle dernier, les normes internationales du travail de l’OIT ont été le socle du travail décent et de la justice sociale. </p>\n\n<p>Le dialogue social et le tripartisme ont contribué à la plus grande expansion de la croissance économique de l’histoire humaine, et sont plus pertinents que jamais quand on se tourne vers l’avenir.</p>\n\n<p>C’est à nous tous de nous engager, face aux changements qui se profilent, à coopérer et à trouver des solutions aux défis qui pourraient surgir à l’avenir. </p>',
  future_calloutParadox: 'Le paradoxe dans le monde d’aujourd’hui est que même dans les régions les plus touchées par le chômage, il reste encore beaucoup à faire.',
  future_calloutSickelderly: 'Les malades et les personnes âgées ont besoin de personnel soignant. Les enfants et les jeunes ont besoin de professeurs et de formation professionnelle. Il faut rénover les bâtiments et verdir les villes. Il faut préserver l’environnement et lutter contre le changement climatique.',
  future_infoFulfilling: '<h3>Respecter les quatre piliers du travail décent</h3>\n\n<p>En d’autres termes, il y aura toujours une demande de travail et de travailleurs, dans les emplois où les interactions humaines sont essentielles, où notre créativité peut être libérée et nos aspirations réalisées.. </p>\n\n<p>Les quatre piliers du travail décent – la création d’emplois, la protection sociale, les droits au travail et le dialogue social – sont donc tout aussi pertinents qu’il y a 100 ans, et ils détiennent les clés d’un avenir dont nous pourrons être fiers. </p>',
  future_infoNext100Years: '<h3>Les 100 prochaines années</h3>\n\n<p>Il y a 100 ans, qui aurait pu prédire le monde dans lequel nous vivons aujourd’hui? Un monde où des millions de personnes sont sorties de la pauvreté et où un nombre illimité d’informations est disponible en cliquant simplement sur un bouton. Un monde où des milliards de personnes ont entendu parler des droits de l’homme et où le mot «égalité» a une nouvelle signification pour d’innombrables femmes et hommes dans le monde. </p>\n\n<p>Il reste pourtant encore beaucoup à faire pour réaliser notre rêve d’un monde où règne une paix universelle et durable, fondée sur la justice sociale.</p>',
  future_calloutHistory: 'L’histoire a prouvé que les défis auxquels nous sommes confrontés ne peuvent être surmontés qu’en travaillant ensemble. Quand nous ferons tous passer le bien commun avant les petits intérêts, tout sera possible.',
  future_calloutInthisspirit: 'C’est dans cet espoir que l’OIT se tourne vers son deuxième siècle. Nous vous invitons à nous rejoindre pour façonner l’avenir du travail que nous voulons tous: un monde de justice sociale et de travail décent pour tous.',
  future_imageIndustrobotsCaption: 'Des robots industriels à l’usine Gestamp Automocion à Abrera, Espagne. © AFP/P. Barrena',
  future_imageIndustrobotsAlt: 'Des robots industriels blancs sont disposés en cercle pour travailler sur différentes parties d’un produit qui tourne au centre.',
  future_imageAerialCaption: 'Vue aérienne lors d’un atterrissage à l’aéroport de Yangon, Myanmar. © ILO/M. Fossat',
  future_imageAerialAlt: 'Vue aérienne de champs, de routes avec une rivière',
  future_imageChildrenCaption: 'Enfants jouant dans une crèche de la province de Phu Tho, Viet Nam. © ILO',
  future_imageChildrenAlt: 'Une petite fille et un petit garçon jouent avec des blocs de plastiques colorés',
  future_imageMarineCaption: 'Une chercheuse cartographie les récifs coralliens aux Seychelles. © ONU Femmes/Ryan Brown',
  future_imageMarineAlt: 'Une femme nageant avec un tuba qui écrit sur son bloc-notes sous l’eau.',
  future_imageNurseCaption: 'Une infirmière  vaccine une enfant contre la polio au centre de soins de santé primaires  Howard Karagheusian, à Beyrouth, Liban. © Banque mondiale/Dominic Chavez',
  future_imageNurseAlt: 'Dans une salle, une infirmière tient le visage d’une enfant d’une main et un flacon de médicament de l’autre.',
  future_imageManworkshomeCaption: 'Un homme travaille chez lui. L’émergence des plateformes numériques en ligne a apporté l’un des plus grands bouleversements du monde du travail de ces dix dernières années. © iStock',
  future_imageManworkshomeAlt: 'Un homme assis dans sa cuisine à une table sur laquelle il y a un ordinateur et une pile de documents.',
  future_imageTwofarmersCaption: 'Deux agriculteurs travaillent dans les champs au Viet Nam. Il est essentiel de promouvoir le travail décent dans l’économie rurale pour éradiquer la pauvreté. © ILO/M. Fossat',
  future_imageTwofarmersAlt: 'Deux agricultrices portant des chapeaux vietnamiens travaillent dans un champ plein de plantes avec des outils agricoles.',
  future_imageGarmentCaption: 'Des ouvrières dans une usine de prêt-à-porter en Jordanie – l’un des pays couverts par le Programme Better Work, dont l’objectif est d’améliorer les conditions de travail tout en renforçant la compétitivité des entreprises du prêt-à-porter. © ILO',
  future_imageGarmentAlt: 'Des travailleuses assises en lignes parallèles qui travaillent sur des machines à coudre. Il y a des piles de tissu rouge au centre des tables.',
  future_imageRobotsiranCaption: 'Des robots travaillent sur une voiture dans l’usine automobile de Khodro, Iran, à l’Ouest de Téhéran, 2008. © AFP/Behrouz Mehri',
  future_imageRobotsiranAlt: 'Pluie d’étincelles provoquées par le travail de robots orange sur la structure métallique d’une automobile en production.',
  future_imageChildrencodeCaption: 'Des enfants âgés de 7 à 13 ans apprennent l’encodage. Antananarivo, Madagascar. © AFP/Rijasolo',
  future_imageChildrencodeAlt: 'Une rangée de garçons regardent des écrans d’ordinateurs, avec une professeur derrière, dans un bus reconverti.',
  future_imageSchoolgirlsCaption: 'Enfants dans une école, Java Ouest, Indonésie. © BIT/A. Mizra',
  future_imageSchoolgirlsAlt: 'Quatre filles, debout les unes à côté des autres, lisent des livres.',
  future_imageDomesticCaption: 'Une travailleuse domestique, Philippines. Les travailleurs domestiques représentent un pourcentage significatif de la main d’œuvre mondiale dans l’emploi informel, et font partie des groupes de travailleurs les plus vulnérables. © ILO/J. Aliling',
  future_imageDomesticAlt: 'Une femme assise à une table joue avec un bébé assis en face d’elle dans sa chaise haute.',
  future_imageKofiCaption: 'Kofi Annan, ancien secrétaire général de l’ONU, à la Conférence  des femmes africaines et du développement économique, Addis Abeba, 1998. © BIT',
  future_imageEveldoCaption: 'Eveldo, de la communauté indigène Bribri, est membre de l’association des petits producteurs de Talamanca, une des plus grandes coopératives du Costa Rica. © AFP/H. Retamal',
  future_imageEveldoAlt: 'Un homme dans une exploitation agricole qui s’occupe d’une plante dans un pot sur une table.',
  future_imageWomencoopCaption: 'Des membres d’une coopérative de femmes, Tanzanie. Dans beaucoup de pays, la participation des femmes aux coopératives est beaucoup plus élevée que leur participation dans l’ensemble de la main d’œuvre. © ILO/M. Crozet',
  future_imageWomencoopAlt: 'Trois femmes assises en cercle écrivent sur des feuilles de papier. D’autres femmes sont assises autour d’elles.',
  future_imageFlowersellerCaption: 'Une vendeuse de fleurs sur le marché aux fleurs de Cuenca, Équateur. La majeure partie des travailleurs de l’économie informelle ne disposent pas de protection sociale ni de droits au travail. © ILO/P. Virot',
  future_imageFlowersellerAlt: 'Une vieille femme assise dehors entourée de pots de fleurs qu’elle vend.',
  future_imageOfficialCaption: 'Présentation officielle du rapport de la Commission mondiale sur l’avenir du travail aux mandants de l’OIT et aux représentants de la communauté diplomatique, janvier 2019. © ILO',
  future_imageOfficialAlt: 'Cyril Ramaphosa, président de la République d’Afrique du Sud ; Guy Ryder, directeur général du BIT, et Deborah Greenfield, directrice générale adjointe siègent à la tribune de la salle de réunion devant la bannière « Commission mondiale sur l’avenir du travail ».',
  future_imageSyrianCaption: 'Une travailleuse réfugiée syrienne en Jordanie. L’OIT aide les travailleurs à accéder aux emplois dans l’agriculture dans le cadre de la réponse de l’agence à la crise des réfugiés. © ILO/L. Abu Sha\'ireh',
  future_imageSyrianAlt: 'Une femme dans un champ de grandes plantes qui tient une cagette en polystyrène pleine de haricots qu’elle vient de récolter.',
  future_visOfWomen: 'des femmes',
  future_visOfMen: 'des hommes',
  future_visCareeconomyDetail2: '<p>D\'après les statistiques de l’OIT, on estime qu\'environ 269 millions de nouveaux emplois pourraient être créés si les investissements dans l’éducation, la santé et le travail social doublent d’ici 2030.</p>',
  future_visGigeconomyDetail3: '<ul>\n<li>Comment garantir les droits fondamentaux des travailleurs de l’économie des plateformes numériques?</li>\n<li>Que faut-il pour garantir le respect de ces droits?</li>\n<li>Comment les travailleurs de l\'économie des plateformes numériques pourraient-ils représenter collectivement leurs intérêts afin de négocier une amélioration de leurs salaires et de leurs conditions de travail?</li>\n<li>Comment réguler des conditions minimales d’emploi (le salaire minimum par exemple)?</li>\n<li>Comment les travailleurs de l’économie des plateformes numériques peuvent-ils avoir accès à une protection sociale appropriée?</li>\n<li>Comment la croissance du travail collaboratif dans le monde affecte-t-elle les efforts visant à garantir le travail décent? </li>\n</ul>',
  future_visRuraleconomyDetail3: '<p>En outre, les changements de la structure démographique des zones rurales (les jeunes partent vers les villes pour étudier et trouver du travail, alors que les personnes âgées et leurs familles retournent vivre dans les zones rurales) auront des implications sur les prestations des services publics. </p>\n\n<p>Les populations rurales vieilliront plus rapidement que les populations urbaines, ce qui va entraîner une augmentation de la demande d’emplois dans le secteur des soins à la personne en dehors des zones urbaines. Ce secteur a un grand potentiel de création d’emplois à l’avenir.</p>\n\n<p>Le travail décent dans l’économie rurale a été identifié comme prioritaire en 2014 et le restera au moment où l’OIT entame un nouveau siècle. </p>',
  future_visGlobalsupplyDetail3: '<p>Le prix des machines diminue avec le temps, ce qui permet de relocaliser une grande partie de cette production. Cela pourrait induire une reconfiguration des chaînes d\'approvisionnement mondiales, les régions en développement perdant des emplois du fait de la mécanisation dans les régions développées. Les pertes d’emplois et la concurrence peuvent engendrer des pressions sur les salaires et les conditions de travail. </p>\n\n<p>L’OIT voit donc un besoin accru de:</p>\n\n<ul>\n<li>politiques du marché du travail qui prennent en compte ces changements</li>\n<li>politiques économiques menant à une diversification vers de nouveaux secteurs économiques</li>\n<li>d’inspections du travail qui garantissent de bonnes conditions de travail</li>\n</ul>',
  future_visGlobalsupplyDetail4: '<p>En 2017, la Conférence internationale du Travail a adopté un rapport sur le travail décent dans les chaînes d\'approvisionnement mondiales, qui a abouti à l’élaboration d’un plan d’action pour veiller au respect des normes du travail décent. </p>' };
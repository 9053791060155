import { createGlobalStyle } from 'styled-components';
import fonts from 'assets/fonts';

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Oswald';
    src: url(${fonts.OswaldRegularWOFF2}) format('woff2'),
      url(${fonts.OswaldRegularWOFF}) format('woff'),
      url(${fonts.OswaldRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Oswald';
    src: url(${fonts.OswaldBoldWOFF2}) format('woff2'),
      url(${fonts.OswaldBoldWOFF}) format('woff'),
      url(${fonts.OswaldBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Crimson Text';
    src: url(${fonts.CrimsonTextRegularWOFF2}) format('woff2'),
      url(${fonts.CrimsonTextRegularWOFF}) format('woff'),
      url(${fonts.CrimsonTextRegularWOFF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Crimson Text';
    src: url(${fonts.CrimsonTextItalicWOFF2}) format('woff2'),
      url(${fonts.CrimsonTextItalicWOFF}) format('woff'),
      url(${fonts.CrimsonTextItalicTTF}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Crimson Text';
    src: url(${fonts.CrimsonTextBoldWOFF2}) format('woff2'),
      url(${fonts.CrimsonTextBoldWOFF}) format('woff'),
      url(${fonts.CrimsonTextBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${fonts.OpenSansRegularWOFF2}) format('woff2'),
      url(${fonts.OpenSansRegularWOFF}) format('woff'),
      url(${fonts.OpenSansRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${fonts.OpenSansSemiBoldWOFF2}) format('woff2'),
      url(${fonts.OpenSansSemiBoldWOFF}) format('woff'),
      url(${fonts.OpenSansSemiBoldTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${fonts.OpenSansSemiBoldItalicWOFF2}) format('woff2'),
      url(${fonts.OpenSansSemiBoldItalicWOFF}) format('woff'),
      url(${fonts.OpenSansSemiBoldItalicTTF}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${fonts.OpenSansBoldWOFF2}) format('woff2'),
      url(${fonts.OpenSansBoldWOFF}) format('woff'),
      url(${fonts.OpenSansBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${fonts.OpenSansItalicWOFF2}) format('woff2'),
      url(${fonts.OpenSansItalicWOFF}) format('woff'),
      url(${fonts.OpenSansItalicTTF}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${fonts.OpenSansBoldItalicWOFF2}) format('woff2'),
      url(${fonts.OpenSansBoldItalicWOFF}) format('woff'),
      url(${fonts.OpenSansBoldItalicTTF}) format('truetype');
    font-weight: 600;
    font-style: italic;
  }


  @font-face {
    font-family: 'Noto Kufi Arabic';
    src: url(${fonts.NotoKufiArabicWOFF2}) format('woff2'),
      url(${fonts.NotoKufiArabicWOFF}) format('woff');
    font-weight: 400;
    font-style: normal;
  }


  @font-face {
    font-family: 'Noto Kufi Arabic';
    src: url(${fonts.NotoKufiArabicBoldWOFF2}) format('woff2'),
      url(${fonts.NotoKufiArabicBoldWOFF}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Karla';
    src: url(${fonts.KarlaRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Karla';
    src: url(${fonts.KarlaBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Karla';
    src: url(${fonts.KarlaBoldItalicTTF}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Karla';
    src: url(${fonts.KarlaItalicTTF}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }
`;

export default Fonts;

/* eslint-disable import/extensions */

import React from 'react';
import PropTypes from 'prop-types';
import Head from 'components/Head';
import { withIntl } from 'i18n';
import 'utils/polyfill';
import 'normalize.css';
import 'intl';
import Fonts from 'styles/fonts';
import GlobalStyles from 'styles/globals';

const DefaultLayout = ({ children, pageContext: { locale } }) => (
  <div>
    <Head />
    <Fonts />
    <GlobalStyles locale={locale} />
    {children}
  </div>
);

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string
  })
};

DefaultLayout.defaultProps = {
  pageContext: {
    locale: 'en'
  }
}

export default withIntl(DefaultLayout);

/* eslint-disable no-plusplus */

(function mathHypot() {
  if (typeof Math.hypot === 'undefined') {
    Math.hypot = function hypot(...args) {
      let index = -1;
      let y = 0;
      while (++index in args && Math.abs(y) !== Infinity) {
        y += args[index] * args[index];
      }
      return Math.abs(y) === Infinity ? Infinity : Math.sqrt(y);
    };
  }
})();

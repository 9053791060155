module.exports = { interface_loading: 'cargando',
  interface_headphones: 'Se recomienda usar auriculares',
  interface_title: 'Nuestra historia, tu historia',
  interface_share: 'compartir',
  interface_fb: 'facebook',
  interface_twitter: 'twitter',
  interface_youtube: 'youtube',
  interface_on: 'activar',
  interface_off: 'desactivar',
  interface_start: 'Presione la barra espaciadora',
  interface_scrollDown: 'Desplazarse hacia abajo',
  interface_description: 'La Organización Internacional del Trabajo (OIT) conmemora 100 años de avances en materia de justicia social y de promoción de trabajo decente.',
  interface_news: 'Noticias y eventos',
  interface_fraudAlert: 'Alerta de fraude',
  interface_disclaimer: 'Descargo de responsabilidad',
  interface_privacyPolicy: 'Política de privacidad',
  interface_copyright: 'Derechos de autor y autorizaciones',
  interface_ilo: 'Organización Internacional del Trabajo (OIT)',
  interface_ilo100: 'OIT 100',
  interface_ourStoryDescription: 'Emprenda un viaje interactivo a través del pasado, presente y futuro de la Organización Internacional del Trabajo, el organismo especializado más antiguo de las Naciones Unidas. Avances en materia de justicia social y promoción del trabajo decente desde 1919.',
  interface_about: 'Acerca de la OIT',
  interface_credits: 'Créditos',
  interface_mobileTitle: 'Nuestra historia, <br>tu historia',
  interface_mobileStart: 'Pulse para comenzar',
  interface_html5VideoError: 'Su navegador no es compatible con video HTML5',
  interface_previous: 'Anterior',
  interface_next: 'Siguiente',
  interface_seePhoto: 'Ver esta foto',
  interface_seeVideo: 'Mirar este video',
  interface_nextChapter: 'Próximo capítulo',
  interface_discoverChapter: 'Descubra este capítulo el',
  interface_noOptions: 'Sin resultados',
  interface_learnMore: 'Más información',
  home_title: 'El centenario de la OIT',
  home_subtitle: 'Impulsar la justicia social, promover el trabajo decente',
  home_news: 'Noticias y eventos',
  home_ourStory: 'Nuestra historia, tu historia',
  home_newsSubtitle: 'Descubra cómo estamos celebrando el centenario',
  home_ourStorySubtitle: 'Descubrir nuestra historia y misión',
  home_ourStoryCta: '¡Ahora disponible!',
  home_newsCta: 'Ver más',
  home_description: 'En 2019, la OIT festejará su 100.o aniversario. El centenario será una oportunidad para celebrar los logros de la OIT y reafirmar su posición como la organización mundial con autoridad en el mundo del trabajo.',
  home_socialMedia: 'En 2019,  @OITNoticias celebra su centenario. 100 años impulsando la justicia social y promoviendo el trabajo decente. #OIT100 https://www.ilo.org/100/es/',
  home_pageTitle: 'El centenario de la OIT',
  home_ourStoryCta2: 'EXPLORAR',
  home_ilcTitle: '',
  intro_heading: 'Mientras la OIT celebra su centenario, emprenda un viaje a través de su pasado, presente y futuro.',
  intro_title: 'Nuestra historia, tu historia',
  intro_buttonStart: 'Comience a explorar',
  intro_buttonDates: 'Vea las fechas de lanzamiento',
  intro_tableCaption: 'Oír las fechas de lanzamiento para los capítulos de Nuestra historia, tu historia',
  intro_chapters: 'Capítulos',
  intro_launchDate: 'Fechas de lanzamiento',
  intro_aboutIntro: '<p>En el 2019 la Organización Internacional del Trabajo celebra 100 años de avance sobre la justicia social y la promoción del trabajo decente.</p>\n\n<p>Este sitio web lleva a los usuarios en una travesía multimedia a través del pasado, presente y futuro de la organización mientras esta se prepara para afrontar los desafíos del mañana.</p>\n\n<p>Para conocer más sobre cómo ILO celebra su Centenario, visite la <a href="https://www.ilo.org/100/news/es"> página de Noticias y Eventos del Centenario</a>.</p>',
  intro_aboutTitle: 'Acerca de la OIT',
  intro_aboutDescription: 'El sitio web OIT100 conmemora 100 años de avances en justicia social y promoción del trabajo decente. No hubiese sido posible sin el apoyo generoso de nuestros constituyentes y socios.',
  intro_aboutResourcesTitle: 'Más recursos',
  intro_aboutCreditsTitle: 'Créditos',
  intro_aboutResTripartism1: '<a href="https://www.ilo.org/global/lang--es/index.htm">Sitio web de la OIT</a>',
  intro_aboutResFuture1: '<a href="https://www.ilo.org/global/topics/future-of-work/WCMS_569909/lang--es/index.htm">Comisión Mundial sobre el Futuro del Trabajo</a>',
  intro_aboutResTripartism2: '<a href="https://www.ilo.org/global/about-the-ilo/history/lang--es/index.htm">Historia de la OIT</a>',
  intro_aboutCreditsH1: 'Historia, fotografía y video',
  intro_aboutCreditsP1: 'Departamento de comunicaciones de la OIT',
  intro_aboutCreditsH2: 'Otras fotos y videos',
  intro_aboutCreditsH3: 'Dirección creativa y diseño',
  intro_aboutCreditsH4: 'Desarrollo web',
  intro_aboutCreditsH5: 'Música y diseño de sonido',
  intro_aboutCreditsH6: 'Agradecimientos especiales',
  intro_aboutCreditsP2: 'AFP • Biblioteca FDR • Jessica Maida • Lisa Kristine • Biblioteca del Congreso • Maxime Fossat • Unsplash • Wikicommons',
  intro_aboutCreditsP3: 'Departamento de Comunicaciones de la OIT<br>con ElkanoData',
  intro_aboutCreditsP4: 'Departamento de Comunicacion de la OIT<br>con Squareball Digital',
  intro_aboutCreditsP5: 'ElkanoData<br>con Extreme Music (Sellos Discográficos)',
  intro_aboutCreditsP6: 'El sitio web OIT100 conmemora 100 años de avances en justicia social y promoción del trabajo decente. No hubiese sido posible sin la generosa ayuda de nuestros constituyentes y socios.\n\n<br><br>\n\nBernice Antoine (activista juvenil)  •  Valentina Auneme (recepcionista de hotel)  •  Michelle Bachelet (Alta Comisionada de las Naciones Unidas para los Derechos Humanos)  •  Sreela Das Gupta (especialista en diversidad e inclusión)  •  Carling Dumulot Sr. (líder indígena de los Aeta )  •  Zainab Ecumba (trabajador doméstico)  •  Apolonia Fontes (pensionista)  •  Ulf Gustafsson (minero y sindicalista)  •  Zainab Ahmed Hamza (diseñadora de joyas) •  Alison Hart (trabajadora autónoma)  •  Shinichi Kondo (Director, Hospital Kinrou, Japón)  •  Yukinori Kusaka (profesor emérito, Universidad de Fukui, Japón)  •  Dao Thuy Ly (trabajadora desempleada)  •  Zeinab Mahmoud (emprendedor)  •  Marina Mejia (emprendedora)  •  Zin Mar Myint (trabajadora en una fábrica textil)  •  Narufumi Suganuma (profesor, Escuela Médica Kochi, Japón)  •  Francis Phiri (instructor en lengua de señas)  •  Vishani Ragobeer (activista juvenil)  •  Nicolás Gurría Sánchez (estudiante)  •  Laurel Sprague (consejera especial, ONUSIDA)  •  Aya Tanimura (directora de cine) •  Afonsu de Jesus Vidal (jubilado)  • Zhang Yanyan (Confederación de Empresas de China)\n\n<br><br>\n\nUn agradecimiento muy especial a todos los colegas de la OIT que ayudaron a lograr que este sitio web sea posible',
  intro_dateChap1: '9 de enero',
  intro_dateChap2: '16 de enero',
  intro_dateChap3: '30 de enero',
  intro_dateChap4: '6 de febrero',
  intro_dateChap5: '13 de febrero',
  intro_dateChap6: '21 de febrero',
  intro_dateChap7: '27 de febrero',
  intro_dateChap8: '13 de marzo',
  intro_dateChap9: '1 de abril',
  intro_post: 'Embárquese es una travesía interactiva a través del pasado, presente y futuro de @OIT—celebrando 100 años de avances en justicia social y promoción del trabajo decente.',
  intro_aboutResTripartism3: '<a href="https://www.ilo.org/actemp/lang--en/index.htm">Bureau for Employers\' Activities (ACTEMP)</a>',
  intro_aboutResRights1: '<a href="https://www.ilo.org/global/standards/introduction-to-international-labour-standards/conventions-and-recommendations/lang--es/index.htm">Convenios y recomendaciones de l\'OIT</a>',
  intro_aboutResRights2: '<a href="https://www.alliance87.org/">Alliance 8.7</a>',
  intro_aboutResRights3: '<a href="https://www.ilo.org/global/about-the-ilo/how-the-ilo-works/departments-and-offices/governance/fprw/lang--es/index.htm">Servicio de Principios y derechos fundamentales en el trabajo (FUNDAMENTALS) de la OIT</a>',
  intro_aboutResTripartism4: '<a href="https://www.ilo.org/actrav/lang--es/index.htm">Oficina de Actividades para los Trabajadores (ACTRAV)</a>',
  intro_aboutResEquality1: '<a href="https://www.equalpayinternationalcoalition.org/">EPIC: the Equal Pay International Coalition</a>',
  intro_aboutResEquality2: '<a href="https://www.ilo.org/global/topics/indigenous-tribal/lang--es/index.htm">Portal temático sobre los pueblos indígenas y tribales</a>',
  intro_aboutResEquality3: '<a href="https://www.ilo.org/global/topics/hiv-aids/lang--es/index.htm">Portal temático sobre VIH y SIDA</a>',
  intro_aboutResEmployment1: '<a href="https://www.ilo.org/global/topics/youth-employment/databases-platforms/global-initiative-decent-jobs/lang--es/index.htm">La Iniciativa Mundial sobre Empleo Decente para los Jóvenes</a>',
  intro_aboutResEmployment2: '<a href="https://www.skillsforemployment.org/KSP/es/index.htm">La Plataforma Global Público-Privada de Intercambio de Conocimientos sobre Competencias para el Empleo</a>',
  intro_aboutResEmployment3: '<a href="https://www.ilo.org/ilostat/faces/ilostat-home?locale=es&_afrLoop=443313493281838#!%40%40%3Flocale%3Des%26_afrLoop%3D443313493281838%26_adf.ctrl-state%3D109upvcqd2_163">ILOSTAT: Estadísticas laborales</a>',
  intro_aboutResProtection1: '<a href="https://www.social-protection.org/gimi/gess/Wspr.action?lang=ES">Informe Mundial sobre la Protección Social 2017-2019</a>',
  intro_aboutResWork1: '<a href="https://www.ilo.org/global/topics/labour-law/lang--es/index.htm">Portal temático sobre el derecho del trabajo </a>',
  intro_aboutResConditions1: '<a href="https://betterwork.org/">Better Work</a>',
  intro_aboutResConditions2: '<a href="https://www.ilo.org/safework/projects/WCMS_616519/lang--es/index.htm">Fondo Visión Cero</a>',
  intro_aboutResConditions3: '<a href="https://www.ilo.org/global/topics/wages/minimum-wages/lang--es/index.htm">La Guía de Políticas de Salario Mínimo de la OIT</a>',
  intro_aboutResEnterprises1: '<a href="https://www.ilo.org/empent/areas/business-helpdesk/lang--es/index.htm">Helpdesk de la OIT para empresas sobre las normas internacionales del trabajo</a>',
  intro_aboutResEnterprises2: '<a href="https://www.ilo.org/empent/units/boosting-employment-through-small-enterprise-development/eese/lang--en/index.htm">Enabling Environment for Sustainable Enterprises (EESE)</a>',
  intro_aboutResEnterprises3: '<a href="http://www.knowaboutbusiness.org/">Know About Business (KAB)</a>',
  intro_aboutResEnterprises4: '<a href="http://mmw.itcilo.org/es/home">Gestión de Instituciones Microfinancieras: Cómo mejorar el desempeño institucional!</a>',
  intro_aboutResEnterprises5: '<a href="https://www.ilo.org/empent/areas/start-and-improve-your-business/lang--es/index.htm">Inicie y Mejore Su Negocio (IMESUN)</a>',
  intro_aboutResEnterprises6: '<a href="https://www.itcilo.org/en/areas-of-expertise/rural-development/my-coop-manging-your-agricultural-cooperative">My.COOP</a>',
  intro_aboutResEnterprises7: '<a href="https://www.ilo.org/empent/Projects/score/lang--es/index.htm">El Programa SCORE</a>',
  intro_aboutResEnterprises8: '<a href="https://www.ilo.org/empent/areas/womens-entrepreneurship-development-wed/lang--es/index.htm">Desarrollo de la iniciativa empresarial de la mujer (WED)</a>',
  intro_aboutResEnterprises9: '<a href="https://www.ilo.org/empent/areas/mne-declaration/lang--es/index.htm">Declaración tripartita de principios sobre las empresas multinacionales y la política social</a>',
  intro_aboutResFuture2: '<a href="https://www.ilo.org/global/topics/sdg-2030/lang--es/index.htm">Trabajo decente y la Agenda 2030 de Desarrollo Sostenible</a>',
  intro_aboutResEquality4: '<a href="https://www.ilo.org/global/topics/disability-and-work/lang--es/index.htm">Portal temático sobre discapacidad y trabajo</a>',
  intro_aboutResEquality5: '<a href=" http://www.businessanddisability.org/">ILO Global Business and Disability Network</a>',
  tripartism_title: 'Los cimientos de la justicia social',
  tripartism_heading: 'Tripartismo y diálogo social',
  tripartism_chapter: 'capítulo 1',
  tripartism_ilo: 'Organización Internacional del Trabajo',
  tripartism_infoboxIlo: '<p>La Organización Internacional del Trabajo se creó en una época de agitación y de rápidas transformaciones en los planos social y tecnológico.</p>\n\n<p>En un contexto internacional marcado por las actividades de reconstrucción y recuperación tras los estragos provocados por la guerra, la OIT fue fundada en la creencia de que la justicia social es esencial para lograr una paz universal y duradera.</p>',
  tripartism_socialJustice: 'Justicia social',
  tripartism_keysQuote: '“Cuando estas tres llaves giran a la vez, cuando gobiernos, empleadores y trabajadores trabajan juntos, las puertas se abren y la justicia social avanza".',
  tripartism_guyRyder: 'Guy Ryder, Director General de la OIT',
  tripartism_calloutWorld1919: 'el mundo en 1919',
  tripartism_infoWw1: '<p>La Primera Guerra Mundial dejó países en ruinas, personas traumadas por la guerra y economías nacionales devastadas. Se trazaron nuevas fronteras en los mapas y aquellos que habían estado día y noche en el frente de batalla pedían que se escuchase su voz.</p>\n\n<p>En el siglo que precedió a la guerra, hubo grandes transformaciones en Europa y en Estados Unidos en los planos económico y social. La industrialización había dado lugar a un desarrollo sin precedentes del poder económico de las naciones europeas, así como a una competencia cada vez mayor entre ellas, propiciada parcialmente por las transformaciones, a veces inhumanas, que se producían en el mundo del trabajo.</p>',
  tripartism_calloutVersailles: 'El Tratado de Versalles',
  tripartism_infoVersailles: '<p>Al finalizar la Primera Guerra Mundial, la creciente decepción producida por la desigualdad generalizada y las deficientes condiciones de trabajo hicieron que Europa estuviera al borde de la revolución.</p>\n\n<p>Los trabajadores exigían que, en el acuerdo de paz, se tuvieran en cuenta medidas que garantizaran condiciones de trabajo más equitativas a través de normas internacionales del trabajo y derechos sindicales.</p>',
  tripartism_whatIsSocialJustice: '¿Qué es la justicia social?',
  tripartism_infoUnrest: '<h3>"El malestar es tan grande que la paz y la armonía mundiales se ven amenazadas"</h3>\n\n<p>Esas demandas eran tan reiteradas y la situación social era tan inestable que el extracto de texto de la Constitución que sigue a continuación pone de manifiesto una de las principales inquietudes en la Conferencia de Paz: "...Hay un malestar tan grande que la paz y la armonía mundiales se ven amenazadas... ".</p>\n\n<p>Esta afirmación, con la que comienza el Preámbulo de la Constitución de la OIT, claramente atribuye a esta Organización un papel esencial en el mantenimiento de la paz.</p>',
  tripartism_calloutIloCreated: 'La OIT, que reúne a representantes de gobiernos, trabajadores y empleadores, se constituyó en 1919 en virtud de lo dispuesto en la Parte XIII del Tratado de Paz de Versalles, con el que se puso fin a la Primera Guerra Mundial.',
  tripartism_quoteWildDreamBody: '“Recuerdo muy bien que la OIT seguía siendo un sueño por ese entonces. Para muchos, un sueño inalcanzable. ¿Alguien había oído alguna vez que los gobiernos se reunieran para mejorar las normas del trabajo a escala internacional?"<br><br>"Aún más inalcanzable era la idea de que las personas directamente interesadas, a saber, los trabajadores y los empleadores de cada país, colaboraran con su gobierno para establecer esas normas del trabajo.”',
  tripartism_quoteWildDreamCaption: 'Franklin Delano Roosevelt era el presidente de los Estados Unidos cuando se unió a la OIT en 1934.',
  tripartism_calloutWildDream: 'Para muchos era un sueño inalcanzable.',
  tripartism_timeline1969Heading: 'La OIT recibe el Premio Nobel de la Paz en su 50.º aniversario',
  tripartism_timeline1969Para: '“Son pocas las organizaciones cuyo éxito pueda equipararse al de la OIT en lo relativo a la aplicación de su principio moral fundamental subyacente.... El trabajo responsable y eficaz de la OIT ha logrado implantar reformas que han contribuido a erradicar las más flagrantes injusticias en muchos países.” Aase Lionaes, presidenta del Comité para el Nobel del Parlamento Noruego.',
  tripartism_timeline1919Heading: 'Redacción de la Constitución de la OIT',
  tripartism_timeline1919Para: 'De enero a abril, la Comisión del Trabajo establecida en el marco de la Conferencia de Paz se reúne en París, y posteriormente en Versalles, a fin de redactar la Constitución de la OIT. La primera Conferencia Internacional del Trabajo, en la que participan delegaciones de 40 países, tiene lugar en Washington, D.C., de octubre a noviembre de 1919.',
  tripartism_timeline1920Heading: 'Inauguración de la Oficina Internacional del Trabajo en Ginebra',
  tripartism_timeline1920Para: 'La sede de la OIT queda establecida en Ginebra (Suiza) en el verano de 1920. Albert Thomas, francés, se convierte en el primer Director de la Oficina Internacional del Trabajo, la Secretaría permanente de la Organización.',
  tripartism_timeline1936Heading: 'Primera Conferencia Regional de la OIT en Santiago de Chile',
  tripartism_timeline1944Heading: 'Declaración de Filadelfia',
  tripartism_timeline1946Heading: 'La OIT pasa a formar parte de las Naciones Unidas',
  tripartism_timeline1946Para: 'La OIT se convierte en el primer organismo especializado de las Naciones Unidas, organización creada recientemente. Ello hace que la OIT sea uno de los principales foros de debate a lo largo de la Guerra Fría.',
  tripartism_timeline1947Heading: 'Primera Conferencia Regional Asiática de la OIT en Nueva Delhi (India)',
  tripartism_timeline1947Para: 'Delegados de 18 países y territorios se reúnen para reforzar las actividades de la OIT en Asia, en un momento de grandes transformaciones en la región.',
  tripartism_timeline1964Heading: 'Declaración contra el Apartheid',
  tripartism_timeline1964Para: 'La Conferencia Internacional del Trabajo adopta por unanimidad la Declaración sobre la política del apartheid, en virtud de la cual se condena la política racial del Gobierno de Sudáfrica. En 1990, Nelson Mandela participa en la CIT, en la que rinde homenaje a la OIT por su contribución a la lucha contra el apartheid.',
  tripartism_timeline2014Heading: 'El Centro Internacional de Formación de la OIT en Turín (Italia) celebra su 50.° aniversario',
  tripartism_timeline1982Heading: 'La OIT promueve la libertad sindical en Europa del Este',
  tripartism_timeline1982Para: 'La OIT respalda plenamente la legitimidad del sindicato Solidaridad (Solidarność) en Polonia, país en el que Lech Walesa encabeza una huelga con el fin de establecer el primer sindicato independiente y autónomo en el entonces denominado Bloque del Este, y sienta las bases para la restauración definitiva de la democracia.',
  tripartism_timeline1998Heading: 'Declaración de la OIT relativa a los principios y derechos fundamentales en el trabajo',
  tripartism_timeline1998Para: 'Los países miembros de la OIT se comprometen a respetar y promover los derechos y principios relativos a la libertad sindical, el trabajo forzoso, el trabajo infantil y la discriminación, con independencia de que hayan ratificado o no los Convenios pertinentes.',
  tripartism_timeline1999Heading: 'Creación del Programa de Trabajo Decente',
  tripartism_timeline1999Para: 'El trabajo decente sintetiza las aspiraciones de una persona a lo largo de su vida profesional. Los cuatro elementos fundamentales del Programa son la promoción del empleo y de las empresas, la ampliación de la protección social, el fomento del diálogo social y el amparo de los derechos laborales. La igualdad de oportunidades y de trato para mujeres y hombres constituye un elemento transversal de esos cuatro elementos fundamentales.',
  tripartism_timeline2008Heading: 'Declaración sobre la justicia social para una globalización equitativa',
  tripartism_timeline2008Para: 'En virtud de esta Declaración, se promueve el trabajo decente por medio de un enfoque integrado orientado a cuatro objetivos estratégicos, a saber, la creación de empleo, la protección social, el diálogo social y los principios y derechos fundamentales en el trabajo. Se fomentan el diálogo social y el tripartismo como métodos idóneos para transformar el desarrollo económico en avance social, y el avance social en desarrollo económico.\n\nEn ese año, se afianza la crisis financiera mundial hasta llegar a constituir la peor recesión económica desde la Gran Depresión.',
  tripartism_timeline2009Heading: 'Pacto Mundial para el Empleo',
  tripartism_timeline2009Para: 'Ante la perspectiva de una coyuntura de desempleo, pobreza y desigualdad a largo plazo, la Conferencia Internacional del Trabajo adopta el Pacto Mundial para el Empleo, con objeto de fomentar la recuperación económica, generar empleo y ofrecer protección a los trabajadores y sus familias.',
  tripartism_timeline2015Heading: 'El trabajo decente pasa a ser uno de los ejes de los Objetivos de Desarrollo Sostenible',
  tripartism_timeline2015Para: 'En la Asamblea General de las Naciones Unidas celebrada en septiembre de 2015, los cuatro elementos fundamentales del Programa de Trabajo Decente, a saber, las normas y los derechos en el trabajo, la creación de empleo y el desarrollo empresarial, la protección social y el diálogo social, pasan a ser elementos constitutivos de la Agenda 2030 para el Desarrollo Sostenible.',
  tripartism_timeline2019Heading: 'Centenario de la OIT: construir un futuro que beneficie a todos',
  tripartism_timeline2019Para: 'La Organización Internacional del Trabajo, el organismo especializado más antiguo de las Naciones Unidas, conmemora su centenario.',
  tripartism_infoLifeIndustry: '<h3>La vida en los países industrializados era muy diferente de la vida actual</h3>\n\n<p>El grado y el alcance de la pobreza, desigualdad y discriminación eran mayores. Con frecuencia se veía a niños que trabajaban en las fábricas y en el campo. Los accidentes y las muertes en el lugar de trabajo eran habituales.</p>',
  tripartism_timeline1926Heading: 'Sistema de control del cumplimiento de los Convenios de la OIT',
  tripartism_timeline1926Para: 'Se establece la Comisión de Expertos sobre aplicación de los Convenios y las Recomendaciones a fin de hacer responsables a los países del cumplimiento de los Convenios de la OIT que han ratificado.',
  tripartism_timeline1929Heading: 'Comienzo de la Gran Depresión',
  tripartism_timeline1929Para: 'La OIT trabaja directamente en la búsqueda de soluciones para afrontar la Gran Depresión, el principal problema de la época.',
  tripartism_timeline1936Para: 'En la primera Conferencia del Trabajo de los Estados Miembros americanos, se reafirma el concepto de universalidad, en virtud del cual las acciones de la OIT deben atender las necesidades de todas las personas del mundo, independientemente del régimen social o económico de su país.',
  tripartism_timeline1939Heading: 'Estalla la Segunda Guerra Mundial',
  tripartism_timeline1939Para: 'Puesto que Suiza se encuentra rodeada por fuerzas del Eje, la sede de la OIT se traslada temporalmente a Montreal, Canadá, en agosto de 1940.',
  tripartism_timeline1944Para: 'La OIT adopta la Declaración de Filadelfia, en virtud de la cual se reafirman los principios de que el trabajo no es una mercancía, de que la libertad de expresión y de sindicación es esencial para el progreso constante, y de que la pobreza, en cualquier lugar, constituye un peligro para la prosperidad de todos; ello pone de manifiesto la pertinencia de la labor de la OIT para afrontar los retos de los años de la posguerra.',
  tripartism_timeline1948Heading: 'Adopción del Convenio fundamental de la OIT sobre libertad sindical',
  tripartism_timeline1948Para: 'El Convenio sobre la libertad sindical y la protección del derecho de sindicación (núm. 87) establece el derecho de los trabajadores y de los empleadores de constituir las organizaciones que estimen convenientes y de afiliarse a las mismas sin autorización previa.',
  tripartism_timeline1949Heading: 'Adopción Convenio fundamental de la OIT sobre el derecho de sindicación y de negociación colectiva',
  tripartism_timeline1949Para: 'El Convenio sobre el derecho de sindicación y de negociación colectiva (núm. 98) establece que los trabajadores deberán gozar de adecuada protección contra todo acto de discriminación tendiente a menoscabar la libertad sindical, y consagra el derecho de negociación colectiva.',
  tripartism_timeline1952Heading: 'Programa Indígena Andino',
  tripartism_timeline1952Para: 'Se pone en marcha el primer programa de cooperación técnica a gran escala, dirigido por la OIT con la colaboración de varios organismos de las Naciones Unidas. El programa abarca siete países andinos y su objetivo es mejorar las condiciones sociales y económicas de los pueblos indígenas. Su actividad finaliza en 1972.',
  tripartism_timeline1955Heading: 'Primera Conferencia Regional Europea de la OIT en Ginebra',
  tripartism_timeline1955Para: '“La importancia de esta Conferencia obedece primordialmente al hecho de que se pone de manifiesto que los países de Europa están dispuestos a buscar soluciones para subsanar los problemas sociales que plantea la mayor integración económica del continente". David A. Morse, Director General de la OIT',
  tripartism_timeline1960Heading: 'Primera Conferencia Regional Africana de la OIT en Lagos, Nigeria',
  tripartism_timeline1960Para: 'La mayor conferencia regional organizada hasta entonces por la OIT tiene lugar en un contexto especial: el proceso de independencia de una gran cantidad de nuevos Estados africanos, lo que hace que la Conferencia tenga gran alcance a escala internacional.',
  tripartism_timeline2014Para: 'El CIF presta servicios de formación, enseñanza y capacitación a gobiernos, organizaciones de empleadores y de trabajadores, así como a otros socios nacionales e internacionales, para fomentar el trabajo decente. Desde su creación en 1964, el Centro de Turín ha formado a más de 80 000 personas de 172 países.',
  tripartism_timeline1976Heading: 'Adopción del Convenio de la OIT sobre el tripartismo y las normas del trabajo',
  tripartism_timeline1976Para: 'El Convenio (núm. 144)  sobre la consulta tripartita (normas internacionales del trabajo) establece que los empleadores y los trabajadores deben estar representados en pie de igualdad en cualquier organismo mediante el cual se lleven a cabo las consultas de la OIT, y que esas consultas deben realizarse al menos una vez al año.',
  tripartism_timeline1977Heading: 'Nuevo programa para mejorar las condiciones y el lugar de trabajo',
  tripartism_timeline1977Para: 'El Programa Internacional para el mejoramiento de las condiciones y del lugar de trabajo (PIACT) tiene como objeto promover y apoyar medidas destinadas a "humanizar el trabajo". El programa hace hincapié en la mejora de la calidad de la vida en el trabajo por medio de la prevención de accidentes y de enfermedades laborales y "una mayor inquietud por el factor humano en la transferencia tecnológica”.',
  tripartism_timeline1992Heading: 'Nuevo programa para erradicar el trabajo infantil',
  tripartism_timeline1992Para: 'La OIT pone en marcha el Programa Internacional para la Erradicación del Trabajo Infantil (IPEC), el programa de mayor alcance jamás establecido para erradicar el trabajo infantil, que ha contribuido, según datos estadísticos de la OIT, a poner fin a más de 86 millones de casos de trabajo infantil en todo el mundo.',
  tripartism_timeline2002Heading: 'Creación de la Comisión Mundial sobre la Dimensión Social de la Globalización',
  tripartism_timeline2002Para: 'Veintiséis figuras clave, entre ellas, jefes de Estado, líderes empresariales, sindicatos y Premios Nobel, analizan el proceso de globalización desde la perspectiva de la gente común. Por primera vez, se trata de encontrar un punto de coincidencia sobre uno de los temas más controvertidos y divisorios de nuestro tiempo.',
  tripartism_quoteSocJusBody1: '"Vencer la pobreza no es un gesto de caridad, es un acto de justicia."',
  tripartism_quoteSocJusBody2: '"Hasta que la gran mayoría de la gente no tenga pleno sentido de la responsabilidad con respecto al bienestar de los demás, nunca se podrá lograr la justicia social”.',
  tripartism_quoteSocJusCaption1: 'Nelson Mandela, expresidente de Sudáfrica, ganador del Premio Nobel de la Paz en 1993',
  tripartism_quoteSocJusCaption2: 'Helen Keller, escritora y activista política estadounidense',
  tripartism_quoteSocJusBody3: '"Cuando se priva a personas de su derecho a vivir con dignidad, anhelar un futuro mejor y controlar su propia vida, cuando se les niega esas posibilidades, cabe esperar que luchen por esos derechos".',
  tripartism_quoteSocJusCaption3: 'Reina Rania Al Abdullah de Jordania',
  tripartism_quoteSocJusBody4: '"Uno de los elementos esenciales para alcanzar una paz universal duradera es la justicia social".',
  tripartism_quoteSocJusCaption4: 'Arthur Henderson, exlíder del Partido Laborista del Reino Unido y Premio Nobel de la Paz en 1934',
  tripartism_quoteSocJusBody5: '"No hay paz sin justicia; no hay justicia sin equidad; no hay equidad sin desarrollo; no hay desarrollo sin democracia; no hay democracia sin  respeto a la identidad y dignidad de las culturas y los pueblos".',
  tripartism_quoteSocJusCaption5: 'Rigoberta Menchú, activista guatemalteca de los derechos humanos, ganadora del Premio Nobel de la Paz en 1992',
  tripartism_quoteSocJusBody6: '"La educación es fundamental para el empoderamiento y la sostenibilidad. La educación es un derecho fundamental para el resto de los derechos de la vida en sociedad. La educación es fundamental para la justicia social".',
  tripartism_quoteSocJusCaption6: 'Kailash Satyarthi, activista indio defensor de los derechos del niño y Premio Nobel de la Paz en 2014',
  tripartism_infoFounders: '<h3>Los fundadores de la OIT</h3>\n\n<p>… estaban convencidos de la existencia de un vínculo fundamental entre la paz internacional y la justicia social en todos los países. Ese vínculo revestía tanta importancia que era necesario establecer una organización que abordara las cuestiones laborales a fin de promover y mantener la paz mundial.</p>',
  tripartism_visSocJustTitle: 'Elementos clave para la justicia social',
  tripartism_visSocJustHeading1: 'Mecanismo de trabajo de la OIT para alcanzar la justicia social',
  tripartism_visSocJustBody1: '<p>La OIT es la única organización internacional en la que los gobiernos no tienen poder de voto exclusivo. Al formular normas y políticas, los representantes de los empleadores y de los trabajadores tienen el mismo derecho a voto que los gobiernos en los procesos de toma de decisiones. Este concepto se conoce como "tripartismo".</p>\n\n<p>En 1919, la decisión de otorgar a los representantes "no gubernamentales" el derecho de participación en conferencias internacionales constituyó una drástica propuesta. Transcurrirían muchos años, incluso décadas, después del establecimiento de la OIT hasta que la mayoría de los países implantara el tripartismo a escala nacional.</p>\n\n<p>Este mecanismo singular brinda a la OIT la ventaja de incorporar a su labor conocimientos del "mundo real" en materia de empleo y cuestiones laborales.</p>\n\n<p>Sobre la base de este exitoso ejemplo, alrededor del 85% de los Estados Miembros de la OIT han establecido desde entonces instituciones tripartitas destinadas a abordar cuestiones relacionadas con el trabajo.</p>',
  tripartism_visSocJustHeading2: 'Diálogo social',
  tripartism_visSocJustBody2: '<p>Ese proceso democrático, en virtud del cual las personas concernidas por las decisiones relativas al mundo del trabajo tienen derecho a voto en los procesos de toma de decisiones, incluida su aplicación, se conoce como diálogo social.</p>\n\n<p>Dicho proceso puede tener lugar de muchas formas, en particular mediante el intercambio de información, la celebración de consultas y la negociación entre trabajadores, empleadores y gobiernos. Ello se fundamenta en el derecho de sindicación, a tenor del cual los trabajadores y los empleadores pueden constituir las organizaciones que estimen convenientes y afiliarse a las mismas, y en el derecho de negociación colectiva de salarios y otras condiciones de empleo. El diálogo social es más eficaz si se apoya en la transparencia, la honestidad y el respeto y la comprensión mutuos.</p>\n\n<p>En el plano laboral, el diálogo social puede mejorar las condiciones de trabajo y contribuir a fomentar el rendimiento económico, y constituye un eficaz motor para promover la estabilidad, la equidad, la productividad y el desarrollo sostenible.</p>\n\n<p>A escala nacional, el diálogo social y el tripartismo actúan como garantes de la democracia participativa. Aúnan sus fuerzas para garantizar y promover los principios y derechos fundamentales en el trabajo. Amparan a los trabajadores para que puedan beneficiarse de forma justa del desarrollo económico, y permiten velar por la seguridad en el trabajo y para que la globalización redunde en beneficio de todos. El diálogo social y el tripartismo son esenciales para el desarrollo sostenible, la justicia social y la paz.</p>',
  tripartism_visSocJustHeading3: 'Organizaciones de trabajadores y de empleadores',
  tripartism_visSocJustBody3: '<p>El establecimiento de relaciones laborales eficaces sigue constituyendo un enorme reto en todo el mundo, tanto para las organizaciones de trabajadores como para las de empleadores.</p>\n\n<p>Habida cuenta de que el diálogo social es primordial para la labor de la OIT, esta contribuye a mejorar la eficacia de dichas organizaciones para que puedan apoyar y representar mejor a sus miembros.</p>\n\n<p>En consecuencia, la OIT colabora estrechamente con los representantes de los trabajadores y de los empleadores, que al igual que los representantes de los gobiernos, integran el Consejo de Administración de la OIT y ayudan a establecer su programa.</p>\n\n<p>La labor de la OIT sigue siendo pertinente en el mundo del trabajo, sobre la base de la cooperación en el ámbito laboral, para afrontar retos en continua evolución, en particular la introducción de nuevas tecnologías, el fomento de la innovación y la seguridad de los entornos de trabajo.</p>',
  tripartism_calloutDistinctHeading: 'La OIT se diferencia asimismo de otras organizaciones intergubernamentales en lo concerniente a otro aspecto importante, a saber, las normas internacionales del trabajo que adopta y el modo de controlar su aplicación.',
  tripartism_calloutDistinctBody: 'En los citados tratados internacionales se determinan las medidas que deben adoptar los países que los ratifican, así como los principios que deben observar a tal efecto. Dichos tratados constituyen el principal medio de acción e influencia de la OIT con respecto al mundo del trabajo y contribuyen a establecer las condiciones necesarias para que los países, las empresas y los trabajadores compitan en pie de igualdad.',
  tripartism_timeline1919Caption: 'Primera Conferencia Internacional del Trabajo, Washington  D. C. (Estados Unidos), 1919. © OIT',
  tripartism_timeline1920Caption: 'Albert Thomas, primer Director de la OIT. © OIT',
  tripartism_timeline1926Caption: 'El Consejo de Administración de la OIT, reunido en 1926, designa un comité técnico, denominado ulteriormente Comisión de Expertos en Aplicación de Convenios y Recomendaciones (CEACR), con objeto de supervisar la aplicación de las Normas Internacionales del Trabajo. © OIT',
  tripartism_timeline1929Caption: 'Trabajadores desempleados haciendo cola durante la Gran Depresión. Nueva York (Estados Unidos), 1930. © Biblioteca del Congreso de Estados Unidos',
  tripartism_timeline1936Caption: 'Primera Conferencia del Trabajo de los Estados Miembros Americanos de la OIT, Santiago, Chile, 1936. © OIT',
  tripartism_timeline1939Caption: 'Un grupo de funcionarios de la OIT y sus cónyuges esperan a embarcarse en Lisboa (Portugal), en septiembre de 1940, al tiempo que la sede de la OIT se traslada a Canadá durante la guerra. © OIT',
  tripartism_timeline1944Caption: 'El Director de la OIT, Edward Phelan (sentado, a la derecha) firma la histórica Declaración de Filadelfia de la OIT en la Casa Blanca (Washington D. C.), el 17 de mayo de 1944, en presencia del presidente de los Estados Unidos, Franklin Roosevelt (sentado, a la izquierda). © OIT',
  tripartism_timeline1946Caption: 'El Director de la OIT, Edward Phelan (a la izquierda) y el Secretario General de las Naciones Unidas, Trygve Lie (a la derecha), firman el acuerdo en virtud del cual la OIT pasa a ser el primer organismo especializado de la recién creada organización de las Naciones Unidas. © OIT',
  tripartism_timeline1947Caption: 'Primera Conferencia Regional Asiática de la OIT, Nueva Delhi (India), 1947. © OIT',
  tripartism_timeline1948Caption: 'Personal de la OIT en la Conferencia Internacional del Trabajo celebrada en San Francisco (Estados Unidos), en 1948. © OIT',
  tripartism_timeline1949Caption: 'Edward Phelan (a la izquierda), 4º Director de la OIT, y David Morse (a la derecha), 5º Director General de la OIT. Ginebra (Suiza), 1949 © OIT',
  tripartism_timeline1952Caption: 'Programa Indígena Andino, Perú. © OIT',
  tripartism_timeline1955Caption: 'Max Petitpierre, presidente de la Confederación Suiza, da un discurso ante la primera Conferencia Regional Europea, junto a David Morse, 5º Director General de la OIT (a la izquierda), y Roberto Ago, presidente del Consejo de Administración de la OIT (a la derecha). © OIT',
  tripartism_timeline1960Caption: 'David Morse, 5º Director General de la OIT, llega a Lagos (Nigeria), para participar en la primera Conferencia Regional Africana. © OIT',
  tripartism_timeline1964Caption: 'La OIT fue una de las primeras organizaciones internacionales en imponer sanciones a Sudáfrica por sus políticas de apartheid. Más de 30 años después, en la 77ª Conferencia Internacional del Trabajo, Nelson Mandela agradeció a la OIT por su apoyo a la lucha contra el apartheid. 1990. © OIT',
  tripartism_timeline2014Caption: 'Cada año, personas de todo el mundo acuden al Centro Internacional de Formación de la OIT en Turín para participar en seminarios, talleres y cursos. © Davide Bellucca',
  tripartism_timeline1969Caption: 'El Director General de la OIT, David Morse, recibe el Premio Nobel de la Paz en nombre de la OIT de manos de Aase Lionaes, presidenta del Comité del Premio Nobel del Parlamento Noruego, en la Universidad de Oslo, el 10 de diciembre de 1969. © OIT',
  tripartism_timeline1976Caption: 'Delegados de la 61ª Reunión de la CIT en la Sala de las Asambleas de las Naciones Unidas en Ginebra (Suiza), 1976. © OIT',
  tripartism_timeline1977Caption: 'Carteles sobre salud y seguridad en el trabajo en Mauritania. © OIT',
  tripartism_timeline1982Caption: 'La OIT respalda el sindicato independiente Solidaridad (Solidarnosc), liderado por Lech Walesa, que posteriormente llegaría a ser presidente de Polonia. © OIT',
  tripartism_timeline1992Caption: 'La OIT pone en marcha el Programa Internacional para la Erradicación del Trabajo Infantil (IPEC). © OIT / E. Gianotti',
  tripartism_timeline1998Caption: 'Adopción de la Declaración de la OIT relativa a los principios y derechos fundamentales en el trabajo en la 86ª Reunión de la CIT, Ginebra (Suiza). © OIT',
  tripartism_timeline1999Caption: 'La OIT pone en marcha el Programa de Trabajo Decente. Desempleados zimbabuenses de Harare (Zimbabue) buscan trabajo. 7 de junio de 2001. © AFP/Alexander Joe',
  tripartism_timeline2002Caption: 'Comisión Mundial sobre la Dimensión Social de la Globalización. De izquierda a derecha, Benjamin William Mkapa, presidente de Tanzania, Tarja Halonen, presidenta de la República de Finlandia y Juan Somavia, Director General de la OIT. © OIT / M. Crozet',
  tripartism_timeline2008Caption: 'Sesión de apertura de la 97ª CIT de 2008, celebrada en Ginebra (Suiza), en la que se adoptó la Declaración de la OIT sobre la justicia social para una globalización equitativa. © OIT',
  tripartism_timeline2009Caption: 'Discurso de Juan Somavia, Director General de la OIT, en la Sesión de Apertura de la Cumbre de la OIT sobre la Crisis Mundial del Empleo. Ginebra (Suiza), 15 de junio de 2009. © OIT',
  tripartism_timeline2015Caption: 'Ban Ki-moon, 8° Secretario General de las Naciones Unidas, en un discurso en la CIT. © OIT',
  tripartism_timeline2017Caption: 'Discurso de Guy Ryder, Director General de la OIT, en la presentación de la Comisión Mundial de la OIT sobre el Futuro del Trabajo. Ginebra (Suiza), 21 de agosto de 2017. © OIT / M. Crozet',
  tripartism_timeline2019Caption: 'Fotografía de grupo de la Oficina Regional de la OIT para Asia y el Pacífico para conmemorar el próximo centenario de la OIT. © OIT',
  tripartism_visSocJustTripartism: 'Tripartismo',
  tripartism_visSocJustGovernments: 'Gobiernos',
  tripartism_visSocJustEmployers: 'Empleadores',
  tripartism_visSocJustWorkers: 'Trabajadores',
  tripartism_imageThreekeysCaption: 'Tres llaves, que representan a gobiernos, empleadores y trabajadores, abrieron las puertas de la nueva sede de la OIT en Ginebra (Suiza) durante su inauguración en 1926. © OIT',
  tripartism_imageThreekeysAlt: 'Tres llaves en la cerradura de la puerta de la Villa Rappard en Ginebra (Suiza).',
  tripartism_imageGirlcottonCaption: 'Joven hilando algodón en un telar en Texas (Estados Unidos), 1913. © Biblioteca del Congreso de Estados Unidos',
  tripartism_imageFactoriesCaption: 'La industria en el 1900. © OIT',
  tripartism_imageProtestCaption: 'Protesta en Europa, 1919. © British Pathé',
  tripartism_imageCommissionCaption: 'La Comisión sobre Legislación Internacional del Trabajo, reunida durante la Conferencia de Paz de París, febrero-marzo de 1919. La Comisión redactó la Constitución de la OIT, que pasó a formar parte del Tratado de Versalles. © OIT',
  tripartism_imageCommissionAlt: 'Hombres de pie frente a la puerta de un edificio. En primera línea se encuentra Samuel Gompers, Presidente de la Comisión.',
  tripartism_imageInaugurationCaption: 'Inauguración de la sede de la OIT, Ginebra (Suiza), 1926. © OIT',
  tripartism_imageRooseveltCaption: 'Franklin Roosevelt, 32º Presidente de los Estados Unidos. Durante su administración, dicho país pasó a ser miembro de la OIT. © AFP (Audio: Archivo Nacional de los Estados Unidos)',
  tripartism_imageFirstilcCaption: 'Primera Conferencia Internacional del Trabajo, Washington  D. C. (Estados Unidos), 1919. © OIT',
  tripartism_imageAlbertthomasCaption: 'Albert Thomas, primer Director de la OIT. © OIT',
  tripartism_imageGbtwentysixCaption: 'El Consejo de Administración de la OIT, reunido en 1926, designa un comité técnico, denominado ulteriormente Comisión de Expertos en Aplicación de Convenios y Recomendaciones (CEACR), con objeto de supervisar la aplicación de las Normas Internacionales del Trabajo. © OIT',
  tripartism_imageGbtwentysixAlt: 'Grupo de delegados sentados en una sala de reuniones.',
  tripartism_imageUnemployedCaption: 'Trabajadores desempleados haciendo cola durante la Gran Depresión. Nueva York (Estados Unidos), 1930. Biblioteca del Congreso.',
  tripartism_imageFirstchileCaption: 'Primera Conferencia del Trabajo de los Estados Miembros Americanos de la OIT, Santiago, Chile, 1936. © OIT',
  tripartism_imageFirstchileAlt: 'Grupo de delegados saliendo del edificio. Al frente está Harold Butler, segundo Director General de la OIT.',
  tripartism_imageShiplisbonCaption: 'Un grupo de funcionarios de la OIT y sus cónyuges esperan a embarcarse en Lisboa (Portugal), en septiembre de 1940, al tiempo que la sede de la OIT se traslada a Canadá durante la guerra. © OIT',
  tripartism_imageShiplisbonAlt: 'Tres hombres y tres mujeres ante la cámara delante de un gran barco con la bandera de Estados Unidos.',
  tripartism_imagePhelandofpCaption: 'El Director de la OIT, Edward Phelan (sentado, a la derecha) firma la histórica Declaración de Filadelfia de la OIT en la Casa Blanca (Washington D. C.), el 17 de mayo de 1944, en presencia del presidente de los Estados Unidos, Franklin Roosevelt (sentado, a la izquierda). © OIT',
  tripartism_imagePhelandofpAlt: 'Tres hombres, incluidos Roosevelt y Phelan, sentados en torno a un escritorio. Detrás de ellos, hay dos hombres y una mujer, Frances Perkins.',
  tripartism_imagePhelanlieCaption: 'El Director de la OIT, Edward Phelan (a la izquierda) y el Secretario General de las Naciones Unidas, Trygve Lie (a la derecha), firman el acuerdo en virtud del cual la OIT pasa a ser el primer organismo especializado de la recién creada organización de las Naciones Unidas. © OIT',
  tripartism_imagePhelanlieAlt: 'Dos hombres sentados en un escritorio, uno al lado del otro, firmando el acuerdo.',
  tripartism_imageFirstasiaconfCaption: 'Primera Conferencia Regional Asiática de la OIT, Nueva Delhi (India), 1947. © OIT',
  tripartism_imageFirstasiaconfAlt: 'Delegados de la Conferencia Regional y Jawaharlal Nehru, el primero en ocupar el cargo de Primer Ministro de la India, de pie en una sala.',
  tripartism_imageIlcsanfranCaption: 'Personal de la OIT en la Conferencia Internacional del Trabajo celebrada en San Francisco (Estados Unidos), en 1948. © OIT',
  tripartism_imageIlcsanfranAlt: 'Hombres y mujeres de pie frente a un edificio debajo de una pancarta que reza "Conferencia Internacional del Trabajo".',
  tripartism_imagePhelanmorseCaption: 'Edward Phelan (a la izquierda), 4º Director de la OIT, y David Morse (a la derecha), 5º Director General de la OIT. Ginebra (Suiza), 1949 © OIT',
  tripartism_imagePhelanmorseAlt: 'Dos hombres de pie, mirándose el uno al otro.',
  tripartism_imageAndeanCaption: 'Programa Indígena Andino, Perú. © OIT',
  tripartism_imageAndeanAlt: 'Trabajadores indígenas y expertos de la OIT en una obra de construcción en Chucuito (Perú).',
  tripartism_imagePetitpierreCaption: 'Max Petitpierre, presidente de la Confederación Suiza, da un discurso ante la primera Conferencia Regional Europea, junto a David Morse, 5º Director General de la OIT (a la izquierda), y Roberto Ago, presidente del Consejo de Administración de la OIT (a la derecha). © OIT',
  tripartism_imagePetitpierreAlt: 'Dos hombres sentados detrás de un atrio con micrófonos. El hombre del medio está de pie y se dirige al público.',
  tripartism_imageMorsenigeriaCaption: 'David Morse, 5º Director General de la OIT, llega a Lagos (Nigeria), para participar en la primera Conferencia Regional Africana. © OIT',
  tripartism_imageMorsenigeriaAlt: 'David Morse y varios delegados bajando las escaleras del avión.',
  tripartism_imageMandelaCaption: 'La OIT fue una de las primeras organizaciones internacionales en imponer sanciones a Sudáfrica por sus políticas de apartheid. Más de 30 años después, en la 77ª Conferencia Internacional del Trabajo, Nelson Mandela agradeció a la OIT por su apoyo a la lucha contra el apartheid. 1990. © OIT',
  tripartism_imageItcturinCaption: 'Sesión de capacitación en el Centro Internacional de Formación de la OIT en Turín (Italia). © OIT',
  tripartism_imageItcturinAlt: 'Plano general de una gran sala de reuniones, donde docenas de personas se sientan alrededor de mesas y escuchan a un orador que está de pie frente a la sala junto a una gran pantalla.',
  tripartism_imageNobelprizeCaption: 'El Director General de la OIT, David Morse, recibe el Premio Nobel de la Paz en nombre de la OIT de manos de Aase Lionaes, presidenta del Comité del Premio Nobel del Parlamento Noruego, en la Universidad de Oslo, el 10 de diciembre de 1969. © OIT',
  tripartism_imageIlcseventysixCaption: 'Delegados de la 61ª Reunión de la CIT en la Sala de las Asambleas de las Naciones Unidas en Ginebra (Suiza), 1976. © OIT',
  tripartism_imageIlcseventysixAlt: 'Hombres y mujeres sentados en una gran sala de asambleas.',
  tripartism_imagePosteroshCaption: 'Carteles sobre salud y seguridad en el trabajo en Mauritania. © OIT',
  tripartism_imagePosteroshAlt: 'Dos personas delante de un cartel que reza "Accidentes laborales".',
  tripartism_imageLechCaption: 'La OIT respalda el sindicato independiente Solidaridad (Solidarnosc), liderado por Lech Walesa, que posteriormente llegaría a ser presidente de Polonia. © OIT',
  tripartism_imageIpecCaption: 'La OIT pone en marcha el Programa Internacional para la Erradicación del Trabajo Infantil (IPEC). © OIT / E. Gianotti',
  tripartism_imageIpecAlt: 'Joven con un pañuelo en la cabeza sentada detrás de un telar para alfombras.',
  tripartism_imageAdoptfprwCaption: 'Adopción de la Declaración de la OIT relativa a los principios y derechos fundamentales en el trabajo en la 86ª Reunión de la CIT, Ginebra (Suiza). © OIT',
  tripartism_imageAdoptfprwAlt: 'El 8° Director General de la OIT, Michel Hansenne y otros delegados aplaudiendo en el podio tras la aprobación de la Declaración.',
  tripartism_imageZimbabweCaption: 'La OIT pone en marcha el Programa de Trabajo Decente. Desempleados zimbabuenses de Harare (Zimbabue) buscan trabajo. 7 de junio de 2001. © AFP/Alexander Joe',
  tripartism_imageZimbabweAlt: 'Seis hombres de pie en la calle junto a un poste. Tres de ellos leen periódicos.',
  tripartism_imageWorldcomCaption: 'Comisión Mundial sobre la Dimensión Social de la Globalización. De izquierda a derecha, Benjamin William Mkapa, presidente de Tanzania, Tarja Halonen, presidenta de la República de Finlandia y Juan Somavia, Director General de la OIT. © OIT / M. Crozet',
  tripartism_imageWorldcomAlt: 'Tres personas de pie delante de una pancarta, sonriendo.',
  tripartism_imageDeclarationsjCaption: 'Sesión de apertura de la 97ª CIT de 2008, celebrada en Ginebra (Suiza), en la que se adoptó la Declaración de la OIT sobre la justicia social para una globalización equitativa. © OIT',
  tripartism_imageDeclarationsjAlt: 'Vista de la Sala de las Asambleas de la ONU repleta de delegados.',
  tripartism_imageSomaviajobsCaption: 'Discurso de Juan Somavia, Director General de la OIT, en la Sesión de Apertura de la Cumbre de la OIT sobre la Crisis Mundial del Empleo. Ginebra (Suiza), 15 de junio de 2009. © OIT',
  tripartism_imageSomaviajobsAlt: 'Discurso de un hombre en el podio en la Sala de las Asambleas de las Naciones Unidas, con el logo de la OIT.',
  tripartism_imageBankimoonCaption: 'Ban Ki-moon, 8° Secretario General de las Naciones Unidas, en un discurso en la CIT. © OIT',
  tripartism_imageBankimoonAlt: 'Hombre hablando en el podio. Detrás de él hay una bandera con el logo de la OIT.',
  tripartism_imageRyderfowCaption: 'Discurso de Guy Ryder, Director General de la OIT, en la presentación de la Comisión Mundial de la OIT sobre el Futuro del Trabajo. Ginebra (Suiza), 21 de agosto de 2017. © OIT / M. Crozet',
  tripartism_imageRyderfowAlt: 'Hombre hablando en un podio en el que reza "Comisión Mundial sobre el Futuro del Trabajo".',
  tripartism_imageAsiaofficeCaption: 'Fotografía de grupo de la Oficina Regional de la OIT para Asia y el Pacífico para conmemorar el próximo centenario de la OIT. © OIT',
  tripartism_imageAsiaofficeAlt: 'Grupo de hombres y mujeres usan camisetas azules con el logo de la OIT. Las personas de la primera fila sostienen carteles con el logo “OIT 100”.',
  rights_heading: 'Derechos y normas del trabajo',
  rights_chapter: 'capítulo 2',
  rights_title: 'Sus derechos laborales',
  rights_calloutRightsTitle: 'Historias como estas suceden en todos los países del mundo y todas tienen como punto de encuentro la vulneración de nuestros derechos.',
  rights_calloutRightsBody: 'El derecho a escolarizase durante la infancia, afiliarse a un sindicato, constituir una empresa sin sufrir discriminación, trabajar en condiciones seguras y recibir un trato digno son derechos consagrados en las normas internacionales del trabajo de la OIT.',
  rights_infoPathtodw: '<h3>El camino hacia el trabajo decente</h3>\n\n<p>El trabajo no es una mercancía. No es un objeto inanimado que pueda negociarse para obtener más ganancias o conseguir el precio más bajo.</p>\n\n<p>El concepto de trabajo decente guarda relación con el respeto propio de una persona, así como con su bienestar y desarrollo como ser humano. Las normas internacionales del trabajo velan por que se garantice un camino hacia el trabajo decente.</p>\n\n<p>El desarrollo económico debe incluir la creación de empleo y condiciones de trabajo adecuadas para que las personas puedan trabajar con libertad y en condiciones de igualdad, seguridad y dignidad.</p>\n\n<p>Las normas internacionales del trabajo se han establecido para garantizar que el desarrollo económico se centre en la mejora del bienestar de todos los seres humanos.</p>',
  rights_infoLevelplayBody1: '<h3>Igualdad de oportunidades</h3>\n\n<p>Alcanzar la meta del trabajo decente en la economía globalizada requiere la adopción de medidas en el plano internacional. Eso es lo que hace la Organización Internacional del Trabajo desde que se creó, a través de un sistema de instrumentos jurídicos respaldados por gobiernos, empleadores y trabajadores.</p>\n\n<p>Dichos instrumentos pueden ser convenios, es decir, tratados internacionales que pasan a ser legalmente vinculantes una vez que han sido aprobados o "ratificados" por los Estados Miembros, o recomendaciones, que actúan como directrices no vinculantes.</p>\n\n<p>Los convenios y las recomendaciones de la OIT establecen niveles mínimos de protección en relación con cuestiones laborales específicas como el trabajo infantil, el trabajo forzoso y la igualdad de género, entre otras.</p>\n\n<p>Permiten abordar los nuevos contextos y retos que deben afrontar todos los trabajadores en un mundo en constante evolución, y establecer las condiciones necesarias para que los países y las industrias compitan en pie de igualdad. \nGran parte de la legislación nacional de los países que garantiza condiciones laborales adecuadas en el ámbito laboral fueron elaboradas, muy probablemente, con la finalidad de que esté de conformidad con las normas de la OIT.</p>',
  rights_calloutDevstandards: 'La elaboración de normas internacionales del trabajo en la OIT constituye un proceso específico en el que colaboran gobiernos, trabajadores y empleadores de todo el mundo.',
  rights_infoRoadtolsBody1: '<h3>Proceso de elaboración de las normas del trabajo</h3>\n\n<p>Una vez que se ha identificado un problema en el ámbito laboral, el Consejo de Administración acuerda poner el tema en el orden del día de una futura Conferencia Internacional del Trabajo, sobre la base de un informe elaborado por la OIT que se envía a los Estados Miembros y a las organizaciones de trabajadores y de empleadores para que formulen comentarios al respecto.</p>\n\n<p>Con arreglo a las conclusiones de esa deliberación, la OIT redacta posteriormente la norma del trabajo, que se debate más a fondo y, de ser necesario, se enmienda en la siguiente Conferencia, antes de proponerse su adopción. Para la adopción de una norma se requiere una mayoría de dos tercios de los votos.</p>',
  rights_calloutSohowprobTitle: '¿Cómo se identifican los problemas asociados al mundo del trabajo?',
  rights_calloutSohowprobBody: 'Los gobiernos, las organizaciones de trabajadores y empleadores y diversos grupos sociales son los que determinan la necesidad de elaborar nuevas normas internacionales del trabajo.',
  rights_infoGlobalmarchBody1: '<p>En 1998, un movimiento de base denominado Marcha Mundial contra el Trabajo Infantil reunió a varias ONG, sindicatos, profesores y niños bajo el liderazgo de Kailash Satyarthi, con objeto de exigir que se pusiera fin al trabajo infantil.</p> \n\n<p>La Marcha recorrió África, Asia, América del Norte y América Latina y Europa, y terminó en Ginebra, su destino final, para participar en la Conferencia Internacional del Trabajo.</p>\n\n<p>Dicha marcha tuvo gran notoriedad porque la OIT debatía entonces la posibilidad de adoptar un nuevo Convenio contra las peores formas de trabajo infantil.</p>\n\n<p>La marcha infantil tuvo tanta repercusión que hizo que ese Convenio fuera el que más rápidamente se ha ratificado en la historia de la OIT.</p>',
  rights_calloutUniversalvalues: 'Nuestros valores universales',
  rights_infoGbfourissues: '<p>El Consejo de Administración de la OIT ha identificado cuatro ámbitos específicos que se consideran principios y derechos fundamentales en el trabajo. Estos se amparan en ocho Convenios "fundamentales" y sus correspondientes Recomendaciones y Protocolos.</p>',
  rights_listFprw1: 'Libertad de asociación y sindicación y derecho de negociación colectiva',
  rights_listFprw2: 'Erradicación de todas las formas de trabajo forzoso',
  rights_listFprw3: 'Abolición efectiva del trabajo infantil',
  rights_listFprw4: 'Eliminación de la discriminación en materia de empleo y ocupación',
  rights_infoBasisfprw: '<p>Estos principios constituyen la base de la Declaración de la OIT sobre los principios y derechos fundamentales en el trabajo. Adoptada en 1998, en virtud de dicha Declaración, los Estados Miembros se comprometen a respetar y fomentar los principios y derechos relativos a los cuatro ámbitos anteriormente enumerados, con independencia de que hayan ratificado o no los Convenios pertinentes.</p>',
  rights_visConventionsTitle: 'NUESTROS CONVENIOS FUNDAMENTALES',
  rights_infoSupervisoryBody: '<h3>Sistema de control de la OIT</h3>\n\n<p>Las normas internacionales del trabajo solo son eficaces si se vela por su cumplimiento. A tal efecto, los Convenios de la OIT están respaldados por un <mark id="rights_infoSupervisoryBody">sistema de control que es único en el ámbito internacional</mark>, y que contribuye a garantizar que los países apliquen los convenios que ratifican.</p>',
  rights_calloutYourrights: 'Derechos laborales',
  rights_infoAdoptionils: '<p>Por medio de la adopción de normas internacionales del trabajo, la OIT ha dado respuesta en el pasado, y sigue haciéndolo en la actualidad, a una cantidad de retos económicos cada vez más afrontados por trabajadores, empleadores y gobiernos de todo el mundo.</p>',
  rights_calloutManyideas: 'Muchos principios que hoy se dan por sentados, en particular la igualdad de género y la no discriminación en el trabajo, se debatieron por primera vez a escala internacional en la OIT',
  rights_imageChildmechanicCaption: 'Niño trabajando como mecánico, La Paz (Bolivia). © OIT / M. Crozet',
  rights_imageDomesticCaption: 'Trabajadora doméstica tamizando arroz en Bengala (India). © OIT / S.J. Mohammad',
  rights_imageDomesticAlt: 'Mujer vistiendo ropa de colores tamizando arroz en el suelo con una barra de madera.',
  rights_imageChildmechanicAlt: 'Fotografía retroiluminada de un niño sosteniendo herramientas para efectuar una reparación en los bajos de un coche elevado mediante un gato hidráulico.',
  rights_imageIlc1998Caption: 'Conferencia Internacional del Trabajo, Ginebra (Suiza), 1998. © OIT',
  rights_imageIlc1998Alt: 'Imagen de ojo de pez de la Sala de asambleas de la ONU con delegados en la CIT.',
  rights_imageGlobalmarchCaption: 'La Marcha Global contra el Trabajo Infantil llega al Palacio de las Naciones, Ginebra (Suiza), en 1998. © OIT',
  rights_imageAdoptfprwCaption: 'La Declaración de la OIT sobre los principios y derechos fundamentales en el trabajo se adoptó en la 86.ª Reunión de la Conferencia Internacional del Trabajo en 1998. © OIT',
  rights_imageAdoptfprwAlt: 'El 8° Director General, Michel Hansenne, aplaudiendo junto a otros delegados en el podio tras la aprobación de la Declaración.',
  rights_imageC182Caption: 'Niño trabajando en un muelle. © OIT / P. Hien',
  rights_imageChilddockAlt: 'Un niño con docenas de ladrillos en la espalda camina sobre un puente de madera que une una pequeña embarcación con el puerto.',
  rights_imageC105Caption: 'Trabajadores rescatados de la esclavitud en Pará (Brasil) hacen fila para identificarse. © OIT / J. Ripper',
  rights_imageRescuedAlt: 'Una mano sostiene pequeñas fotografías de hombres rescatados. En el fondo hay una fila de hombres.',
  rights_imageC29Caption: 'Trabajadores en una fábrica de ladrillos en Nepal. © Lisa Kristine/Human Thread Foundation',
  rights_imageBrickyardAlt: 'Un trabajador con cuatro ladrillos apilados sobre la cabeza añade dos más. Los trabajadores están cubiertos de polvo naranja proveniente de los ladrillos.',
  rights_imageC111Caption: 'Trabajadoras agrícolas de Sindh se movilizan por sus derechos en el Día Internacional de la Mujer en Pakistán. © OIT',
  rights_imageSindhAlt: 'Mujeres vestidas con ropa de colores sentadas en una gran carpa sostienen carteles que rezan "Día de la mujer, discriminación cero".',
  rights_imageC100Caption: 'Mujeres piden igualdad de remuneración en una marcha de trabajadoras del Primero de Mayo. 1 de mayo, París. 2001 © AFP/M. Medina/AFP',
  rights_imageEqualpayAlt: 'Mujeres manifestándose en la calle con una gran pancarta en francés que reza "Igualdad de remuneración para hombres y mujeres, ya".',
  rights_imageC138Caption: 'Niña transporta un enorme fajo de ramas sobre la cabeza. © OIT/A. Khan',
  rights_imageC98Caption: 'Trabajadores y estudiantes protestan contra la reforma de las pensiones. Annecy, Francia. © OIT/M. Crozet',
  rights_imagePensionreformAlt: 'Personas caminando por las calles. Dos hombres visten chalecos azules con banderas de la CFTC (Confederación Francesa de Trabajadores Cristianos) del mismo color.',
  rights_imageC87Caption: 'Reunión sindical en Japón. © OIT',
  rights_imageUnionmeetAlt: 'Un hombre levanta el brazo izquierdo frente a un grupo de hombres sentados en una grada.',
  rights_imageCasCaption: 'Comisión de Aplicación de Normas. 106.ª Reunión de la Conferencia Internacional del Trabajo. Ginebra, junio de 2017. © OIT',
  rights_imageCasAlt: 'Sala de conferencias con delegados. En las pantallas se muestra que la reunión guarda relación con la Comisión de Aplicación de Normas.',
  rights_imageEbelingCaption: 'L.E. Ebeling presenta la opinión de varios empleadores de Estados Unidos con respecto a la igualdad de remuneración para las mujeres, en la 33ª Conferencia General de la OIT celebrada en 1950. Este debate condujo a la adopción del Convenio sobre igualdad de remuneración en 1951. © OIT',
  rights_imageEbelingAlt: 'Mujer de pie con semblante serio y un bloc de notas da un discurso en una sala.',
  rights_detailSupervisory1: '<h3>Mecanismo de control de la aplicación de normas de la OIT</h3>\n\n<p>La OIT ha establecido varios mecanismos de control de la aplicación de sus Convenios y Recomendaciones.</p>\n\n<p>Si existe algún problema en la aplicación de las normas, la OIT puede ayudar a los países a subsanar las deficiencias constatadas, en particular mediante la prestación de asistencia técnica.</p>',
  rights_detailSupervisory2Title: 'Comisión de Expertos en aplicación de Convenios y Recomendaciones',
  rights_detailSupervisory2: '<h3>Comisión de Expertos en aplicación de Convenios y Recomendaciones</h3>\n\n<p>Cuando un país ratifica un Convenio de la OIT, debe informar periódicamente de las medidas que ha implementado para aplicarlo. Los gobiernos deben facilitar sus informes a las organizaciones nacionales de empleadores y de trabajadores más representativas, con objeto de que formulen las observaciones pertinentes.</p>',
  rights_detailSupervisory3Title: 'Comisión de Aplicación de Normas de la Conferencia',
  rights_detailSupervisory3: '<p>La Comisión de Expertos se constituyó en 1926 con el fin de proporcionar una evaluación técnica imparcial del estado de aplicación de las normas internacionales del trabajo en los Estados Miembros de la OIT.</p>\n\n<p>En la actualidad está integrada por 20 eminentes juristas designados por el Consejo de Administración por un período de tres años. Los expertos proceden de varias regiones geográficas y cuentan con un acervo jurídico y cultural de índole diversa.</p>',
  rights_ilsShortNameC29: 'Trabajo forzoso',
  rights_ilsShortNameC182: 'Peores formas de trabajo infantil',
  rights_ilsShortNameC105: 'Abolición del trabajo forzoso',
  rights_ilsShortNameC111: 'Discriminación',
  rights_ilsShortNameC100: 'Igualdad de remuneración',
  rights_ilsShortNameC138: 'Edad mínima',
  rights_ilsShortNameC98: 'Sindicación y negociación colectiva',
  rights_ilsShortNameC87: 'Libertad sindical',
  rights_ilsBlurbC29: 'Los Estados Miembros se comprometen a suprimir y evitar, lo más pronto posible, el empleo del trabajo forzoso u obligatorio, incluida la trata de seres humanos, así como a garantizar a las víctimas medidas de reparación eficaces.',
  rights_ilsBlurbC182: 'Los Estados Miembros adoptarán medidas inmediatas y eficaces para erradicar las peores formas de trabajo infantil, incluidas todas las formas de esclavitud, como la venta y la trata de niños o el reclutamiento de niños para utilizarlos en conflictos armados; la prostitución infantil; la utilización de niños para la realización de actividades ilícitas, en particular la producción y el tráfico de estupefacientes; y el trabajo que pueda dañar la salud, la seguridad o la moralidad de los niños.',
  rights_ilsBlurbC105: 'Los Estados Miembros se comprometen a poner fin a toda forma de trabajo forzoso u obligatorio como medio de coerción política o como castigo por tener o expresar determinadas opiniones políticas; como método de movilización y utilización de la mano de obra con fines de fomento económico; como medida de disciplina en el trabajo; como castigo por haber participado en huelgas; o como medida de discriminación racial, social, nacional o religiosa.',
  rights_ilsBlurbC111: 'Los Estados Miembros se comprometen a llevar a cabo una política nacional que promueva la igualdad de oportunidades y de trato en materia de empleo y ocupación, con miras a erradicar toda discriminación por motivos de raza, color de piel, sexo, religión, opinión política, ascendencia nacional u origen social que tenga por efecto anular o alterar la igualdad de oportunidades o de trato en el empleo y la ocupación.',
  rights_ilsBlurbC100: 'La remuneración de todos los trabajadores deberá ajustarse al principio de igualdad entre la mano de obra masculina y la femenina por un trabajo de igual valor.',
  rights_ilsBlurbC138: 'Los Estados Miembros se comprometen a garantizar la abolición efectiva del trabajo infantil y a elevar la edad mínima de trabajo hasta un nivel que permita el desarrollo íntegro de los menores en los planos físico y mental; esa edad no será inferior a la edad en la que termina la obligación escolar. La edad mínima para realizar todo tipo de empleo que pueda resultar peligroso para la salud, la seguridad o la moralidad de los menores (trabajo peligroso) no deberá ser inferior a 18 años.',
  rights_ilsBlurbC98: 'Los trabajadores tienen el derecho de gozar de protección contra todo acto de discriminación tendiente a menoscabar la libertad sindical en relación con su empleo. Deberá fomentarse la negociación voluntaria entre los empleadores y las organizaciones de empleadores, por una parte, y las organizaciones de trabajadores, por otra, con objeto de reglamentar, por medio de contratos colectivos, las condiciones de empleo.',
  rights_ilsBlurbC87: 'Los trabajadores y los empleadores, sin ninguna distinción y sin autorización previa, tienen el derecho de constituir las organizaciones que estimen convenientes, y de adherirse a las mismas. Los Estados Miembros se comprometen a adoptar todas las medidas necesarias y apropiadas para velar por que los trabajadores y los empleadores puedan ejercer libremente ese derecho de sindicación.',
  rights_conventionsCountries: 'países',
  rights_visConventionsSee: 'Ver ratificaciones por país',
  rights_visConventionsUpdate: 'Última actualización:',
  rights_visConventionsSelect: 'Buscar país',
  rights_visConventionsCountries: 'países',
  rights_visConventionsRatified: 'Países que lo ratificaron',
  rights_visConventionsNotRatified: 'Países que aún no lo han ratificado',
  rights_visConventionsRatifs: 'ratificaron este convenio',
  rights_date: 'Descubra este capítulo el 16 de enero de 2019',
  rights_visLatestUpdate: '',
  rights_visConventionsFundratif: 'Convenios fundamentales ratificados',
  rights_calloutManyIdeasSub: '... lo que sirvió para que en la actualidad millones de personas puedan beneficiarse de su aplicación.',
  rights_calloutConclusion2: 'Al establecer nuevos derechos y protecciones para los trabajadores y los empleadores en ese entorno evolutivo, la OIT aboga por un mundo en el que primen la igualdad y la libertad sin discriminación.',
  rights_visConventionsC182: 'Convenio 182',
  rights_visConventionsC29: 'Convenio 29',
  rights_visConventionsP29: 'Protocolo 29',
  rights_visConventionsC105: 'Convenio 105',
  rights_visConventionsC111: 'Convenio 111',
  rights_visConventionsC100: 'Convenio 100',
  rights_visConventionsC138: 'Convenio 138',
  rights_visConventionsC98: 'Convenio 98',
  rights_visConventionsC87: 'Convenio 87',
  rights_calloutConclusion1: 'La misión fundamental de la OIT de esforzarse por lograr un futuro laboral mejor para todos hace que tenga que entender y prever los factores de cambio que contribuyen a transformar el mundo del trabajo en la actualidad.',
  rights_infoSuperTest: '',
  rights_detailSupervisory4: '<h3>Comisión de Aplicación de Normas de la Conferencia</h3>\n\n<p>El informe anual de la Comisión de Expertos se presenta a la Conferencia Internacional del Trabajo, en la que es examinado por la Comisión de Aplicación de Normas de la Conferencia. </p>\n\n<p>Esta Comisión está compuesta por delegados de gobiernos, empleadores y trabajadores, que escogen un conjunto de casos para que sean objeto de debate sobre la base del informe de la Comisión de Expertos. </p>',
  rights_detailSupervisory5: '<p>Se espera que los gobiernos que figuran en la lista de casos escogidos respondan ante la Comisión de la Conferencia y proporcionen información relativa a la situación específica de que se trate. </p>\n\n<p>En muchos casos, la Comisión de la Conferencia formula recomendaciones para que los gobiernos adopten medidas de reparación específicas, y ofrece el apoyo de la OIT a esos gobiernos con miras a subsanar los problemas constatados.</p>\n\n<p>La asistencia técnica que proporciona la OIT, en particular, desde hace muchos años ha contribuido a poner fin a la vulneración de derechos laborales y ha permitido que los trabajadores gocen de sus derechos y protecciones fundamentales.</p>',
  employment_heading: 'Empleo',
  employment_chapter: 'capítulo 4',
  employment_title: 'No  cualquier trabajo',
  employment_infoEmppromotBody: '<p>La promoción del empleo se encuentra en el corazón del mandato de la OIT y es uno de los elementos fundamentales del Programa de Trabajo Decente.</p>\n\n<p>Desde sus comienzos, la promoción del empleo pleno y productivo ha constituido una parte esencial de la labor de la OIT ya que el trabajo decente, no cualquier trabajo, es un elemento fundamental para alcanzar la paz, la justicia social, la inclusión social, el desarrollo económico y la plenitud personal. </p>',
  employment_quoteSantosBody: '"La justicia social se logra con más empleo, menos pobreza y menos desigualdad. El trabajo decente es esencial y tanto las políticas laborales como un desarrollo económico más equitativo son fundamentales para alcanzar este objetivo”.',
  employment_quoteSantosCaption: 'Juan Manuel Santos, Premio Nobel de la Paz y expresidente de Colombia, en la 107.ª Conferencia Internacional del Trabajo, 2018.',
  employment_infoEndpovertyTitle: '¿El fin de la pobreza?',
  employment_infoEndpoverty: '<h3>¿El fin de la pobreza?</h3>\n\n<p>La pobreza es la causa principal de muchos de los problemas que afronta el mundo en la actualidad. La desigualdad salarial, el desempleo y la privación material pueden ocasionar alienación, lo que contribuye a la inestabilidad, el conflicto y el extremismo violento.</p>\n\n<p>Por otro lado, mejorar el acceso a empleos de calidad puede redundar en un aumento de ingresos y contribuir a lograr sociedades más cohesionadas y equitativas.  La creación de empleos de mejor calidad es primordial para lograr el mundo que anhelamos.</p>',
  employment_infoEndpovertyBody2: 'Por otro lado, la mejora del acceso a empleos de calidad puede redundar en un aumento de ingresos y contribuir a lograr sociedades más cohesionadas y equitativas.  La creación de nuevos empleos de mejor calidad es primordial para promover el tipo de mundo que todos anhelamos.',
  employment_calloutWhatstandsTitle: '¿Qué se interpone entre las personas y el trabajo decente?',
  employment_calloutWhatstandsBody: 'Examine algunos de los retos que deben afrontar las mujeres y los hombres que buscan más y mejores trabajos.',
  employment_detailInequalityTitle: 'Desigualdad',
  employment_detailInequalityBody1: '<h3>Desigualdad</h3>\n\n<p>La desigualdad en el mercado laboral conduce a la pobreza. Hay injusticia cuando las personas no pueden acceder al mercado laboral o no poseen las competencias adecuadas para encontrar trabajo, y cuando se repeta tan poco sus derechos laborales  que no pueden percibir ingresos suficientes para garantizar la manutención de ellos mismos y la de su familia.</p>\n\n<p>Con objeto de subsanar las desigualdades que conducen a la injusticia, es necesario que las personas puedan aprovechar toda su capacidad para ganarse la vida y poner fin a su situación de pobreza. La OIT brinda asistencia a los países para hacer frente a situaciones de desigualdad en los mercados laborales, con miras a lograr una solución sostenible que permita erradicar la pobreza, por ejemplo, por medio de programas de subsidio salarial.</p>',
  employment_detailInequalityBody2: '<p>En la Federación de Rusia, una asociación público-privada establecida con la empresa rusa LUKOIL proporciona subvenciones salariales a empleadores locales para ayudar a los jóvenes a encontrar trabajo.</p>\n\n<p>El subsidio salarial a jóvenes trabajadores les brinda nuevas oportunidades, les permite adquirir experiencia profesional y demuestra a los empleadores que los trabajadores jóvenes constituyen un activo valioso  para sus empresas.</p>',
  employment_detailConflictTitle: 'Conflictos y catástrofes',
  employment_detailConflictBody1: '<h3>Conflictos y catástrofes</h3>\n\n<p>Casi 1500 millones de personas viven en situaciones de inestabilidad o de violencia armada, y otros 200 millones se ven afectadas por catástrofes. Los conflictos y las catástrofes menoscaban los medios de subsistencia de las personas y dan lugar a un déficit de empleos decentes. Ello puede ocasionar más pobreza, desigualdad y malestar social.</p>\n\n<p>La OIT presta apoyo a programas que promueven el empleo decente para personas afectadas por conflictos y catástrofes. Ello permite aumentar su resiliencia y ayudar a sus países a lograr avances en aras de la paz y la estabilidad.</p>',
  employment_detailConflictBody2: '<p>En Colombia, donde el proceso de paz ha permitido poner fin a medio siglo de conflicto armado, la OIT ayuda a las comunidades locales a desarrollar el sector turístico con objeto de crear empleo para los miembros más vulnerables de la sociedad, en particular los jóvenes y las mujeres.</p>\n\n<p>Los ingresos que perciben las familias de las zonas rurales sirven para subsanar la falta de oportunidades laborales y de desarrollo, una de las causas subyacentes del conflicto, y para lograr una paz estable y sostenible.</p>',
  employment_detailYouthempTitle: 'Empleo juvenil',
  employment_detailYouthempBody1: '<h3>Empleo juvenil</h3>\n\n<p>Según las estadísticas de la OIT, actualmente hay 66 millones de jóvenes desempleados y más de 145 millones de trabajadores jóvenes que viven en situación de pobreza, pese a la mejora a lo largo de las últimas décadas de la calidad de la enseñanza y de las oportunidades que brinda.</p>\n\n<p>En el futuro, los jóvenes gozarán de pocas oportunidades para encontrar un empleo decente, pese a su gran capacitación y voluntad de trabajo.</p>',
  employment_detailYouthempBody2: '<p>La inversión en empleo juvenil requiere que trabajemos juntos para lograr que la creación de empleo constituya una actividad prioritaria. Por otro lado, es necesario ayudar a los jóvenes a superar ciertas desventajas concretas, en particular, la discriminación y la falta de capacitación adecuada.</p>\n\n<p>La OIT brinda asistencia a los países para formular, aplicar y evaluar políticas y programas de empleo con objeto de que los jóvenes gocen de más oportunidades para lograr un empleo decente.</p>',
  employment_detailRuralexodusTitle: 'Éxodo rural',
  employment_detailRuralexBody1: '<h3>Éxodo rural</h3>\n\n<p>Se prevé que en 2050, el 68% de la población mundial resida en zonas urbanas, a las que se desplazarán millones de personas en busca de trabajo. Esto podría menoscabar el nivel de empleo y las oportunidades de empleo en las zonas rurales.</p>\n\n<p>Promover la capacitación de mujeres y jóvenes en las zonas rurales, en particular en materia empresarial, facilita su acceso al empleo decente.</p>\n\n<p>La OIT se esfuerza por fomentar la capacitación en el ámbito rural y en las pequeñas y medianas empresas.</p>',
  employment_detailRuralexBody2: '<p>En Kenia, la República Unida de Tanzania y Uganda, más de 2900 mujeres empresarias asistieron a cursos de capacitación para crear sus propias empresas. Las mujeres no solo afirmaron que dicha capacitación reforzó su confianza, sino que también una encuesta de seguimiento demostró que una de cada dos mujeres que habían asistido a dichos cursos de capacitación empresarial  crearon una nueva empresa.</p>\n\n<p>Gracias al apoyo de la OIT, unas 6300 mujeres y siete cooperativas formadas por mujeres en Etiopía se capacitaron en materia de información crediticia y gestión empresarial, lo que contribuyó a aumentar sustancialmente sus ingresos y a crear empleos.</p>',
  employment_detailInformalTitle: 'Trabajo informal',
  employment_detailInformalBody1: '<h3>Trabajo informal</h3>\n\n<p>Más de la mitad de los trabajadores de todo el mundo trabajan en la economía informal. Esto significa que su empleo es ocasional y no están amparados por ninguna legislación laboral. Tampoco gozan de ningún derecho, su opinión no cuenta y su remuneración no les permite poner fin a su situación de pobreza.</p>\n\n<p>Las empresas informales tienden a tener un bajo índice de productividad y no ofrecen trabajo decente. Ello dificulta el desarrollo de este tipo de empresas y su acceso a recursos básicos.</p>',
  employment_detailInformalBody2: '<p>La OIT brinda apoyo a trabajadores y a empresas ayudándoles a adquirir los conocimientos y las competencias que precisan para pasar de la economía informal a la formal.</p>\n\n<p>A raíz del devastador terremoto que tuvo lugar en Nepal en 2015, la OIT capacitó a guías turísticos y pequeñas empresas del país con objeto de fomentar el sector turístico.</p>\n\n<p>La OIT también ayudó a los empleadores a prestar un mejor servicio a sus clientes y a ofrecer empleos de más calidad.</p>',
  employment_detailMigrationTitle: 'Migración laboral',
  employment_detailMigrationBody1: '<h3>Migración laboral</h3>\n\n<p>Según estimaciones recientes de la OIT, de los 232 millones de migrantes internacionales que existen en el mundo, 150 millones son trabajadores migrantes.</p>\n\n<p>Estos contribuyen al desarrollo económico, social y cultural de sus países de acogida. Las remesas de dinero que envían a sus familias y las competencias que adquieren en el extranjero son de gran utilidad para sus comunidades y países de origen pero, muy a menudo, su contribución no es reconocida.</p>',
  employment_detailMigrationBody2: '<p>Muchos migrantes son vulnerables frente a la explotación y al abuso. De los 21 millones de personas que realizan trabajo forzoso en la actualidad, 44% de ellas fueron víctimas de engaño o de redes de trata de personas al abandonar su hogar o trasladarse a otro país.</p>\n\n<p>La OIT presta apoyo para la formulación de políticas que permitan aprovechar al máximo las ventajas que brinda la migración laboral, tanto a los migrantes como a las comunidades de acogida.</p>',
  employment_calloutDecentjobsTitle: 'Trabajo decente para todos',
  employment_calloutDecentjobsBody: 'Desde hace décadas, la OIT realiza estudios y proyectos de asistencia en varios países, lo que ha resultado en la formulación de políticas que fomentan la promoción y la creación de empleo decente',
  employment_infoRightskillsTitle: 'Competencias adecuadas',
  employment_infoRightskills: '<h3>Competencias adecuadas</h3>\n\n<p>Habida cuenta de las rápidas transformaciones que tienen lugar en el mundo del trabajo a raíz de la evolución tecnológica, las características productivas, el tipo de empleo y el cambio climático, entre otros factores, la adquisición de competencias útiles hoy es más importante que nunca.</p> \n\n<p>La OIT lidera la promoción de políticas y mecanismos que anticipen y fomenten la adquisición de competencias para los empleos del futuro.</p>',
  employment_infoRightskillsBody2: 'Cabe señalar que la OIT respalda la integración social de grupos desfavorecidos por medio del desarrollo de sus competencias.',
  employment_infoInvestmentsTitle: 'Inversiones para crear empleo',
  employment_infoInvestments: '<h3>Inversiones para crear empleo</h3>\n\n<p>Millones de personas viven en regiones que no poseen infraestructuras esenciales, como carreteras, puentes, instalaciones de suministro de agua, electricidad o Internet. Muchas de esas personas no tienen acceso a servicios básicos como agua corriente, sanidad o educación.</p>\n\n<p>Invertir en infraestructuras puede contribuir a mejorar sustancialmente el nivel de vida e incide directamente en la calidad de vida de las personas.</p>\n\n<p>La utilización de mano de obra y recursos locales para construir las tan necesarias infraestructuras, junto con la participación de las comunidades en las actividades de planificación, permite crear empleo, reducir costos y fomentar la industria local. Las instituciones locales se ven fortalecidas y su eficacia aumenta.</p>\n\n<p>Por otro lado, ello contribuye a mitigar la pobreza en los medios rurales y urbanos y a mejorar el bienestar económico y social de las personas y las comunidades.</p>',
  employment_infoInvestmentsBody2: 'Por otro lado, ello contribuye a mitigar la pobreza en los medios rurales y urbanos y a mejorar el bienestar económico y social de las personas y las comunidades.',
  employment_infoStronginstTitle: 'Instituciones eficaces',
  employment_infoStronginstBody: '<h3>Instituciones eficaces</h3>\n\n<p>En un mundo globalizado cada vez más competitivo, los mercados de trabajo son más eficaces si las instituciones por las que se rigen establecen un entorno propicio para garantizar ingresos y protección social y laboral a los trabajadores, al tiempo que las empresas disponen de recursos suficientes para su desarrollo.</p> \n\n<p>El buen funcionamiento de los mercados de trabajo exige marcos políticos e instituciones eficaces. </p>',
  employment_calloutThisiswhy: 'Es por esto que la OIT ayuda a los países a establecer las instituciones pertinentes y formular políticas basadas en el diálogo entre representantes de gobiernos, empleadores y trabajadores.',
  employment_infoNepalBody1: '<p>En Nepal, por ejemplo, el programa de la OIT (LIfE) sobre información relativa al mercado laboral y los servicios de empleo ofrece apoyo a los servicios públicos de empleo del país, que desempeñan una función cada vez más importante para el desarrollo de competencias profesionales adaptadas a la demanda laboral existente, a fin de mejorar la empleabilidad, subsanar posibles desajustes en materia de competencias profesionales y fomentar los vínculos entre empleadores y trabajadores.</p>\n\n<p>Una de las ventajas fundamentales del programa es que permite a los participantes desarrollar sus conocimientos no solo a través de la adquisición de competencias en los planos técnico, personal y social, sino también en el marco de un proceso de socialización aplicado al lugar de trabajo. La capacitación laboral permite asimismo a los empleadores aumentar la productividad y colmar posibles lagunas en materia de competencias.</p>',
  employment_infoNepalBody2: 'Una de las ventajas fundamentales del programa es que permite a los participantes desarrollar sus conocimientos no solo a través de la adquisición de competencias en los planos técnico, personal y social, sino también en el marco de un proceso de socialización aplicado al lugar de trabajo. La capacitación laboral permite asimismo a los empleadores aumentar la productividad y colmar posibles lagunas en materia de competencias.',
  employment_calloutChallengesdwTitle: 'Los retos para lograr el trabajo decente son complejos, al igual que sus soluciones.',
  employment_calloutChallengesdwBody: 'Los 100 años de experiencia de la OIT le permiten asumir el liderazgo para forjar un futuro laboral basado en el trabajo decente.',
  employment_imageLocalyouthsCaption: 'Jóvenes en un taller de capacitación de la OIT en Madagascar. Este proyecto, que contó con el apoyo de la OIT, ha ayudado a combatir la violencia urbana al permitir que los jóvenes aprendan un oficio y abandonen el mundo de la delincuencia. © OIT Madagascar',
  employment_imageLocalyouthsAlt: 'Tres hombres y una mujer con indumentaria de trabajo azul y cascos amarillos en torno a una mesa de madera con una caja de herramientas abierta.',
  employment_imageWastepickCaption: 'Recolectores de desechos en un vertedero de Manila (Filipinas). Las 3.000 personas que clasifican la basura en la ciudad de Quezon ahora están sindicalizadas y representan un modelo para la gestión de residuos ya que reciclan alrededor del 40% de los residuos domésticos. © OIT/M. Rimando',
  employment_imageWastepickAlt: 'Grupo de hombres, mujeres y niños recogiendo desechos en un vertedero.',
  employment_imageSlumsCaption: 'Barrios marginales en West Point, Monrovia (Liberia). Aquí viven aproximadamente 75.000 personas que solo cuentan con cuatro baños públicos. La tuberculosis y otras enfermedades son endémicas. © UNMIL/A.G. Farran',
  employment_imageSlumsAlt: 'Vista aérea de casas bajas con techos de chapa oxidada cerca de la orilla del mar.',
  employment_imageTaclobanCaption: 'La ciudad de Tacloban en Filipinas después del supertifón Haiyan en noviembre de 2013. Aproximadamente el 90% de la ciudad fue destruida. Los sobrevivientes describieron la devastación como "apocalíptica". © OIT / M. Crozet',
  employment_imageTaclobanAlt: 'Un avión sobrevuela una zona repleta de escombros, coches destruidos y árboles caídos.',
  employment_imageMigrantaddisCaption: 'Un migrante retornado conversa sobre oportunidades profesionales con un posible empleador en una feria de empleo organizada por la OIT en Addis Abeba (Etiopía). © OIT/M. Tadesse',
  employment_imageMigrantaddisAlt: 'Dos hombres de pie charlando cerca de una mesa con una computadora y varios folletos.',
  employment_imageFarmersCaption: 'Agricultoras en Madagascar. La agricultura emplea a la mayoría de la población de Madagascar, que trabaja con equipos limitados y con una infraestructura deficiente, y que produce lo mínimo para alimentar a sus familias. © OIT Madagascar',
  employment_imageFarmersAlt: 'Dos agricultoras agachadas recogiendo plantas en un arrozal.',
  employment_imageHandicraftCaption: 'Un fabricante de artesanías en Katmandú, Nepal, donde la OIT apoya a mujeres empresarias a través de prácticas comerciales que no solo crean seguridad para ellas y sus familias, sino que también cambian las percepciones sobre el género y el papel de las mujeres en la sociedad. © OIT/M. Crozet',
  employment_imageHandicraftAlt: 'Mujer con un bolso amarillo en el hombro y un muñeco con forma de pavo real azul y verde en las manos.',
  employment_imageMigrantdatesCaption: 'Trabajador migrante cosechando dátiles en Bahrein. La proporción de migrantes en realación a trabajadores locales en Bahrein se encuentra entre las más altas del mundo. © OIT',
  employment_imageMigrantdatesAlt: 'Hombre de pie entre ramas recogiendo dátiles rojos en una rama repleta de estos.',
  employment_imageApprenticeCaption: 'Asia encontró trabajo en un hotel en Zanzíbar, Tanzania, después de completar un curso de capacitación de habilidades de la OIT en la industria de la hospitalidad. © OIT Tanzania',
  employment_imageApprenticeAlt: 'Mujer con hiyab blanco observa la pantalla de una computadora en el mostrador de una recepción.',
  employment_imageConstructionCaption: 'Trabajadores en una obra en La Paz (Bolivia). Según la OCDE, la industria de la construcción emplea a aproximadamente el 7% de la fuerza laboral mundial y se prevé que represente el 13% del PIB para 2020. © OIT',
  employment_imageConstructionAlt: 'Tres trabajadores de la construcción en una obra con cascos y gafas protectoras.',
  employment_imageTraineesCaption: 'Aprendices participando en una formación en el lugar de trabajo en el marco del programa LIfE de la OIT en Nepal. © Federación de Asociaciones de Artesanía de Nepal (FHAN)',
  employment_imageTraineesAlt: 'Hombres y mujeres jóvenes sentados en el suelo tallando piezas de madera.',
  employment_date: 'Descubra este capítulo el 6 de febrero de 2019',
  employment_detailConflictBody3: '<p>En el acuerdo de paz definitivo se alude específicamente a las normas internacionales del trabajo de la OIT como instrumento para promover los empleos decentes y los derechos de los trabajadores en las zonas rurales, habida cuenta de que la falta de trabajo decente contribuyó en gran medida al malestar social.</p>',
  employment_detailMigrationBody3: '<p>En Jordania, Nepal, Filipinas y Túnez, la OIT ha colaborado estrechamente con otras organizaciones de las Naciones Unidas con objeto de evitar la explotación de trabajadores migrantes proporcionándoles acceso a información fidedigna al respecto.</p>\n\n<p>También divulgó información sobre contratación equitativa a través de medios de comunicación locales e internacionales para aumentar la concienciación de los trabajadores migrantes y de los posibles empleadores, labor que fue asimismo muy importante.',
  protection_heading: 'Protección social',
  protection_chapter: 'capítulo 5',
  protection_title: 'Usted no está solo',
  protection_calloutWhathappensTitle: '¿Qué sucede si, en caso de enfermedad, incapacidad, embarazo o durante la vejez, no posee ingresos suficientes o ningún ingreso al no poder trabajar?',
  protection_calloutWhathappensBody: '¿Cómo podemos ofrecer protección a las personas y a sus familias para que, a lo largo de sus vidas, afronten esos riesgos y eviten terminar en situación de pobreza?',
  protection_infoFacesofspBody: '<h3>Los rostros de la protección social</h3>\n\n<p>La protección social es un derecho de todos los seres humanos. Garantiza atención médica y un ingresos mínimo para todos. </p>\n\n<p>También le brinda a usted y a su familia los medios de subsistencia en caso de enfermedad, desempleo, lesión, embarazo o incapacidad laboral por motivos de edad. La protección social le ofrece la seguridad y esperanza para lograr un futuro mejor. </p>',
  protection_calloutEveryoneright: 'Todo el mundo tiene derecho a la seguridad social',
  protection_quoteEveryonerightBody: '"Toda persona tiene derecho a un nivel de vida adecuado que le asegure, así como a su familia, la salud y el bienestar, y en especial la alimentación, el vestido, la vivienda, la asistencia médica y los servicios sociales necesarios; tiene asimismo derecho a los seguros en caso de desempleo, enfermedad, invalidez, viudez, vejez u otros casos de pérdida de sus medios de subsistencia por circunstancias independientes de su voluntad".',
  protection_quoteEveryonerightCaption: 'Declaración Universal de Derechos Humanos, Artículo 25 (1)',
  protection_infoYourspBody: '<h3>Su protección social</h3>\n\n<p>La protección social comprende una serie de políticas y programas formulados para mitigar y evitar situaciones de pobreza y vulnerabilidad en nuestra vida. La protección social le ofrece...</p>\n\n<ul>\n<li>Prestaciones por hijos y familiares</li>\n<li>Protección por maternidad</li>\n<li>Prestaciones de desempleo</li>\n<li>Prestaciones en caso de accidente laboral</li>\n<li>Prestaciones en caso de enfermedad</li>\n<li>Protección sanitaria</li>\n<li>Pensión de vejez</li>\n<li>Pensión de discapacidad</li>\n<li>Pensión para los supervivientes</li>\n</ul>',
  protection_calloutWhyneedsp: '¿Por qué necesitamos protección social?',
  protection_infoEndpovertyBody: '<h3>Erradicación de la pobreza</h3>\n\n<p>Según se desprende de datos estadísticos de la OIT, en la actualidad 4000 millones de personas en todo el mundo carecen de protección social. Únicamente el 29% de la población mundial posee cobertura adecuada en materia de seguridad social y el 55% no tiene cobertura alguna. </p>',
  protection_calloutUniversalsp: 'La protección social universal es clave para alcanzar varios de los Objetivos de Desarrollo Sostenible de la ONU. Sin ella, no es posible erradicar la pobreza, reducir la desigualdad y lograr la igualdad de género.',
  protection_infoTimeforactionBody: '<h3>Llego la hora de actuar: protección social universal</h3>\n\n<p>Se puede extender la protección social para que abarque a los 4000 millones de personas que aún carecen de ella en todo el mundo. La OIT lleva a cabo una ardua labor al apoyar a los países a fin de establecer o mejorar sus sistemas nacionales de protección social.</p>\n\n<p>Las normas y los conocimientos especializados de la OIT son esenciales para ofrecer orientación a los países sobre el desarrollo de sus propios programas nacionales de protección social.</p>',
  protection_calloutPasttenyears: 'A lo largo de la última década, la OIT ha promovido el desarrollo de los sistemas de protección social en 136 países, lo que incluye :',
  protection_listSpfloors1: 'Estrategias nacionales<br> de protección social en',
  protection_listSpfloors2: 'Protección<br> sanitaria en',
  protection_listSpfloors3: 'Prestaciones<br> por hijos en',
  protection_listSpfloors4: 'Prestaciones<br> por maternidad en',
  protection_listSpfloors5: 'Sistemas de seguro<br> de desempleo en',
  protection_listSpfloors6: 'Programas públicos<br> de empleo en',
  protection_listSpfloors7: 'Pensiones<br> de vejez en',
  protection_vizColombiaTitle: 'Colombia',
  protection_vizColombiaBody: 'Definió su estrategia nacional de protección social',
  protection_vizPeruTitle: 'Perú',
  protection_vizPeruBody: 'Estableció un plan de pensiones no contributivas',
  protection_vizTogoTitle: 'Togo',
  protection_vizTogoBody: 'Estableció un sistema nacional de seguro de salud que cubre a 400.000 beneficiarios.',
  protection_vizCyprusTitle: 'Chipre',
  protection_vizCyprusBody: 'Reformó su sistema de asistencia social',
  protection_vizKazakhstanTitle: 'Kazajstán',
  protection_vizKazakhstanBody: 'Ratificó el Convenio de la OIT sobre la protección de la maternidad (núm. 183)',
  protection_vizIndiaTitle: 'India',
  protection_vizIndiaBody: 'Se amplió el Sistema Nacional de Seguro de Salud (RSBY)',
  protection_vizCambodiaTitle: 'Camboya',
  protection_vizCambodiaBody: 'Estableció un plan de seguro social de salud a nivel nacional',
  protection_vizThailandTitle: 'Tailandia',
  protection_vizThailandBody: 'Implementó el Programa de Atención Sanitaria Universal',
  protection_vizVietnamTitle: 'Vietnam',
  protection_vizVietnamBody: 'Extendió la cobertura de las pensiones no contributivas',
  protection_vizMozambiqueTitle: 'Mozambique',
  protection_vizMozambiqueBody: 'Formulación de su estrategia nacional de protección social',
  protection_vizJordanTitle: 'Jordania, Baréin y Arabia Saudita',
  protection_vizJordanBody: 'Implementación de varios programas de seguro de desempleo',
  protection_vizSouthafricaTitle: 'Sudáfrica',
  protection_vizSouthafricaBody: 'Ampliación de su Programa sobre obras públicas',
  protection_infoRoadaheadBody: '<h3>El camino por recorrer</h3>\n\n<p>Si bien se han registrado avances encomiables en muchos países, millones de personas en todo el mundo siguen sin poder acceder a una protección social adecuada. </p>\n\n<p>La falta de protección social hace que las personas sean vulnerables frente a la pobreza, la desigualdad y la exclusión social durante toda su vida. La solución es la protección social universal.</p>',
  protection_calloutOnlywhen: 'Solo cuando se haya alcanzado este objetivo habremos avanzado lo suficiente en aras de un desarrollo sostenible para todos.',
  protection_imageGarmentmedCaption: 'Centro médico de una fábrica de prendas de vestir en Jordania. © OIT',
  protection_imageGarmentmedAlt: 'Cartel en una ventana que reza "Centro médico". Detrás de la ventana hay pacientes y profesionales médicos.',
  protection_imagePregnantCaption: 'Trabajadora embarazada en una fábrica de piezas de automóvil en Hangzhou (China). © OIT',
  protection_imagePregnantAlt: 'Mujer embarazada de pie detrás de un escritorio en un pasillo. Sella una pila de boletos de papel.',
  protection_imageNurseCaption: 'Enfermera atiende a un niño en un centro médico de Bagdad (Iraq). © OIT / Apex Image',
  protection_imageNurseAlt: 'Enfermera sosteniendo la pierna derecha de un niño acostado en una cama. Otra mujer sostiene la mano izquierda del niño.',
  protection_imageHomelessCaption: 'Un vagabundo busca comida en un cesto de basura en San Francisco (Estados Unidos). © OIT',
  protection_imageHomelessAlt: 'Hombre agachado introduciendo una mano en un cubo de basura en la calle. Detrás del hombre hay un tranvía.',
  protection_imageVendorCaption: 'Vendedora en el mercado de flores de Cuenca (Ecuador). © OIT',
  protection_imageVendorAlt: 'Mujer sentada delante de varios ramos de flores que vende.',
  protection_imageNursesnewCaption: 'Enfermeras cuidan de recién nacidos en el Hospital de Maternidad Princesa Cristiana en Freetown (Sierra Leona). © Banco Mundial/D. Chavez',
  protection_imageNursesnewAlt: 'Dos enfermeras con uniforme rosa observan a tres recién nacidos envueltos en mantas de color naranja mientras duermen en una cuna.',
  protection_date: 'Descubra este capítulo el 13 de febrero de 2019',
  protection_countries: 'países',
  protection_vizDominicanrepTitle: 'República Dominicana',
  protection_vizDominicanrepBody: 'Ratificó el Convenio Nº 102 de la OIT sobre la seguridad social (normas mínimas)',
  protection_vizMoldovaTitle: 'República de Moldova',
  protection_vizMoldovaBody: 'Creó acuerdos bilaterales de seguridad social para cubrir a los trabajadores migrantes moldavos en varios países',
  protection_vizRwandaTitle: 'Ruanda',
  protection_vizRwandaBody: 'Implementó plan de prestaciones por licencia de maternidad',
  protection_vizTimorlesteTitle: 'Timor Oriental',
  protection_vizTimorlesteBody: 'Creó su primer régimen contributivo de seguridad social',
  protection_country: '',
  protection_countries1: 'países',
  work_heading: 'Trabajo y gobernanza',
  work_chapter: 'capítulo 6',
  work_title: 'Normas del trabajo',
  work_infoMostofusBody: '<p>Ya sea una oficina, un mercado, una fábrica o un barco, muchas personas pueden pensar que su lugar de trabajo es simplemente un lugar donde se ganan la vida.</p>\n\n<p>En su rutina diaria, pocos probablemente se detienen a pensar en las leyes, reglas y regulaciones que son tan importantes para hacer que el trabajo sea seguro y garantizar condiciones de trabajo decentes para todos.</p>\n\n<p>Y con tantos cambios en marcha, como por ejemplo, las nuevas tecnologías, los contratos a corto plazo y el trabajo en línea, las leyes, normas, políticas e instituciones que sustentan nuestro mundo laboral deben seguir el ritmo para que puedan apoyar y proteger el trabajo decente.</p>',
  work_calloutRoleofilo: 'Entonces, ¿cuál es el papel de la OIT en todo esto? ¿Y qué tiene que ver con usted?',
  work_infoLabourstdsBody: '<p>Las normas internacionales del trabajo aprobadas por la OIT, que han adoptado los gobiernos a lo largo del último siglo, se han convertido en un sistema integral de tratados internacionales consagrados en legislaciones y políticas nacionales, así como en la labor de instituciones públicas y privadas. </p> \n\n<p>En su conjunto, estas normas promueven el empleo decente y productivo en su lugar de trabajo, en condiciones de libertad, equidad, seguridad y dignidad. </p>    \n\n<p>La OIT colabora estrechamente con gobiernos y organizaciones de empleadores y de trabajadores para velar por el cumplimiento de la legislación y regulación que afectan en su vida laboral. </p> \n\n<p>También brinda apoyo a instituciones públicas para que lleven a cabo inspecciones para garantizar la implementación de la legislación laboral en lugares de trabajo como el suyo.</p>',
  work_infoKeepingyouBody: '<h3>Su seguridad en el trabajo</h3>\n\n<p>La seguridad y salud en el trabajo (SST) es uno de los problemas más críticos en cualquier lugar de trabajo. Significa garantizar y promover la seguridad, la salud y el bienestar de los trabajadores.</p>\n\n<p>La SST tiene su origen en la revolución industrial del siglo XIX, surgió a raíz de la inquietud provocada por el número de hombres, mujeres y niños que padecían lesiones o eran víctimas de accidentes mortales en el trabajo.  </p>\n\n<p>En el momento de la fundación de la OIT en 1919, el desarrollo de normas internacionales que protegieran la seguridad y la salud de los trabajadores era una actividad prioritaria. Cuatro de las diez primeras recomendaciones de la OIT abordaron cuestiones de seguridad y salud en el trabajo.</p>\n\n<p>En la actualidad, más de 40 Convenios y Recomendaciones de la OIT tratan específicamente sobre la seguridad y la salud en el trabajo. El Convenio núm. 187, uno de los más recientes, tiene como objetivo promover una cultura de la prevención a escala mundial que permita reducir de forma eficaz el número de víctimas mortales, lesiones y enfermedades en el ámbito laboral.</p>',
  work_calloutLawscompliedTitle: 'Una de las formas en que los gobiernos pueden garantizar el cumplimiento de la legislación laboral en el lugar de trabajo es velando por la implementación efectiva de la misma.',
  work_calloutLawscompliedBody: 'Dicho cumplimiento conlleva la realización de inspecciones de trabajo en oficinas, fábricas, obras de construcción y otros puestos de trabajo para garantizar que las condiciones se ajusten a los requisitos de la legislación y políticas laborales.',
  work_infoFrontlineBody: '<h3>En primera línea</h3>\n\n<p>Sin embargo, los presupuestos gubernamentales para la implementación de la legislación no han ido a la par con la evolución de los puestos de trabajo, ni con la rápida transformación que ha tenido lugar en el ámbito laboral. Los inspectores de trabajo tienen cada vez más dificultades para examinar todos los lugares de trabajo y analizar exhaustivamente sus condiciones laborales. </p>\n\n<p>La OIT siempre ha prestado apoyo a los gobiernos en el desarrollo de sistemas de inspección laboral y modernización de sus métodos de planificación y realización de inspecciones, en respuesta a los rápidos cambios que se dan en nuestros lugares de trabajo. </p>',
  work_calloutFourareas: 'La OIT también desarrolla su labor en otras cuatro áreas relacionadas para garantizar que las leyes laborales se apliquen adecuadamente y velar por el funcionamiento adecuado de los organismos de trabajo públicos y privados.',
  work_listFitforpurpose1Title: 'Adaptación a los objetivos',
  work_listFitforpurpose1Body: 'Hacer que los sistemas de administración laboral funcionen',
  work_listLawmatters2Title: 'Importancia de la legislación',
  work_listLawmatters2Body: 'Asistencia en materia de reforma de la legislación laboral',
  work_listWorkingtog3Title: 'Eficacia de la colaboración',
  work_listWorkingtog3Body: 'Tripartismo, diálogo social y relaciones laborales',
  work_listReaching4Title: 'Promoción del consenso',
  work_listReaching4Body: 'Modo de prevenir y resolver conflictos laborales',
  work_detailFitforpurpose1: '<h3>Hacer que los sistemas de administración laboral funcionen</h3>\n\n<p> La organización adecuada de los organismos públicos y privados encargados de formular y aplicar la legislación laboral nacional de cada país, desde los Ministerios de Trabajo a las agencias de contratación, es de gran importancia. Conviene que desarrollen su función de forma profesional y eficaz para garantizar una gobernanza adecuada del mercado laboral. </p>',
  work_detailLawmatters1: '<h3>Asistencia en materia de reforma de la legislación laboral</h3> \n\n<p>La evolución de las legislaciones laborales debe ir a la par de las rápidas transformaciones que tienen lugar en el de trabajo. Estas han de estar en consonancia con las normas de la OIT y cumplir los objetivos de trabajo decente.</p>\n\n<p>Para ayudar a los Estados Miembros a evaluar, formular y revisar sus legislaciones laborales y aplicar los Convenios de la OIT, la OIT facilita conocimientos técnicos especializados y asistencia. </p>',
  work_detailWorkingtog1: '<h3>Tripartismo, diálogo social y relaciones laborales</h3>  \n\n<p>Si los gobiernos, los trabajadores y los empleadores abordan conjuntamente los problemas en un sistema de relaciones laborales eficaces, los resultados económicos mejoran y tiene lugar una distribución más equitativa de los beneficios y de la riqueza. Ello se logra mediante: </p>\n\n<ul>\n<li>Negociaciones, consultas e intercambio de información</li>\n<li>La negociación colectiva</li>\n<li>La prevención y resolución de conflictos</li>\n<li>Otros instrumentos de diálogo social, incluidas las normas internacionales del trabajo</li>\n</ul>',
  work_detailReaching1: '<h3>Modo de prevenir y resolver conflictos laborales</h3>  \n\n<p>En una relación laboral, las quejas y los conflictos  son inevitables. </p>\n\n<p>No obstante, antes de alcanzar la etapa de los tribunales, hay formas de prevenir y resolver las disputas laborales a través del consenso. </p>',
  work_infoBrokenrulesBody: '<h3>Incumplimiento de normas</h3>\n\n<p>Incluso cuando existe legislación, siempre habrá desafíos en el cumplimiento de la ley.</p> \n\n<p>Por ejemplo, 182 de los 187 Estados Miembros han ratificado el Convenio sobre las peores formas de trabajo infantil, lo que significa que podría ser el primer Convenio de la OIT en ser ratificado universalmente. Sin embargo, pese a la importante reducción del trabajo infantil en los últimos años, todavía sigue habiendo 152 millones de niños en situación de trabajo infantil en todo el mundo.</p> \n\n<p> Aunque 178 de los 187 países han ratificado el Convenio sobre el trabajo forzoso, según se desprende de datos estadísticos de la OIT, 25 millones de personas siguen siendo víctimas del trabajo forzoso. Esto genera 150.000 millones de dólares anuales de beneficios ilícitos. </p> \n\n<p>La OIT se enfrenta a este y otros problemas, en todos los sectores, con una serie de proyectos y programas destinados a prestar apoyo a los gobiernos en la aplicación de legislaciones laborales. Esto incluye, también, la puesta al día de los Convenios.</p> \n\n<p>Como respuesta a las nuevas formas de esclavitud moderna, la Conferencia Internacional del Trabajo adoptó en 2014 el Protocolo sobre el trabajo forzoso, un Apéndice al Convenio de 1930 sobre el trabajo forzoso, cuyo objetivo es intensificar los esfuerzos para eliminar todas las formas de trabajo forzoso, incluida la trata de personas. </p>',
  work_infoTacklingBody: '<h3>Intermediarios</h3> \n\n<p>En la compleja coyuntura económica actual, en la que los trabajadores tienen dificultades para encontrar trabajo, las empresas de contratación son más importantes que nunca. </p>\n\n<p>Las empresas de empleo públicas y privadas, si se regulan de forma adecuada, pueden desempeñar un papel muy eficaz para satisfacer adecuadamente la demanda de trabajadores cualificados. </p>\n\n<p>Se estima que en todo el mundo hay 244 millones de migrantes internacionales y 740 millones de migrantes internos. La mayoría se desplazan en busca de empleo decente y de mejores medios de subsistencia.</p>\n\n<p>No obstante, algunos grupos vulnerables, en particular los trabajadores migrantes poco calificados, pueden ser víctimas de empresas de contratación o de intermediarios de trabajo informal sin escrúpulos que mediante engaño les aboquen a situaciones en las que se conviertan en víctimas de redes de trata de personas, o en las que tengan que realizar un trabajo forzoso. </p>',
  work_calloutTrappedflTitle: '25 millones de personas son víctimas del trabajo forzoso en el mundo. De ellas, el 44% migró internamente o a otro país para encontrar trabajo.',
  work_calloutTrappedflBody: 'Los trabajadores migrantes que piden dinero prestado a terceros corren un mayor riesgo de verse expuestos a situaciones de trabajo forzoso.',
  work_infoInresponseBody: '<p>Para dar respuesta a estos desafíos, la OIT trabaja en estrecha colaboración con gobiernos, organizaciones de empleadores y de trabajadores, el sector privado y otras organizaciones internacionales para:</p>\n\n<ul>\n<li>Luchar contra la trata de personas y el trabajo forzoso.</li>\n<li>Proteger los derechos de los trabajadores, incluidos los trabajadores migrantes, de prácticas abusivas y fraudulentas en procesos de contratación.</li>\n<li>Reducir el costo de la migración laboral y lograr que el desarrollo redunde en mayor beneficio de los trabajadores migrantes y de sus familias, tanto en los países de origen como en los de destino.</li>\n</ul>',
  work_calloutIloaimstoTitle: 'El objetivo de la OIT es proporcionar información más exhaustiva sobre prácticas de contratación eficaces, mejorar las legislaciones, políticas y mecanismos de implementación de las mimas, promover prácticas empresariales responsables y empoderar y proteger a los trabajadores.',
  work_calloutIloaimstoBody: 'Las herramientas de gobernanza relativas al mundo del trabajo, citadas anteriormente, permiten a la OIT apoyar mejores condiciones de trabajo en todo el mundo y así fortalecer la lucha por el Trabajo Decente para todos.',
  work_imageNightshiftCaption: 'Trabajadoras durante el turno de noche en una fábrica de lichi en Tamatave (Madagascar). © OIT / M. Fossat',
  work_imageNightshiftAlt: 'Trabajadoras en una fábrica vestidas de blanco con máscaras sanitarias, gorras y guantes azules sentadas en bancos y clasificando lichis en cintas transportadoras.',
  work_imageFishersCaption: 'Pescadores adquieren técnicas de piscifactoría respetuosas con el medio ambiente en el marco de un programa de empleo de emergencia OIT-AusAID en Filipinas. © OIT/M. Crozet',
  work_imageFishersAlt: 'Dos pescadores de pie sobre una estructura flotante de madera tratando de lanzar una gran red de pesca de color verde.',
  work_imageWomenwarCaption: 'Trabajadoras en la fábrica Royal Shell Factory 3 durante la guerra, Woolwich Arsenal (Reino Unido), mayo de 1918. © Imperial War Museum',
  work_imageWomenwarAlt: 'Mujeres trabajando con bombas sobre una mesa utilizando varias herramientas.',
  work_imageLabourinspCaption: 'Inspectores de trabajo llevan a cabo una inspección de salud y seguridad laborales en un taller de Amán (Jordania). © OIT / G. Megevand',
  work_imageLabourinspAlt: 'Inspectora de trabajo con un controlador en la mano conversando con un trabajador sentado delante de una máquina.',
  work_imageChildcarpetCaption: 'Niña tejedora de alfombras en Lahore (Pakistán). © OIT / M. Crozet',
  work_imageChildcarpetAlt: 'Niña sentada detrás de un telar de producción de alfombras sujetando varios hilos delante de ella, a través de los cuales muestra un ojo.',
  work_imageMigrantCaption: 'Trabajador migrante en Qatar. © Apex Image',
  work_imageMigrantAlt: 'Hombre con indumentaria de trabajo de color amarillo esparciendo hierba seca sobre el suelo. Hay varios rascacielos al fondo.',
  work_imageSyrianCaption: 'Obrero sirio refugiado trabajando en Jordania. © OIT/A. Al Sukhni',
  work_imageSyrianAlt: 'Hombre de pie sobre un andamio pintando un elaborado dibujo en el techo con pintura amarilla.',
  work_date: 'Descubra este capítulo el 20 de febrero de 2019',
  work_detailFitforpurpose2: '<p> La OIT brinda asesoramiento y asistencia técnica sobre la reforma de los sistemas de administración laboral y capacitación de los funcionarios encargados de las inspecciones laborales, los servicios de empleo, las prestaciones de protección social, el desarrollo empresarial, la resolución de controversias, la formulación de políticas y las legislaciones laborales, la recopilación y el análisis de datos y el diálogo social. </p>',
  work_detailWorkingtog2: '<p>Dicho diálogo social es esencial para fomentar el trabajo decente sostenible.</p>\n\n<p>La OIT promueve el diálogo social entre gobiernos y representantes de empleadores y trabajadores, y ayuda a los Estados Miembros a fortalecer las relaciones laborales en sus países para una gobernanza eficiente y eficaz del ámbito laboral. </p>\n\n<p>A tal efecto, la Organización brinda su apoyo con programas de investigación, formación y capacitación, y mediante plataformas informativas y de intercambio de competencias.</p>',
  work_detailReaching2: '<p>En la conciliación y la mediación, un tercero proporciona asistencia durante las negociaciones para ayudar a las partes a alcanzar a un acuerdo, especialmente si el diálogo entre ambas partes está estancado. </p>\n\n<p>El arbitraje es cuando un tercero, que no actúa como un tribunal de justicia, está facultado para tomar una decisión para resolver la disputa. </p>\n\n<p>Las soluciones judiciales especializadas facultan a un tribunal ordinario, o a un tribunal laboral especial, para resolver controversias en materia de derechos y obligaciones.</p>',
  work_imageConsensusCaption: 'Los interlocutores sociales en el sector del metal negocian un acuerdo para evitar conflictos. Las negociaciones se llevan a cabo en los locales de la Dirección General de Inspección y Conciliación del Trabajo en Túnez. © OIT',
  work_imageWorkingtoCaption: 'Wided Bouchamaoui, presidente de la Confederación Tunecina de Industria, Comercio y Artesanía (UTICA) y miembro del Cuarteto del Diálogo Nacional de Túnez. El Cuarteto recibió el Premio Nobel de la Paz en 2015 "por su contribución decisiva a la construcción de una democracia pluralista en Túnez después de la Revolución de los Jazmines de 2011" © OIT',
  work_imageFitforpurposeCaption: 'Un centro de desarrollo de recursos humanos y empleo en Tianjin. China. Los centros de servicios de empleo como este ayudan a desarrollar el conocimiento y las habilidades de los trabajadores para mejorar la competitividad y la productividad, al tiempo que promueven la inclusión social y el trabajo decente. © OIT',
  work_imageLawmattersCaption: 'Inspección de la embarcación "Jolly Blu" en el puerto de Génova, Italia. El teniente Paolo Leone verifica si el barco cumple con las normas internacionales de seguridad de los buques, prevención de la contaminación marina y condiciones de trabajo y de vida dignas para la gente de mar, según lo establecido en el Convenio sobre el trabajo marítimo de la OIT, 2006. © OIT',
  equality_heading: 'Igualdad y no discriminación',
  equality_title: 'La libertad de ser uno mismo',
  equality_chapter: 'capítulo 3',
  equality_calloutHundreds: 'Cientos de millones de personas padecen discriminación en el trabajo. Ello vulnera un derecho humano fundamental y repercute de forma más amplia en los planos social y económico.',
  equality_infoConsequencesTitle: 'Las consecuencias de la discriminación',
  equality_infoConsequencesBody1: '<h3>Las consecuencias de la discriminación</h3>\n\n<p>La discriminación se produce en todos los países y sectores laborales del mundo. Niega oportunidades a las personas, impide que se aproveche el talento humano y merma el progreso económico. Por otro lado, aumenta la tensión social y agrava la desigualdad.</p>\n\n<p>La lucha contra la discriminación constituye un elemento esencial del Programa de Trabajo Decente de la OIT. Los éxitos logrados a tal efecto no solo abarcan el entorno profesional, sino también los ámbitos familiar, escolar y social.</p>',
  equality_infoConsequencesBody2: 'La lucha contra la discriminación constituye un elemento esencial del Programa de Trabajo Decente de la OIT. Los éxitos logrados a tal efecto no solo abarcan el entorno profesional, sino también los ámbitos familiar, escolar y social.',
  equality_infoDiscriminationTitle: 'La discriminación nos afecta a todos',
  equality_infoDiscrimination: '<h3>La discriminación nos afecta a todos</h3>\n\n<p>La discriminación puede obedecer a muchos factores, en particular la raza, el sexo, la orientación sexual, la situación con respecto al VIH, la discapacidad, la edad y la afiliación política. Los pueblos indígenas, las minorías étnicas, los grupos religiosos, las mujeres y los migrantes son objeto de discriminación con frecuencia. La discriminación guarda relación asimismo con la violencia y el acoso contra mujeres y hombres en el ámbito laboral.</p>\n\n<p>El acoso sexual es una práctica generalizada. Incide en los estereotipos sobre capacidades y aspiraciones de las mujeres y puede hacer que estas pierdan su empleo, menoscabar su trayectoria profesional y provocar secuelas a largo plazo en todos los aspectos de su vida.</p>',
  equality_infoDiscriminationBody2: 'El acoso sexual es una práctica generalizada. Incide en los estereotipos sobre capacidades y aspiraciones de las mujeres y puede hacer que estas pierdan su empleo, menoscabar su trayectoria profesional y provocar secuelas a largo plazo en todos los aspectos de su vida.',
  equality_infoResearchnineBody: '<p>Varios estudios llevados a cabo por la OIT en nueve países, de 2013 a 2016, ponen de manifiesto que los estereotipos y prejuicios repercuten adversamente en las perspectivas laborales de personas lesbianas, homosexuales, bisexuales y transexuales. Dichas personas deben hacer frente a la discriminación, la violencia y el acoso en muchos niveles. Con frecuencia se ven obligadas a ocultar su identidad sexual.</p>',
  equality_infoDomino: '<h3>El efecto en cascada de la discriminación</h3>\n\n<p>La discriminación, sutil o explícita, puede repercutir también en la salud mental de una persona. Estudios llevados a cabo durante varias decadas han demostrado que las situaciones que dan lugar a un trato injusto o a un entorno de trabajo hostil por períodos de tiempo prolongados, aumentan el riesgo de padecer trastornos provocados por tensión, ansiedad o depresión. </p>\n\n<p>Si la salud mental de una persona se ve afectada, puede darse un efecto en cascada que repercute, no solo en esa persona, sino también en su pareja, así como en sus hijos y compañeros de trabajo.</p>\n\n<p>La discriminación puede menoscabar los objetivos de las personas e impedir que prosigan su trayectoria profesional. Merma su capacidad y reduce en más de 400 000 millones de dólares el aumento del PIB a escala mundial.</p>',
  equality_infoDominoBody1: 'La discriminación, sutil o explícita, puede repercutir también en la salud mental de una persona. Estudios llevados a cabo durante varias decadas han demostrado que las situaciones que dan lugar a un trato injusto o a un entorno de trabajo hostil por períodos de tiempo prolongados, aumentan el riesgo de padecer trastornos provocados por tensión, ansiedad o depresión.\n\nSi la salud mental de una persona se ve afectada, puede darse un efecto en cascada que repercute, no solo en esa persona, sino también en su pareja, así como en sus hijos y compañeros de trabajo.',
  equality_infoDominoBody2: 'La discriminación puede menoscabar los objetivos de las personas e impedir que prosigan su trayectoria profesional. Merma su capacidad y reduce en más de 400 000 millones de dólares el aumento del PIB a escala mundial.',
  equality_calloutPortraitsTitle: 'Retratos de la desigualdad',
  equality_calloutPortraitsBody: 'La discriminación no solo constituye un agravio moral; también vulnera los derechos humanos y tiene consecuencias económicas adversas.',
  equality_infoBreakingexpectTitle: 'Romper los estereotipos sobre cuestiones de género',
  equality_infoBreakingexpectBody1: '<h3>Romper los estereotipos sobre cuestiones de género</h3>\n\n<p>En el informe conjunto OIT-Gallup Hacia un futuro mejor para las mujeres en el trabajo: la opinión de las mujeres y de los hombres, se arroja luz por primera vez sobre la actitud y la opinión de mujeres y hombres de todo el mundo con respecto a la mujer en el trabajo. Los resultados de dicho informe revelan que la opinión de los hombres es muy similar a la de las mujeres en muchos casos, lo que pone de manifiesto a nivel mundial el deseo generalizado de que la mujer goce de las mismas oportunidades laborales que el hombre.</p>\n\n<p>La encuesta, en la que participaron unas 149 000 personas adultas, se llevó a cabo en 142 países y territorios, y sus resultados son representativos para más del 99% de la población adulta mundial.</p>',
  equality_infoBreakingexpectBody2: 'La encuesta, en la que participaron unas 149.000 personas adultas, se llevó a cabo en 142 países y territorios, y sus resultados son representativos para más del 99% de la población adulta mundial.',
  equality_calloutDespiteprog: 'A pesar de los notables avances registrados a lo largo del siglo pasado, la integración de la mujer en la fuerza de trabajo sigue siendo menos habitual que la del hombre.',
  equality_calloutAbetterlife: 'Una mejor vida laboral para todo el mundo',
  equality_infoIlsdiscrimination: '<p>Los gobiernos, los trabajadores y los empleadores de todo el mundo reconocen las normas y las directrices laborales de la OIT destinadas a hacer frente a la discriminación.</p>\n\n<p>A medida que más países han promulgado legislaciones con objeto de ofrecer amparo frente a casos de discriminación y fomentar la igualdad, los expertos de la OIT han desempeñado un papel primordial en la prestación de asesoramiento sobre enmiendas legislativas a tal efecto.</p>',
  equality_calloutConclusion1: 'Un mundo sin discriminación es posible y todos tenemos un papel a desempeñar para lograrlo.',
  equality_imageMigrantreturnCaption: 'Silueta de una trabajadora migrante retornado que ha recibido apoyo psicosocial en un proyecto de reintegración respaldado por la OIT en Addis Abeba, Etiopía. Los trabajadores migrantes a menudo están sujetos a diferentes formas de discriminación, violencia y acoso. © OIT / A. Muluneh',
  equality_imageMigrantreturnAlt: 'Silueta de la cabeza de una mujer fotografiada delante de una ventana. La foto está al trasluz y no se aprecia su rostro.',
  equality_imageDisabledgarmentCaption: 'Trabajadora en una fábrica de prendas de vestir, Nguyen Thi Thanh Hieu. Dong Nai (Vietnam). © OIT / A. Nguyen',
  equality_imageDisabledgarmentAlt: 'Mujer con deformación en la espalda sentada delante de una máquina de coser en una fábrica con un pedazo de tela entre las manos.',
  equality_imageTranswomenCaption: 'Travestis y mujeres trans participan en un curso de formación de asistente de cocina en Brasil para lesbianas, gays, bisexuales, transexuales e intersexuales, impartido por la chef argentina Paola Carosella. La OIT promueve la inclusión de personas LGBTI en el mercado laboral formal. © OIT Brasil',
  equality_imageTranswomenAlt: 'Dos mujeres chef cocinando. Los ingredientes están sobre una mesa de acero inoxidable en una cocina.',
  equality_visGenderBody: '<h3>Sexo</h3>\n\n<p>La igualdad entre sexos fortalece la economía y contribuye a su desarrollo sostenido. Según se desprende de un informe del Instituto McKinsey Global, la promoción de la igualdad de la mujer podría conllevar un aumento de 12 billones de dólares del PIB a escala mundial en 2025.</p>\n\n<p>La lucha por la igualdad entre sexos se pone de manifiesto en todos los aspectos de la labor de la OIT y está consagrada en su Constitución y en sus Convenios Fundamentales.</p>',
  equality_visLgbtBody: '<h3>LGBT</h3>\n\n<p>Según las estimaciones de un reciente estudio sobre la India realizado por el Banco Mundial, además del pesar y del sufrimiento que padecen las personas lesbianas, homosexuales, bisexuales o transexuales (LGBT) como consecuencia de su discriminación, el costo de la homofobia para dicho país en términos económicos podría conllevar una disminución del 1,7% de su PIB anual.</p>\n\n<p>En el marco del proyecto de la OIT “Identidad de género y orientación sexual: Promoción de los derechos, la diversidad y la igualdad en el mundo del trabajo (PRIDE)”, se llevan a cabo diversos estudios sobre discriminación de los trabajadores LGBT en todo el mundo y se destacan prácticas idóneas para promover su inclusión laboral. Entre los países abarcados cabe destacar Argentina, Costa Rica, Francia, Hungría, India, Indonesia, Montenegro, Sudáfrica y Tailandia.</p>',
  equality_visHivBody: '<h3>VIH y SIDA</h3>\n\n<p>Las políticas y la formación en materia de VIH en el ámbito laboral, así como la promoción de pruebas y actividades de orientación voluntarias en relación con el VIH, disminuyen los estigmas en el trabajo y ayudan a los empleados a conocer a tiempo su situación con respecto al VIH para recibir tratamiento en caso de que los resultados de las pruebas sean positivos. El tratamiento mejora la salud de los trabajadores. Estos se toman menos días en el trabajo por enfermedad y su productividad laboral aumenta. Ello permite a las personas que son portadoras del VIH seguir trabajando y cuidar de su familia.</p>\n\n<p>La Iniciativa de la OIT para realizar pruebas y actividades de orientación voluntarias (VCT@WORK) ha abarcado más de 6 millones de trabajadores, a los que ha ayudado a conocer su situación con respecto al VIH y, en su caso, a solicitar el tratamiento adecuado.</p>\n\n<p>Las iniciativas de esta índole contribuyen a reducir la cantidad de personas incapacitadas para trabajar por ser seropositivas. En 2005, 350 000 personas no pudieron trabajar por ser portadoras del VIH. Se prevé que esa cantidad se reduzca hasta 40 000 personas en 2020, con arreglo a una disminución del 85% en el caso de los hombres, y del 93% en el caso de las mujeres.</p>',
  equality_visDisabilityBody: '<h3>Discapacidad</h3>\n\n<p>Alrededor de 1000 millones de personas, o una séptima parte de la población mundial, tiene algún tipo de discapacidad. De ellas, aproximadamente 800 millones están en edad de trabajar, si bien muchas padecen exclusión laboral. Ello no solo les impide integrarse plenamente en la sociedad, sino que provoca una disminución del PIB a escala mundial que oscila del 3% al 7%.</p> \n\n<p>La Red Mundial de la OIT sobre Empresas y Discapacidad, integrada por empresas multinacionales que poseen en su conjunto más de 4,2 millones de empleados, comparte conocimientos y desarrolla diversos métodos para que las empresas y los empleadores contraten a personas con discapacidad y mantengan su empleo.</p>',
  equality_visIndigenousBody: '<h3>Pueblos indígenas</h3> \n\n<p>Los pueblos indígenas constituyen alrededor del 5% de la población mundial, pero representan el 15% de la población en situación de pobreza. Con frecuencia se les priva de sus tierras y se les niega una enseñanza de calidad, y su voz no es escuchada por los gobiernos. El aumento de su participación en el sector del empleo formal contribuiría a que millones de esas personas, en particular mujeres, dejaran de estar en situación de pobreza.</p>    \n\n<p>La OIT aborda la cuestión relativa a los pueblos indígenas y tribales desde 1920. Por otro lado, impulsó el establecimiento del Convenio sobre pueblos indígenas y tribales, 1989 (núm. 169), el único tratado internacional que aboga exclusivamente por sus derechos.</p>',
  equality_visEthnicityBody: '<h3>Etnicidad</h3>\n\n<p>Si los hombres y las mujeres de ascendencia inmigrante tuvieran más poder económico, los gobiernos obtendrían mayores ingresos fiscales por medio de impuestos a personas físicas y morales. Por ejemplo, según han puesto de manifiesto varios estudios, si en Francia se pusiera fin a la discriminación de personas de origen norteafricano, los ingresos estatales aumentarían en un 2,0% y se reduciría el gasto público en un 0,5%.</p>\n\n<p>La supresión de la discriminación por motivos raciales ha constituido uno de los principales ejes de la labor de la OIT desde 1919, y está consagrada en el Convenio sobre la discriminación (empleo y ocupación), 1958 (núm. 111). La obligación de todos los Estados Miembros de respetar, promover y aplicar este principio se confirmó en la Declaración de la OIT relativa a los principios y derechos fundamentales en el trabajo, adoptada en 1998.</p>',
  equality_visMigrantBody: '<h3>Trabajadores migrantes</h3> \n \n<p>Los trabajadores migrantes constituyen uno de los grupos más afectados por la recesión económica y son los más susceptibles de ser objeto de discriminación. Sin embargo, los economistas reconocen que la inmigración contribuye a elevar ligeramente los niveles salariales y de empleo, así como a fomentar la economía.</p>\n\n<p>La Iniciativa de la OIT sobre Contratación Equitativa promueve las oportunidades de contratación de trabajadores migrantes en pie de igualdad. La plataforma Asesoría de contratación de trabajadores migrantes promueve la protección de los trabajadores migrantes frente a prácticas de empleo abusivas por medio del examen de agencias de contratación efectuado por usuarios.</p>',
  equality_visDomesticBody: '<h3>Trabajadoras y trabajadores domésticos</h3>\n\n<p>Según se desprende de datos estadísticos de la OIT, en el mundo hay 67 millones de trabajadoras y trabajadores domésticos. Cuidan de familias y realizan labores de limpieza y cocina, y son una parte fundamental del sector económico relativo a la prestación de cuidados. No obstante, solo el 10% de esos trabajadores goza de protección equiparable a la del resto de empleados en el plano laboral. Por lo general, su salario no rebasa la mitad del salario promedio y constituyen la mayoría de las personas que padecen discriminación salarial por sexo en la base del mercado laboral. Las condiciones de trabajo deficientes de muchos de esos trabajadores obedecen a varios tipos de discriminación; con frecuencia, el trabajo doméstico no se considera un empleo, sino una responsabilidad consustancial a la mujer.</p>\n\n<p>El Convenio sobre las trabajadoras y los trabajadores domésticos, 2011 (núm. 189), constituye la única norma internacionalmente reconocida destinada a proteger y mejorar las condiciones de trabajo y de vida de los trabajadores domésticos en todo el mundo.</p>',
  equality_visAgeBody: '<h3>Edad</h3>\n\n<p>El bajo índice de participación de las personas de edad en la fuerza de trabajo conlleva notables costos económicos. En Australia, si se incorporara un 3% más de trabajadores de 55 años de edad o más a la población activa, se produciría un aumento del PIB de 33.000 millones de dólares, y un incremento de la renta nacional de alrededor del 1,6%. Si el empleo remunerado de este grupo social se elevara un 5%, el PIB aumentaría en 48.000 millones de dólares, es decir, un 2,4% de la renta nacional.</p>\n\n<p>La OIT aborda la situación específica de dichos trabajadores en la Recomendación sobre los trabajadores de edad, 1980 (núm. 162). A través de esta Recomendación se ofrece a los países un marco de propuestas para brindar a los trabajadores de edad condiciones de trabajo decentes y productivas.</p>',
  equality_visWomen1Body: 'Preferencias de las mujeres',
  equality_visWomen2Body: 'Preferencias de los hombres',
  equality_visWomen3Body: '<p>El 70% de las mujeres y el 66% de los hombres encuestados prefieren que la mujer tenga un empleo remunerado, más del doble que el porcentaje de personas que prefieren que la mujer se quede en el hogar sin trabajar.</p>',
  equality_visWomen4Body: '<p>Las mujeres de todo el mundo prefieren tener un empleo remunerado (29%) o tener una situación que les permita trabajar y cuidar de su familia (41%), según se desprende del informe.</p>',
  equality_visWomen5Body: '<p>Solo el 27% de las mujeres manifiesta el deseo de quedarse en el hogar sin trabajar, y únicamente el 29% de los hombres opina lo mismo.</p>',
  equality_visWomen6Body: '<p>El 70% de las mujeres que desea tener un empleo remunerado comprende especialmente una mayoría de mujeres que no integran la fuerza de trabajo.</p>',
  equality_visWomen7Body: '<p>Cabe señalar que ese es el caso en todas las regiones del mundo, incluidas aquellas en las que la participación de la mujer en la fuerza de trabajo es tradicionalmente baja, en particular, en los Estados Árabes.</p>',
  equality_visWomen8Body: 'Prefieren que la mujer tenga un empleo remunerado',
  equality_visWomen9Body: 'Prefieren que la mujer haga ambas cosas',
  equality_visWomen10Body: 'Prefieren que la mujer se quede en el hogar sin trabajar para ocuparse de las tareas domésticas y del cuidado familiar.',
  equality_visWomen11Body: 'No saben o se negaron a contestar',
  equality_date: 'Descubra este capítulo el 30 de enero de 2019',
  equality_visGapTitle: 'La brecha de género en la fuerza de trabajo',
  equality_visGapSelect: 'Buscar país',
  equality_visGapRatesLabel: 'Índices de participación en la fuerza de trabajo (%)',
  equality_visGapWomen: 'La mujer en la fuerza de trabajo',
  equality_visGapMen: 'El hombre en la fuerza de trabajo',
  equality_visGapGap: 'BRECHA',
  equality_visGapNote: 'Datos de 2019. Fuente: ILOSTAT – Estimaciones modeladas de la OIT.',
  equality_visGapDefinition: 'La FUERZA DE TRABAJO integra todas personas en edad de trabajar, tanto empleadas como desempleadas. El índice de participación en la fuerza de trabajo expresa la fuerza de trabajo como porcentaje de la población en edad de trabajar.',
  equality_calloutPortraitsSub: 'El costo de la desigualdad se eleva a miles de millones de dólares, lo que menoscaba la riqueza de los países, tanto en el plano económico como con respecto al talento humano. La igualdad de oportunidades de todos los miembros de la sociedad significaría un beneficio de todos.',
  equality_calloutDespiteProgSub: 'El índice actual de participación de la mujer en la fuerza de trabajo a escala mundial es casi del 49%. En el caso de los hombres, es del 75%.',
  equality_calloutConclusion2: 'La igualdad permite promover el empleo decente para todos, hombres y mujeres, y se incluye en todos los programas de empleo de la OIT.',
  equality_infoWomenBody1: '<p>En los casos en los que la mujer trabaja, suele tener un empleo de baja calidad e insuficientemente remunerado, y con frecuencia, infravalorado. </p>\n\n<p>En todo el mundo la mujer trabaja más horas diarias al tenerse en cuenta el tiempo de trabajo remunerado y el no remunerado. Las mujeres realizan el 75% del trabajo de prestación de cuidados no remunerado en todo el mundo. Según estimaciones de la OIT, se dedican 16 400 millones de horas diarias a la realización de trabajo de prestación de cuidados no remunerado, cuyo valor equivale al 9% del PIB mundial.</p>',
  equality_infoWomenBody2: 'Con objeto de paliar la situación, la Coalición Internacional para la Igualdad Salarial (EPIC), liderada por la OIT, ONU Mujeres y la OCDE, promueve la adopción de medidas específicas, en colaboración con los gobiernos, el sector privado, la sociedad y el sector académico, que permitan colmar lo antes posible las lagunas salariales entre hombres y mujeres, y lograr la igualdad salarial en 2030.',
  equality_calloutPortraitsSubtitle: 'Explore estos retratos y conozca sus experiencias en el mundo del trabajo.',
  equality_portrait0Detail1: '<h3>Carling, líder indígena</h3>\n\n<p>Carling Dumulot es un aeta, un pueblo indígena que vive en Filipinas. A lo largo de su vida ha visto su cultura y las necesidades de su pueblo marginados e ignorados, incluso en sus tierras ancestrales.</p>\n\n<p>Como líder indígena, su lucha ha sido lograr el reconocimiento necesario para ayudar a su pueblo a salir de la pobreza y tener una voz en las decisiones que afectan su vida diaria.</p>',
  equality_portrait0Detail2: '<h3>Lucha contra la discriminación de los pueblos indígenas.</h3> \n\n<p>Los pueblos indígenas constituyen alrededor del 5% de la población mundial, pero representan el 15% de la población en situación de pobreza. Con frecuencia se les priva de sus tierras y se les niega una enseñanza de calidad, y su voz no es escuchada por los gobiernos. El aumento de su participación en el sector del empleo formal contribuiría a que millones de esas personas, en particular mujeres, dejaran de estar en situación de pobreza.</p>    \n\n<p>La OIT aborda la cuestión relativa a los pueblos indígenas y tribales desde 1920. Por otro lado, impulsó el establecimiento del Convenio sobre pueblos indígenas y tribales, 1989 (núm. 169), el único tratado internacional que aboga exclusivamente por sus derechos.</p>',
  equality_portrait0Caption: 'Carling Dumulot, Sénior, un líder indígena del pueblo aeta que ha luchado junto con la OIT para concienciar sobre la discriminación que sufren los pueblos indígenas en Filipinas y en todo el mundo. © OIT',
  equality_portrait1Detail1: '<h3>Sreela, especialista en diversidad e inclusión</h3>\n\n<p>El mundo de Sreela Das Gupta cambió hace 15 años cuando le diagnosticaron una enfermedad autoinmune que la dejó en silla de ruedas. Vivir con una discapacidad cambió la forma en que la gente la veía y las expectativas que tenían de ella en el trabajo.</p>',
  equality_portrait1Detail2: '<p>Sreela se considera afortunada de que sus empleadores estuvieran dispuestos a comprometerse con ella para identificar la mejor manera en que podría contribuir a su lugar de trabajo, ya que esto todavía es poco común en gran parte del mundo actual.</p>\n\n<p>Por lo tanto, empoderar a otros se ha convertido en una fuerza orientadora en su vida, ya que ha trabajado para apoyar a otros en áreas como la inclusión de la discapacidad, los derechos humanos, el empoderamiento de la mujer y la salud materna e infantil durante más de dos décadas.</p>',
  equality_portrait1Caption: 'Sreela Das Gupta, especialista en ayudar a las empresas a ser más diversas e inclusivas con las personas con discapacidad en el lugar de trabajo. © OIT',
  equality_portrait2Detail1: '<h3> Abel, trabajador migrante</h3>\n\n<p>La gente como Abel Torres García es crítica para proveer el suministro de alimentos y apoyar la economía de las naciones industrializadas.</p>\n\n<p>Durante años, Abel ha hecho un trabajo agotador bajo el sol, por poco dinero y poco reconocimiento, cosechando las batatas, tomates y otras verduras, que acaban en nuestras mesas. A pesar de realizar trabajos que pocos locales quieren hacer, se ha visto obligado a soportar la violencia y la intimidación casi a diario.</p>',
  equality_portrait2Detail2: '<h3>Luchar contra la discriminación de los trabajadores migrantes.</h3> \n \n<p>Los trabajadores migrantes constituyen uno de los grupos más afectados por la recesión económica y son los más susceptibles de ser objeto de discriminación. Sin embargo, los economistas reconocen que la inmigración contribuye a elevar ligeramente los niveles salariales y de empleo, así como a fomentar la economía.</p>',
  equality_portrait2Caption: 'Abel Torres García, un trabajador agrícola migrante mexicano en Carolina del Norte, EE.UU., enfrenta discriminación a diario a pesar de trabajar legalmente en una industria que contribuye con 84 mil millones de dólares a la economía del estado. © Marc McAndrews',
  equality_portrait3Detail1: '<h3>Blanca, trabajadora mayor</h3>\n\n<p>Blanca Ester Castillo, de 77 años, enfrentó la pobreza y la exclusión a lo largo de su vida. A pesar de cuidar de 14 hijos y 11 nietos, cuanto mayor era, mayor era la discriminación a la que se enfrentaba para encontrar trabajo debido a su edad.</p>\n\n<p>Ella es una de los millones de mujeres y hombres quienes son los principales sostenes de la familia, y quienes se enfrentan a una ardua lucha para mantenerlos a medida que envejecen.</p>',
  equality_portrait3Detail2: '<h3>Luchar contra la discriminación de las personas mayores.</h3>\n\n<p>El bajo índice de participación de las personas mayores en la fuerza de trabajo conlleva notables costos económicos. En Australia, si se incorporara un 3% más de trabajadores de 55 años o más a la población activa, se produciría un aumento del PIB de 33.000 millones de dólares, y un incremento de la renta nacional de alrededor del 1,6%.</p>',
  equality_portrait3Caption: 'Blanca Ester Castillo, una jubilada reciente de 77 años que sufrió discriminación para encontrar trabajo, con pocos empleadores dispuestos a contratar a una mujer de su edad. © OIT',
  equality_visGapWorld: 'Mundo',
  equality_portrait4Detail1: '<h3>Aya, directora galardonada</h3>\n\n<p>Aya Tanimura es una galardonada directora de videos musicales, televisión y cine. Con sus bellos y originales conceptos para grandes marcas, compañías y artistas como Katy Perry, su trabajo ha acumulado más de mil millones de visitas en línea.</p>',
  equality_portrait4Detail2: '<p>Sin embargo, Tanimura siente que la discriminación y las barreras a las que se enfrentan las mujeres que trabajan en la producción cinematográfica y televisiva han limitado el trabajo que ha podido realizar. Incluso, en los set de rodaje, ha sido tratada como una asistente en los mismos proyectos que dirigía.</p>\n\n<p>De las 100 películas más taquilleras del 2018, solo el 4% fueron dirigidas por mujeres.</p>',
  equality_portrait4Caption: 'Aya Tanimura, directora de cine australiana-japonesa que ha sufrido discriminación en su lugar de trabajo debido a su género. © Lauren Crew',
  equality_portrait5Detail1: '<h3>Emma, activista por los derechos de las lesbianas</h3>\n\n<p>Emma Chacón A. es una sobreviviente de acoso laboral. En 2014, cuando se descubrió que era activista en eventos de derechos de las lesbianas, sus responsabilidades en el trabajo se redujeron sin previo aviso, y tuvo que soportar los comentarios homofóbicos de sus colegas.</p>\n\n<p>Luego fue trasladada contra su voluntad a otro lugar de trabajo.</p>',
  equality_portrait5Detail2: '<p>Al principio, no podía creer la situación en la que se encontraba, ya que, como mujer lesbiana y feminista, siempre se había sentido empoderada. Sin embargo, la manera en que la trataban en el trabajo hizo que se cuestionara a sí misma, a su trabajo y a su valor.</p>\n\n<p>Llevó varios años recuperarse y volver a sentirse reconocida, apreciada y valorada en el trabajo. Hoy en día, se enorgullece de apoyar a otras personas que han experimentado situaciones similares y de ayudarlas a superarlas.</p>',
  equality_portrait5Caption: 'Emma Chacón A., sobreviviente de acoso y discriminación en el lugar de trabajo debido a su activismo a favor de los derechos de las lesbianas. © Emma Chacón A.',
  equality_portrait6Detail1: '<h3>Laurel, HIV and human rights activist</h3>\n\n<p>Laurel Sprague fue diagnosticada en 1991 cuando tenía 20 años. Se enfermó muy rápidamente y muchos de sus amigos y miembros de la red de apoyo también se estaban muriendo.</p>\n\n<p>Casi inmediatamente se enfrentó al estigma y la discriminación. En ese momento no podía hacer nada para detener lo que le estaba sucediendo a ella misma, pero podía hacer mucho para ayudar a otros en la misma situación.</p>\n\n<p>Hoy en día, es una líder en la lucha contra el estigma relacionado con el VIH, al tiempo que lucha para poner fin a la discriminación, la criminalización y los abusos contra los derechos humanos que sufren las personas que viven con el VIH.</p>',
  equality_portrait6Detail2: '<h3>Lucha contra la discriminación de las personas que viven con el VIH</h3>\n\n<p>Las políticas y la formación en materia de VIH en el ámbito laboral, así como la promoción de pruebas y actividades de orientación voluntarias en relación con el VIH, disminuyen los estigmas en el trabajo y ayudan a los empleados a conocer a tiempo su situación con respecto al VIH para recibir tratamiento en caso de que los resultados de las pruebas sean positivos.</p>\n\n<p>El tratamiento mejora la salud de los trabajadores. Estos se toman menos días en el trabajo por enfermedad y su productividad laboral aumenta. Ello permite a las personas que son portadoras del VIH seguir trabajando y cuidar de su familia.</p>',
  equality_portrait6Caption: 'Laurel Sprague, líder en la lucha contra el estigma relacionado con el VIH, y activista que lucha para poner fin a la discriminación, la criminalización y los abusos contra los derechos humanos que sufren las personas que viven con el VIH © OIT',
  equality_portrait7Detail1: '<h3>Zainab, trabajadora doméstica</h3>\n\n<p>Zainab Ecumba pasó años siendo acosada. "Me trataban como un objeto", asegura Zainab. "Mis jefes me levantaban la ropa, o cuando estaba durmiendo venían a quitarme las mantas y se reían."</p>\n\n<p>Las trabajadoras domésticas son vulnerables al acoso porque trabajan a puerta cerrada donde nadie las vigila. Las personas de afuera rara vez  le creyeron a Zainab cuando les contó su terrible experiencia.</p>',
  equality_portrait7Detail2: '<h3>Lucha contra la discriminación de las trabajadoras domésticas</h3>\n\n<p>Según se desprende de datos estadísticos de la OIT, en el mundo hay 67 millones de trabajadoras y trabajadores domésticos. Cuidan de familias y realizan labores de limpieza y cocina, y son una parte fundamental del sector económico relativo a la prestación de cuidados. No obstante, solo el 10% de esos trabajadores goza de protección equiparable a la del resto de empleados en el plano laboral.</p>\n\n<p>Por lo general, su salario no rebasa la mitad del salario promedio y constituyen la mayoría de las personas que padecen discriminación salarial por sexo en la base del mercado laboral.</p>',
  equality_portrait7Caption: 'Zainab Ecumba, una trabajadora doméstica que ha sufrido años de acoso, violencia y discriminación en las casas de algunos de sus empleadores. © Zainab Ecumba',
  equality_portrait8Detail1: '<h3Leroy, entrenador de fútbol</h3>\n\n<p>Leroy Rosenior fue futbolista profesional en las décadas de 1980 y 1990. "La discriminación racial era horrible", dice. "¿Conoces esa frase: "Los palos y las piedras pueden romperme los huesos, pero las palabras nunca me harán daño"? Bueno, creo que me he roto la mitad de los huesos del cuerpo, pero el dolor físico desaparece pronto. Las palabras, sin embargo, se han quedado conmigo."  </p>\n\n<p>En los últimos años, Leroy ha estado trabajando con Show Racism the Red Card (Sácale tarjeta roja al racismo) para educar a la gente sobre los peligros del racismo en el fútbol y en toda la sociedad. En marzo de 2019, recibirá un MBE, (Orden del Imperio Británico, por sus siglas en inglés), un prestigioso premio otorgado por el monarca del Reino Unido. Espera que el MBE destaque la labor de la organización y promueva la necesidad permanente de hacer frente a la discriminación racial en el fútbol y en todos los lugares de trabajo.</p>',
  equality_portrait8Detail2: '<h3>Lucha contra la discriminación racial</h3>\n\n<p>Si los hombres y mujeres que sufren discriminación racial tuvieran más poder económico, los gobiernos se beneficiarían de ingresos adicionales en forma de impuestos sobre la renta y sobre las empresas.</p>\n\n<p>Por ejemplo, las investigaciones han demostrado que en Francia, si se pusiera fin a la discriminación contra las personas de ascendencia norteafricana, se produciría un aumento del 2,0% de los ingresos adicionales y se reduciría el gasto público en un 0,5%.</p>',
  equality_portrait8Caption: 'Leroy Rosenior, futbolista profesional en las décadas de 1980 y 1990, sufrió discriminación racial dentro y fuera del terreno de juego. © Sácale Tarjeta Roja al Racismo',
  equality_portrait1Detail3: '<h3>Lucha contra la discriminación a las personas con discapacidad.</h3>\n\n<p>Alrededor de 1000 millones de personas, o una séptima parte de la población mundial, tiene algún tipo de discapacidad. De ellas, aproximadamente 800 millones están en edad de trabajar, si bien muchas padecen exclusión laboral.',
  equality_portrait1Detail4: '<p>Ello no solo les impide integrarse plenamente en la sociedad, sino que provoca una disminución del PIB a escala mundial que oscila del 3% al 7%.</p> \n\n<p>La Red Mundial de la OIT sobre Empresas y Discapacidad, integrada por empresas multinacionales que poseen en su conjunto más de 4,2 millones de empleados, comparte conocimientos y desarrolla diversos métodos para que las empresas y los empleadores contraten a personas con discapacidad y mantengan su empleo.</p>',
  equality_portrait2Detail3: '<p>La Iniciativa de la OIT sobre Contratación Equitativa promueve las oportunidades de contratación de trabajadores migrantes en pie de igualdad.</p>\n\n<p>La plataforma Asesoría de contratación de trabajadores migrantes promueve la protección de los trabajadores migrantes frente a prácticas de empleo abusivas por medio del examen de agencias de contratación efectuado por usuarios.</p>',
  equality_portrait3Detail3: '<p>Si el empleo remunerado de este grupo social se elevara un 5%, el PIB aumentaría en 48.000 millones de dólares, es decir, un 2,4% de la renta nacional.</p>\n\n<p>La OIT aborda la situación específica de dichos trabajadores en la Recomendación sobre los trabajadores de edad, 1980 (núm. 162). A través de esta Recomendación se ofrece a los países un marco de propuestas para brindar a los trabajadores de edad condiciones de trabajo decentes y productivas.</p>',
  equality_portrait4Detail3: '<h3>Lucha contra la discriminación de género</h3>\n\n<p>La igualdad entre sexos fortalece la economía y contribuye a su desarrollo sostenido. Según se desprende de un informe del Instituto McKinsey Global, la promoción de la igualdad de la mujer podría conllevar un aumento de 12 billones de dólares del PIB a escala mundial en 2025.</p>\n\n<p>La lucha por la igualdad entre sexos se pone de manifiesto en todos los aspectos de la labor de la OIT y está consagrada en su Constitución y en sus Convenios Fundamentales.</p>',
  equality_portrait5Detail3: '<h3>Lucha contra la discriminación de la comunidad LGBTI</h3>\n\n<p>Todos los países del mundo se beneficiarán de una mejor defensa de los derechos de las lesbianas, gays, bisexuales o personas transgénero e Intersexuales. (LGBTI).</p>\n\n<p>Por ejemplo, un estudio reciente sobre la India realizado por el Banco Mundial estima que, además del dolor y el sufrimiento que experimenta la comunidad LGBTI a causa de la discriminación, el costo económico para el país debido a la homofobia podría llegar al 1,7% del PIB perdido al año.</p>',
  equality_portrait5Detail4: '<p>Identidad de género y orientación sexual de la OIT: El proyecto PRIDE (Promoción de los derechos, la diversidad y la igualdad en el mundo del trabajo) lleva a cabo investigaciones sobre la discriminación contra los trabajadores LGBTI en todo el mundo y destaca las buenas prácticas que promueven su inclusión en el lugar de trabajo. </p>\n\n<p>Los países que recibieron apoyo fueron Argentina, Costa Rica, Francia, Hungría, India, Indonesia, Montenegro, Sudáfrica y Tailandia.</p>',
  equality_portrait6Detail3: '<p>La Iniciativa de la OIT para realizar pruebas y actividades de orientación voluntarias (VCT@WORK) ha abarcado más de 6 millones de trabajadores, a los que ha ayudado a conocer su situación con respecto al VIH y, de ser necesario, a solicitar el tratamiento adecuado.</p>\n\n<p>Las iniciativas de esta índole contribuyen a reducir la cantidad de personas incapacitadas para trabajar por ser seropositivas. En 2005, 350.000 personas no pudieron trabajar por ser portadoras del VIH. Se prevé que esa cantidad se reduzca hasta 40.000 personas en 2020, con arreglo a una disminución del 85% en el caso de los hombres, y del 93% en el caso de las mujeres.</p>',
  equality_portrait7Detail3: '<p>Las condiciones de trabajo deficientes de muchos de esos trabajadores obedecen a varios tipos de discriminación; con frecuencia, el trabajo doméstico no se considera un empleo, sino una responsabilidad consustancial a la mujer.</p>\n\n<p>El Convenio sobre las trabajadoras y los trabajadores domésticos, 2011 (núm. 189), constituye la única norma internacionalmente reconocida destinada a proteger y mejorar las condiciones de trabajo y de vida de los trabajadores domésticos en todo el mundo.</p>',
  equality_portrait8Detail3: '<p>La eliminación de la discriminación basada en la raza ha estado en el centro de la labor de la OIT desde 1919 y está consagrada en el Convenio sobre la discriminación (empleo y ocupación), 1958 (número. 111).</p>\n\n<p>La obligación de todos los Estados miembros de respetar, promover y realizar este principio se reafirmó en la Declaración de la OIT relativa a los principios y derechos fundamentales en el trabajo, adoptada en 1998.</p>',
  enterprises_heading: 'Empresas sostenibles',
  enterprises_chapter: 'capítulo 8',
  enterprises_title: 'Empresas para las personas y el planeta',
  enterprises_date: 'Descubra este capítulo el 6 de marzo de 2019',
  enterprises_infoAroundtheworld: '<p>La demanda de empresas cuyo objetivo no sea únicamente lograr beneficios está en auge en todo el mundo. Las empresas y los consumidores buscan cada vez más productos y servicios que repercutan favorablemente en la comunidad, el medio ambiente y la sociedad.</p>\n\n<p>Las empresas sostenibles incluyen empresas pequeñas, medianas, grandes y multinacionales, cooperativas, empresas "verdes" y empresas sociales, que equilibren sus necesidades e intereses con los de las personas que trabajan para ellas y los de la sociedad en su conjunto.</p> \n\n<p>La promoción de estas empresas es parte esencial del Programa de Trabajo Decente de la OIT, que hace hincapié en la creación de empleo, los derechos laborales, la protección social y el diálogo social. A su vez, se vincula con los objetivos mundiales de las Naciones Unidas, conocidos como Objetivos de Desarrollo Sostenible, que ponen el trabajo decente en el centro de la formulación de políticas económicas y de desarrollo. Un desarrollo que beneficia a todos en el presente y en el futuro, sin dañar el medio ambiente.</p>',
  enterprises_infoTherightenviro: '<h3>Entorno propicio para las empresas adecuadas</h3>\n\n<p>Las empresas sostenibles no surgen de la nada. El fomento de la innovación y del desarrollo para crear más y mejores puestos de trabajo requiere de entornos propicios a escala regional, nacional y local. La OIT colabora en todas las etapas de ese proceso.</p>\n\n<p>La OIT ofrece asistencia a las partes interesadas, ya sean personas, empresas e instituciones nacionales, con el fin de establecer entornos propicios que permitan a todo tipo de empresas, independientemente de su tamaño, cumplir los Objetivos de Desarrollo Sostenible a través de su programa Entorno Propicio para las Empresas Sostenibles.</p>',
  enterprises_infoInclusive: '<h3>Lugares de trabajo inclusivos, responsables y sostenibles</h3>\n\n<p>La labor de la OIT se fundamenta en el tripartismo, que reúne a gobiernos y a representantes de empleadores y trabajadores para lograr el consenso, esencial para la creación de empresas que desarrollen su labor a favor de las personas y del planeta.</p>\n\n<p>La Declaración tripartita de la OIT de principios sobre las empresas multinacionales y la política social, conocida como Declaración EMN, constituye el único documento jurídico internacional que proporciona orientación específica a las empresas sobre política social y prácticas laborales inclusivas, responsables y sostenibles en el lugar de trabajo.</p> \n\n<p>Elaborada y adoptada por gobiernos, empleadores y trabajadores de todo el mundo, tiene por objeto fomentar las aportaciones de las empresas multinacionales para favorecer el progreso económico y social y el trabajo decente para todos. Su objetivo es también minimizar y resolver las dificultades que las empresas pueden encontrar en el proceso.</p>\n\n<p><mark id="enterprises_infoInclusive">El apoyo de la OIT a gobiernos, empresas y empresarios se basa en las orientaciones que figuran en la Declaración EMN.</mark></p>',
  enterprises_detailInclusive1: '<h3>Trabajo decente y Juegos Olímpicos</h3>\n\n<p>Sobre la base de la asociación establecida entre la OIT y el Comité Organizador de los Juegos Olímpicos y Paralímpicos de Tokio 2020, las orientaciones de la Declaración EMN sirven para promover prácticas laborales responsables en el ámbito social en las empresas que colaboran en la organización de los Juegos Olímpicos de 2020.</p>',
  enterprises_infoEntrepreneurship: '<h3>Herramientas empresariales para lograr el éxito</h3> \n\n<p>La OIT brinda asistencia a personas y organizaciones para que adquieran, perfeccionen y mantengan las competencias y los conocimientos que precisan para fomentar las empresas sostenibles.</p>\n\n<p>Ello se aborda en varios de nuestros programas, en particular los dos siguientes:</p>\n\n<ul>\n<li>Inicie y mejore su negocio (IMESUN)</li>\n<li>Conozca su negocio (CSN)</li>\n</ul>',
  enterprises_visStartyourbusHeading: 'Comience su negocio',
  enterprises_visKnowyourbusHeading: 'Conozca su negocio',
  enterprises_visKnowyourbusBody: '¿Qué conocimientos empresariales desea adquirir?',
  enterprises_visStartyourbusBody: 'Uno de los mayores programas de formación en gestión empresarial del mundo',
  enterprises_visStartyourbusDetail1: '<h3>Uno de los mayores programas de formación en gestión empresarial del mundo</h3>\n\n<p>El programa “Inicie y mejore su negocio”, cuenta con una red de más de 65.000 formadores en más de 100 países, ha capacitado a más de 15 millones de empresarios, y es actualmente uno de los mayores programas de formación empresarial del mundo.</p>',
  enterprises_visKnowyourbusDetail1: '<h3>¿Qué conocimientos empresariales desea adquirir?</h3>\n\n<p>El programa “Conozca su negocio” promueve la iniciativa empresarial juvenil, y ayuda a jóvenes de 15 a 24 años a comprender mejor el mundo de los negocios. Contribuye a aumentar su concienciación sobre las oportunidades y los desafíos de la iniciativa empresarial y al empleo por cuenta propia.</p>',
  enterprises_infoHelpingbus: '<h3>Fomentar el éxito de las empresas</h3>\n\n<p>La OIT ofrece formación y asesoramiento para ayudar a las empresas a adoptar prácticas responsables, sostenibles e inclusivas de forma integral.</p>\n\n<p>Nuestros programas cubren todos los aspectos, desde asesoría para mejorar la productividad y las condiciones de trabajo hasta capacitación y servicios de apoyo para mujeres que desean iniciar y administrar sus negocios. Entre esos programas cabe destacar:</p>\n\n<ul>\n<li>My.COOP</li>\n<li>Promoción de Empresas Competitivas, Responsables y Sostenibles</li>\n<li>Desarrollo de la Iniciativa Empresarial de la Mujer</li>\n</ul>',
  enterprises_visAgriCoopHeading: 'Cooperativas agrícolas',
  enterprises_visAgriCoopDetail1: '<h3>My.COOP\n\n<p>En las cooperativas agrícolas, los agricultores aportan recursos de forma conjunta en ciertas áreas de actividad para reducir costos y aprovechar las economías de escala. My.COOP es un programa de formación de la OIT para gerentes de cooperativas agrícolas que desarrollan actualmente su actividad, y para aquellos que están interesados en hacerlo en el futuro.</p>',
  enterprises_visResponsibleHeading: 'Empresas responsables',
  enterprises_visResponsibleDetail1: '<h3>Promoción de Empresas Competitivas, Responsables y Sostenibles (SCORE)</h3>\n\n<p>Dirigido a pequeñas y medianas empresas de mercados emergentes que desarrollan su actividad en las cadenas de suministro a escalas mundial y local, tiene como objetivo ayudar a aumentar la productividad y mejorar las condiciones de trabajo. El programa SCORE abarca la colaboración en el lugar de trabajo, la gestión de la calidad y la seguridad y salud en el trabajo, entre otros aspectos.</p>',
  enterprises_visWomenentrepHeading: 'La iniciativa empresarial de la mujer',
  enterprises_visWomenentrepDetail1: '<h3>Desarrollo de la Iniciativa Empresarial de la Mujer (OIT-WED)</h3>\n\n<p>El programa ILO-WED proporciona capacitación y servicios de apoyo para mujeres, con una perspectiva de género que contribuye a su empoderamiento. Esto se logra al trabajar con los gobiernos, las organizaciones de empleadores y de trabajadores y los proveedores de servicios para mejorar sus servicios y la divulgación a las mujeres.</p>',
  enterprises_infoAddresslegal: '<h3>Retos jurídicos</h3>\n\n<p>Incluso las empresas mejor intencionadas pueden tener dificultades para armonizar su actividad con las normas internacionales del trabajo y propiciar relaciones laborales adecuadas.</p>\n\n<p>El servicio de la OIT de asistencia a empresas en relación con las normas internacionales del trabajo es útil al respecto. Es una herramienta legal confidencial  destinada a directivos y trabajadores de empresas que proporciona información y asesoramiento sobre una gran variedad de temas laborales, derivados de la Declaración EMN.</p>\n\n<p>Por ejemplo, varias empresas han recibido apoyo para luchar contra el trabajo infantil y el trabajo forzoso, así como para hacer frente a la discriminación y la desigualdad en el lugar de trabajo y mejorar la seguridad y la salud laborales.</p>',
  enterprises_infoRethinking: '<h3>Repensando la forma en la qué hacemos negocios</h3> \n\n<p>El desempleo, la exclusión, la desigualdad, las recesiones económicas, la pobreza y el calentamiento global, entre otros problemas actuales, nos obligan a repensar la manera en la que hacemos negocios.</p>\n\n<p>A raíz de ello han comenzado a surgir empresas sociales con objetivos económicos y sociales explícitos, con frecuencia relacionados con medio ambiente, como parte de una economía social y solidaria en auge.</p>\n\n<p>Esas empresas, a diferencia de las empresas con fines de lucro convencionales, están integradas habitualmente por asociaciones y cooperativas de base, asociaciones que promueven el comercio justo y ONG. Muchas son voluntarias y respaldan una causa específica, por ejemplo, los derechos de la mujer, por lo que cada vez son más influyentes a los efectos de formulación de políticas públicas a escala internacional.</p>',
  enterprises_calloutIntheeu: 'En la Unión Europea, la economía social y solidaria abarca alrededor del 10% de las empresas y el 6% del empleo; muchas de esas empresas son cooperativas.',
  enterprises_visWhyrcoops: '¿Por qué son importantes las cooperativas?',
  enterprises_visWhyrcoopsDetail1: '<h3>Cooperando por el trabajo decente</h3>\n\n<p>Las cooperativas constituyen uno de los ejes fundamentales de la economía social y solidaria. Su peculiaridad con respecto a las empresas sostenibles es que son de propiedad conjunta y están gestionadas por sus miembros de forma democrática.</p>\n\n<p>Las cooperativas operan en todos los sectores de la economía y generan 279 millones de empleos, o más, en todo el mundo. </p>',
  enterprises_visWhyrcoopsDetail2: '<p>Como se reconoce en la Recomendación de la OIT sobre la promoción de las cooperativas, 2002 (núm. 193), las cooperativas que son propiedad de sus miembros y se gestionan democráticamente contribuyen a crear empleo de calidad, movilizar recursos y fomentar la inversión, en particular con respecto a grupos desfavorecidos.</p>\n\n<p>Según estimaciones de las Naciones Unidas, el medio de vida de 3.000 millones de personas está mejor garantizado en el marco de las cooperativas,  gracias a la manutención alimentaria, la protección social, la energía, la salud y los servicios financieros que proporcionan.</p>\n\n<p>En la actualidad, las cooperativas representan del 3 al 3,5% del PIB mundial. Las 2.000 cooperativas de mayor tamaño de 56 países generan una facturación global de más de 2,5 billones de dólares.</p>',
  enterprises_calloutCooperatives: 'Las cooperativas contribuyen a cumplir el objetivo general de la OIT de crear trabajo decente para todos por medio del empleo productivo, la protección social y la observancia de derechos. También ofrecen a las personas un foro adecuado para manifestar inquietudes, organizarse y tomar parte en decisiones que repercutan en su vida.',
  enterprises_calloutTheilosupports: 'La OIT apoya la economía social y solidaria por medio de actividades de promoción, asociaciones, programas de formación y asesoramiento en materia de formulación políticas.',
  enterprises_calloutPositiveimpact: 'El impacto positivo de las empresas sostenibles constituirá un elemento primordial de las actividades de la OIT encaminadas a examinar el futuro del trabajo.',
  enterprises_calloutAfuture: 'Un futuro en el que todos deberíamos querer vivir.',
  enterprises_imageNuragroCaption: 'Trabajadores de la Asociación Nuragro de Colombia durante una sesión de formación de la OIT. © OIT',
  enterprises_imageNuragroAlt: 'Tres trabajadores de una plantación caminando entre plantas.',
  enterprises_imageLakumbaCaption: 'Una trabajadora de la Asociación La Kumba (Colombia) durante una sesión de formación de la OIT. © OIT',
  enterprises_imageLakumbaAlt: 'Mujer sosteniendo una canasta llena de fresas en un invernadero.',
  enterprises_imageTechnicianCaption: 'Técnico en la planta de producción de un fabricante internacional de detergentes para ropa en El Cairo, Egipto. © OIT/M. Crozet',
  enterprises_imageTechnicianAlt: 'Hombre con atuendo de trabajo de color azul, máscara protectora y gafas en una fábrica rodeado de maquinaria.',
  enterprises_imageSiybCaption: 'Formación en el marco del programa IMESUN en Burundi. © OIT',
  enterprises_imageSiybAlt: 'Una formadora sostiene la ilustración de una vendedora de fruta con un cliente.',
  enterprises_imageKabCaption: 'Participantes en una formación en el marco del programa CSN en Siria. © OIT',
  enterprises_imageKabAlt: 'Tres hombres y dos mujeres aprendices sentados alrededor de un escritorio deliberando sobre los documentos de sus carpetas.',
  enterprises_imageKhouryCaption: 'Lara Khoury, diseñadora de prendas de vestir, en su estudio de Beirut (Líbano). © OIT/J. Saad',
  enterprises_imageKhouryAlt: 'Mujer dibujando en una hoja de papel sobre una mesa. Trabaja rodeada de telas y revistas.',
  enterprises_imageMycoopCaption: 'Formación en el marco del programa My.Coop en Mongolia. © OIT',
  enterprises_imageMycoopAlt: 'Cuatro hombres y una mujer sentados en torno a un escritorio escribiendo en una gran cartulina.',
  enterprises_imageScoreCaption: 'Trabajadores de una fábrica de embalaje de naranjas en el marco del Programa SCORE de la OIT, Perú. © OIT',
  enterprises_imageScoreAlt: 'Cuatro trabajadores con chalecos protectores blancos y gorras clasificando naranjas en una cinta transportadora.',
  enterprises_imageWedCaption: 'Josepha participa en el programa de la OIT en Tanzania para el desarrollo de la iniciativa empresarial de la mujer y su empoderamiento económico. © OIT/D. Debru',
  enterprises_imageWedAlt: 'Mujer hablando con una persona sentada al otro lado de su escritorio.',
  enterprises_imageChildhuachipaCaption: 'Niño trabajador en una fábrica de ladrillos en Huachipa (Perú). © OIT/E. Gianotti',
  enterprises_imageChildhuachipaAlt: 'Niño con la ropa sucia y las manos embarradas.',
  enterprises_imageWomencoopCaption: 'Mujeres participantes en una cooperativa de Dar Es Salaam (Tanzania). © OIT/M. Crozet',
  enterprises_imageWomencoopAlt: 'Dos mujeres en un gallinero sosteniendo gallinas blancas en las manos.',
  enterprises_imageFarmerseedsCaption: 'Agricultores comprando semillas de una cooperativa en Kaolack (Senegal).\n© Banco Mundial / D. Van Leggelo-Padilla',
  enterprises_imageFarmerseedsAlt: 'Dos hombres de pie delante de varios sacos de semillas conversando mientras sostienen un papel y una calculadora en las manos.',
  enterprises_imagePalestinianCaption: 'Eman Abu Medeen, instructora palestina, en el Centro "País infantil", financiado por la OIT, en la aldea beduina Um Al-Nasser del norte de la Franja de Gaza. © S. Jadallah',
  enterprises_imagePalestinianAlt: 'Mujer de pie al lado de una muestra de juguetes y muñecas de madera hechos a mano.',
  enterprises_visStartyourbusDetail2: '<p>El Programa IMESUN incluye cuatro paquetes de formación relativos a todas las fases de desarrollo empresarial, en particular la de definición del concepto de negocio, y las de puesta en marcha, mejora y expansión del mismo. Abarca íntegramente los temas que guardan relación con la elaboración de planes de negocios, el control de existencias, la comercialización, el cálculo de costes, el mantenimiento de registros y la gestión de recursos humanos.</p>',
  enterprises_visKnowyourbusDetail2: '<p>En el marco del programa CSN se ha capacitado a lo largo del último decenio a más de 14.000 profesores y 3 millones de jóvenes en materia de competencias empresariales fundamentales, en particular sobre comunicación, negociación y planificación.</p> \n\n<p>La documentación del programa CSN se ha utilizado en centros de enseñanza secundaria públicos y privados y en instituciones de formación profesional y técnica, así como en centros de enseñanza superior. El programa CSN, implantado en 56 países, ha pasado a incluirse en los planes académicos nacionales de 18 de esos países.</p>',
  enterprises_visAgriCoopDetail2: '<p>En el marco de My.COOP se tratan una gran variedad de retos que afrontan los gestores de cooperativas. El programa se basa en el principio de que la mejora de la gestión da lugar a un aumento de la calidad y a una mayor eficiencia y eficacia en la prestación de servicios cooperativos. Permite proporcionar orientación sobre satisfacción de los miembros, oportunidades de negocio y temas sociales.</p>',
  enterprises_visWomenentrepDetail2: '<p>En los últimos 15 años, el programa OIT-WED ha impartido formación en gestión a más de 1,5 millones de mujeres, ha colaborado con más de 75 proveedores de servicios para atender mejor a las necesidades de la mujer y ha llevado a cabo 25 evaluaciones de países para ayudar a sus asociados nacionales a llevar a cabo planes de acción para lograr un entorno más propicio para el desarrollo de la capacidad empresarial de la mujer.</p>',
  enterprises_visResponsibleDetail2: '<p>Desde su puesta en marcha, el programa SCORE ha impartido formación a más de 1.400 pequeñas y medianas empresas de todo el mundo. Más de 300.000 trabajadores se han beneficiado de diversos avances empresariales, incluidos los esfuerzos desplegados para fomentar una cultura basada en el respeto, la confianza y la comunicación en el lugar de trabajo.</p>\n\n<p>Por otro lado, el programa SCORE ha contribuido a lograr un aumento de hasta el 50% en la productividad de las empresas participantes, y cuenta con un índice de satisfacción del 88%.</p>',
  enterprises_imageTokyoCaption: 'Guy Ryder, Director General de la OIT, junto a Toshiro Muto, Director Ejecutivo de los Juegos Olímpicos y Paralímpicos de Tokio 2020. © Uta Mukuo',
  enterprises_detailInclusive2: '<p>En Pakistán, la OIT forjó una asociación con el Gobierno y organizaciones de empleadores y de trabajadores para establecer prácticas empresariales sostenibles con el fin de erradicar el trabajo infantil en las empresas que producen los balones de fútbol en Sialkot, suministradoras de varias marcas internacionales.</p>',
  enterprises_visWhyrcoopsDetail3: '<p>Los bancos cooperativos representaron un motor clave de la recuperación económica después de la crisis financiera mundial, ya que cuentan con más de 215 millones de clientes y una cuota de mercado promedio de alrededor del 20%.</p>\n\n<p>Desde la adopción de la Recomendación núm. 193, casi 100 países han examinado, revisado o adoptado políticas o legislaciones relativas a las cooperativas. Esto ha contribuido asimismo a transformar la opinión sobre las cooperativas como modalidad empresarial viable.</p>',
  conditions_heading: 'Condiciones de trabajo',
  conditions_chapter: 'capítulo 7',
  conditions_title: '¿Cómo fue su día en el trabajo?',
  conditions_date: 'Descubra este capítulo el 27 de febrero de 2019',
  conditions_visTimeTitle: 'Tiempo de trabajo en el mundo',
  conditions_visTimeSelect: 'Buscar país',
  conditions_visTimeSharesLabel: 'Porcentaje de empleados',
  conditions_visTimeColour1: '<strong>Menos de 35 horas</strong> de trabajo semanales',
  conditions_visTimeColour2: '<strong>35-48 horas</strong> de trabajo semanales',
  conditions_visTimeColour3: '<strong>Más de 48 horas</strong> de trabajo semanales',
  conditions_visTimeNote: 'Datos de 2018 o últimos datos disponibles. Fuente: ILOSTAT',
  conditions_infoWhenwego: '<p>Cuando vamos a trabajar, esperamos poder ganar suficiente dinero para vivir bien, comprar comida, pagar nuestras facturas y hacer frente a situaciones difíciles como una enfermedad o la llegada de un bebé. </p>\n\n<p>Esperamos disponer de suficiente tiempo libre para descansar y estar con nuestra familia, e incluso contar con dinero extra que nos permita disfrutar de los pequeños placeres de la vida.</p>\n\n<p>Al llegar a nuestro lugar de trabajo, esperamos sentirnos seguros, sin que ponga en riesgo nuestra vida ni suframos lesiones o enfermedades que nos impidan trabajar y llegar a fin de mes, o que nos lleven a una situación de pobreza.</p>\n\n<p>En definitiva, esperamos encontrar condiciones de trabajo decentes.</p>',
  conditions_calloutTheseshouldnot: 'No deberían ser solo expectativas. \nSon nuestros derechos.',
  conditions_calloutManymillions: 'Muchos de nosotros podemos beneficiarnos de estos derechos gracias a una serie de Convenios de la OIT adoptados por los Estados Miembros a lo largo de los años, que abarcan la seguridad y la salud en el trabajo, el horario laboral, el salario mínimo y la protección de la maternidad, entre otros ámbitos. Sin embargo, millones de personas no gozan de estos derechos.',
  conditions_infoSafetyinworkplace: '<h3>Seguridad en el lugar de trabajo</h3>\n\n<p>Según  datos estadísticos de la OIT, cada año 2,78 millones de personas fallecen como resultado de la exposición a riesgos de seguridad y salud asociados al lugar de trabajo. Esto significa que más de cinco personas fallecen por minuto.</p>\n\n<p>Otros 374 millones de trabajadores son víctimas de accidentes laborales no mortales cada año.</p>\n\n<p>Los accidentes laborales, además de provocar sufrimiento personal, conllevan un notable costo económico para los trabajadores y sus familias, así como para los empresarios y la economía en su conjunto. Según diversas estimaciones, las lesiones, enfermedades y afecciones relacionadas con el trabajo, ya sean mortales o no, ocasionan un coste económico mundial de alrededor del 4% del PIB.</p>',
  conditions_infoIloflagship: '<p>El programa de referencia de la OIT “Acción global para la prevención en el ámbito de la seguridad y salud en el trabajo (OSH-GAP)” brinda apoyo a los Estados Miembros para reforzar las capacidades necesarias para mejorar la seguridad y la salud en el lugar de trabajo a nivel nacional.</p>\n\n<p>Trabajamos en más de 140 países para:</p>\n\n<ul>\n<li><mark id="conditions_infoIloflagship">Fomentar las inversiones públicas y privadas para evitar víctimas mortales, lesiones y enfermedades en el ámbito laboral.</mark></li>\n<li>Capacitar a inspectores de trabajo y compartir las mejores prácticas con empleadores y trabajadores para mejorar la seguridad y la salud en su lugar de trabajo.</li>\n<li>Desarrollar legislaciones eficaces y sistemas de recopilación de datos sobre seguridad y salud.</li>\n<li>Integrar la seguridad y la salud en los programas de enseñanza y formación de jóvenes trabajadores.</li>\n</ul>',
  conditions_infoHowfaryourmoney: '<h3>¿Para cuánto le alcanza el dinero?</h3> \n\n<p>Hay millones de personas que tienen dificultades para llegar a fin de mes. Si dispusieran de un salario mínimo, no se encontrarían en situación de pobreza. El salario mínimo puede contribuir a superar la pobreza y también a reducir las desigualdades entre hombres y mujeres. Por otro lado, complementa y fomenta diversas políticas, en particular, la negociación colectiva que se utiliza para fijar las condiciones de empleo y de trabajo.</p>\n\n<p>En más del 90% de los Estados Miembros de la OIT se ha fijado algún tipo de salario mínimo. La OIT colabora con los gobiernos para establecer salarios mínimos con arreglo a las necesidades de los trabajadores y de sus familias, y las de la economía en su conjunto.</p>\n\n<p>Estas son las áreas centrales de la labor de la OIT, que figuran en varios de sus Convenios, en particular el Convenio sobre la fijación de salarios mínimos, 1970 (núm. 131).</p>',
  conditions_detailVisionzero1: '<h3>Fondo Visión Cero</h3> \n\n<p>En 2013, 1137 trabajadores fallecieron en una fábrica de ropa en el complejo Rana Plaza en Bangladesh. A raíz de esta catástrofe, los líderes del G7 establecieron el nuevo fondo mundial "Fondo Visión Cero" para reforzar los esfuerzos de mejora de la seguridad y la salud en el trabajo en las cadenas mundiales de suministro.</p>',
  conditions_infoLimitinghours: '<h3>Limitación del exceso de horas de trabajo</h3>\n\n<p>La preocupación por el exceso de horas de trabajo influyó en el primer Convenio de la OIT, adoptado en 1919, que limitaba la cantidad de tiempo que los trabajadores debían pasar en su lugar de trabajo por día y por semana.</p>\n\n<p>En la actualidad, las normas de la OIT sobre el tiempo de trabajo están en el centro de la relación laboral.</p> \n\n<p>Estas normas cubren temas como la cantidad de horas de trabajo, los períodos de descanso necesarios y las vacaciones anuales.</p> \n\n<p>Por otro lado, las normas de la OIT contribuyen a lograr un equilibrio entre la vida laboral y la personal, y desempeñan un papel fundamental al alentar a las empresas a preservar la salud y la productividad de su mano de obra.</p>',
  conditions_vis1994: 'En 1994',
  conditions_infoJobparents: '<h3>Seguridad laboral para los padres</h3>\n\n<p>En ocasiones, la seguridad laboral de los trabajadores con hijos puede verse comprometida.</p>  \n\n<p>La OIT reconoce la protección de la maternidad como un derecho laboral fundamental, esencial para promover la salud y el bienestar de las madres y sus hijos. También contribuye a lograr la igualdad de género en el trabajo y promueve el trabajo decente para hombres y mujeres.</p>\n\n<p>La mayoría de los países han adoptado legislaciones sobre la protección de la maternidad en el trabajo. Estas medidas que apoyan a los trabajadores con responsabilidades familiares, incluidos los padres, son cada vez más en el mundo.</p>',
  conditions_vis38Percent: '<p class="bubble-percent">38%</p>',
  conditions_visCountriesmaternity: 'de los países otorgaban al menos 14 semanas  de licencia por maternidad',
  conditions_vis2013: 'en  2013',
  conditions_vis51Percent: '<p class="bubble-percent">51%</p>',
  conditions_calloutHoweverimprove: 'Para mejorar las condiciones laborales de todos los trabajadores, es necesario que más países ratifiquen los convenios de la OIT sobre la protección de la maternidad.',
  conditions_vis34: '34',
  conditions_visRatified183: 'países han ratificado el Convenio de la OIT sobre la protección de la maternidad al 1 de enero de 2019',
  conditions_visSeeratifications: 'Ver ratificaciones',
  conditions_infoNewways: '<h3>Nuevas formas de trabajo</h3> \n\n<p>La innovación tecnológica y la transformación de la estructura de trabajo tradicional están dando lugar a nuevas formas de trabajo.</p>\n\n<p>Estas incluyen el tranajo a tiempo parcial, trabajo temporal, trabajo interino a través de agencias de contratación, o lo que se conoce como el trabajo "gig”, es decir, proyectos a corto plazo que no vinculan al empleado con el empleador mediante una relación laboral tradicional. Se conocen como trabajos “atípicos” y plantean cuestiones en materia de condiciones laborales.</p>',
  conditions_carouselIsyourjob: '¿Su  trabajo es atípico?',
  conditions_carouselYesBody1: 'Sí, si trabaja únicamente por un período de tiempo limitado',
  conditions_carouselYesCaption1: 'Esto se denomina empleo temporal y es un trabajo atípico; abarca los contratos de duración determinada, los contratos basados en proyectos o tareas y el trabajo estacional u ocasional, incluido el trabajo jornalero.',
  conditions_carouselYesBody2: 'Sí, si trabaja menos horas que las personas que poseen un empleo comparable a tiempo completo',
  conditions_carouselYesCaption2: 'Se conoce como empleo a tiempo parcial y es un trabajo atípico. Permite a determinados trabajadores conjugar su empleo con el cuidado de los hijos o con la realización de estudios o cursos de formación. No obstante, en ocasiones la cantidad de horas de trabajo puede ser muy pequeña, o aleatoria, como en el caso de los contratos de cero horas, en los que el empleador no se ve obligado a establecer una cantidad mínima de horas.',
  conditions_carouselYesBody3: 'Sí, si le paga una empresa, pero trabaja para otra.',
  conditions_carouselYesCaption3: 'Se conoce como empleo multipartito y es un trabajo atípico. Con frecuencia tiene lugar cuando un trabajador percibe su remuneración de una empresa, por ejemplo, una empresa de trabajo temporal o un subcontratista, pero su labor la lleva a cabo para otra empresa en su lugar de trabajo. Por lo general, no se establece ninguna relación laboral entre el trabajador y la empresa a la que presta sus servicios.',
  conditions_carouselYesBody4: 'Sí, si trabaja como contratista independiente, pero lo supervisan como si fuera un empleado.',
  conditions_carouselYesCaption4: 'Se conoce como empleo encubierto y es un trabajo atípico. Si los trabajadores son supervisados por un superior como si fueran empleados, pese a haber sido contratados en calidad de contratistas independientes/autónomos, ello oculta el tipo real de la relación laboral con el fin de eludir la reglamentación laboral.',
  conditions_carouselYesBody5: 'Sí, si legalmente usted es independiente, pero económicamente dependiente',
  conditions_carouselYesCaption5: 'Se conoce como empleo dependiente por cuenta propia y constituye una zona gris. Este caso se produce cuando los trabajadores prestan servicios a una empresa en el marco de un contrato comercial, pero sus ingresos dependen en gran medida de muy pocos clientes.',
  conditions_calloutNonstdemp: 'El empleo atípico es una práctica generalizada tanto en los países industrializados como en los países en desarrollo, en la mayoría de los sectores y de las actividades de la economía.',
  conditions_callout150Countries: 'En más de 150 países, el 11% de los trabajadores del sector privado son empleados temporales. Si bien las mujeres representan menos del 40% del total de empleados, el 57% de los empleados a tiempo parcial son mujeres.',
  conditions_infoNonstdjobs: '<p>Los empleos atípicos pueden facilitar el acceso al mercado laboral y mejorar el equilibrio entre la vida laboral y la personal. No obstante, los trabajadores con empleo atípico pueden verse desprovistos de protección y seguridad social y tener menos oportunidades de formación y de desarrollo profesional.</p>\n\n<p>También participan en menor medida en la negociación colectiva y corren mayor riesgo de discriminación.</p>\n\n<p>Para muchos trabajadores, los contratos de duración determinada, el trabajo por proyectos o  estacional, el trabajo temporal y el trabajo a demanda conllevan una mayor inseguridad, y afectan de manera desproporcionada a las mujeres.</p>\n\n<p>No obstante, con independencia de si su empleo es típico o atípico, todos los trabajos deberían ser decentes.</p>',
  conditions_infoSourcing: '<h3>Abastecimiento y ventas a escala mundial</h3> \n\n<p>La globalización ha hecho que los negocios y el comercio sean cada vez más complejos. En la actualidad, la mayoría de las empresas se proveen o realizan sus ventas a escala mundial, o compiten con empresas que lo hacen.</p>\n\n<p>Significa que las empresas ahora tratan con una red de proveedores, fabricantes, almacenes, centros de distribución, mayoristas y minoristas.</p>\n\n<p>Ello constituye lo que se conoce como una cadena mundial de suministro y es una de las mayores transformaciones que ha tenido lugar en el mundo del trabajo a lo largo de los últimos 50 años.</p>  \n\n<p>Uno de los aspectos clave asociados al surgimiento de las cadenas mundiales de suministro es el que guarda relación con las condiciones de trabajo a lo largo de esa cadena.</p>',
  conditions_infoInmanycountries: '<p>En muchos países, las cadenas mundiales de suministro contribuyen a crear empleo y fomentan las oportunidades de desarrollo económico y social.</p>\n\n<p>Allí donde los empleadores respetan los reglamentos laborales y las normas internacionales del trabajo, el trabajo decente se ve favorecido, aumenta la productividad, se fomenta el desarrollo y los trabajadores pueden encontrar un empleo de mayor capacitación que ofrezca mejores salarios y condiciones de trabajo.</p>',
  conditions_calloutButpoor: 'No obstante, las malas condiciones de trabajo y las relaciones laborales deficientes en determinadas cadenas mundiales de suministro pueden tener consecuencias nefastas.',
  conditions_infoCollapseranaplaza: '<p>El derrumbe del edificio Rana Plaza en 2013 y los incendios en varias fábricas de Pakistán y Bangladesh en 2012 se cobraron la vida de más de 1500 personas y propiciaron un nuevo llamamiento a la acción mundial para promover condiciones de trabajo decentes en las cadenas mundiales de suministro.</p> \n\n<p><mark id="conditions_infoCollapseranaplaza">La OIT se encuentra en primera línea de esta acción.</mark></p>\n\n<p>A raíz de la catástrofe del Rana Plaza, más de 800.000 trabajadores de Bangladesh recibieron capacitación sobre medidas esenciales de seguridad y salud, y se prestó apoyo a las víctimas heridas. Se capacitó, también, a casi 300 supervivientes para que mejoraran sus medios de subsistencia, más de 3.000 personas recibieron orientación profesional y 66 personas recibieron asesoramiento psicosocial.</p>',
  conditions_detailBetterwork1: '<h3>Better Work</h3> \n\n<p>El Programa Better Work se basa en la colaboración entre la OIT y la Corporación Financiera Internacional (CFI), un miembro del Grupo del Banco Mundial. Su objetivo es mejorar las condiciones de trabajo en la industria del textil y promover el respecto de los derechos laborales de los trabajadores, al tiempo que se impulsa la competitividad de las empresas en el sector. Better Work reúne a todas las partes interesadas de la industria del textil.</p>',
  conditions_infoChallengesarise: '<p>Los desafíos surgen cuando las empresas toman decisiones que afectan las condiciones de trabajo, sin responsabilizarse directamente de las personas que llevan a cabo el trabajo.</p>\n\n<p>Las subcontratas pueden incumplir las normas laborales, lo que en determinados casos puede dar lugar a empleo informal, condiciones de trabajo deficientes o situaciones de trabajo forzoso o infantil.</p>\n\n<p>Debido a que el suministro de bienes y servicios es transfronterizo, la aplicación normativa se hace más difícil, no todos los gobiernos han podido hacer frente a la rápida transformación provocada por la exposición a la economía mundial y a las cadenas de suministro mundiales.</p>',
  conditions_calloutSolution: 'La solución: promover normas internacionales del trabajo, mejorar la aplicación de la legislación en vigor y fortalecer el diálogo social.',
  conditions_calloutThroughitswork: 'La OIT, a través de su trabajo en todas estas áreas, desempeña una función de primer orden para fomentar el trabajo decente en las cadenas mundiales de suministro.',
  conditions_infoTheilohelps: '<p>La OIT ayuda a los Estados Miembros a mejorar la seguridad y la salud en el lugar de trabajo, así como a establecer salarios mínimos y elaborar legislaciones para mejorar las condiciones laborales.</p> \n\n<p>La OIT ha demostrado, en el marco de diversas actividades de formación, intercambio de conocimientos y colaboración con asociados de los sectores público y privado, que se puede mejorar la vida de millones de trabajadores de las cadenas mundiales de suministro.</p>',
  conditions_calloutPromotingsafe: 'El fomento de condiciones de trabajo seguras y productivas ha constituido uno de los ejes de la labor de la OIT a lo largo del último siglo.',
  conditions_calloutFromthefactories: 'Tanto en las fábricas en las que surgió la revolución industrial, como en relación con los retos que plantean actualmente la economía de trabajos esporádicos en plataformas y las cadenas mundiales de suministro, garantizar condiciones de trabajo decente ha constituido siempre una prioridad y marca el camino a seguir para lograr empresas sostenibles en el futuro.',
  conditions_captionVietnam: 'Joven trabajador de la construcción sin equipo de seguridad adecuado, Hanoi (Viet Nam). © OIT/M. Fossat',
  conditions_imageConstructionAlt: 'Piernas de un obrero con sandalias sobre barras de un andamio situado a gran altura sobre el suelo.',
  conditions_imageFactoryCaption: 'Empleado de una quesería. Montevideo (Uruguay). © OIT/M. Fossat',
  conditions_imageFactoryAlt: 'Hombre con atuendo sanitario blanco, mascarilla, guantes y delantal industrial limpiando el suelo con una manguera.',
  conditions_imageMadagascarCaption: 'Trabajadoras exigiendo un aumento salarial y mejores condiciones de trabajo durante una huelga sin autorización sindical en una fábrica de prendas de vestir en Antananarivo (Madagascar). © OIT/M. Fossat',
  conditions_imageMadagascarAlt: 'Mujeres trabajadoras en el pasillo de una fábrica. Una de ellas sostiene un letrero de cartón que reza: "Aumenten nuestro salario".',
  conditions_imagePlatformCaption: 'Mujer trabajando desde casa con su hijo a su lado. © iStock',
  conditions_imagePlatformAlt: 'Mujer con gafas sentada en la mesa de la cocina utilizando una computadora. Un bebé está sentado en una sillita alta a su lado.',
  conditions_imageCateringCaption: 'Mujer trabajando en servicios de cáterin en Estados Unidos.  © J. Maida',
  conditions_imageCateringAlt: 'Mujer con vestimenta profesional sosteniendo una gran bandeja llena de canapés.',
  conditions_captionGarmentHaiti: 'Trabajadores de una fábrica de prendas de vestir en Haití. © OIT',
  conditions_imageHaitiAlt: 'Vista aérea de una fábrica con maquinaria de confección y tejidos de distintos colores.',
  conditions_captionGarmentBang: 'Trabajadores de una fábrica de prendas de vestir en Bangladesh. © OIT',
  conditions_imageBangladeshAlt: 'Mujeres con hiyab de color verde y máscaras sanitarias sentadas en una larga mesa trabajando con máquinas de coser.',
  conditions_captionMyanmarSafety: 'Un joven obrero de la construcción trabaja descalzo en Yangon (Myanmar). © OIT/M. Fossat',
  conditions_imageMyanmarAlt: 'Obrero de la construcción trabajando en un andamio. En la valla hay una pancarta que reza "Seguridad ante todo".',
  conditions_imageVisionCaption: 'El proyecto Fondo Visión Cero en Myanmar contribuye a mejorar la seguridad y la salud en el trabajo. © OIT',
  conditions_imageVisionAlt: 'Mujer con sombrero tradicional sentada en el campo junto a un montón de jengibre.',
  conditions_imageBetterworkCaption: 'Trabajadora en una fábrica de Better Work en Vietnam. Las fábricas de Better Work han recibido pedidos de mayor tamaño, mayor acceso a los compradores y precios más altos de los compradores principales a lo largo del tiempo como resultado de la participación en el programa. © OIT',
  conditions_imageBetterworkAlt: 'Una trabajadora de una fábrica de ropa en Vietnam sonriendo mientras trabaja.',
  conditions_detailBetterwork2: '<p>Como resultado de su participación en el Programa Better Work, las fábricas han mejorado paulatinamente sus condiciones de trabajo y han aumentado su productividad y rentabilidad.</p>\n\n<p>En la actualidad, el Programa abarca 1.450 fábricas en siete países que emplean a más de 1,9 millones de trabajadores. Además de prestar asesoramiento en fábricas, Better Work colabora con los gobiernos para mejorar las legislaciones laborales, así como con varias marcas internacionales, a fin de garantizar la sostenibilidad de sus avances.</p>\n\n<p>La visión del Programa es poner fin a la situación de pobreza de millones de personas proporcionándoles trabajo decente en el sector internacional del textil, así como empoderar a la mujer, fomentar la competitividad empresarial y promover un desarrollo económico inclusivo.</p>',
  conditions_detailVisionzero2: '<p>La OIT lidera la implementación del Fondo Visión Cero, se encarga de su administración y utiliza su amplia experiencia y sus conocimientos para promover las competencias, las estrategias y los instrumentos que permitan a gobiernos, empleadores y trabajadores mejorar la seguridad y la salud en las cadenas mundiales de suministro.</p>\n\n<p>En Myanmar, la OIT colaboró con los cultivadores de jengibre al brindarles información exhaustiva sobre la utilización segura de productos agroquímicos y ayudándoles a encontrar soluciones de mercado para la mejora de sus condiciones laborales.</p>',
  future_heading: 'El futuro del trabajo',
  future_chapter: 'capítulo 9',
  future_title: 'Mirar hacia el futuro',
  future_date: 'Descubra este capítulo el 29 de marzo de 2019',
  future_infoLookingtowards: '<p>El mundo del trabajo experimenta una profunda transformación. La globalización y la evolución tecnológica marcan nuevos caminos hacia la prosperidad, pero alteran las actuales formas de trabajo.</p>',
  future_visIceberg1: '<p>Los avances digitales y tecnológicos, que también incluyen las tecnologías de la información y comunicación, crean nuevas oportunidades para los trabajadores y las empresas,</p>\n\n<p style="font-weight: 600;">pero esto es solo la “punta del iceberg”.</p>',
  future_visIceberg2: '<p>El cambio climático, la transformación demográfica, la migración y los cambios en la organización del trabajo afectarán a sociedades, organizaciones, trabajadores y empresas en su conjunto.</p>\n\n<p>La demanda de determinados tipos de trabajo se verá alterada, otros desaparecerán, y muchos probablemente serán  muy distintos de los que conocemos hoy día.</p>',
  future_visIceberg3: '<p>La tendencia de esos cambios y los efectos que tendrán en el trabajo dependerán de nuestras políticas y acciones, en particular si nuestro objetivo es forjar el futuro del trabajo que queremos.</p>',
  future_visQuestions1: '¿De dónde vendrán los <span class="emphasis">trabajos del futuro</span> y cómo serán?',
  future_visQuestions2: '¿Qué depara el futuro a los <span class="emphasis">jóvenes</span> y qué competencias necesitarán?',
  future_visQuestions3: '¿Cómo podemos garantizar que todas las formas de trabajo sean <span class="emphasis">decentes</span>?',
  future_visQuestions4: '¿Cómo asegurarnos de que la tecnología mejore <span class="emphasis">nuestras capacidades</span> en lugar de disminuirlas?',
  future_calloutIloidentified: 'La OIT ha identificado varios sectores clave susceptibles de crear empleo en el futuro.',
  future_calloutExplorethem: 'A continuación se detallan las oportunidades emergentes:',
  future_visGreenjobsHeading: 'Empleos verdes',
  future_visGreenjobsDetail1: '<h3>Empleos verdes</h3>\n\n <p>Los empleos verdes son una de las formas de trabajo más recientes, surgidas en los últimos 20 años. Su objetivo es contribuir al medio ambiente, así como a su preservación y recuperación.</p>\n\n<p>Estos empleos se dan en sectores tan variados como la fabricación, la construcción, la moda, las energías renovables, la electrónica y la gestión de residuos.</p>',
  future_visGreenjobsDetail2: '<p>Los empleos verdes contribuyen a:</p>\n\n<ul>\n<li>aumentar la eficiencia energética</li>\n<li>limitar las emisiones de gases de efecto invernadero</li>\n<li>minimizar los residuos y la contaminación</li>\n<li>proteger y restaurar los ecosistemas</li>\n<li>facilitar la adaptación al cambio climático</li>\n</ul>\n\n<p>Según estimaciones de la OIT, la transición a una economía verde podría crear 60 millones de empleos en los próximos 15 años.</p>',
  future_visCareeconomyHeading: 'La economía del cuidado',
  future_visCareeconomyDetail1: '<h3>La economía del cuidado</h3>\n\n<p>El trabajo de cuidados incluye los servicios de salud, el cuidado de niños y niñas, la educación de la primera infancia, los cuidados dirigidos a las personas con discapacidad y los cuidados de larga duración, así como el cuidado de las personas de edad, son otros de los ámbitos que integran la economía del cuidado.</p>\n\n<p>Habida cuenta de los cambios demográficos en todo el mundo y del envejecimiento de la población en gran parte de los países desarrollados, la economía del cuidado ofrece unas de las mejores oportunidades para crear los empleo del futuro.</p>',
  future_visGigeconomyHeading: 'La "gig economy"',
  future_visGigeconomyDetail1: '<h3>La “gig economy”</h3> \n\n<p>La innovación tecnológica ofrece nuevas oportunidades para trabajar a distancia y en línea. En la llamada ‘gig economy’ o economía de trabajos esporádicos en plataformas, los trabajadores tienen varios empleos a corto plazo o contratos en línea con múltiples empleadores, en lugar de una relación laboral más tradicional y permanente entre empleador y empleado. Esto permite que los trabajadores obtengan ingresos y que las empresas organicen el trabajo de manera diferente.</p>',
  future_visGigeconomyDetail2: '<p>Trabajadores que, en condiciones habituales, podrían verse excluidos del mercado laboral, por ejemplo, por discapacidad, responsabilidades de cuidado o enfermedad, tienen más oportunidades de trabajar. Sin embargo, cabe destacar que las investigaciones realizadas por la OIT dan cuenta de que la remuneración que perciben muchos trabajadores de plataformas es inferior al salario mínimo.</p>\n\n<p>La inseguridad que experimentan estos trabajadores es preocupante, así como también lo son sus condiciones de trabajo. La OIT lleva a cabo varios estudios sobre las principales cuestiones y dificultades que plantean esas nuevas formas de trabajo, en particular:</p>\n\n<ul>\n<li>¿Cuál es la situación laboral de las personas inmersas en el trabajo "colaborativos" y el trabajo "a pedido", que compiten para realizar tareas puntuales para sus empleadores?</li>\n</ul>',
  future_visRuraleconomyHeading: 'La economía rural',
  future_visRuraleconomyDetail1: '<h3>La economía rural</h3>\n\n<p>El desarrollo rural siempre ha figurado en el programa de trabajo de la OIT. Desde 1919, la OIT ha adoptado más de 30 normas internacionales del trabajo que guardan relación directa con la agricultura y el desarrollo rural, en áreas como los derechos laborales, el empleo, la protección social y el diálogo social.</p>\n\n<p>La economía rural ofrece un gran potencial para crear empleo decente y productivo, y contribuye al desarrollo sostenible y al crecimiento económico.</p>',
  future_visRuraleconomyDetail2: '<p>Por otro lado, casi el 80% de las personas en situación de pobreza de todo el mundo viven en zonas rurales, por lo que todo avance que permita mejorar los medios de vida de las poblaciones rurales contribuirá en gran medida a mitigar la pobreza en 2030, plazo establecido para el cumplimiento de los Objetivos de Desarrollo Sostenible de la ONU.</p>\n\n<p>La agricultura seguirá siendo el principal sector económico en muchas zonas rurales. Por otro lado, es probable que las poblaciones rurales se beneficien del desarrollo de los sectores turístico y del ocio.</p>',
  future_visGlobalsupplyHeading: 'Cadenas mundiales de suministro',
  future_visGlobalsupplyDetail1: '<h3>Cadenas mundiales de suministro</h3>\n\n<p>La producción y distribución de bienes y servicios han pasado a ser cada vez más complejas. Antes, las empresas concentraban su producción en países o regiones específicas. En la actualidad, se distribuye a través de redes mundiales transnacionales cuyo objetivo es maximizar los beneficios y minimizar las pérdidas. </p>\n\n<p>Las cadenas mundiales de suministro han transformado la economía mundial a lo largo de las últimas tres décadas ya que han sido un motor de crecimiento y de creación de empleo, en particular en los países en desarrollo.</p>',
  future_visGlobalsupplyDetail2: '<p>Los empleos en las cadenas mundiales de suministro son tan variados como los países en los que se implementan y han permitido que cada vez más personas encuentren trabajo.</p>\n\n<p>Si bien muchos de esos trabajos podrían ser reemplazados por máquinas, especialmente en la industria ligera, varios estudios llevados a cabo por la OIT ponen de manifiesto que, de momento, esa alternativa podría no ser rentable en las regiones en desarrollo debido al costo comparativamente elevado de la maquinaria, el bajo costo de la mano de obra y  limitada capacidad de implantación de nuevas tecnologías. Sin embargo, ¿qué cabe esperar en el futuro?</p>',
  future_visRobotageHeading: 'La era de los robots',
  future_visRobotageDetail1: '<h3>La era de los robots</h3>\n\n<p>El aumento de la especialización y la sofisticación de la tecnología aceleran el ritmo de automatización en el trabajo y la demanda de robots industriales. Con índices de automatización que van del 10% y el 60% en algunos lugares de trabajo, inevitablemente se darán pérdidas de empleos.</p>\n\n<p>Ante este panorama un tanto pesimista, todavía hay esperanzas relacionadas con la transformación del empleo y la reorientación de las competencias de los trabajadores.</p>',
  future_visRobotageDetail2: '<p>Los sistemas robóticos deben conectarse a una red, mantenerse y actualizarse. Ello conllevará la creación de nuevos puestos de trabajo, especialmente empleos que requieran competencias sobre ciencia, tecnología, ingeniería y matemáticas (STEM).</p>\n\n<p>Por otro lado, los programadores de software y de código informático pueden llegar a ser los trabajadores manuales del futuro. En las cadenas de producción de las economías desarrolladas hay actualmente 14 robots, que requieren un mantenimiento periódico, por cada 1000 empleados. Se prevé que esa proporción aumente.</p>',
  future_calloutBrighterfuture: 'Un futuro más prometedor',
  future_infoSkillsweneed: '<h3>Competencias necesarias</h3>\n\n<p>La evolución del mundo del trabajo requerirá el desarrollo de nuevas competencias y aprendizaje continuado durante toda la vida. La automatización y la robotización, por ejemplo, aumentarán la demanda de conocimientos técnicos sobre resolución de problemas e innovación.</p>\n\n<p>También habrá una demanda cada vez mayor de "técnicas flexibles", en particular capacidad para comunicar eficazmente, trabajar en equipo, lograr soluciones creativas para resolver problemas, socializar eficazmente y adaptarse a situaciones que evolucionen rápidamente. Esas competencias traerán consigo beneficios específicos. En consecuencia:</p>\n\t\n<ul>\n<li>Los sistemas de enseñanza y formación deberán servir para dotar a la fuerza de trabajo de las capacidades y competencias que se necesiten en el futuro.</li>\n<li>Deberán tomarse medidas políticas para reforzar los vínculos entre las instituciones de formación y las empresas.</li>\n<li>Será necesario lograr un equilibrio entre la calidad de la formación y la satisfacción de la demanda de programas de capacitación más flexibles y de menor duración.</li>\n</ul>',
  future_infoOpportunity: '<h3>Una oportunidad para cerrar la brecha de género</h3>\n\n<p>La evolución del mundo del trabajo es una oportunidad para eliminar las barreras estructurales a la igualdad de género y para abordar la desigualdad en el lugar de trabajo y en nuestras sociedades.</p>\n\n<p>Las mujeres realizan la inmensa mayoría de los cuidados no remunerados y el trabajo doméstico en todo el mundo, lo que a menudo dificulta su participación en la fuerza laboral.</p>\n\n<p>Por lo tanto, la formalización del trabajo de cuidados representa una gran oportunidad para garantizar que más mujeres pasen a formar parte de la fuerza de trabajo.  La OIT colabora en más de 80 países a tal efecto.</p>\n\n<p>Cabe destacar también varios proyectos de la OIT cuyo objetivo es ayudar a las mujeres a acceder a programas educativos y de formación profesional de calidad para mejorar sus posibilidades de encontrar un empleo decente.</p>',
  future_visWomen: 'no trabajan ya que realizan tareas de cuidado no remuneradas',
  future_visMen: 'no trabajan ya que realizan tareas de cuidado no remuneradas',
  future_quoteKofiBody: '“La igualdad de género es más que un objetivo en sí mismo. Es una condición previa para enfrentar el desafío de reducir la pobreza, promover el desarrollo sostenible y construir un buen gobierno".',
  future_quoteKofiCaption: 'Kofi Annan, ex Secretario General de las Naciones Unidas y ganador del Premio Nobel de la Paz',
  future_infoNewforms: '<h3>Nuevos tipos de empresas</h3>\n\n<p>Las empresas constituyen el motor de nuestras economías y fomentan la prosperidad y el empleo. No obstante, existe una preocupación creciente por el hecho de que el énfasis en recompensar a inversores y acreedores se produzca en detrimento del medio ambiente y del bienestar de los trabajadores.</p>\n\n\n<p>El surgimiento de formas de trabajo alternativas pone de manifiesto que estas pueden desempeñar un papel clave para alcanzar los Objetivos de Desarrollo Sostenible de las Naciones Unidas, al contribuir a que millones de personas abandonen su situación de pobreza.</p>',
  future_infoThesebusinesses: '<p>Estos tipos de empresas incluyen cooperativas de trabajadores, mutualidades y empresas sociales, que dan respuesta de forma oportuna a las necesidades de los grupos de ciudadanos y de las comunidades locales. Según se desprende de varios estudios de la OIT:</p>\n\n<ul>\n<li>Es menos probable que estas empresas se deslocalicen o trasladen a otros territorios su actividad de producción, contribuyen tanto a crear empleo como a mantenerlo.</li> \n<li>Con frecuencia, estas empresas están en primera línea de los esfuerzos encaminados a regenerar las comunidades locales y rescatar a empresas en riesgo de quiebra.</li>\n<li>También son más eficaces al reunir recursos destinados a microempresas o trabajadores autónomos.</li>\n</ul>\n\n<p>En la Recomendación de la OIT sobre la promoción de las cooperativas (núm. 193) se proporcionan orientaciones para desarrollar este tipo de empresas.</p>',
  future_infoModernizing: '<p>Modernización de los sistemas de protección social</p>\n\n<p>Es previsible que aumente la demanda sobre los sistemas de protección social a la luz de los crecientes niveles de desigualdad y el papel cambiante del trabajo en la vida de las personas.</p>\n\n<p>La pérdida de empleos hará más necesarios los seguros de desempleo. El desajuste entre las competencias de los trabajadores y las que necesitará la economía en el futuro requerirá de programas públicos de empleo. El aumento de la esperanza de vida ejercerá una mayor presión sobre los sistemas de pensiones.</p> \n\n<p>Para enfrentar estos desafíos, ciertas innovaciones recientes tanto en los países en desarrollo como en los desarrollados muestran que los sistemas de protección social pueden adaptarse y acercarse a la cobertura universal.</p>\n\n<p>Por ejemplo, varios países han introducido mecanismos para garantizar la protección social de trabajadores autónomos e informales, incluidos los que desarrollan su labor a través de plataformas digitales.</p>\n\n<p>Las comunicaciones digitales y los procedimientos automatizados se utilizan para garantizar que los procedimientos administrativos sean más efectivos y rentables.</p>',
  future_infoHumancentred: '<h3>Un programa orientado al ser humano</h3>\n\n<p>Con objeto de sentar las bases para los próximos 100 años, la OIT estableció la Comisión Mundial sobre el Futuro del Trabajo, integrada por jefes de Estado, destacados académicos y representantes de las principales organizaciones de trabajadores y de empleadores.</p>\n\n<p>Dicha Comisión llevó a cabo un minucioso examen del futuro del trabajo, a fin de ofrecer un punto de partida a nivel analítico para fomentar la justicia social en el siglo XXI. Por otro lado, aboga por un programa de trabajo orientado al ser humano que haga hincapié en el contrato social, al considerar las personas y el trabajo que realizan el elemento central de la política socioeconómica y de la actividad empresarial.</p>\n\n<p>Dicho programa consta de tres ejes de acción, que en su conjunto deberían servir para promover el desarrollo, la equidad y la sostenibilidad en aras de las generaciones actuales y las futuras, en particular mediante un aumento de la inversión en:</p>\n\n<ul>\n<li>las capacidades humanas</li>\n<li>Ias instituciones relativas al trabajo</li>\n<li>el trabajo decente y sostenible</li>\n</ul>',
  future_infoFuturewewant: '<h3>El futuro que queremos</h3>\n\n<p>Desde la fundación de la OIT hace un siglo, las normas internacionales del trabajo han sido la base del trabajo decente y de la justicia social.</p>\n\n<p>El diálogo social y el tripartismo han contribuido a fomentar el mayor nivel de desarrollo económico de la historia de la humanidad, y cabe pensar que su pertinencia sea aún mayor en el futuro.</p>\n\n<p>De todos nosotros depende que, con todos los cambios que se vislumbran, mantengamos el compromiso de colaboración para encontrar soluciones a los desafíos que pueda deparar el futuro.</p>',
  future_calloutParadox: 'Una paradoja que se da en el mundo de hoy es que incluso en los lugares con mayor desempleo, existe un gran potencial de trabajo.',
  future_calloutSickelderly: 'Los niños y los jóvenes necesitan profesores y programas de formación. Los edificios precisan renovación y las ciudades han de ser más ecológicas. Hay que respetar el medio ambiente y abordar el cambio climático.',
  future_infoFulfilling: '<h3>Revindicar los cuatro pilares del trabajo decente</h3>\n\n<p>En otras palabras, seguirá habiendo una demanda de trabajo y de trabajadores en empleos en los que la interacción humana es importante y en los que podamos dar más rienda suelta a nuestra creatividad y satisfacer mejor nuestras aspiraciones.</p>\n\n<p>De ahí que los cuatro pilares del trabajo decente -creación de empleo, protección social, derechos en el trabajo y diálogo social-, sean hoy tan pertinentes como hace 100 años, y que sean la clave de un futuro del que todos nos sintamos orgullosos.</p>',
  future_infoNext100Years: '<h3>Los próximos 100 años</h3>\n\n<p>¿Quién podría haber predicho hace un siglo el mundo en el que vivimos hoy? Un mundo en el que se ha puesto fin a la situación de pobreza de millones de personas y en el que se dispone de información ilimitada con sólo pulsar un botón. Un mundo en el que miles de millones de personas han oído hablar de los derechos humanos, y en el que la palabra "igualdad" ha cobrado un nuevo significado para una gran cantidad de mujeres y hombres de todo el mundo.</p>\n\n<p>Sin embargo, todavía queda mucho por hacer para cumplir nuestro sueño de lograr un mundo con paz universal y duradera basada en la justicia social.</p>',
  future_calloutHistory: 'La historia ha puesto de manifiesto que los retos que enfrentamos solo pueden superarse cuando trabajamos de manera conjunta. Si anteponemos el bien común a intereses particulares, no hay nada que no podamos lograr.',
  future_calloutInthisspirit: 'La OIT afronta su segundo siglo de existencia con este anhelo de esperanza. Lo invitamos a forjar con nosotros el futuro que todos deseamos, en el que haya justicia social y trabajo decente para todos en el mundo.',
  future_imageIndustrobotsCaption: 'Robots industriales en la fábrica Gestamp Automoción en Abrera (España). © AFP/P. Barrena',
  future_imageIndustrobotsAlt: 'Robots industriales de color blanco y azul dispuestos en círculo para montar varios componentes de un producto mientras este gira en el centro.',
  future_imageAerialCaption: 'Vista aérea al aterrizar en el aeropuerto de Yangon (Myanmar). ©OIT/M. Fossat',
  future_imageAerialAlt: 'Perspectiva aérea de un mosaico formado por campos, carreteras y un río.',
  future_imageChildrenCaption: 'Niños jugando en una guardería en la provincia de Phu Tho (Viet Nam). © OIT',
  future_imageChildrenAlt: 'Una niña y un niño juegan con bloques de plástico de colores.',
  future_imageMarineCaption: 'Una investigadora marina traza mapas de arrecifes de coral en las Seychelles. © ONU Mujeres / Ryan Brown',
  future_imageMarineAlt: 'Mujer buceando en superficie realizando anotaciones en su libreta bajo el agua.',
  future_imageNurseCaption: 'Una enfermera vacuna a un niño contra la poliomielitis en el centro de atención sanitaria primaria Howard Karagheusian en Beirut (Líbano). © Banco Mundial/Dominic Chavez',
  future_imageNurseAlt: 'Enfermera sosteniendo la cara de un niño en una mano y un medicamento en la otra en una sala de consultas.',
  future_imageManworkshomeCaption: 'Hombre trabajando desde casa. El surgimiento de las plataformas de trabajo digital en línea ha dado lugar a una de las mayores transformaciones del mundo del trabajo a lo largo de la última década. © iStock',
  future_imageManworkshomeAlt: 'Un hombre sentado junto a la mesa de su cocina con una computadora y varios documentos.',
  future_imageTwofarmersCaption: 'Dos agricultores trabajando en el campo en Viet Nam. La promoción del trabajo decente en la economía rural es primordial para erradicar la pobreza. © OIT/M. Fossat',
  future_imageTwofarmersAlt: 'Dos agricultores con sombreros vietnamitas trabajando en un campo con plantas mientras emplean herramientas agrícolas.',
  future_imageGarmentCaption: 'Trabajadoras en fábricas de confección en Jordania, uno de los países en los que se ha implementado el programa "Better Work", cuyo objetivo es mejorar las condiciones de trabajo en las empresas de confección y fomentar su competitividad. © OIT',
  future_imageGarmentAlt: 'Trabajadoras sentadas en filas paralelas mientras utilizan máquinas de coser. En el centro de cada mesa hay fardos de tela roja amontonada.',
  future_imageRobotsiranCaption: 'Robots fabricando un coche en la planta automovilística Iran Khodro, al oeste de Teherán, 2008. © AFP/Behrouz Mehri',
  future_imageRobotsiranAlt: 'Chispazos producidos mientras máquinas robóticas de color naranja ensamblan el bastidor metálico plateado de un automóvil en una planta producción.',
  future_imageChildrencodeCaption: 'Niños de 7 a 13 años de edad aprendiendo a codificar. Antananarivo (Madagascar). © AFP/Rijasolo',
  future_imageChildrencodeAlt: 'Hilera de niños observando la pantalla de computadoras portátiles con un maestro en el fondo, en un autobús transformado.',
  future_imageSchoolgirlsCaption: 'Alumnas en Java Occidental (Indonesia). © OIT/A. Mizra',
  future_imageSchoolgirlsAlt: 'Cuatro niñas de pie, una al lado de la otra, leyendo libros.',
  future_imageDomesticCaption: 'Trabajadora doméstica en Filipinas. Las trabajadoras y los trabajadores domésticos comprenden una gran parte de la mano de obra global en el empleo informal y constituyen uno de los grupos de trabajadores más vulnerables. © OIT/J. Aliling',
  future_imageDomesticAlt: 'Mujer sentada en una mesa jugando con un bebé colocado enfrente de ella en una sillita alta.',
  future_imageKofiCaption: 'Kofi Annan, ex Secretario General de la ONU, en la Conferencia sobre la mujer en África y desarrollo económico celebrada en Addis Abeba en 1998. © OIT',
  future_imageEveldoCaption: 'Eveldo, de la comunidad indígena Bribri, es miembro de la Asociación de Pequeños Productores de Talamanca, una de las mayores cooperativas de Costa Rica. © AFP/H. Retamal',
  future_imageEveldoAlt: 'Hombre en una plantación trabajando con una planta situada sobre una mesa en una maceta.',
  future_imageWomencoopCaption: 'Miembros de una cooperativa femenina en Tanzania. En muchos países la participación de la mujer en cooperativas es mucho más amplia que la de su fuerza laboral en su conjunto. © OIT/M. Crozet',
  future_imageWomencoopAlt: 'Tres mujeres sentadas en círculo escribiendo en un cuaderno. Otras mujeres permanecen sentadas a su alrededor.',
  future_imageFlowersellerCaption: 'Vendedora de flores en el mercado de flores de Cuenca (Ecuador). La mayoría de las personas que trabajan en la economía informal carecen de protección social y de derechos laborales. © OIT/P. Virot',
  future_imageFlowersellerAlt: 'Anciana sentada en la calle vendiendo macetas con flores.',
  future_imageOfficialCaption: 'Presentación oficial del informe de la Comisión Mundial sobre el Futuro del Trabajo a los mandantes de la OIT y a los representantes de la comunidad diplomática, enero de 2019. © OIT',
  future_imageOfficialAlt: 'Cyril Ramaphosa, Presidente de la República de Sudáfrica; Guy Ryder, Director General de la OIT y Deborah Greenfield, Directora General Adjunta de Políticas de la OIT, sentados frente a la sala de reuniones, delante de una pancarta en la que reza "Comisión Mundial sobre el Futuro del Trabajo".',
  future_imageSyrianCaption: 'Trabajadora refugiada siria en Jordania. La OIT ayuda a trabajadores a lograr un empleo en el sector agrícola en el marco de las actividades del organismo para dar respuesta a la crisis de refugiados. OIT/L. Abu Sha\'ireh',
  future_imageSyrianAlt: 'Mujer en un campo de plantas de gran altura sosteniendo una  caja de tergopol que contiene judías cosechadas.',
  future_visOfWomen: 'de mujeres',
  future_visOfMen: 'de los hombres',
  future_visCareeconomyDetail2: '<p>Según se desprende de datos estadísticos de la OIT, se podrían crear unos 269 millones de puestos de trabajo si, para 2030, se duplica la inversión en educación, salud y trabajo social.</p>',
  future_visGigeconomyDetail3: '<ul>\n<li>¿Cómo pueden garantizarse los derechos fundamentales de los trabajadores de la economía de plataformas?</li>\n<li>¿Qué se necesita para asegurar que esos derechos se respeten?</li>\n<li>¿Cómo pueden los trabajadores de la economía de plataformas tener sus intereses representados colectivamente para que puedan negociar mejores salarios y condiciones de trabajo?</li>\n<li>¿Cómo pueden regularse las condiciones mínimas de empleo (como el salario mínimo)?</li>\n<li>¿Cómo pueden los trabajadores de la economía de plataformas tener acceso a una protección social adecuada?</li>\n<li>¿En qué medida repercute el desarrollo de estas modalidades de trabajo a escala mundial en los esfuerzos para garantizar el trabajo decente?</li>\n</ul>',
  future_visRuraleconomyDetail3: '<p>Los cambios de las estructuras demográficas en las zonas rurales (migración de jóvenes a las ciudades para trabajar o estudiar, mientras personas de edad y familias se trasladan a las zonas rurales) repercutirá asimismo en la prestación de servicios públicos.</p>\n\n<p>La población de las zonas de rurales envejecerá a un ritmo más rápido que el de las zonas urbanas, lo que probablemente contribuirá a un aumento de la demanda de empleos de cuidado fuera de las zonas metropolitanas. El sector de cuidados presenta un gran potencial de creación de empleo en el futuro.</p>\n\n<p>El trabajo decente en la economía rural fue una de las cuestiones prioritarias establecidas por la OIT en 2014 y lo seguirá siendo a medida que la Organización comienza su segundo siglo.</p>',
  future_visGlobalsupplyDetail3: '<p>Los costos de las máquinas disminuyen con el tiempo, lo que puede hacer viable que se reubique gran parte de esta producción. Ello podría conllevar la reestructuración de las cadenas mundiales de suministro, a medida que las regiones en desarrollo pierden puestos de trabajo, cuya actividad pasaría a ser realizada por máquinas en regiones desarrolladas. La pérdida de puestos de trabajo y la competencia por los que seguirán existiendo podrían mermar los niveles salariales y las condiciones de trabajo.</p>\n\n<p>De ahí que la OIT considere cada vez más necesario:</p>\n\n<ul>\n<li>Formular políticas relativas al mercado de trabajo que aborden esa transformación</li>\n<li>Formular políticas económicas que propicien la diversificación en nuevos sectores económicos</li>\n<li>Promover las inspecciones de trabajo para garantizar condiciones de trabajo adecuadas</li>\n\n</ul>',
  future_visGlobalsupplyDetail4: '<p>La Conferencia Internacional del Trabajo de 2017 adoptó un informe sobre el trabajo decente en las cadenas mundiales de suministro, que dio lugar a la elaboración de un plan de acción para velar por el cumplimiento en las mismas de las normas sobre trabajo decente.</p>' };
module.exports = { interface_loading: 'loading',
  interface_headphones: 'Better with headphones',
  interface_title: 'Our story, your story',
  interface_share: 'share',
  interface_fb: 'facebook',
  interface_twitter: 'twitter',
  interface_youtube: 'youtube',
  interface_on: 'on',
  interface_off: 'off',
  interface_start: 'hold spacebar to start',
  interface_scrollDown: 'Scroll down',
  interface_description: 'The International Labour Organization (ILO) celebrates 100 years of advancing social justice and promoting decent work.',
  interface_news: 'News & Events',
  interface_fraudAlert: 'Fraud alert',
  interface_disclaimer: 'Disclaimer',
  interface_privacyPolicy: 'Privacy policy',
  interface_copyright: 'Copyright and permissions',
  interface_ilo: 'International Labour Organization (ILO)',
  interface_ilo100: 'ILO 100',
  interface_ourStoryDescription: 'Embark on an interactive journey through the past, present and future of the International Labour Organization, the oldest specialized agency of the United Nations.  Advancing social justice and promoting decent work since 1919.',
  interface_about: 'About',
  interface_credits: 'Credits',
  interface_mobileTitle: 'Our story, <br>your story',
  interface_mobileStart: 'Press to start',
  interface_html5VideoError: 'Your browser does not support HTML5 video',
  interface_previous: 'Previous',
  interface_next: 'Next',
  interface_seePhoto: 'See this photo',
  interface_seeVideo: 'Watch this video',
  interface_nextChapter: 'Next chapter',
  interface_discoverChapter: 'Discover this chapter on',
  interface_noOptions: 'No results',
  interface_learnMore: 'Learn more',
  home_title: 'The <span class="ilo">ILO</span> Centenary',
  home_subtitle: 'Advancing social justice, promoting decent work',
  home_news: 'News & Events',
  home_ourStory: 'Our story, your story',
  home_newsSubtitle: 'Find out how we\'re celebrating the Centenary',
  home_ourStorySubtitle: 'Explore our history and mission',
  home_ourStoryCta: 'Now live!',
  home_newsCta: 'See More',
  home_description: 'In 2019, the ILO will celebrate its 100th anniversary. The Centenary will be an opportunity to celebrate the ILO’s achievements and reaffirm its position as the authoritative global organization for the world of work.',
  home_socialMedia: 'In 2019, the @ILO celebrates its Centenary. 100 years of advancing social justice and promoting decent work. #ILO100 https://www.ilo.org/100',
  home_pageTitle: 'The ILO Centenary',
  home_ourStoryCta2: 'EXPLORE',
  home_ilcTitle: '',
  intro_heading: 'As the ILO celebrates its 100th anniversary, embark on a journey through its past, present and future.',
  intro_title: 'Our story, your story',
  intro_buttonStart: 'Start discovering',
  intro_buttonDates: 'See launch dates',
  intro_tableCaption: 'Hear the launch dates for the chapters of Our Story, Your Story',
  intro_chapters: 'Chapters',
  intro_launchDate: 'Launch dates',
  intro_aboutIntro: '<p>In 2019, the International Labour Organization (ILO) marks 100 years of advancing social justice and promoting decent work.</p>\n\n<p>This website takes users on a multimedia journey through the organization\'s past, present and future as it prepares to take on the challenges of tomorrow.</p>\n\n<p>To find out more about how the ILO is celebrating its Centenary visit the <a href="https://www.ilo.org/100/news/en">ILO Centenary News and Events page</a>.</p>',
  intro_aboutTitle: 'About',
  intro_aboutDescription: 'The ILO100 website commemorates 100 years of advancing social justice and promoting decent work. It would not have been possible without the generous support of our constituents and partners.',
  intro_aboutResourcesTitle: 'More resources',
  intro_aboutCreditsTitle: 'Credits',
  intro_aboutResTripartism1: '<a href="https://www.ilo.org">ILO Website</a>',
  intro_aboutResFuture1: '<a href="https://www.ilo.org/global/topics/future-of-work/WCMS_569528/lang--en/index.htm">Global Commission on the Future of Work</a>',
  intro_aboutResTripartism2: '<a href="https://www.ilo.org/global/about-the-ilo/history/lang--en/index.htm">History of the ILO</a>',
  intro_aboutCreditsH1: 'Story, photography and video',
  intro_aboutCreditsP1: 'ILO Department of Communications',
  intro_aboutCreditsH2: 'Other photos and videos',
  intro_aboutCreditsH3: 'Creative direction and design',
  intro_aboutCreditsH4: 'Web development',
  intro_aboutCreditsH5: 'Music and sound design',
  intro_aboutCreditsH6: 'Special thanks',
  intro_aboutCreditsP2: 'AFP • FDR Library • Jessica Maida • Lisa Kristine • Library of Congress • Maxime Fossat • Unsplash • Wikicommons',
  intro_aboutCreditsP3: 'ILO Department of Communications<br>with ElkanoData',
  intro_aboutCreditsP4: 'ILO Department of Communication<br>with Squareball Digital',
  intro_aboutCreditsP5: 'ElkanoData<br>with Extreme Music (Record Labels)',
  intro_aboutCreditsP6: 'The ILO100 website commemorates 100 years of advancing social justice and promoting decent work. It would not have been possible without the generous support of our constituents and partners.\n\n<br><br>\n\nBernice Antoine (youth activist)  •  Valentina Auneme (hotel receptionist)  •  Michelle Bachelet (United Nations High Commissioner for Human Rights)  •  Sreela Das Gupta (diversity and inclusion specialist)  •  Carling Dumulot Sr. (Aeta indigenous leader)  •  Zainab Ecumba (domestic worker)  •  Apolonia Fontes (pensioner)  •  Ulf Gustafsson (mine worker and trade unionist)  •  Zainab Ahmed Hamza (jewellery designer)  •  Alison Hart (online freelance worker)  •  Shinichi Kondo (Director, Kinrou Hospital)  •  Yukinori Kusaka (Professor Emeritus, University of Fukui)  •  Dao Thuy Ly (unemployed worker)  •  Zeinab Mahmoud (entrepreneur)  •  Marina Mejia (entrepreneur)  • Zin Mar Myint (garment factory worker)  •  Narufumi Suganuma (Professor, Kochi Medical School)  •  Francis Phiri (sign language instructor)  •  Vishani Ragobeer (youth activist)  •  Nicolás Gurría Sánchez (student)  •  Laurel Sprague (Special Advisor, Community, UNAIDS)  •  Aya Tanimura (film director)  •  Afonsu de Jesus Vidal (pensioner)   • Zhang Yanyan (China Enterprise Confederation)\n\n<br><br>\n\nA very special thanks to all ILO colleagues who helped make this website possible',
  intro_dateChap1: '09 January',
  intro_dateChap2: '16 January',
  intro_dateChap3: '30 January',
  intro_dateChap4: '6 February',
  intro_dateChap5: '13 February',
  intro_dateChap6: '21 February',
  intro_dateChap7: '27 February',
  intro_dateChap8: '13 March',
  intro_dateChap9: '1 April',
  intro_post: 'Embark on an interactive journey through the past, present and future of the @ILO--celebrating 100 years of advancing social justice and promoting decent work. http://www.ilo.org/en #ilo100',
  intro_aboutResTripartism3: '<a href="https://www.ilo.org/actemp/lang--en/index.htm">Bureau for Employers\' Activities (ACTEMP)</a>',
  intro_aboutResRights1: '<a href="https://www.ilo.org/global/standards/introduction-to-international-labour-standards/conventions-and-recommendations/lang--en/index.htm">What are International Labour Standards, Conventions and Recommendations?</a>',
  intro_aboutResRights2: '<a href="https://www.alliance87.org/">Alliance 8.7</a>',
  intro_aboutResRights3: '<a href="https://www.ilo.org/global/about-the-ilo/how-the-ilo-works/departments-and-offices/governance/fprw/lang--en/index.htm">The ILO Fundamental Principles and Rights at Work Branch</a>',
  intro_aboutResTripartism4: '<a href="https://www.ilo.org/actrav/lang--en/index.htm">Bureau for Workers’ Activities (ACTRAV)</a>',
  intro_aboutResEquality1: '<a href="https://www.equalpayinternationalcoalition.org/">EPIC: the Equal Pay International Coalition</a>',
  intro_aboutResEquality2: '<a href="https://www.ilo.org/global/topics/indigenous-tribal/lang--en/index.htm">Indigenous and tribal peoples ILO topic portal</a>',
  intro_aboutResEquality3: '<a href="https://www.ilo.org/global/topics/hiv-aids/lang--en/index.htm">HIV and AIDS ILO topic portal</a>',
  intro_aboutResEmployment1: '<a href="https://www.decentjobsforyouth.org/">The Global Initiative on Decent Jobs for Youth</a>',
  intro_aboutResEmployment2: '<a href="https://www.skillsforemployment.org/KSP/en/index.htm">Skills for Employment Knowledge Sharing Platform</a>',
  intro_aboutResEmployment3: '<a href="https://www.ilo.org/ilostat/faces/wcnav_defaultSelection;ILOSTATCOOKIE=R-wfbFBlo0oFiXPV-X-uScWMUKkEUyQ18RILGxzS2kO8HhTHCNYu!1595421183?_afrLoop=284774499866132&_afrWindowMode=0&_afrWindowId=null">ILOSTAT: Labour statistics</a>',
  intro_aboutResProtection1: '<a href="https://www.social-protection.org/gimi/gess/Wspr.action">World Social Protection Report Data 2017-2019</a>',
  intro_aboutResWork1: '<a href="https://www.ilo.org/global/topics/labour-law/lang--en/index.htm">Labour law ILO topic portal</a>',
  intro_aboutResConditions1: '<a href="https://betterwork.org/">Better Work</a>',
  intro_aboutResConditions2: '<a href="https://www.ilo.org/wcmsp5/groups/public/---ed_dialogue/---lab_admin/documents/projectdocumentation/wcms_572474.pdf">Vision Zero Fund</a>',
  intro_aboutResConditions3: '<a href="https://www.ilo.org/global/topics/wages/minimum-wages/lang--en/index.htm">ILO Minimum Wage Policy Guide</a>',
  intro_aboutResEnterprises1: '<a href="https://www.ilo.org/empent/areas/business-helpdesk/lang--en/index.htm">ILO Helpdesk for Business on International Labour Standards</a>',
  intro_aboutResEnterprises2: '<a href="https://www.ilo.org/empent/units/boosting-employment-through-small-enterprise-development/eese/lang--en/index.htm">Enabling Environment for Sustainable Enterprises (EESE)</a>',
  intro_aboutResEnterprises3: '<a href="http://www.knowaboutbusiness.org/">Know About Business (KAB)</a>',
  intro_aboutResEnterprises4: '<a href="http://mmw.itcilo.org/en/home">Making Microfinance Work!</a>',
  intro_aboutResEnterprises5: '<a href="https://www.ilo.org/empent/areas/start-and-improve-your-business/lang--en/index.htm">Start and Improve Your Business Programme (SIYB)</a>',
  intro_aboutResEnterprises6: '<a href="https://www.itcilo.org/en/areas-of-expertise/rural-development/my-coop-manging-your-agricultural-cooperative">My.COOP</a>',
  intro_aboutResEnterprises7: '<a href="https://www.ilo.org/empent/Projects/score/lang--en/index.htm">Sustaining Competitive and Responsible Enterprises (SCORE)</a>',
  intro_aboutResEnterprises8: '<a href="https://www.ilo.org/empent/areas/womens-entrepreneurship-development-wed/lang--en/index.htm">Women\'s Entrepreneurship Development Programme (WED)</a>',
  intro_aboutResEnterprises9: '<a href="https://www.ilo.org/empent/areas/mne-declaration/lang--en/index.htm">Tripartite Declaration of Principles concerning Multinational Enterprises and Social Policy (MNE Declaration)</a>',
  intro_aboutResFuture2: '<a href="https://www.ilo.org/global/topics/sdg-2030/lang--en/index.htm">Decent work and the 2030 Agenda for sustainable development</a>',
  intro_aboutResEquality4: '<a href="https://www.ilo.org/global/topics/disability-and-work/lang--en/index.htm">Disability and work ILO topic page</a>',
  intro_aboutResEquality5: '<a href=" http://www.businessanddisability.org/">ILO Global Business and Disability Network</a>',
  tripartism_title: 'Laying the foundations of social justice',
  tripartism_heading: 'Tripartism and social dialogue',
  tripartism_chapter: 'chapter 1',
  tripartism_ilo: 'international labour organization',
  tripartism_infoboxIlo: '<p>The International Labour Organization was born in a time of turmoil, of rapid social and technological change. </p>\n\n<p>In a world struggling to rebuild and rebound from the ravages of war, the ILO was created in the belief that social justice is essential to universal and lasting peace.</p>',
  tripartism_socialJustice: 'social justice',
  tripartism_keysQuote: '“When these three keys turn together, when governments, employers and workers are able to come together, doors open and social justice advances.”',
  tripartism_guyRyder: 'Guy Ryder, ILO Director-General',
  tripartism_calloutWorld1919: 'the world in 1919',
  tripartism_infoWw1: '<p>The First World War left behind countries in ruins, people traumatized by war and national economies devastated. Maps were redrawn and the voices of those who had worked day and night behind the front were clamouring to be heard.</p>\n\n<p>The century preceding the war had been one of profound economic and social change in Europe and the United States. Industrialization had led to an unprecedented growth of the economic power of European nations and to increasingly fierce competition amongst them, driven in part by the sometimes dehumanizing changes taking place in the world of work.</p>',
  tripartism_calloutVersailles: 'the treaty of versailles',
  tripartism_infoVersailles: '<p>In the aftermath of the First World War, growing disenchantment with massive inequality and poor conditions of work created a Europe on the brink of revolution.</p>\n\n<p>Workers demanded that the peace settlement should include measures to ensure fairer working conditions, promoted through international labour legislation and trade union rights.</p>',
  tripartism_whatIsSocialJustice: 'What is social justice?',
  tripartism_infoUnrest: '<h3>"Unrest so great that the peace and harmony of the world are imperilled"</h3>\n\n<p>These demands were so insistent and the social situation so explosive that one of the main concerns of the Peace Conference was about "…unrest so great that the peace and harmony of the world are imperilled…".</p>\n\n<p>This statement, which opens the Preamble to the ILO Constitution, clearly casts the Organization in a major role in the maintenance of peace.</p>',
  tripartism_calloutIloCreated: 'The ILO – bringing together governments, workers\' and employers\' representatives – was therefore created in 1919 as Part XIII of the Versailles Peace Treaty, which ended World War I.',
  tripartism_quoteWildDreamBody: '“I well remember that in those days the ILO was still a dream. To many it was a wild dream. Who had ever heard of governments getting together to raise the standards of labor on an international plane?”<br><br>“Wilder still was the idea that the people themselves who were directly affected – the workers and the employers of the various countries – should have a hand with government in determining these labor standards.”',
  tripartism_quoteWildDreamCaption: 'Franklin Delano Roosevelt was president of the United States of America when it joined the ILO in 1934.',
  tripartism_calloutWildDream: 'To many it was a wild dream.',
  tripartism_timeline1969Heading: 'ILO awarded the Nobel Peace Prize on its 50th anniversary',
  tripartism_timeline1969Para: '“There are few organizations that have succeeded to the extent that the ILO has, in translating into action the fundamental moral idea on which it is based.… Working earnestly and untiringly, the ILO has succeeded in introducing reforms that have removed the most flagrant injustices in a great many countries.” Mrs. Aase Lionaes, Chairman of the Nobel Committee of the Norwegian Parliament.',
  tripartism_timeline1919Heading: 'Drafting of the ILO Constitution',
  tripartism_timeline1919Para: 'Between January and April, the Labour Commission set up by the Peace Conference meets in Paris and then in Versailles to write the ILO Constitution. The first International Labour Conference, bringing together delegations from 40 countries, is held in Washington, D.C. in October-November 1919.',
  tripartism_timeline1920Heading: 'International Labour Office opens in Geneva',
  tripartism_timeline1920Para: 'The ILO is established in Geneva, Switzerland, in the summer of 1920, with France\'s Albert Thomas as the first Director of the International Labour Office, the Organization\'s permanent Secretariat.',
  tripartism_timeline1936Heading: 'First ILO Regional Conference in Santiago, Chile',
  tripartism_timeline1944Heading: 'The Declaration of Philadelphia',
  tripartism_timeline1946Heading: 'The ILO joins the United Nations',
  tripartism_timeline1946Para: 'The ILO becomes the first specialized agency of the newly formed United Nations. This establishes the ILO as one of the central fora for debate throughout the Cold War.',
  tripartism_timeline1947Heading: 'First ILO Asian Regional Conference in New Delhi, India',
  tripartism_timeline1947Para: 'Delegates from 18 countries and territories meet to strengthen ILO activities in Asia, at a time of great change in the region.',
  tripartism_timeline1964Heading: 'The Declaration against Apartheid',
  tripartism_timeline1964Para: 'The International Labour Conference unanimously adopts the Declaration concerning the Policy of Apartheid, condemning the South African Government\'s racial policy. In 1990, Nelson Mandela attends the ILC, where he pays tribute to the ILO for its contribution to the struggle against apartheid.',
  tripartism_timeline2014Heading: 'ILO International Training Centre in Turin, Italy celebrates its 50th anniversary',
  tripartism_timeline1982Heading: 'ILO promotes trade union freedom in Eastern Europe',
  tripartism_timeline1982Para: 'The ILO gives its full support to the legitimacy of Solidarność in Poland, where Lech Walesa leads a strike that launches the first independent, self-governing trade union in the then Eastern bloc, and opens the door to the eventual return to democracy.',
  tripartism_timeline1998Heading: 'The ILO Declaration on Fundamental Principles and Rights at Work',
  tripartism_timeline1998Para: 'Member countries of the ILO commit to respect and promote rights and principles relating to freedom of association, forced labour, child labour and discrimination - whether or not they have ratified the relevant Conventions.',
  tripartism_timeline1999Heading: 'Launch of the Decent Work Agenda',
  tripartism_timeline1999Para: 'Decent Work sums up the aspirations of people in their working lives. The Agenda’s four pillars are promoting jobs and enterprises, extending social protection, promoting social dialogue and guaranteeing rights at work. Equality of opportunity and treatment for all women and men unifies these four pillars.',
  tripartism_timeline2008Heading: 'The Declaration on Social Justice for a Fair Globalization',
  tripartism_timeline2008Para: 'The Declaration promotes decent work through an integrated approach aimed at four strategic objectives: employment creation, social protection, social dialogue, and fundamental principles and rights at work. It promotes social dialogue and tripartism as the most appropriate methods for translating economic development into social progress, and social progress into economic development.\n\nThe same year, the global financial crisis takes hold, becoming the worst economic downturn since the Great Depression.',
  tripartism_timeline2009Heading: 'The Global Jobs Pact',
  tripartism_timeline2009Para: 'Faced with the prospect of prolonged unemployment, poverty and inequality, the International Labour Conference adopts the Global Jobs Pact, aimed at stimulating economic recovery, generating jobs and providing protection to working people and their families.',
  tripartism_timeline2015Heading: 'Decent Work at the centre of the Sustainable Development Goals',
  tripartism_timeline2015Para: 'During the UN General Assembly in September 2015, the four pillars of the Decent Work Agenda – standards and rights at work, employment creation and enterprise development, social protection and social dialogue – become integral elements of the 2030 Agenda for Sustainable Development.',
  tripartism_timeline2019Heading: 'ILO Centenary: Shaping a future that works for all',
  tripartism_timeline2019Para: 'The International Labour Organization, the oldest UN specialized agency, celebrates its 100th anniversary.',
  tripartism_infoLifeIndustry: '<h3>Life in industrialized countries was very different to what it is today</h3>\n\n<p>The level and extent of poverty, inequality and discrimination were much greater. It was not unusual to find children working in factories and fields. Workplace accidents and deaths were common.</p>',
  tripartism_timeline1926Heading: 'A monitoring system for ILO Conventions',
  tripartism_timeline1926Para: 'The Committee of Experts on the Application of Conventions and Recommendations is established to hold countries accountable to the ILO Conventions they have ratified.',
  tripartism_timeline1929Heading: 'The beginning of the Great Depression',
  tripartism_timeline1929Para: 'ILO work is directly linked to finding solutions to the Great Depression, the overriding issue of the time.',
  tripartism_timeline1936Para: 'The first-ever labour conference of American member States reaffirms the idea of Universality - that the actions of the ILO must correspond with the needs of all the peoples throughout the world, whatever social or economic regime exists in their countries.',
  tripartism_timeline1939Heading: 'The Second World War begins',
  tripartism_timeline1939Para: 'As Switzerland is surrounded by Axis forces, the ILO\'s operational centre is temporarily moved to Montreal, Canada, in August 1940.',
  tripartism_timeline1944Para: 'The ILO adopts the Declaration of Philadelphia, which reasserts that labour is not a commodity, freedom of expression and of association are essential to sustained progress, and poverty anywhere constitutes a danger to prosperity everywhere, making the ILO relevant for the challenges of the post-war years.',
  tripartism_timeline1948Heading: 'Adoption of the fundamental ILO Convention on freedom of association',
  tripartism_timeline1948Para: 'The Freedom of Association and Protection of the Right to Organise Convention (No. 87) sets forth the right for workers and employers to establish and join organizations of their own choosing without needing authorization.',
  tripartism_timeline1949Heading: 'Adoption of the fundamental ILO Convention on collective bargaining',
  tripartism_timeline1949Para: 'The Right to Organise and Collective Bargaining Convention (No. 98) states that workers shall be protected against acts of anti-union discrimination, and enshrines the right to collective bargaining.',
  tripartism_timeline1952Heading: 'The Andean Indian Programme',
  tripartism_timeline1952Para: 'The first large-scale technical cooperation programme, led by the ILO in cooperation with other UN agencies, is launched. Covering seven Andean countries, its aim is to improve the social and economic conditions of indigenous peoples. It lasts until 1972.',
  tripartism_timeline1955Heading: 'First ILO European Regional Conference, Geneva',
  tripartism_timeline1955Para: '“This conference’s significance lies chiefly in the fact that it shows that the countries of Europe now declare themselves ready to look for solutions to the social problems posed by the more complete economic integration of the continent.” ILO Director-General, David A. Morse',
  tripartism_timeline1960Heading: 'First ILO African Regional Conference in Lagos, Nigeria',
  tripartism_timeline1960Para: 'The largest regional conference ever organized by the ILO takes place in a special context: the accession to independence of many new African States, which gives the conference great resonance internationally.',
  tripartism_timeline2014Para: 'The ITC runs training, learning and capacity development services for governments, employers\' and workers\' organizations, and other national and international partners in support of decent work. The Turin centre has trained over 80,000 people from 172 countries since it opened in 1964.',
  tripartism_timeline1976Heading: 'Adoption of ILO Convention on tripartism and labour standards',
  tripartism_timeline1976Para: 'The Tripartite Consultation (International Labour Standards) Convention (No. 144) sets forth that employers and workers shall be represented equally on any bodies through which ILO consultations are undertaken, and consultations shall take place at least once every year.',
  tripartism_timeline1977Heading: 'New programme to improve working conditions and the environment',
  tripartism_timeline1977Para: 'The International Programme for the Improvement of Working Conditions and Environment (PIACT) is designed to promote and support actions aiming at "making work more human". The programme is concerned with improving the quality of working life through prevention of occupational accidents and diseases, and "a greater concern for human element in the transfer of technology”.',
  tripartism_timeline1992Heading: 'New programme to eliminate child labour',
  tripartism_timeline1992Para: 'The ILO launches the International Programme on the Elimination of Child Labour (IPEC), the most far-reaching programme ever to eliminate child labour, which, according to ILO statistics, has contributed to removing over 86 million children from child labour situations worldwide.',
  tripartism_timeline2002Heading: 'Establishment of the World Commission on the Social Dimension of Globalization',
  tripartism_timeline2002Para: 'Twenty-six key figures - including Heads of State, business leaders, trade unions and Nobel Laureates - examine the process of globalization through the eyes of ordinary people. It is the first attempt at finding common ground on one of the most controversial and divisive subjects of our time.',
  tripartism_quoteSocJusBody1: '“Overcoming poverty is not a task of charity, it is an act of justice.”',
  tripartism_quoteSocJusBody2: '“Until the great mass of the people shall be filled with the sense of responsibility for each other\'s welfare, social justice can never be attained.”',
  tripartism_quoteSocJusCaption1: 'Nelson Mandela, former President of South Africa, Nobel Peace Prize laureate in 1993',
  tripartism_quoteSocJusCaption2: 'Helen Keller, American author and political activist',
  tripartism_quoteSocJusBody3: '“When you deprive people of their right to live in dignity, to hope for a better future, to have control over their lives, when you deprive them of that choice, then you expect them to fight for these rights.”',
  tripartism_quoteSocJusCaption3: 'Queen Rania Al Abdullah of Jordan',
  tripartism_quoteSocJusBody4: '“Another essential to a universal and durable peace is social justice.”',
  tripartism_quoteSocJusCaption4: 'Arthur Henderson, former leader of the UK Labour Party, Nobel Peace Prize laureate in 1934',
  tripartism_quoteSocJusBody5: '“Peace cannot exist without justice, justice cannot exist without fairness, fairness cannot exist without development, development cannot exist without democracy, democracy cannot exist without respect for the identity and worth of cultures and peoples.”',
  tripartism_quoteSocJusCaption5: 'Rigoberta Menchú, Guatemalan human rights activist, Nobel Peace Prize laureate in 1992',
  tripartism_quoteSocJusBody6: '“Education is the key to empowerment and sustainability, education is a right which is the key to the rest of the rights of life in society. Education is the key to social justice.”',
  tripartism_quoteSocJusCaption6: 'Kailash Satyarthi, Indian children’s rights activist, Nobel Peace Prize laureate in 2014',
  tripartism_infoFounders: '<h3>The founders of the ILO</h3>\n\n<p>…were convinced that there was an essential link between international peace and social justice within countries. This link was so important that an organization dealing with labour matters needed to be set up for the promotion and protection of world peace.</p>',
  tripartism_visSocJustTitle: 'The keys to social justice',
  tripartism_visSocJustHeading1: 'How the ILO works to achieve social justice',
  tripartism_visSocJustBody1: '<p>The ILO is the only international organization in which governments do not have exclusive voting power. When setting standards and policies, employers’ and workers’ representatives have an equal voice with governments in its decision-making processes. This concept is known as “tripartism”.</p>\n\n<p>In 1919, the decision to give “non-government” representatives a right to participate in international conferences was an entirely radical suggestion. In fact, even after the founding of the ILO, it would be many years, even decades, before most countries practiced tripartism at the national level.</p>\n\n<p>This unique arrangement gives the ILO the advantage of incorporating “real world” knowledge about employment and labour issues into its work.</p>\n\n<p>Based on this successful example, some 85% of ILO member States have since created tripartite institutions to deal with labour-related matters.</p>',
  tripartism_visSocJustHeading2: 'Social dialogue',
  tripartism_visSocJustBody2: '<p>This democratic process, whereby the people affected by decisions in the world of work should have a voice in the decision-making and implementation processes, is known as social dialogue.</p> \n\n<p>It takes many forms including information exchange, consultation and negotiation between workers, employers and governments. This is based on the right to freedom of association, which allows workers and employers to form and join organizations of their own choosing, and the right to collectively negotiate wages and other conditions of employment. Social dialogue works best when it is based on transparency, honesty, mutual respect and understanding. </p>\n\n<p>In the workplace, social dialogue can improve working conditions and contribute to economic performance, and it is a strong driver of stability, equity, productivity and sustainable growth.</p>\n\n<p>At the national level, social dialogue and tripartism act as guardians of participatory democracy. They work together to guarantee and promote fundamental principles and rights at work. They ensure that workers benefit fairly from the economy, that workplaces are safe, and that globalization is good for everyone. Social dialogue and tripartism are essential for sustainable development, social justice and peace.</p>',
  tripartism_visSocJustHeading3: 'Workers\' and employers\' organizations',
  tripartism_visSocJustBody3: '<p>Sound industrial relations remain a common and formidable challenge for both workers’ and employers’ organizations around the world.</p>\n<p>As social dialogue is critical to its work, the ILO helps improve the effectiveness of these organizations so they can better support and represent their members. The ILO therefore works closely with worker and employer representatives who, along with government representatives, sit on the Governing Body of the ILO and help set its agenda. </p>\n<p>Through workplace cooperation, the ILO remains continually relevant in the world of work and the ever-changing challenges it faces, such as introducing new technologies, promoting innovation and securing safe working environments.</p>',
  tripartism_calloutDistinctHeading: 'The ILO is also distinct from other intergovernmental organizations in another important respect – in the international labour standards that it adopts and the way it supervises their implementation.',
  tripartism_calloutDistinctBody: 'These international treaties spell out the actions to be taken and the principles to be respected by the countries that ratify them. They are a principal means of ILO action and influence in the world of work and help create a level playing field in which countries, companies and workers compete fairly.',
  tripartism_timeline1919Caption: '1st International Labour Conference, Washington DC, USA, 1919. © ILO',
  tripartism_timeline1920Caption: 'Albert Thomas, first Director of the ILO. © ILO',
  tripartism_timeline1926Caption: 'ILO Governing Body of 1926, appoints a technical committee, later called the Committee of Experts on the Application of Conventions and Recommendations (CEACR), to monitor the implementation of International Labour Standards. © ILO',
  tripartism_timeline1929Caption: 'Unemployed workers wait in line during the Great Depression. New York, USA, 1930. © Library of Congress',
  tripartism_timeline1936Caption: 'First Labour Conference of American member States of the ILO,  Santiago, Chile, 1936. © ILO',
  tripartism_timeline1939Caption: 'A group of ILO officials and their spouses wait to board ship in Lisbon, Portugal, September 1940, as the ILO moves to Canada during the war. © ILO',
  tripartism_timeline1944Caption: 'ILO Director Edward  Phelan (seated, right) signs the the ILO’s historic Declaration of Philadelphia at the White House, Washington DC, on 17 May 1944, in the presence of US President, Franklin Roosevelt (seated, left).  © ILO',
  tripartism_timeline1946Caption: 'ILO Director, Edward Phelan (left) and UN Secretary-General, Trygve Lie (right),  signs an agreement by which the ILO becomes the first specialized agency of the newly-formed United Nations. © ILO',
  tripartism_timeline1947Caption: 'First ILO Asian Regional Conference, New Delhi, India, 1947. © ILO',
  tripartism_timeline1948Caption: 'ILO staff at the International Labour Conference in San Francisco, USA, 1948, where the Freedom of Association and Protection of the Right to Organise Convention (No. 87) was adopted. © ILO',
  tripartism_timeline1949Caption: 'Edward Phelan (left), 4th Director of the ILO and David Morse (right), 5th Director-General of the ILO. Geneva, Switzerland, 1949. © ILO',
  tripartism_timeline1952Caption: 'Andean Indian Programme, Peru. © ILO',
  tripartism_timeline1955Caption: 'Max Petitpierre, President of the Swiss Confederation, addresses the first European Regional Conference with 5th ILO Director-General David Morse (left), and Roberto Ago, Chairperson of the ILO Governing Body (right). © ILO',
  tripartism_timeline1960Caption: 'David Morse, 5th ILO Director-General, arriving in Lagos, Nigeria, for the first African Regional Conference. © ILO',
  tripartism_timeline1964Caption: 'The ILO was one the first international organizations to impose sanctions on South Africa over its apartheid policies. More than 30 years later, at the 77th ILC, Nelson Mandela thanked the ILO for supporting the struggle against apartheid. 1990. © ILO',
  tripartism_timeline2014Caption: 'Every year, people from across the world come to the ILO\'s International Training Centre in Turin to take part in its seminars, workshops and courses. © Davide Bellucca',
  tripartism_timeline1969Caption: 'ILO Director-General David Morse receives the Nobel Peace Prize on behalf of the ILO from Aase Lionaes, Chair of the Nobel Committee of the Norwegian Parliament, at the University of Oslo, 10 December 1969. © ILO',
  tripartism_timeline1976Caption: 'Delegates of the 61st session of the ILC in UN assembly hall, Geneva, Switzerland, 1976. © ILO',
  tripartism_timeline1977Caption: 'Posters on occupational health and safety in Mauritania. © ILO',
  tripartism_timeline1982Caption: 'The ILO gives its support to the independent trade union Solidarnosc, led by Lech Walesa, who later became Polish President. © ILO',
  tripartism_timeline1992Caption: 'ILO creates International Programme on the Elimination of Child Labour (IPEC). © ILO/E. Gianotti',
  tripartism_timeline1998Caption: 'Adoption of the ILO Declaration on Fundamental Principles and Rights at Work at the 86th session of the ILC, Geneva, Switzerland. © ILO',
  tripartism_timeline1999Caption: 'ILO launches the Decent Work Agenda. Unemployed Zimbabweans in Harare, Zimbabwe, look for work. 7 June 2001. © AFP/Alexander Joe',
  tripartism_timeline2002Caption: 'World Commission on the Social Dimension of Globalization. From left to right, Benjamin William Mkapa, President of Tanzania, Tarja Halonen, President of the Republic of Finland and Juan Somavia, Director-General of the ILO. © ILO/M. Crozet',
  tripartism_timeline2008Caption: 'Opening session of the 97th ILC, Geneva, Switzerland, 2008 at which the Declaration on Social Justice for a Fair Globalization is adopted. © ILO',
  tripartism_timeline2009Caption: 'Speech by Juan Somavia, Director-General of the ILO, during the Opening Session of the ILO Summit on the Global Jobs Crisis. Geneva, Switzerland, 15 June 2009. © ILO',
  tripartism_timeline2015Caption: 'Ban Ki-moon, the eighth Secretary-General of the UN, addresses the ILC. © ILO',
  tripartism_timeline2017Caption: 'Guy Ryder, ILO Director-General, speaking at the launch of the ILO Global Commission on the Future of Work. Geneva, Switzerland, 21 August 2017. ©ILO/M. Crozet',
  tripartism_timeline2019Caption: 'Group photo of ILO Regional Office for Asia Pacific celebrating the upcoming ILO Centenary. © ILO',
  tripartism_visSocJustTripartism: 'Tripartism',
  tripartism_visSocJustGovernments: 'Governments',
  tripartism_visSocJustEmployers: 'Employers',
  tripartism_visSocJustWorkers: 'Workers',
  tripartism_imageThreekeysCaption: 'Three keys, representing governments, employers and workers, opened the gates to the ILO’s new headquarters in Geneva, Switzerland, at the building’s  inauguration in 1926. © ILO',
  tripartism_imageThreekeysAlt: 'Three keys in the lock of the gate of Villa Rappard in Geneva, Switzerland.',
  tripartism_imageGirlcottonCaption: 'A young girl spinning cotton at a mill in Texas, USA, 1913. The fight against child labour is one of the ILO’s key priorities.  © Library of Congress',
  tripartism_imageFactoriesCaption: 'Work in factories of the early 20th-century was often grueling and poorly paid. Children were a common sight on the factory floors of Europe and North America. © ILO',
  tripartism_imageProtestCaption: 'Protest in Europe, 1919. Across Europe, the immediate post-war years were a period of unrest, strikes and protests.  © British Pathé',
  tripartism_imageCommissionCaption: 'The Commission on International Labour Legislation, meeting during the Paris Peace Conference, February-March 1919. The Commission drafted  the ILO Constitution, which became part of the Treaty of Versailles. © ILO',
  tripartism_imageCommissionAlt: 'Group of men standing on a staircase outside a building. At the front is Samuel Gompers, the Chair of the Commission.',
  tripartism_imageInaugurationCaption: 'Inauguration of ILO headquarters, Geneva, Switzerland,1926. © ILO',
  tripartism_imageRooseveltCaption: 'Franklin Roosevelt, 32nd president of the United States. The US joined the ILO during his administration. © AFP (Audio source: National Archive of the US)',
  tripartism_imageFirstilcCaption: '1st International Labour Conference, Washington DC, USA, 1919. © ILO',
  tripartism_imageAlbertthomasCaption: 'Albert Thomas, first Director of the ILO. © ILO',
  tripartism_imageGbtwentysixCaption: 'ILO Governing Body of 1926, appoints a technical committee, later called the Committee of Experts on the Application of Conventions and Recommendations (CEACR), to monitor the implementation of International Labour Standards. © ILO',
  tripartism_imageGbtwentysixAlt: 'Group of delegates sitting in a meeting room.',
  tripartism_imageUnemployedCaption: 'Unemployed workers wait in line during the Great Depression. New York, USA, 1930. © Library of Congress',
  tripartism_imageFirstchileCaption: 'First Labour Conference of American member States of the ILO,  Santiago, Chile, 1936. © ILO',
  tripartism_imageFirstchileAlt: 'Group of delegates walking out the building. At the front is Harold Butler, the second Director-General of the ILO.',
  tripartism_imageShiplisbonCaption: 'A group of ILO officials and their spouses wait to board ship in Lisbon, Portugal, September 1940, as the ILO moves to Canada during the war . © ILO',
  tripartism_imageShiplisbonAlt: 'Three men and three women facing the camera in front of a large boat with the American flag on it.',
  tripartism_imagePhelandofpCaption: 'ILO Director Edward  Phelan (seated, right) signs the the ILO’s historic Declaration of Philadelphia at the White House, Washington DC, on 17 May 1944, in the presence of US President, Franklin Roosevelt (seated, left).  © ILO',
  tripartism_imagePhelandofpAlt: 'Three men, including Roosevelt and Phelan, sitting around a desk. Behind them stands two men and a woman, Frances Perkins.',
  tripartism_imagePhelanlieCaption: 'ILO Director, Edward Phelan (left) and UN Secretary-General, Trygve Lie (right),  signs an agreement by which the ILO becomes the first specialized agency of the newly-formed United Nations. © ILO',
  tripartism_imagePhelanlieAlt: 'Two men sitting at a desk side by side, each signing the agreement.',
  tripartism_imageFirstasiaconfCaption: 'First ILO Asian Regional Conference, New Delhi, India, 1947. © ILO',
  tripartism_imageFirstasiaconfAlt: 'Delegates of the Regional Conference and Jawaharlal Nehru, the first Prime Minister of India, standing in a room.',
  tripartism_imageIlcsanfranCaption: 'ILO staff at the International Labour Conference in San Francisco, USA, 1948. © ILO',
  tripartism_imageIlcsanfranAlt: 'Men and women standing next to each other in front of a building under a banner that says “International Labour Conference”.',
  tripartism_imagePhelanmorseCaption: 'Edward Phelan (left), 4th Director of the ILO and David Morse (right), 5th Director-General of the ILO. Geneva, Switzerland, 1949. © ILO',
  tripartism_imagePhelanmorseAlt: 'Two men are standing and looking at each other.',
  tripartism_imageAndeanCaption: 'Andean Indian Programme, Peru. © ILO',
  tripartism_imageAndeanAlt: 'Indigenous workers and ILO experts at a construction site in Chucuito, Peru.',
  tripartism_imagePetitpierreCaption: 'Max Petitpierre, President of the Swiss Confederation, addresses the first European Regional Conference with David Morse, 5th ILO Director-General (left), and Roberto Ago, Chairperson of the ILO Governing Body (right). © ILO',
  tripartism_imagePetitpierreAlt: 'Two men are sitting behind a desk with microphones. The man in the middle is standing and addressing the audience.',
  tripartism_imageMorsenigeriaCaption: 'David Morse, 5th ILO Director-General, arriving in Lagos, Nigeria, for the first African Regional Conference. © ILO',
  tripartism_imageMorsenigeriaAlt: 'David Morse and delegates descending the airplane stairs.',
  tripartism_imageMandelaCaption: 'The ILO was one the first international organizations to impose sanctions on South Africa over its apartheid policies. More than 30 years later, at the 77th ILC,, Nelson Mandela thanked the ILO for supporting the struggle against apartheid. 1990. © ILO',
  tripartism_imageItcturinCaption: 'Training session at the ILO Training Centre in Turin, Italy. © ILO',
  tripartism_imageItcturinAlt: 'Wide shot of a large meeting room, where dozens of people are sitting around tables and listening to a speaker who is standing at the front of the room next to a big screen.',
  tripartism_imageNobelprizeCaption: 'ILO Director-General David Morse receives the Nobel Peace Prize on behalf of the ILO from Aase Lionaes, Chair of the Nobel Committee of the Norwegian Parliament, at the University of Oslo, 10 December 1969. © ILO',
  tripartism_imageIlcseventysixCaption: 'Delegates of the 61st session of the ILC in UN assembly hall, Geneva, Switzerland, 1976. © ILO',
  tripartism_imageIlcseventysixAlt: 'Men and women sitting in a large assembly hall.',
  tripartism_imagePosteroshCaption: 'Posters on occupational health and safety in Mauritania. © ILO',
  tripartism_imagePosteroshAlt: 'Two people in front of a poster board that says “Industrial Accidents”.',
  tripartism_imageLechCaption: 'The ILO gives its support to the independent trade union Solidarnosc, led by Lech Walesa, who later became Polish President. © ILO',
  tripartism_imageIpecCaption: 'ILO creates International Programme on the Elimination of Child Labour (IPEC). © ILO/E. Gianotti',
  tripartism_imageIpecAlt: 'A girl with a headscarf sitting behind a carpet weaving loom.',
  tripartism_imageAdoptfprwCaption: 'Adoption of the ILO Declaration on Fundamental Principles and Rights at Work at the 86th session of the ILC, Geneva, Switzerland. © ILO',
  tripartism_imageAdoptfprwAlt: 'The 8th Director-General of the ILO, Michel Hansenne and other delegates clapping at the podium upon the adoption of the Declaration.',
  tripartism_imageZimbabweCaption: 'ILO launches the Decent Work Agenda. Unemployed Zimbabweans in Harare, Zimbabwe, look for work, 7 June 2001. © AFP/Alexander Joe',
  tripartism_imageZimbabweAlt: 'Six men standing on a street next to a pole. Three of them are reading newspapers.',
  tripartism_imageWorldcomCaption: 'World Commission on the Social Dimension of Globalization. From left to right, Benjamin William Mkapa, President of Tanzania, Tarja Halonen, President of the Republic of Finland and Juan Somavia, Director-General of the ILO. © ILO/M. Crozet',
  tripartism_imageWorldcomAlt: 'Three people standing in front of a banner side by side, smiling.',
  tripartism_imageDeclarationsjCaption: 'Opening session of the 97th ILC, Geneva, Switzerland, 2008 at which the Declaration on Social Justice for a Fair Globalization is adopted. © ILO',
  tripartism_imageDeclarationsjAlt: 'View of UN assembly hall filled with delegates.',
  tripartism_imageSomaviajobsCaption: 'Speech by Juan Somavia, Director-General of the ILO, during the Opening Session of the ILO Summit on the Global Jobs Crisis. Geneva, Switzerland, 15 June 2009. © ILO',
  tripartism_imageSomaviajobsAlt: 'A man speaking in UN assembly hall at the podium with the ILO logo.',
  tripartism_imageBankimoonCaption: 'Ban Ki-moon, the eighth Secretary-General of the UN, addresses the ILC. © ILO',
  tripartism_imageBankimoonAlt: 'A man speaking at the podium. Behind him there is a flag with the ILO logo.',
  tripartism_imageRyderfowCaption: 'Guy Ryder, ILO Director-General, speaking at the launch of the ILO Global Commission on the Future of Work. Geneva, Switzerland, 21 August 2017. ©ILO/M. Crozet',
  tripartism_imageRyderfowAlt: 'A man speaking at a podium that says “Global Commission on the Future of Work”.',
  tripartism_imageAsiaofficeCaption: 'Group photo of ILO Regional Office for Asia Pacific celebrating the upcoming ILO Centenary. © ILO',
  tripartism_imageAsiaofficeAlt: 'Group of men and women are wearing matching blue shirts with the ILO logo. People in the front row are holding signs with the ILO100 logo.',
  rights_heading: 'Rights and labour standards',
  rights_chapter: 'chapter 2',
  rights_title: 'Your rights at work',
  rights_calloutRightsTitle: 'Stories like these can be found in every country in the world, and what they have in common is that they concern violations of our rights.',
  rights_calloutRightsBody: 'The right to be a child and to go to school, the right to join a union, the right to set up a company free from discrimination, or the right to work in safety and to be treated with dignity - these rights are enshrined in ILO international labour standards',
  rights_infoPathtodw: '<h3>The path to decent work</h3>\n\n<p>Labour is not simply a commodity, an inanimate product that can be negotiated for the highest profit or the lowest price.</p>\n\n<p>What we call Decent Work is bound in a person\'s self-respect, well-being and development as a human being. International labour standards ensure a path to decent work.</p>\n\n<p>For economic development to be meaningful, it should include the creation of jobs and conditions in which people can work in freedom, equity, safety and dignity.</p>\n\n<p>International labour standards are there to ensure that development remains focused on improving the well-being of all.</p>',
  rights_infoLevelplayBody1: '<h3>A level playing field</h3>\n\n<p>Achieving the goal of Decent Work in the globalized economy requires action at the international level. Since its creation, the International Labour Organization has done just that, through a system of legal instruments backed by governments, employers and workers alike.</p>\n\n<p>These can be either Conventions, which are international treaties that become legally binding once they are approved or “ratified” by governments, or Recommendations, which are non-binding guidelines.</p>\n\n<p>ILO Conventions and Recommendations establish minimum levels of protection relating to specific labour issues, such as child labour, forced labour, gender equality and many others.</p>\n\n<p>They address new realities and challenges faced by working people in a changing world, and in turn serve to create a level playing field for countries and industries to compete fairly. Many of the laws in your country that ensure good working conditions in your place of work were, most likely, drafted to comply with ILO international labour standards.</p>',
  rights_calloutDevstandards: 'Developing international labour standards at the ILO is a unique process involving governments, workers and employers from around the world working together.',
  rights_infoRoadtolsBody1: '<h3>The road to labour standards</h3>\n\n<p>Once a problem in the world of work is identified, the Governing Body of the ILO agrees to discuss the issue at the International Labour Conference, based on a report prepared by the ILO and commented upon by all member States, workers’ and employers’ organizations.</p>\n\n<p>Based on this discussion, the ILO then drafts the labour standard, which is further discussed at the following Conference, where it is amended as necessary and proposed for adoption. A two-thirds majority of votes is required for a standard to be adopted.</p>',
  rights_calloutSohowprobTitle: 'So how are these problems in the world of work identified?',
  rights_calloutSohowprobBody: 'Governments, workers’ and employers’ organizations, and civil society groups are involved in identifying the need for new international labour standards.',
  rights_infoGlobalmarchBody1: '<p>In 1998, a grassroots movement called the Global March against Child Labour brought together NGOs, trade unions, teachers, and children, under the leadership of Kailash Satyarthi to demand an end to child labour.</p>\n\n<p>They marched through Africa, Asia, North and Latin America, and Europe, where their final destination was the International Labour Conference in Geneva.</p>\n\n<p>This march was significant because at that time the ILO was discussing a possible new Convention against the worst forms of child labour.</p>\n\n<p>So great was the impact of the marching children that it contributed to it becoming the fastest ratified Convention in the history of ILO.</p>',
  rights_calloutUniversalvalues: 'Our universal values',
  rights_infoGbfourissues: '<p>The ILO Governing Body has identified four issues considered as core principles and rights in the workplace. These are protected by eight "fundamental" Conventions and associated Recommendations and Protocols.</p>',
  rights_listFprw1: 'Freedom of association and the right to collective bargaining',
  rights_listFprw2: 'Elimination of all forms of forced labour',
  rights_listFprw3: 'Effective abolition of child labour',
  rights_listFprw4: 'Elimination of discrimination at work',
  rights_infoBasisfprw: '<p>These principles form the basis of the ILO Declaration on Fundamental Principles and Rights at Work. Adopted in 1998, the Declaration commits member States to respect and promote principles and rights in these four categories, whether or not they have ratified the relevant Conventions.</p>',
  rights_visConventionsTitle: 'OUR FUNDAMENTAL CONVENTIONS',
  rights_infoSupervisoryBody: '<h3>The ILO supervisory system</h3>\n\n<p>International labour standards are only effective if they are enforced. To achieve this, ILO Conventions are backed by a <mark id="rights_infoSupervisoryBody">supervisory system that is unique at the international level</mark>, which helps ensure that countries apply the Conventions they ratify.</p>',
  rights_calloutYourrights: 'YOUR RIGHTS at WORK',
  rights_infoAdoptionils: '<p>The ILO, through the adoption of international labour standards, has responded in the past and continues to respond today to a growing number of challenges faced by workers, employers and governments alike in the global economy.</p>',
  rights_calloutManyideas: 'Many ideas, which we now take for granted, from gender equality to non-discrimination in the workplace, were first discussed at the international level by the ILO',
  rights_imageChildmechanicCaption: 'Child mechanic, La Paz, Bolivia. © ILO/M. Crozet',
  rights_imageDomesticCaption: 'A domestic worker sifts rice, Bengal, India. © ILO/S.J. Mohammad',
  rights_imageDomesticAlt: 'A woman in colorful clothes sifting rice on the ground with a wooden stick.',
  rights_imageChildmechanicAlt: 'A backlit photo of a boy holding tools to fix something under the car. The car is lifted with a car jack.',
  rights_imageIlc1998Caption: 'International Labour Conference, Geneva, Switzerland, 1998. © ILO',
  rights_imageIlc1998Alt: 'A fisheye photo of UN assembly hall full of delegates to the ILC.',
  rights_imageGlobalmarchCaption: 'The Global March against Child Labour reaches the Palais des Nations, Geneva, Switzerland, 1998. © ILO',
  rights_imageAdoptfprwCaption: 'The ILO Declaration on Fundamental Principles and Rights at Work is adopted at the 86th Session of the International Labour Conference in 1998. © ILO',
  rights_imageAdoptfprwAlt: 'The 8th Director-General, Michel Hansenne and other delegates clapping at the podium upon the adoption of the Declaration.',
  rights_imageC182Caption: 'Child working at a dock. © ILO/P. Hien',
  rights_imageChilddockAlt: 'A boy with dozens of bricks on his back walking on a wooden bridge connecting a small boat to the port.',
  rights_imageC105Caption: 'Rescued slave labourers in Pará, Brazil, queuing for identification. © ILO/J. Ripper',
  rights_imageRescuedAlt: 'A hand holds small photographs of men being rescued. In the background is a queue of men .',
  rights_imageC29Caption: 'Workers at a brickyard in Nepal. © Lisa Kristine/Human Thread Foundation',
  rights_imageBrickyardAlt: 'A worker with four stacked bricks on his head is adding two more. Workers are covered in orange dust from the bricks.',
  rights_imageC111Caption: 'Sindh women agricultural workers rallying for their rights on International Women’s Day in Pakistan. © ILO',
  rights_imageSindhAlt: 'Women in colourful clothes sitting in a large tent and holding signs that says “Woman’s Day, Zero Discrimination”.',
  rights_imageC100Caption: 'Women calling for equal pay at a May Day workers’ march, 1 May, Paris. 2001 © AFP/M. Medina/AFP',
  rights_imageEqualpayAlt: 'Women marching on the streets with a large sign in French that says “Equal pay for men and women now”.',
  rights_imageC138Caption: 'Child carrying a large bundle of branches on her head. © ILO/A. Khan',
  rights_imageC98Caption: 'Workers and students demonstrate against pension reform. Annecy, France. © ILO/M. Crozet',
  rights_imagePensionreformAlt: 'People walking on the streets. Two men are wearing blue vests and holding blue flags of CFTC (French Confederation of Christian Workers).',
  rights_imageC87Caption: 'Union meeting in Japan. © ILO',
  rights_imageUnionmeetAlt: 'A man with his left arm raised is standing in front of a group of men sitting on a bleacher.',
  rights_imageCasCaption: 'Committee on the Application of Standards. 106th Session of the International Labour Conference. Geneva, June 2017. © ILO',
  rights_imageCasAlt: 'A conference room full of delegates. Screens show that the meeting is on the Committee on the Application of Standards.',
  rights_imageEbelingCaption: 'L.E. Ebeling presenting the views of United States’ employers on the question of equal pay for women, at the 33rd general conference of the ILO in 1950. This discussion led to the adoption of Equal Remuneration Convention in 1951. © ILO',
  rights_imageEbelingAlt: 'A woman standing with a stern face with her notepad is speaking to the room.',
  rights_detailSupervisory1: '<h3>How the ILO supervises the application of standards</h3>\n\n<p>The ILO regularly examines how standards are applied in member States and points out areas where they could be better implemented.</p>\n\n<p>If there are any difficulties in the application of these standards, the ILO can help countries to fill the gaps, in particular by providing technical assistance.</p>',
  rights_detailSupervisory2Title: '<h3>The Committee of Experts on the Application of Conventions and Recommendations</h3>',
  rights_detailSupervisory2: '<h3>The Committee of Experts on the Application of Conventions and Recommendations</h3>\n\n<p>Once a country has ratified an ILO Convention, it is obliged to report regularly on the measures it has taken to implement that Convention. Governments are required to share their reports with the most representative national employers’ and workers’ organizations , which offer their own comments.</p>',
  rights_detailSupervisory3Title: 'The Conference Committee on the Application of Standards',
  rights_detailSupervisory3: '<p>The Committee of Experts was set up in 1926 to provide an impartial and technical evaluation of the state of application of international labour standards in ILO member States.</p>\n\n<p>Today it is composed of 20 eminent jurists appointed by the Governing Body for three-year terms. These experts come from different geographic regions, legal systems and cultures.</p>',
  rights_ilsShortNameC29: 'Forced labour',
  rights_ilsShortNameC182: 'Worst forms of child labour',
  rights_ilsShortNameC105: 'Abolition of forced labour',
  rights_ilsShortNameC111: 'Discrimination',
  rights_ilsShortNameC100: 'Equal remuneration',
  rights_ilsShortNameC138: 'Minimum age',
  rights_ilsShortNameC98: 'Right to organize and collective bargaining',
  rights_ilsShortNameC87: 'Freedom of association',
  rights_ilsBlurbC29: 'Member States undertake to suppress and prevent the use of forced or compulsory labour within the shortest possible period, including human trafficking, and to ensure effective remedies for its victims.',
  rights_ilsBlurbC182: 'Member States will take immediate and effective measures to eliminate the worst forms of child labour, including all forms of slavery such as the sale and trafficking of children or recruitment of children for use in armed conflict; child prostitution; the use of children for illicit activities, in particular for the production and trafficking of drugs; and work which is likely to harm the health, safety or morals of children.',
  rights_ilsBlurbC105: 'Member States undertake to end the use of any form of forced or compulsory labour as a means of political coercion or as a punishment for holding or expressing political views; for mobilizing and using labour for purposes of economic development; as a means of labour discipline; as a punishment for having participated in strikes; or as a means of racial, social, national or religious discrimination.',
  rights_ilsBlurbC111: 'Member States undertake to pursue a national policy designed to promote equality of opportunity and treatment in respect of employment and occupation, with a view to eliminating any discrimination made on the basis of race, colour, sex, religion, political opinion, national extraction or social origin, which has the effect of nullifying or impairing equality of opportunity or treatment in employment or occupation.',
  rights_ilsBlurbC100: 'All workers should be paid according to the principle of equal remuneration for men and women for work of equal value.',
  rights_ilsBlurbC138: 'Member States undertake to ensure the effective abolition of child labour and to raise the minimum age for work to a level consistent with the fullest physical and mental development of young persons and in line with compulsory school education. The minimum age for any type of employment which is likely to jeopardize the health, safety or morals of young persons (called hazardous work) shall not be less than 18 years.',
  rights_ilsBlurbC98: 'Workers have the right to protection against acts of anti-union discrimination in respect of their employment. The voluntary negotiations between employers or employers\' organisations and workers\' organisations to regulate the terms and conditions of employment by means of collective agreements shall be promoted.',
  rights_ilsBlurbC87: 'Workers and employers, without distinction whatsoever, have the right to establish or join organizations of their own choosing without needing authorization. Member States undertake to take all necessary and appropriate measures to ensure that workers and employers may freely exercise this right to organize themselves.',
  rights_conventionsCountries: 'countries',
  rights_visConventionsSee: 'See ratifications by country',
  rights_visConventionsUpdate: 'Latest update:',
  rights_visConventionsSelect: 'Search country',
  rights_visConventionsCountries: 'countries',
  rights_visConventionsRatified: 'Countries that have ratified it',
  rights_visConventionsNotRatified: 'Countries that have not ratified it yet',
  rights_visConventionsRatifs: 'ratified this convention',
  rights_date: 'Discover this chapter on 16 January 2019',
  rights_visLatestUpdate: 'Last update',
  rights_visConventionsFundratif: 'fundamental conventions ratified',
  rights_calloutManyIdeasSub: '... and because of that, are a reality today for millions of women and men.',
  rights_calloutConclusion2: 'By establishing new rights and protections for workers and employers in this changing environment, the ILO continues to fight for a world with equality, and freedom from discrimination.',
  rights_visConventionsC182: 'Convention 182',
  rights_visConventionsC29: 'Convention 29',
  rights_visConventionsP29: 'Protocol 29',
  rights_visConventionsC105: 'Convention 105',
  rights_visConventionsC111: 'Convention 111',
  rights_visConventionsC100: 'Convention 100',
  rights_visConventionsC138: 'Convention 138',
  rights_visConventionsC98: 'Convention 98',
  rights_visConventionsC87: 'Convention 87',
  rights_calloutConclusion1: 'The ILO’s core mission to strive for a better future for all in the world of work requires it to understand and anticipate the factors that contribute to the changes that are transforming the world of work.',
  rights_infoSuperTest: '<h3>The ILO supervisory system</h3>\n\n<p>International labour standards are only effective if they are enforced. To achieve this, ILO Conventions are backed by a <mark id="rights_infoSuperTest">supervisory system that is unique at the international level</mark>, which helps ensure that countries apply the Conventions they ratify.</p>',
  rights_detailSupervisory4: '<h3>The Conference Committee on the Application of Standards</h3>\n\n<p>The annual report of the Committee of Experts is submitted to the International Labour Conference, where it is examined by the Conference Committee on the Application of Standards.</p>\n\n<p>This committee is made up of Government, Employers’ and Workers’ delegates, who select a number of cases for discussion, based on the Committee of Experts’ report.</p>',
  rights_detailSupervisory5: '<p>Governments appearing on this list of cases are expected to respond before the Conference Committee and to provide information on the situation in question.</p>\n\n<p>In many cases, the Conference Committee draws up recommendations for governments to take specific steps to remedy the problem, and offers ILO support to governments in tackling the issues.</p>\n\n<p>It is often through such technical assistance, usually provided by the ILO over many years, that violations of labour rights end and working people can enjoy their fundamental rights and protections.</p>',
  employment_heading: 'Employment',
  employment_chapter: 'chapter 4',
  employment_title: 'Not just any work',
  employment_infoEmppromotBody: '<p>Employment promotion is at the heart of the ILO’s mandate. It’s one of the pillars of the Decent Work Agenda.</p>\n\n<p>From the Organization’s early days, promoting the creation of full and productive employment has been an integral part of its work. This is because decent jobs – not just any jobs – are the basis for peace, social justice, social inclusion, economic development and personal fulfilment.</p>',
  employment_quoteSantosBody: '“Achieving social justice means more jobs and less poverty and inequality. Decent work is a pillar of this effort, to which labour policies and more equitable economic development are essential.”',
  employment_quoteSantosCaption: 'Juan Manuel Santos, Nobel Peace Prize laureate and former President of Colombia, at the 107th International Labour Conference, 2018',
  employment_infoEndpovertyTitle: 'The end of poverty?',
  employment_infoEndpoverty: '<h3>The end of poverty?</h3>\n\n<p>Poverty is the root cause of many of the problems the world faces today.  Income inequality, unemployment and privation can trigger the alienation that contributes to instability, conflict and violent extremism.</p>\n\n<p>Conversely, better access to quality jobs can increase incomes and contribute to more cohesive and equitable societies.  More and better jobs are crucial to creating the kind of world we would all like to live in.</p>',
  employment_infoEndpovertyBody2: 'Conversely, better access to quality jobs can increase incomes and contribute to more cohesive and equitable societies.  More and better jobs are crucial to creating the kind of world we would all like to live in.',
  employment_calloutWhatstandsTitle: 'What stands between people and decent jobs?',
  employment_calloutWhatstandsBody: 'Explore some of the challenges faced by men and women around the world in their search for more and better jobs.',
  employment_detailInequalityTitle: 'Inequality',
  employment_detailInequalityBody1: '<h3>Inequality</h3>\n\n<p>Inequalities in the labour market lead to poverty. Injustice occurs when people lack access to jobs or the right skills to find one, and when their rights at work are so unprotected that they cannot earn enough to feed themselves or their families.</p>\n\n<p>Overcoming the inequalities that lead to injustice means people can use their potential to make a living and lift themselves out of poverty. The ILO helps countries overcome inequalities in labour markets, to find a sustainable way out of poverty, for example through wage subsidy programmes.</p>',
  employment_detailInequalityBody2: '<p>In the Russian Federation, a unique public-private partnership with the Russian company LUKOIL provides wage subsidies to local employers to help young people find a job.</p>\n\n<p>By subsidizing the wages of young workers, it opens up new opportunities for them, gives them work experience, and demonstrates to employers that young workers represent a valuable asset for their companies.</p>',
  employment_detailConflictTitle: 'Conflict and disaster',
  employment_detailConflictBody1: '<h3>Conflict and disaster</h3>\n\n<p>Nearly 1.5 billion people live in situations of fragility and armed violence; another 200 million are affected by disaster. Conflict and catastrophic events undermine people\'s livelihoods and create a shortage of decent work. This can lead to increased poverty, inequality and social unrest. </p>\n\n<p>The ILO supports programmes that create decent jobs for people affected by conflict and disaster. It’s a way of building their resilience and helping their countries move toward peace and stability.</p>',
  employment_detailConflictBody2: '<p>In Colombia, where the peace process has put an end to half a century of armed conflict, the ILO is helping local communities to develop the tourism sector to generate jobs for the most vulnerable members of society, such as young people and women.</p>\n\n<p>By providing income for families in rural areas a root cause of the conflict - the lack of opportunities for work and growth - is being supplanted by a stable and sustainable peace.</p>',
  employment_detailYouthempTitle: 'Youth employment',
  employment_detailYouthempBody1: '<h3>Youth employment</h3>\n\n<p>According to ILO statistics, 66 million young people lack jobs and more than 145 million young workers live in poverty, despite the fact that education and the advantages it brings have improved over the decades.</p>\n\n<p>We are facing a future where there is little room for young people to find a decent job, despite their skills and willingness to work.</p>',
  employment_detailYouthempBody2: '<p>Investing in youth employment means working together to make job creation a priority. At the same time, we must help youth overcome specific disadvantages, such as discrimination and not having the right skills.</p>\n\n<p>The ILO helps countries design, implement and evaluate employment policies and programmes so that young people have a better chance of getting a decent job.</p>',
  employment_detailRuralexodusTitle: 'Rural exodus',
  employment_detailRuralexBody1: '<h3>Rural exodus</h3>\n\n<p>68% of the world’s population is projected to live in urban areas by 2050, as millions move in search of work. This risks leaving rural populations with fewer jobs and opportunities.</p>\n\n<p>Promoting skills development and entrepreneurship among rural women and youth is one way to make sure they have access to decent jobs. The ILO works to promote skills in the rural sector and small and medium enterprises.</p>',
  employment_detailRuralexBody2: '<p>In Kenya, the United Republic of Tanzania and Uganda, over 2,900 women entrepreneurs were trained to start their own businesses. Not only did the women say the training boosted their confidence, a follow-up survey showed that one new business has been created for every two entrepreneurs trained.</p>\n\n<p>Through ILO support, in Ethiopia some 6,300 women and seven women’s cooperatives benefited from credit and business management training, which significantly boosted their earnings and created new jobs.</p>',
  employment_detailInformalTitle: 'Informal work',
  employment_detailInformalBody1: '<h3>Informal work</h3>\n\n<p>More than half of the world’s workers are in the informal economy. That means they are in casual employment that is not protected by labour laws. They have no rights, no voice and not enough income to escape poverty.</p>\n\n<p>Informal enterprises tend to have low productivity rates and don’t offer decent work to the people who work for them. This prevents these types of businesses from growing and means they don’t have access to mainstream resources.</p>',
  employment_detailInformalBody2: '<p>The ILO supports workers and businesses by giving them the knowledge and skills they need to help them move from the informal to the formal economy.</p>\n\n<p>In Nepal, after a disastrous earthquake in 2015, the ILO helped train tour guides and other small businesses that cater to the recovering tourism industry.</p>\n\n<p>While providing better service to their customers, the ILO helped employers ensure they were also providing better jobs.</p>',
  employment_detailMigrationTitle: 'Labour migration',
  employment_detailMigrationBody1: '<h3>Labour migration</h3>\n\n<p>According to the latest ILO estimates, migrant workers account for 150 million of the world’s 232 million international migrants.</p>\n\n<p>They contribute to the economic, social and cultural development of their host countries. The money they send to family and the skills they acquire abroad are invaluable to their home communities and countries. But too often their contributions go unrecognized.</p>',
  employment_detailMigrationBody2: '<p>In fact, many migrants are vulnerable to exploitation and abuse. Of the 21 million people in forced labour today, 44% were trapped or trafficked after moving internally or internationally for work.</p>\n\n<p>The ILO helps develop policies to maximize the benefits of labour migration for migrants and host communities alike.</p>',
  employment_calloutDecentjobsTitle: 'DECENT JOBS for ALL',
  employment_calloutDecentjobsBody: 'The ILO has led decades of research and successful assistance projects in dozens of countries worldwide that have led to policies that promote and create decent jobs.',
  employment_infoRightskillsTitle: 'The right skills',
  employment_infoRightskills: '<h3>The right skills</h3>\n\n<p>As the world of work undergoes rapid change relating to factors such as technology, the nature of production, and employment and climate change, effective skills training is more critical than ever.</p>\n\n<p>The ILO is at the forefront of the development of skills policies and systems that anticipate and build competencies for the jobs of the future.</p>',
  employment_infoRightskillsBody2: 'Importantly, the ILO supports inclusion of disadvantaged groups in society, through skills development.',
  employment_infoInvestmentsTitle: 'Investments that create jobs',
  employment_infoInvestments: '<h3>Investments that create jobs</h3>\n\n<p>Millions of people are living in regions that lack critical infrastructure, such as roads, bridges, water supplies, electricity or the internet. Many do not have access to basic services such as water, health and education.</p>\n\n<p>Investing in infrastructure can dramatically improve living standards and has a direct impact on the quality of people’s lives.</p>\n\n<p>By using local labour and resources to build much-needed infrastructure and by involving communities in the planning, jobs are created, costs are reduced and local industry is supported. Local institutions are strengthened and become more efficient.</p>\n\n<p>In turn, rural and urban poverty are reduced, and the economic and social well-being of individuals and communities improve.</p>',
  employment_infoInvestmentsBody2: 'In turn, rural and urban poverty are reduced, and the economic and social well-being of individuals and communities improve.',
  employment_infoStronginstTitle: 'Strong institutions',
  employment_infoStronginstBody: '<h3>Strong institutions</h3>\n\n<p>In a globalized, increasingly competitive world, labour markets work best when the institutions that govern them create an environment where income and social and labour protection are ensured for workers, while enterprises are given the room they need to prosper. </p>\n\n<p>Well-functioning labour markets need solid policy frameworks and institutions.</p>',
  employment_calloutThisiswhy: 'This is why the ILO helps countries build relevant institutions and develop and implement policies through dialogue between government, employers’ and workers’ representatives.',
  employment_infoNepalBody1: '<p>In Nepal for example, the ILO’s Labour Market Information and Employment Services (LIfE) programme supports the country’s public employment services, which play an increasingly important role in job matching, to enhance employability, address skill mismatches and better link employers and workers.</p>\n\n<p>A key advantage of the programme is that participants develop expertise not only through the acquisition of technical skills and personal and social competences, but also through socialization in the workplace. For employers, workplace training also helps increase productivity by addressing skills gaps.</p>',
  employment_infoNepalBody2: 'A key advantage of the programme is that participants develop expertise not only through the acquisition of technical skills and personal and social competences, but also through socialization in the workplace. For employers, workplace training also helps increase productivity by addressing skills gaps.',
  employment_calloutChallengesdwTitle: 'The challenges to decent work are complex, as are the solutions.',
  employment_calloutChallengesdwBody: 'With 100 years of experience behind us, the ILO is positioned to take the lead on creating a future of work with decent jobs at its core.',
  employment_imageLocalyouthsCaption: 'Local youths at an ILO training workshop in Diego-Suarez, Madagascar. A project supported by the ILO has helped fight urban violence by enabling young people to learn a trade and leave a life of crime behind. © ILO Madagascar',
  employment_imageLocalyouthsAlt: 'Three men and a woman in blue suits and yellow helmets standing around a wooden table with an opened tool kit.',
  employment_imageWastepickCaption: 'Waste pickers at a rubbish dump in Manila, Philippines. The 3,000 people who sort rubbish in Quezon city are now unionized and represent a model for waste management, recycling almost 40% of household waste. © ILO/M. Rimando',
  employment_imageWastepickAlt: 'A large group of men, women and children picking up waste from a landfill site.',
  employment_imageSlumsCaption: 'Slums in West Point, Monrovia, Liberia. Approximately 75,000 people live here but they have access to only four public toilets. Tuberculosis and other diseases are endemic. © UNMIL/A.G. Farran',
  employment_imageSlumsAlt: 'An aerial view of low houses with rusted metal sheet roofs near the shore.',
  employment_imageTaclobanCaption: 'Tacloban city in the Philippines after Super Typhoon Haiyan in November 2013. Roughly 90% of the city was destroyed. Survivors described the devastation as ‘apocalyptic’. © ILO/M. Crozet',
  employment_imageTaclobanAlt: 'An airplane flying above an area full of rubble, destroyed cars and fallen trees.',
  employment_imageMigrantaddisCaption: 'A migrant returnee discusses job opportunities with a potential employer at a job fair organized by the ILO, Addis Ababa, Ethiopia. © ILO/M. Tadesse',
  employment_imageMigrantaddisAlt: 'Two men standing and talking to each other over a desk with a computer and leaflets.',
  employment_imageFarmersCaption: 'Farmers in Madagascar. Agriculture employs the majority of Madagascar\'s population, who toil with limited equipment and poor infrastructure, producing barely enough to feed their families.  © ILO Madagascar',
  employment_imageFarmersAlt: 'Two female farmers bending over and collecting plants from a paddy field.',
  employment_imageHandicraftCaption: 'A handicraft maker in Kathmandu, Nepal, where the ILO is supporting women entrepreneurs by sharing business practices which not only build security and provide for their families but  also change long-held perceptions about gender and the role of women in society. © ILO/M. Crozet',
  employment_imageHandicraftAlt: 'A woman wearing a yellow bag on her shoulder and holding a blue and green peacock doll in her hands.',
  employment_imageMigrantdatesCaption: 'Migrant worker harvesting dates, Bahrain. The proportion of migrant to local workers in Bahrain is among the highest in the world.  © ILO',
  employment_imageMigrantdatesAlt: 'A man standing in the branches and picking red dates from a branch full of them.',
  employment_imageApprenticeCaption: 'Asia found work at a hotel in Zanzibar, Tanzania, after completing an ILO skills training course in the hospitality industry. © ILO Tanzania',
  employment_imageApprenticeAlt: 'A woman wearing a white hijab and looking at a computer screen at a reception desk.',
  employment_imageConstructionCaption: 'Workers at a construction site in La Paz, Bolivia. According to the OECD, the construction industry employs approximately 7% of the global work force and is predicted to account for 13% of GDP by 2020. © ILO',
  employment_imageConstructionAlt: 'Three male construction workers at a construction site with helmets and protective glasses.',
  employment_imageTraineesCaption: 'Workplace-based learning trainees at the ILO’s LIfE programme in Nepal. © Federation of Handicraft Association of Nepal (FHAN)',
  employment_imageTraineesAlt: 'Young men and women sitting on the floor are carving pieces of wood.',
  employment_date: 'Discover this chapter on 6 February 2019',
  employment_detailConflictBody3: '<p>In fact, the final peace accord makes specific reference to ILO international labour standards as a way to guarantee decent work and the rights of workers in rural areas - the lack of which was a major contributor to the unrest.</p>',
  employment_detailMigrationBody3: '<p>In Jordan, Nepal, the Philippines and Tunisia, the ILO worked closely with its UN family to prevent the exploitation of migrant workers by providing them with access to reliable information.</p>\n\n<p>Equally important, the ILO disseminated information about fair recruitment via local and international media to raise awareness among migrant workers and potential employers.</p>',
  protection_heading: 'Social protection',
  protection_chapter: 'chapter 5',
  protection_title: 'Not on your own',
  protection_calloutWhathappensTitle: 'What happens when you are sick, disabled, pregnant or old and lack sufficient income or any income at all because you cannot work?',
  protection_calloutWhathappensBody: 'How can we protect individuals and their families against these risks and shield them from poverty throughout their lives?',
  protection_infoFacesofspBody: '<h3>The faces of social protection</h3>\n\n<p>Social protection is a human right. It ensures access to health care and income security for all.  It provides for you and your family when you’re sick, unemployed, injured, pregnant or too old to work. It provides support for your family in case something happens to you. </p>\n\n<p>Social protection gives you peace of mind and hope for a better future.</p>',
  protection_calloutEveryoneright: 'Everyone has the right to social security',
  protection_quoteEveryonerightBody: '“Everyone has the right to a standard of living adequate for the health and well-being of himself and of his family, including food, clothing, housing and medical care and necessary social services, and the right to security in the event of unemployment, sickness, disability, widowhood, old age or other lack of livelihood in circumstances beyond his control.”',
  protection_quoteEveryonerightCaption: 'Universal Declaration of Human Rights, Article 25(1)',
  protection_infoYourspBody: '<h3>Your social protection</h3>\n\n<p>Social protection is a set of policies and programmes designed to reduce and prevent poverty and vulnerability during our lives. Social protection ensures you have access to...</p>\n\n<ul>\n<li>Child and family benefits</li>\n<li>Maternity protection</li>\n<li>Unemployment benefits</li>\n<li>Employment injury benefits</li>\n<li>Sickness benefits</li>\n<li>Health protection</li>\n<li>Old-age pensions</li>\n<li>Disability pensions</li>\n<li>Survivors’ pensions</li>\n</ul>',
  protection_calloutWhyneedsp: 'Why DO WE NEED SOCIAL PROTECTION?',
  protection_infoEndpovertyBody: '<h3>The end of poverty</h3>\n\n<p>According to ILO statistics, today, 4 billion people worldwide lack social protection. Only 29% of the world’s population has adequate social security coverage and 55% lacks any coverage at all.</p>',
  protection_calloutUniversalsp: 'Universal social protection is key to achieving several of the UN Sustainable Development Goals. Without it, we cannot eradicate poverty, reduce inequalities or achieve gender equality.',
  protection_infoTimeforactionBody: '<h3>Time for action: Universal social protection</h3>\n\n<p>It is possible to extend social protection to the 4 billion people worldwide who still lack it. The ILO works hard to support countries in their efforts to create or improve their national social protection systems.</p>\n\n<p>ILO standards and expertise are crucial in guiding countries in the development of their own national social protection schemes.</p>',
  protection_calloutPasttenyears: 'Over the past ten years, the ILO has supported the development of social protection in 136 countries, including:',
  protection_listSpfloors1: 'National social <br>protection strategies in',
  protection_listSpfloors2: 'Health <br>protection in',
  protection_listSpfloors3: 'Child <br>benefits in',
  protection_listSpfloors4: 'Maternity <br>benefits in',
  protection_listSpfloors5: 'Unemployment <br> insurance schemes in',
  protection_listSpfloors6: 'Public employment <br>programmes in',
  protection_listSpfloors7: 'Old-age<br>pensions in',
  protection_vizColombiaTitle: 'Colombia',
  protection_vizColombiaBody: 'Defined its national social protection strategy',
  protection_vizPeruTitle: 'Peru',
  protection_vizPeruBody: 'Established a non-contributory pension scheme',
  protection_vizTogoTitle: 'Togo',
  protection_vizTogoBody: 'Established a national health insurance scheme that covers 400,000 beneficiaries',
  protection_vizCyprusTitle: 'Cyprus',
  protection_vizCyprusBody: 'Reformed its social assistance scheme',
  protection_vizKazakhstanTitle: 'Kazakhstan',
  protection_vizKazakhstanBody: 'Ratified the ILO Maternity Protection Convention (No.183)',
  protection_vizIndiaTitle: 'India',
  protection_vizIndiaBody: 'The National Health Insurance Scheme (RSBY) was extended',
  protection_vizCambodiaTitle: 'Cambodia',
  protection_vizCambodiaBody: 'Established a nationwide social health insurance scheme',
  protection_vizThailandTitle: 'Thailand',
  protection_vizThailandBody: 'Implemented the Universal Health Care Scheme',
  protection_vizVietnamTitle: 'Viet Nam',
  protection_vizVietnamBody: 'Extended coverage of non-contributory pensions',
  protection_vizMozambiqueTitle: 'Mozambique',
  protection_vizMozambiqueBody: 'Defined its national social protection strategy',
  protection_vizJordanTitle: 'Jordan, Bahrain and Saudi Arabia',
  protection_vizJordanBody: 'Implemented unemployment insurance schemes',
  protection_vizSouthafricaTitle: 'South Africa',
  protection_vizSouthafricaBody: 'Scaled up its Expanded Public Works Programme',
  protection_infoRoadaheadBody: '<h3>The road ahead</h3>\n\n<p>Countries have made laudable progress but billions of people around the world still do not benefit from adequate social protection. </p>\n\n<p>Lack of social protection leaves people vulnerable to poverty, inequality and social exclusion across the life cycle. The solution is universal social protection.</p>',
  protection_calloutOnlywhen: 'It is only when this aspiration becomes reality that we will have made significant progress towards achieving sustainable development for all.',
  protection_imageGarmentmedCaption: 'A garment factory medical centre in Jordan. © ILO',
  protection_imageGarmentmedAlt: 'A sign on a window that says “medical centre”. Behind the window are patients and medical professionals.',
  protection_imagePregnantCaption: 'A pregnant woman worker at an automotive parts manufacturing company in Hangzhou, China. © ILO',
  protection_imagePregnantAlt: 'A pregnant women standing behind a desk in a corridor. She is stamping a pile of small pieces of paper.',
  protection_imageNurseCaption: 'A nurse treats a child at a medical centre in Baghdad, Iraq. © ILO/Apex Image',
  protection_imageNurseAlt: 'A female nurse is holding up the right leg of a child lying on a bed. Another woman is holding the the child’s left hand.',
  protection_imageHomelessCaption: 'A homeless man looking through a trash bin in San Francisco, USA. © ILO',
  protection_imageHomelessAlt: 'A man bent over with a hand inside a trash bin on the streets. There is a tram behind the man.',
  protection_imageVendorCaption: 'A flower vendor at the Cuenca Flower market, Ecuador. © ILO',
  protection_imageVendorAlt: 'A woman sitting in front of bouquets of flowers she is selling.',
  protection_imageNursesnewCaption: 'Nurses look after newborns at the Princess Christian Maternity Hospital, in Freetown, Sierra Leone. © World Bank/D. Chavez',
  protection_imageNursesnewAlt: 'Two nurses dressed in pink uniforms looking at three newborns wrapped in orange blankets sleeping in a cradle.',
  protection_date: 'Discover this chapter on 13 February 2019',
  protection_countries: 'countries',
  protection_vizDominicanrepTitle: 'Dominican Republic',
  protection_vizDominicanrepBody: 'Ratified the ILO Convention No. 102 on Social Security (Minimum Standards)',
  protection_vizMoldovaTitle: 'Republic of Moldova',
  protection_vizMoldovaBody: 'Created bilateral social security agreements to cover Moldovan migrant workers in various countries',
  protection_vizRwandaTitle: 'Rwanda',
  protection_vizRwandaBody: 'Implemented a maternity leave benefits scheme',
  protection_vizTimorlesteTitle: 'Timor-Leste',
  protection_vizTimorlesteBody: 'Created its first contributory social security scheme',
  protection_country: 'country',
  protection_countries1: 'countries',
  work_heading: 'Work and governance',
  work_chapter: 'chapter 6',
  work_title: 'Rules to work by',
  work_infoMostofusBody: '<p>Whether an office, a market, a factory or a ship, many people may think of their workplace as simply somewhere where they earn a living.</p> \n\n<p>In their daily routine, few probably pause to think of the laws, rules and regulations that are so critical to making work safe and ensure decent working conditions for all.</p>  \n\n<p>With so many changes happening - new technologies, short-term contracts and online working, for instance - the laws, rules, policies and institutions that underpin our world of work need to keep pace so that they support and protect decent work.</p>',
  work_calloutRoleofilo: 'So what’s the role of the ILO in all this? And what’s it got to do with you?',
  work_infoLabourstdsBody: '<p>ILO international labour standards, which have been adopted by governments over the past century, have developed into a comprehensive system of international treaties, enshrined in national laws and policies, and in the work of public and private institutions.</p>  \n\n<p>Together, these standards promote decent and productive jobs, wherever you work, in conditions of freedom, equity, security and dignity.</p>  \n\n<p>The ILO works closely with governments, employers’ and workers’ organizations to make sure that the laws and regulations that affect your working life are relevant and are implemented.</p> \n\n<p>It also helps public institutions carry out inspections to make sure that labour laws are implemented in workplaces like yours.</p>',
  work_infoKeepingyouBody: '<h3>Keeping you safe at work</h3>\n\n<p>Occupational safety and health (OSH) is one of the most critical issues in any workplace. It means protecting and supporting the safety, health and welfare of workers.</p>\n\n<p>The evolution of OSH dates back to the industrial revolution during the 1800s, and grew out of concerns about the number of men, women and children being injured or killed at work. By the time of the ILO’s founding in 1919, the development of international standards that would protect the safety and health of workers had become a priority.</p>\n\n<p>Four of the first ten ILO Recommendations addressed occupational safety and health issues. Nowadays, more than 40 ILO Conventions and Recommendations deal specifically with occupational safety and health. Convention No. 187 - one of the most recent - has as its goal the creation of a global culture of prevention that will achieve real reductions in work-related fatalities, injuries and illnesses.</p>',
  work_calloutLawscompliedTitle: 'One of the ways for governments to ensure that labour laws are being complied with in workplaces is through effective enforcement.',
  work_calloutLawscompliedBody: 'Effective enforcement includes the inspection by labour inspectors of offices, factories, construction sites and other places of work to make sure that working conditions in those workplaces meet the requirements of labour laws and policies.',
  work_infoFrontlineBody: '<h3>On the front line</h3>\n\n<p>However, government budgets for enforcement have not kept pace with the growing number of workplaces and accelerated change in the world of work. It has become increasingly difficult for labour inspectors to inspect every workplace and to fully address the working conditions they find.</p>\n\n<p>The ILO has always supported governments in developing labour inspection systems, helping them to update the way they plan and go about labour inspections in response to the rapid changes that are affecting all of us in the workplace.</p>',
  work_calloutFourareas: 'The ILO also works in four other related areas to make sure labour laws are properly implemented and that public and private labour bodies operate efficiently.',
  work_listFitforpurpose1Title: 'Fit for purpose',
  work_listFitforpurpose1Body: 'Making labour administration systems work',
  work_listLawmatters2Title: 'Law matters',
  work_listLawmatters2Body: 'Assisting on labour law reforms',
  work_listWorkingtog3Title: 'Working together works',
  work_listWorkingtog3Body: 'Tripartism, social dialogue and industrial relations',
  work_listReaching4Title: 'Reaching consensus',
  work_listReaching4Body: 'How to prevent and settle labour disputes',
  work_detailFitforpurpose1: '<h3>Making labour administration systems work</h3>\n\n<p>From Ministries of Labour to recruitment agencies, it’s important that the public and private bodies that formulate and implement a country’s national labour laws are well-organized, professional and efficient, so that the labour market is properly governed.</p>',
  work_detailLawmatters1: '<h3>Assisting on labour law reforms</h3>  \n\n<p>Labour laws need to keep pace with the rapid changes that are transforming the workplace. They need to respect ILO standards and meet the goals of Decent Work.</p>\n\n<p>To help member States assess, design and revise their labour laws and implement ILO conventions, the ILO offers technical expertise and assistance.</p>',
  work_detailWorkingtog1: '<h3>Tripartism, social dialogue and industrial relations</h3>\n\n<p>When governments, workers and employers address issues together, in a system of sound industrial relations, it helps improve economic performance and leads to the more equitable distribution of profit and wealth. This happens through:</p>\n<ul>\n<li>Negotiation, consultation and information exchange</li>\n<li>Collective bargaining</li>\n<li>Dispute prevention and resolution</li>\n<li>Other instruments of social dialogue, including international labour standards</li>\n</ul>',
  work_detailReaching1: '<h3>How to prevent and settle labour disputes</h3>  \n\n<p>In the employment relationship, grievances and conflicts are inevitable.</p>\n\n<p>But before reaching the stage of going to court, there are ways to prevent and settle labour disputes through consensus.</p>',
  work_infoBrokenrulesBody: '<h3>Broken rules</h3>   \n\n<p>Even where legislation is in place, there will always be challenges to the rule of law.</p> \n\n<p>For instance, 182 out of 187 member States have ratified the Worst Forms of Child Labour Convention, meaning it could be the first ILO Convention to receive universal ratification. Yet, while the number of child labourers has dropped over the years, there are still 152 million child labourers worldwide.</p> \n\n<p>Even though 178 out of 187 countries have ratified the Forced Labour Convention, 25 million people are still in forced labour – an industry that generates USD 150 billion a year in illegal profits.</p>\n\n<p>These are some of the many the ILO seeks to address across all sectors, with a range of projects and programmes to support governments in implementing labour laws. This includes updating Conventions where necessary.</p>\n\n<p>In 2014, for example, in response to the emergence of new forms of modern slavery, the International Labour Conference adopted the Forced Labour Protocol - an addendum to the 1930 Forced Labour Convention - which aims to intensify efforts to eliminate all types of forced labour, including trafficking in persons.</p>',
  work_infoTacklingBody: '<h3>Tackling the middle men</h3> \n\n<p>In today’s challenging economy, workers are struggling to find job opportunities, and recruitment services are more important than ever. </p>\n\n<p>Public and private employment agencies, when appropriately regulated, can play an important role in matching available jobs with suitably qualified workers.</p>\n\n<p>Worldwide, there are an estimated 244 million international migrants and 740 million internal migrants. Most are migrating in search of decent employment and better livelihoods.</p>\n\n<p>However, some vulnerable groups, particularly migrant and low-skilled workers, are prey to unscrupulous employment agencies and informal labour intermediaries, who may trick them into being trafficked or trap them in situations of forced labour.</p>',
  work_calloutTrappedflTitle: '25 million people are trapped in forced labour globally. Of the total, 44% moved either internally or internationally for work.',
  work_calloutTrappedflBody: 'Migrant workers who borrow money from third parties face an increased risk of being in forced labour.',
  work_infoInresponseBody: '<p>In response to these challenges, the ILO works in close collaboration with governments, employers’ and workers’ organizations, the private sector and other international organizations to:</p>\n\n<ul>\n<li>Help prevent human trafficking and forced labour. </li>\n<li>Protect the rights of workers, including migrant workers, from abusive and fraudulent practices during the recruitment process.</li>\n<li>Reduce the cost of labour migration and enhance development outcomes for migrant workers and their families, for countries of origin as well as of destination.</li>\n</ul>',
  work_calloutIloaimstoTitle: 'The ILO aims to enhance knowledge on effective recruitment practices, improve laws, policies and enforcement mechanisms, promote fair business practices, and empower and protect workers.',
  work_calloutIloaimstoBody: 'Using all the above-mentioned governance toolkit of the world of work, the ILO works to support better working conditions around the world, and so strengthen the fight for Decent Work for all.',
  work_imageNightshiftCaption: 'Night shift workers at a lychee processing factory in Tamatave, Madagascar. © ILO/M. Fossat',
  work_imageNightshiftAlt: 'Workers in a factory dressed in white with sanitary masks, caps and blue gloves sitting on benches and sorting out lychees on conveyor belts.',
  work_imageFishersCaption: 'Fishers learn environmentally-friendly fish farming skills at an ILO AusAID emergency employment programme, Philippines. © ILO/M. Crozet',
  work_imageFishersAlt: 'Two male fishers standing over a wooden float and trying to cast a large green fishing net.',
  work_imageWomenwarCaption: 'Women war workers at Royal Shell Factory 3, Woolwich Arsenal, UK, May 1918. © Imperial War Museum',
  work_imageWomenwarAlt: 'Women working on bombshells on the table using various tools.',
  work_imageLabourinspCaption: 'Labour inspectors conduct an occupational health and safety inspection at a workshop in Amman, Jordan. © ILO/G. Megevand',
  work_imageLabourinspAlt: 'A woman labour inspector with a controller in her hand is conversing with a male worker sitting in front of a machine.',
  work_imageChildcarpetCaption: 'A child carpet weaver in Lahore, Pakistan. © ILO/M. Crozet',
  work_imageChildcarpetAlt: 'A girl sitting behind a carpet weaving loom and grabbing bulks of threads in front of her, through which her eye shows.',
  work_imageMigrantCaption: 'A migrant worker in Qatar. © Apex Image',
  work_imageMigrantAlt: 'A man in a yellow overall spreading dry grass on to the ground. There are tall buildings in the background.',
  work_imageSyrianCaption: 'A Syrian refugee construction worker in Jordan. © ILO/A. Al Sukhni',
  work_imageSyrianAlt: 'A man standing on a scaffolding and painting an elaborate design on the ceiling with yellow paint.',
  work_date: 'Discover this chapter on 20 February 2019',
  work_detailFitforpurpose2: '<p>The ILO provides technical advice and assistance on the reform of labour administration systems.</p>\n\n<p>It also provides training for officials responsible for labour inspection, employment services, social protection benefits, enterprise development, dispute resolution, formulation of labour policy and laws, data collection and analysis, and social dialogue.</p>',
  work_detailWorkingtog2: '<p>This social dialogue is essential to achieving and sustaining Decent Work.</p>\n\n<p>The ILO facilitates social dialogue between governments, employers’ and workers’ representatives and helps member States achieve sound industrial relations in their countries so that the work sphere is governed efficiently and effectively. </p>\n\n<p>It supports this through research, education and training programmes, as well as through information and skills-sharing platforms.</p>',
  work_detailReaching2: '<p>In conciliation and mediation, a third party provides assistance during negotiations to help the parties reach an agreement, especially if the parties have reached an impasse.</p>\n\n<p>Arbitration is when a third party, not acting as a court of law, is empowered make a decision to resolve the dispute.   </p>\n\n<p> Specialized labour adjudication is when an ordinary court or a special labour court settles disputes over rights and obligations.</p>',
  work_imageConsensusCaption: 'Social partners of the metalworking sector negotiating an agreement to avoid conflict, at the premises of the General Inspectorate of Labour and Conciliation at Bab EL khadhra in Tunis, Tunisia. © ILO',
  work_imageWorkingtoCaption: 'Wided Bouchamaoui, President of the Tunisian Confederation of Industry, Trade and Handicrafts (UTICA) and member of the Tunisia National Dialogue Quartet. The Quartet was awarded the Nobel Peace Prize in 2015 "for its decisive contribution to the building of a pluralistic democracy in Tunisia in the wake of the Jasmine Revolution of 2011" © ILO',
  work_imageFitforpurposeCaption: 'A human resources development and employment centre in Tianjin. China. Employment service centres such as this one help develop workers’ knowledge and skills to improve competitiveness and productivity, while promoting social inclusion and decent work. © ILO',
  work_imageLawmattersCaption: 'Inspection of the vessel "Jolly Blu" at the Port of Genoa, Italy. Lieutenant Paolo Leone checking whether the ship complies with international standards for ship safety, marine pollution prevention and for decent working and living conditions for seafarers, as set forth in the  ILO\'s Maritime Labour Convention, 2006. © ILO​',
  equality_heading: 'Equality and non-discrimination',
  equality_title: 'Free to be yourself',
  equality_chapter: 'chapter 3',
  equality_calloutHundreds: 'Hundreds of millions of people suffer discrimination at work. This violates a basic human right and has wider social and economic consequences.',
  equality_infoConsequencesTitle: 'The consequences of discrimination',
  equality_infoConsequencesBody1: '<h3>The consequences of discrimination</h3>\n\n<p>Discrimination is present in every country and industry in the world. It stifles opportunities for individuals, wastes human talent and slows economic progress. It raises social tensions and widens inequalities.</p>\n\n<p>Combating discrimination is an essential part of the ILO’s Decent Work Agenda. Success on this front is felt well beyond the workplace; in the home, at school and even on the streets.</p>',
  equality_infoConsequencesBody2: 'Combating discrimination is an essential part of the ILO’s Decent Work Agenda. Success on this front is felt well beyond the workplace; in the home, at school and even on the streets.',
  equality_infoDiscriminationTitle: 'Discrimination affects us all',
  equality_infoDiscrimination: '<h3>Discrimination affects us all</h3>\n\n<p>Discrimination can be based on many different grounds, such as race, gender, sexual orientation, HIV status, disability, age and political affiliation. Indigenous peoples, ethnic minorities, religious groups, women and migrants are often targets of discrimination. It’s also linked to violence and harassment against women and men in the world of work.</p>\n\n<p>Sexual harassment is widespread. It reinforces stereotypes about women’s abilities and aspirations and can drive them out of work, destroy their careers and have lasting effects on every aspect of their lives.  </p>',
  equality_infoDiscriminationBody2: 'Discrimination affects us all',
  equality_infoResearchnineBody: '<p>ILO research in nine countries undertaken between 2013 and 2016 confirms that stereotypes and prejudice affect the employment prospects of lesbian, gay, bisexual and transgender people.</p>',
  equality_infoDomino: '<h3>The domino effect of discrimination</h3>\n\n<p>Discrimination, both subtle and overt, can also affect a person\'s mental health. Decades of research have shown that when we are treated unfairly or work in a hostile environment over prolonged periods of time, it puts us at a higher risk of developing stress-related disorders, anxiety and depression. </p>\n\n<p>When the mental health of one person is affected, it can produce a domino effect extending beyond that individual to their partners, children and colleagues.</p>\n\n<p>Discrimination can prevent people from achieving their dreams and pursuing their careers. It limits their potential and costs economies over USD 400 billion in lost GDP growth globally.</p>',
  equality_infoDominoBody1: 'Discrimination, both subtle and overt, can also affect a person\'s mental health. Decades of research have shown that when we are treated unfairly or work in a hostile environment over prolonged periods of time, it puts us at a higher risk of developing stress-related disorders, anxiety and depression. When the mental health of one person is affected, it can produce a domino effect extending beyond that individual to their partners, children and colleagues.',
  equality_infoDominoBody2: 'Discrimination can prevent people from achieving their dreams and pursuing their careers. It limits their potential and costs economies over USD 400 billion in lost GDP growth globally.',
  equality_calloutPortraitsTitle: 'PORTRAITS OF INEQUALITY',
  equality_calloutPortraitsBody: 'Discrimination is not only morally wrong; it also violates human rights and has negative economic consequences.',
  equality_infoBreakingexpectTitle: 'Breaking expectations on gender',
  equality_infoBreakingexpectBody1: '<h3>Breaking expectations on gender</h3>\n\n<p>A joint ILO-Gallup report ‘Towards a better future for women and work’ provided the first-ever account of global attitudes and perceptions of women and men regarding women and work. The findings revealed that men’s views are very similar to women’s in many instances, showing that there is a worldwide desire for women to be given the same opportunities as men to work.</p>\n\n<p>The poll was conducted in 142 countries and territories, surveyed almost 149,000 adults and is representative of more than 99% of the global adult population.</p>',
  equality_infoBreakingexpectBody2: 'The poll was conducted in 142 countries and territories, surveyed almost 149,000 adults and is representative of more than 99% of the global adult population.',
  equality_calloutDespiteprog: 'Despite significant progress over the past century, women are still far less likely to be part of the labour force than men.',
  equality_calloutAbetterlife: 'A BETTER WORKING LIFE for ALL',
  equality_infoIlsdiscrimination: '<p>ILO labour standards and guidelines aimed at combating discrimination are recognized by governments, workers and employers around the world.</p>\n\n<p>As more countries have introduced laws to protect against discrimination and promote equality, ILO experts have played a crucial role in providing advice on legislative changes in this area.</p>',
  equality_calloutConclusion1: 'A world without discrimination is possible. We all have a role to play in achieving it.',
  equality_imageMigrantreturnCaption: 'Migrant workers are often subject to discrimination, violence and harassment. This migrant worker has received psychosocial help at an ILO-supported reintegration project in Addis Ababa, Ethiopia. © ILO/A. Muluneh',
  equality_imageMigrantreturnAlt: 'A woman’s head is photographed in front of the window. The photo is backlit and her face is not showing.',
  equality_imageDisabledgarmentCaption: 'Garment worker, Nguyen Thi Thanh Hieu. Dong Nai, Vietnam. Workers with disabilities are supported under the Better Work programme run by the ILO and the International Finance Corporation to improve working conditions and labour rights in the garment industry. © ILO/A. Nguyen',
  equality_imageDisabledgarmentAlt: 'A woman with a hunchback is sitting in front of a sewing machine in a factory with a small piece of cloth in her hands.',
  equality_imageTranswomenCaption: 'Trans women participate at a kitchen assistant course in Brazil for lesbian, gay, bi-sexual, transgender and intersex people, taught by the Argentine chef Paola Carosella. The ILO promotes the inclusion of LGBTI people in the formal job market. © ILO Brazil',
  equality_imageTranswomenAlt: 'Two female chefs cooking with ingredients laid out on a stainless steel table in the kitchen.',
  equality_visGenderBody: '<h3>Gender</h3>\n\n<p>Gender equality strengthens economies, contributing to their growth over time. A McKinsey Global Institute report, for example, found that USD 12 trillion could be added to global GDP by 2025 by advancing women’s equality.</p>\n\n<p>The drive for gender equality is reflected in all aspects of ILO work and is enshrined in both its Constitution and its Fundamental Conventions.</p>\n\n\n\n\n\n\n\n</h3>',
  equality_visLgbtBody: '<h3>LGBT</h3>\n\n<p>A recent study on India by the World Bank estimates that in addition to the pain and suffering experienced by lesbians, gays, bisexuals or transgender people (LGBT) because of discrimination, the economic cost to the country due to homophobia could be as high as 1.7% of GDP lost per year.</p>\n\n<p>The ILO’s Gender Identity and Sexual Orientation: Promoting Rights, Diversity and Equality in the World of Work (PRIDE) project conducts research on discrimination against LGBT workers across the world and highlights good practices that promote their inclusion in the workplace. Countries supported included Argentina, Costa Rica, France, Hungary, India, Indonesia, Montenegro, South Africa and Thailand.</p>',
  equality_visHivBody: '<h3>HIV and AIDS</h3>\n\n<p>HIV workplace policies and education, together with the promotion of voluntary HIV testing and counselling, reduces stigma in the workplace and enables employees to know their HIV status early and have treatment if they test positive. With treatment, workers’ health improves. They take fewer days off work and become more productive in their jobs. This ensures that people living with HIV are able to continue working and take care of their families.</p>\n\n<p>The ILO’s Voluntary Counselling and Testing Initiative (VCT@WORK)  has reached over 6 million workers, helping them to know their HIV status and seek treatment, if necessary.</p>\n\n<p>Initiatives like these help reduce the number of those unable to work because they are HIV positive. In 2005, 350,000 people were unable to work because they were living with HIV. That number is expected to fall to 40,000 by 2020 - an 85% decline for men and 93% decline for women.</p>',
  equality_visDisabilityBody: '<h3>Disability</h3>\n\n<p>Some 1 billion people or one-seventh of the global population have a disability. Of these, approximately 800 million are of working age but many are excluded from the workplace. This not only prevents them from participating fully in society but also leads to a loss of between 3% and 7% of GDP worldwide.</p> \n\n<p>The ILO Global Business and Disability Network, comprising multinational enterprises with over 4.2 million total employees, shares knowledge and develops ways for companies and employers to hire and retain people with disabilities.</p>',
  equality_visIndigenousBody: '<h3>Indigenous peoples</h3>\n\n<p>Indigenous peoples constitute approximately 5% of the world’s population but 15% of the poor. They are often deprived of their land, of quality education and their voices are not heard in government. Increasing their participation in the formal jobs sector could lift millions of them, especially women, out of poverty.</p>    \n\n<p>The ILO has been engaged with indigenous and tribal peoples’ issues since the 1920s. It is responsible for the Indigenous and Tribal Peoples Convention, 1989 (No. 169), the only international treaty that deals exclusively with their rights.</p>',
  equality_visEthnicityBody: '<h3>Ethnicity</h3>\n\n<p>If men and women of immigrant ancestry had more economic power, governments would benefit from additional revenues in the form of income and corporate taxes. For example, research has shown that in France ending discrimination against persons of North-African descent would lead to a 2.0% increase in additional revenues and reduce government spending by 0.5%.</p>\n\n<p>The elimination of discrimination based on race has been at the core of ILO work since 1919 and is enshrined in the Discrimination (Employment and Occupation) Convention, 1958 (No. 111). The obligation of all member States to respect, promote and realize this principle was reaffirmed in the ILO Declaration on Fundamental Principles and Rights at Work, adopted in 1998.</p>',
  equality_visMigrantBody: '<h3>Migrant workers</h3> \n\n<p>Migrant workers are among the groups most affected by economic downturns and the first to be discriminated against. However, the consensus among economists is that immigration has a small but positive impact on wages and employment because immigration makes economies grow.</p>\n\n<p>The ILO Fair Recruitment Initiative helps to create fair recruitment options for migrant workers. The Migrant Worker Recruitment Adviser platform helps protect migrant workers from abusive employment practices through user reviews of recruitment agencies.</p>',
  equality_visDomesticBody: '<h3>Domestic workers</h3>\n\n<p>According to ILO statistics, there are 67 million domestic workers across the world. They care, clean and cook for families and are a fundamental part of the care economy. Yet only 10% enjoy labour protections equal to those of other workers. They typically earn less than half of average wages and account for a significant proportion of the gender wage gap at the bottom of the labour market. The poor working conditions many of them are subjected to are rooted in various forms of discrimination: domestic work is often not seen as work but as part of the natural responsibilities of women.</p>\n\n<p>The Domestic Workers Convention, 2011 (No. 189) is the only internationally recognized standard aimed at protecting and improving the working and living conditions of domestic workers worldwide.</p>',
  equality_visAgeBody: '<h3>Age</h3>\n\n<p>There are considerable economic costs associated with the low labour force participation of older people. In Australia, if an extra 3% of workers aged 55 and over were to participate in the labour force, it would result in a USD 33 billion boost to GDP, or increase the national income by around 1.6%. A 5% increase in paid employment among this group would result in USD 48 billion in extra GDP, or 2.4% of national income.</p>\n\n<p>The ILO specifically addresses the situation of older workers in the Older Workers Recommendation, 1980 (No. 162). This offers a constructive framework for countries to follow in providing decent and productive conditions of work for older workers.</p>',
  equality_visWomen1Body: 'Women\'s preferences',
  equality_visWomen2Body: 'Men’s preferences',
  equality_visWomen3Body: '<p>70% of women and 66% of men surveyed would prefer that women work in paid jobs – more than double the percentages of those who would prefer women to stay at home.</p>',
  equality_visWomen4Body: '<p>Women worldwide would prefer to be either working at paid jobs (29%) or be in situations in which they could both work and take care of their families (41%), according to the report.</p>',
  equality_visWomen5Body: '<p>Only 27% of women want to stay at home, and only 29% of men believe the same.</p>',
  equality_visWomen6Body: '<p>The 70% of women who would like to work at paid jobs notably includes a majority of women who are not in the workforce.</p>',
  equality_visWomen7Body: '<p>Importantly, this is true in all regions worldwide, including several regions where women’s labour force participation is traditionally low, such as the Arab States.</p>',
  equality_visWomen8Body: 'Prefer that women work at paid jobs',
  equality_visWomen9Body: 'Prefer that women do both',
  equality_visWomen10Body: 'Prefer that women stay home, do housework and care for families',
  equality_visWomen11Body: 'Don’t know or refused to answer',
  equality_date: 'Discover this chapter on 30 January 2019',
  equality_visGapTitle: 'The gender gap in the labour force',
  equality_visGapSelect: 'Search country',
  equality_visGapRatesLabel: 'Labour force participation rates (%)',
  equality_visGapWomen: 'women in the labour force',
  equality_visGapMen: 'men in the labour force',
  equality_visGapGap: 'GAP',
  equality_visGapNote: 'Data from 2019. Source: ILOSTAT – ILO modelled estimates.',
  equality_visGapDefinition: 'LABOUR FORCE refers to the sum of all persons of working age (aged 15+) who are employed and those who are not employed but are actively looking for a job and currently available for work. The labour force participation rate expresses the labour force as a percentage of the working-age population.',
  equality_calloutPortraitsSub: 'The cost of inequality runs into billions of dollars, depriving countries of wealth, both in monetary terms and talent. We would all be better off if every member of society had the same opportunities.',
  equality_calloutDespiteProgSub: 'The current global labour force participation rate for women is close to 49%. For men, it’s 75%.',
  equality_calloutConclusion2: 'Equality is a means to creating decent jobs for all men and women and is built into every ILO employment programme.',
  equality_infoWomenBody1: '<p>When women are employed, they tend to work in low quality and low paid jobs that are often undervalued. </p>\n\n<p>In all parts of the world, women work longer hours when paid and unpaid working hours are combined. Women perform 75% of unpaid care work globally. ILO estimates that 16.4 billion hours are spent on unpaid care work every day, which is the equivalent of 9% of global GDP. </p>',
  equality_infoWomenBody2: 'To help solve this problem, the Equal Pay International Coalition (EPIC), led by the ILO, UN Women and the OECD, works to take concrete steps with governments, the private sector, civil society and academia to accelerate the closing of the gender pay gap and the achievement of pay equity by 2030.',
  equality_calloutPortraitsSubtitle: 'Explore these portraits from the world of work to learn more.',
  equality_portrait0Detail1: '<h3>Carling, indigenous leader</h3>\n\n<p>Carling Dumulot, Sr., is an Aeta, an indigenous people who live in the Philippines. Throughout his lifetime he has seen his culture and the needs of his people marginalized and ignored, even on their ancestral lands.</p>\n\n<p>As an indigenous leader, his struggle has been to achieve the recognition necessary to help lift his people out of poverty, and to gain a voice in the decisions that affect their daily lives.</p>',
  equality_portrait0Detail2: '<h3>Fighting discrimination against indigenous peoples</h3>\n\n<p>Indigenous peoples constitute approximately 5% of the world’s population but 15% of the poor. They are often deprived of their land, of quality education and their voices are not heard in government. Increasing their participation in the formal jobs sector could lift millions of them, especially women, out of poverty.</p>\n\n<p>The ILO has been engaged with indigenous and tribal peoples’ issues since the 1920s. It is responsible for the Indigenous and Tribal Peoples Convention, 1989 (No. 169), the only international treaty that deals exclusively with their rights.</p>',
  equality_portrait0Caption: 'Carling Dumulot, Sr., an indigenous leader of the Aeta people who, together with the ILO, has fought to raise awareness to raise awareness of the discrimination faced by indigenous peoples in the Philippines and around the world. © ILO',
  equality_portrait1Detail1: '<h3>Sreela, diversity and inclusion specialist</h3>\n\n<p>Sreela Das Gupta’s world changed 15 years ago when she was diagnosed with an autoimmune disease which left her in a wheelchair. Living with a disability changed how people viewed her and the expectations they had of her at work.</p>',
  equality_portrait1Detail2: '<p>However, she considers herself lucky that her employers were willing to engage with her to identify how she could best contribute to her workplace, as this is still uncommon in much of the world today.</p>\n\n<p>Empowering others has therefore become a guiding force in her life as she has worked to support others in areas of disability inclusion, human rights, women’s empowerment, and maternal and child health for over two decades.</p>',
  equality_portrait1Caption: 'Sreela Das Gupta, a specialist in helping businesses become diverse and inclusive of people living with disabilities in the workplace. © ILO',
  equality_portrait2Detail1: '<h3> Abel, migrant worker</h3>\n\n<p>People like Abel Torres Garcia are critical to providing the food supply of industrialised nationals and supporting their economies. </p>\n\n<p>For years he has done backbreaking work under the sun, for little pay and even less recognition, harvesting the sweet potatoes, tomatoes and other vegetables, which end up on our tables. Despite working at jobs which few locals want to do, he has been forced to endure discrimination on an almost daily basis.</p>',
  equality_portrait2Detail2: '<h3>Fighting discrimination against migrant workers</h3>\n\n<p>Migrant workers are among the groups most affected by economic downturns and the first to be discriminated against. However, the consensus among economists is that immigration has a small but positive impact on wages and employment because immigration makes economies grow.</p>',
  equality_portrait2Caption: 'Abel Torres Garcia, a Mexican migrant farmworker in North Carolina, USA, faces discrimination on a daily basis despite legally working in an industry that contributes $84 billion to the state economy. © Marc McAndrews',
  equality_portrait3Detail1: '<h3>Blanca, older worker</h3>\n\n<p>77-year-old Blanca Ester Castillo faced poverty and exclusion throughout her life. Despite caring for 14 children and 11 grandchildren, the older she got the more age discrimination she faced when searching for work.</p>\n\n<p>She is one of the millions of women and men who are primary breadwinners but who face an uphill struggle to keep providing for their family as they get older.</p>',
  equality_portrait3Detail2: '<h3>Fighting discrimination against the elderly</h3>\n\n<p>There are considerable economic costs associated with the low labour force participation of older people. In Australia, if an extra 3% of workers aged 55 and over were to participate in the labour force, it would result in a USD 33 billion boost to GDP, or increase the national income by around 1.6%.</p>',
  equality_portrait3Caption: 'Blanca Ester Castillo, a 77-year-old recent retiree who faced discrimination when looking for work, with few employers willing to hire a woman of her age. © ILO',
  equality_visGapWorld: 'World',
  equality_portrait4Detail1: '<h3>Aya, award-winning director</h3>\n\n<p>Aya Tanimura is an award-winning music video, television and film director. Her work has amassed over a billion cumulative views online, thanks to her highly original, beautiful concepts for major brands and companies, as well as artists such as Katy Perry.</p>',
  equality_portrait4Detail2: '<p>Yet, Tanimura feels the discrimination and barriers faced by women working in film and television production has limited the work she’s been able to do. She’s even been assumed by others on set to be an assistant on the very projects she’s directing. </p>\n\n<p>Of the top 100 grossing films of 2018, only 4% were directed by women.</p>',
  equality_portrait4Caption: 'Aya Tanimura, an Australian-Japanese film director who has faced discrimination in her workplace due to her gender. © Lauren Crew',
  equality_portrait5Detail1: '<h3>Emma, lesbian rights activist</h3>\n\n<p>Emma Chacón A. is a survivor of workplace harassment. In 2014, when others at her workplace found out that she was lesbian rights’ activist, her responsibilities at work were reduced without warning and she had to endure homophobic comments from colleagues.</p>\n\n<p>Later, she was transferred against her will to a different work location.</p>',
  equality_portrait5Detail2: '<p>At first she couldn’t believe what was happening to her, since, as a feminist lesbian she had always felt empowered. Her treatment at work, however, made her question herself, her work and her worth.</p>\n\n<p>It took several years to recover and feel acknowledged, appreciated and valued at work again. Today, she takes pride in supporting others who have experienced similar situations and helping them overcome those situations.</p>',
  equality_portrait5Caption: 'Emma Chacón A., a survivor of workplace harassment and discrimination due to her lesbian rights activism. © Emma Chacón A.',
  equality_portrait6Detail1: '<h3>Laurel, HIV and human rights activist</h3>\n\n<p>Laurel Sprague was diagnosed HIV positive in 1991, when she was 20 years old. She became ill very quickly and many of her friends and those in her support network were dying, too.</p>\n\n<p>Almost immediately, she faced stigma and discrimination. At the time, she couldn’t do anything to stop what was happening to her, but she could do a lot to be there for others in the same situation.</p>\n\n<p>Today, she’s a leader in addressing the stigma related to HIV, fighting to end the discrimination, criminalization and human rights abuses endured by those living with HIV.</p>',
  equality_portrait6Detail2: '<h3>Fighting discrimination against people living with HIV</h3>\n\n<p>HIV workplace policies and education, together with the promotion of voluntary HIV testing and counselling, reduces stigma in the workplace and enables employees to know their HIV status early and have treatment if they test positive.</p>\n\n<p>With treatment, workers’ health improves. They take fewer days off work and become more productive in their jobs. This ensures that people living with HIV are able to continue working and take care of their families.</p>',
  equality_portrait6Caption: 'Laurel Sprague, an activist fighting to end the discrimination, criminalization, and human rights abuses endured by those living with HIV © ILO',
  equality_portrait7Detail1: '<h3>Zainab, domestic worker</h3>\n\n<p>Zainab Ecumba spent years being harassed. “They saw me as an object,” she says. “My employers would lift my clothes up or when I was sleeping they’d come and pull the blankets off and laugh about it.”</p>\n\n<p>Domestic workers are vulnerable to harassment because they work behind closed doors where no one is watching. Outsiders rarely believed Zainab when she told them of her ordeal.</p>',
  equality_portrait7Detail2: '<h3>Fighting discrimination against domestic workers</h3>\n\n<p>According to ILO statistics, there are 67 million domestic workers across the world. They care, clean and cook for families and are a fundamental part of the care economy. Yet only 10% enjoy labour protections equal to those of other workers.</p>\n\n<p>They typically earn less than half of average wages and account for a significant proportion of the gender wage gap at the bottom of the labour market.</p>',
  equality_portrait7Caption: 'Zainab Ecumba, a domestic worker who has faced years of harassment, violence and discrimination in the homes of some of her employers. © Zainab Ecumba',
  equality_portrait8Detail1: '<h3>Leroy, football coach</h3>\n\n<p>Leroy Rosenior was a professional football player in the 1980s and 1990s. “The racial discrimination was horrific,” he says. “You know that phrase: ‘sticks and stones may break my bones, but words will never hurt me’? Well, I reckon I’ve broken half the bones in my body, but the physical pain is soon over. The words, though, have stayed with me.”</p>\n\n<p>In recent years, Leroy has been working with Show Racism the Red Card to educate people about the dangers of racism in football and across society. In March 2019, he will receive an MBE, a prestigious award given by the United Kingdom’s monarch. He hopes that the MBE will highlight the work of the organization and promote the ongoing need to tackle racial discrimination in football and in all workplaces.</p>',
  equality_portrait8Detail2: '<h3>Fighting racial discrimination</h3>\n\n<p>If those men and women experiencing racial discrimination had more economic power, governments would benefit from additional revenues in the form of income and corporate taxes.</p>\n\n<p>For example, research has shown that in France ending discrimination against persons of North-African descent would lead to a 2.0% increase in revenues and reduce government spending by 0.5%.</p>',
  equality_portrait8Caption: 'Leroy Rosenior, a professional football player in the 1980s and 1990s, suffered from racial discrimination both on and off the pitch. © Show Racism the Red Card',
  equality_portrait1Detail3: '<h3>Fighting discrimination against people with disabilities</h3>\n\n<p>Some 1 billion people or one-seventh of the global population have a disability. Of these, approximately 800 million are of working age but many are excluded from the workplace.</p>',
  equality_portrait1Detail4: '<p>This not only prevents them from participating fully in society but also leads to a loss of between 3% and 7% of GDP worldwide.</p>\n\n<p>The ILO Global Business and Disability Network, comprising multinational enterprises with over 4.2 million total employees, shares knowledge and develops ways for companies and employers to hire and retain people with disabilities.</p>',
  equality_portrait2Detail3: '<p>The ILO Fair Recruitment Initiative seeks to maximize the protection of migrant workers in the recruitment process.</p>\n\n<p>The Migrant Worker Recruitment Adviser platform helps protect migrant workers from abusive employment practices through user reviews of recruitment agencies.</p>',
  equality_portrait3Detail3: '<p>A 5% increase in paid employment among this group would result in USD 48 billion in extra GDP, or 2.4% of national income.</p>\n\n<p>The ILO specifically addresses the situation of older workers in the Older Workers Recommendation, 1980 (No. 162). This offers a constructive framework for countries to follow in providing decent and productive conditions of work for older workers.</p>',
  equality_portrait4Detail3: '<h3>Fighting gender discrimination</h3>\n\n<p>Gender equality strengthens economies, contributing to their growth over time. A McKinsey Global Institute report found that USD 12 trillion could be added to global GDP by 2025 by advancing women’s equality.</p>\n\n<p>The drive for gender equality is reflected in all aspects of the ILO’s work and is enshrined in both its Constitution and its Fundamental Conventions.</p>',
  equality_portrait5Detail3: '<h3>Fighting discrimination against LGBTI people</h3>\n\n<p>Every country in the world stands to gain from better defending the rights of lesbians, gays, bisexuals, transgender and intersex people (LGBTI).</p>\n\n<p>For example, a recent World Bank study in India estimates that in addition to the pain and suffering experienced by the LGBT community because of discrimination, homophobia could cost the country the loss of up to 1.7% of GDP per year.</p>',
  equality_portrait5Detail4: '<p>The ILO’s ‘Gender Identity and Sexual Orientation: Promoting Rights, Diversity and Equality in the World of Work (PRIDE)’ project conducts research on discrimination against LGBTI workers around the world and highlights good practices that promote their inclusion in the workplace.</p>\n\n<p>Countries supported included Argentina, Costa Rica, France, Hungary, India, Indonesia, Montenegro, South Africa and Thailand.</p>',
  equality_portrait6Detail3: '<p>The ILO’s Voluntary Counselling and Testing Initiative (VCT@WORK) has reached over 6 million workers, helping them to know their HIV status and seek treatment, if necessary.</p>\n\n<p>Initiatives like these help reduce the number of people unable to work because they are HIV positive. In 2005, 350,000 people were unable to work because they were living with HIV. That number is expected to fall to 40,000 by 2020 - an 85% decline for men and 93% decline for women.</p>',
  equality_portrait7Detail3: '<p>The poor working conditions many of them are subjected to are rooted in various forms of discrimination: domestic work is often not seen as work but as part of the natural responsibilities of women.</p>\n\n<p>The Domestic Workers Convention, 2011 (No. 189) is the only internationally recognized standard aimed at protecting and improving the working and living conditions of domestic workers worldwide.</p>',
  equality_portrait8Detail3: '<p>The elimination of discrimination based on race has been at the core of ILO work since 1919 and is enshrined in the Discrimination (Employment and Occupation) Convention, 1958 (No. 111).</p>\n\n<p>The obligation of all member States to respect, promote and realize this principle was reaffirmed in the ILO Declaration on Fundamental Principles and Rights at Work, adopted in 1998.</p>',
  enterprises_heading: 'Sustainable enterprises',
  enterprises_chapter: 'chapter 8',
  enterprises_title: 'Businesses for people and planet',
  enterprises_date: 'Discover this chapter on 6 March 2019',
  enterprises_infoAroundtheworld: '<p>Around the world, demand is growing for businesses that are motivated by more than just profit. Companies and consumers are increasingly looking for products and services that have a positive impact on the community, the environment and society.</p>\n\n<p>Sustainable enterprises include small, medium, large and multinational enterprises, cooperatives, "green" businesses and social enterprises which balance their own needs and interests with those of the people who work for them and with the wider society.</p> \n\n<p>The promotion of these businesses is at the heart of the ILO Decent Work Agenda, which hinges on job creation, rights at work, social protection and social dialogue. In turn, it links with the global goals of the United Nations, known as the Sustainable Development Goals, which put decent jobs at the centre of economic policy-making and development. This kind of development benefits everyone in the present and the future, without harming the environment.</p>',
  enterprises_infoTherightenviro: '<h3>The right environment for the right businesses</h3>\n\n<p>Sustainable enterprises do not exist in a vacuum. Driving innovation and growth for more and better jobs requires the right environment at the regional, national and local level. The ILO is involved at every step of this journey.</p>\n\n<p>From individuals and enterprises to national institutions, the ILO helps stakeholders build the right environment for businesses of all shapes and sizes to achieve the Sustainable Development Goals through its Enabling Environment for Sustainable Enterprises programme.</p>',
  enterprises_infoInclusive: '<h3>Inclusive, responsible and sustainable workplaces</h3>\n\n<p>At the heart of the ILO’s work is the idea that tripartism - bringing together governments, employers’ and workers’ representatives to achieve consensus -  is essential to creating businesses that work for people and planet.</p>\n\n<p>The ILO Tripartite Declaration of Principles concerning Multinational Enterprises and Social Policy, known as the MNE Declaration, is the only global legal document that provides specific guidance to enterprises on social policy and inclusive, responsible and sustainable workplace practices.</p>\n\n<p>Elaborated and adopted by governments, employers and workers from around the world, the Declaration aims to encourage the positive contributions multinational enterprises can make to economic and social progress and the realization of decent work for all. It also seeks to minimize and resolve difficulties enterprises may encounter in the process.</p> \n\n<p><mark id="enterprises_infoInclusive">ILO support to governments, enterprises and entrepreneurs draws from the guidance of the MNE Declaration.</mark></p>',
  enterprises_detailInclusive1: '<h3>Decent work and the Olympic Games</h3>\n\n<p>Building on a unique partnership between the ILO and the Tokyo 2020 Organizing Committee of the Olympic and Paralympic Games, guidance from the MNE Declaration is promoting socially-responsible labour practices among the enterprises contributing to the 2020 Olympic Games.</p>',
  enterprises_infoEntrepreneurship: '<h3>Entrepreneurship tools for success</h3>\n\n<p>The ILO helps individuals and organizations obtain, improve and retain the skills and knowledge they need to develop sustainable enterprises.</p>\n\n<p>Explore two of our programmes:</p>\n\n<ul>\n<li>Start and Improve Your Business (SIYB)</li>\n<li>Know about Business (KAB)</li>\n</ul>',
  enterprises_visStartyourbusHeading: 'Start your business',
  enterprises_visKnowyourbusHeading: 'Know your business',
  enterprises_visKnowyourbusBody: 'What do you want to know about business?',
  enterprises_visStartyourbusBody: 'One of the largest business management training programmes in the world',
  enterprises_visStartyourbusDetail1: '<h3>One of the largest business management training programmes in the world</h3>\n\n<p>With a network of over 65,000 trainers in over 100 countries and over 15 million entrepreneurs trained, the Start and Improve Your Business programme is one of the largest entrepreneurship training programmes globally today.</p>',
  enterprises_visKnowyourbusDetail1: '<h3>What do you want to know about business?</h3>\n\n<p>Encouraging entrepreneurship in the younger generation, the Know about Business programme helps young women and men aged 15 to 24 better understand the world of business. It raises their awareness of the opportunities and challenges of entrepreneurship and self-employment.</p>',
  enterprises_infoHelpingbus: '<h3>Helping businesses succeed</h3>\n\n<p>The ILO provides training and advice to help existing businesses adopt responsible, sustainable and inclusive workplace practices at the enterprise level.</p>\n\n<p>Our programmes cover all aspects, from counselling to improve productivity and working conditions to training and support services for women who wish to start and run their businesses. These programmes include:</p>\n\n<ul>\n<li>My.COOP</li>\n<li>Sustaining Competitive and Responsible Enterprises</li>\n<li>Women’s Entrepreneurship Development</li>\n</ul>',
  enterprises_visAgriCoopHeading: 'Agricultural cooperatives',
  enterprises_visAgriCoopDetail1: '<h3>My.COOP</h3>\n\n<p>In agricultural cooperatives, farmers pool their resources in certain areas of activity to lower costs and benefit from economies of scale. My.COOP is the ILO’s training programme for existing and potential managers of agricultural cooperatives. </p>',
  enterprises_visResponsibleHeading: 'Responsible enterprises',
  enterprises_visResponsibleDetail1: '<h3>Sustaining Competitive and Responsible Enterprises (SCORE)</h3>\n\n<p>Aimed at small and medium-sized companies from emerging markets in global and local supply chains, this training and in-factory counselling helps improve productivity and working conditions. SCORE covers issues such as workplace cooperation, quality management, and occupational safety and health.</p>',
  enterprises_visWomenentrepHeading: 'Women\'s entrepreneurship',
  enterprises_visWomenentrepDetail1: '<h3>Women’s Entrepreneurship Development (ILO-WED)</h3>\n\n<p>The ILO-WED programme provides training and support services for women, with a gender perspective that contributes to their empowerment. It accomplishes this by working with governments, employers’ and workers’ organizations, as well as service providers, to improve their services and outreach to women.</p>',
  enterprises_infoAddresslegal: '<h3>Addressing legal challenges</h3>\n\n<p>Even the most well-meaning companies can struggle to align their business operations with international labour standards and to build good industrial relations.</p>\n\n<p>This is where the ILO Helpdesk for Business on International Labour Standards comes in. It is a confidential legal tool for company managers and workers that provides information and advice on a wide range of labour topics, all derived from the MNE Declaration.</p>\n\n<p>Enterprises have received support to combat child labour and forced labour, to counter discrimination and inequality in the workplace and to improve occupational safety and health, to name a few examples.</p>',
  enterprises_infoRethinking: '<h3>Rethinking the way we do business</h3>\n\n<p>Today’s challenges – unemployment, exclusion, inequality, economic shocks, poverty and global warming – oblige us to rethink the way we do business.</p>\n\n<p>One result of this has been the emergence of social enterprises – businesses with explicit economic and social objectives, often related to the environment, as part of a growing social and solidarity economy.</p>\n\n<p>Unlike conventional for-profit enterprises, they are often made up of grassroots associations and cooperatives, fair trade associations and NGOs. Many are voluntary and driven by a particular cause such as women\'s rights, which makes them increasingly influential in public policy-making around the world.</p>',
  enterprises_calloutIntheeu: 'In the European Union, about 10% of all companies and 6% of total employment are in the social and solidarity economy, many of them cooperatives.',
  enterprises_visWhyrcoops: 'Why are cooperatives important?',
  enterprises_visWhyrcoopsDetail1: '<h3>Cooperating for decent work</h3>\n\n<p>Cooperatives are a key pillar of the social and solidarity economy. They are unique among sustainable enterprises in that they are jointly owned and democratically controlled by their members.</p>\n\n<p>Cooperatives exist in all sectors of the economy, providing at least 279 million jobs around the world. </p>',
  enterprises_visWhyrcoopsDetail2: '<p>As acknowledged in the ILO Promotion of Cooperatives Recommendation, 2002 (No.193), member-owned, democratically controlled cooperatives contribute to creating quality jobs, mobilizing resources and generating investment, including for disadvantaged groups.</p>\n\n<p>The UN estimates that the livelihoods of 3 billion people are made more secure by cooperatives, thanks to the food, social protection, energy, health and financial services they provide.</p>\n\n<p>Today, cooperatives represent between 3 and 3.5% of global GDP, with the 2,000 largest cooperatives in 56 countries having a total turnover of over USD 2.5 trillion.</p>',
  enterprises_calloutCooperatives: 'Cooperatives contribute to the ILO’s overall goal of creating decent work for all through productive employment, social protection and the respect for rights. They also give people a forum where they can express their concerns and organize and participate in the decisions that affect their lives.',
  enterprises_calloutTheilosupports: 'The ILO supports the social and solidarity economy through advocacy, partnerships, training and policy advice.',
  enterprises_calloutPositiveimpact: 'The positive impact of sustainable enterprises will be a central consideration as the ILO looks towards the future of work.',
  enterprises_calloutAfuture: 'A future we should all want to live in.',
  enterprises_imageNuragroCaption: 'Workers of the Nuragro Association in Colombia receive ILO training. © ILO',
  enterprises_imageNuragroAlt: 'Three workers in a plant farm moving around plants.',
  enterprises_imageLakumbaCaption: 'A worker from La Kumba Association, Colombia, during ILO training. © ILO',
  enterprises_imageLakumbaAlt: 'A woman holding a basket full of strawberries in a greenhouse.',
  enterprises_imageTechnicianCaption: 'Technician at the production site of a multinational manufacturer of laundry detergents in Cairo, Egypt. © ILO/M. Crozet',
  enterprises_imageTechnicianAlt: 'A man in a blue uniform with a protective mask and glasses at a factory full of machinery.',
  enterprises_imageSiybCaption: 'SIYB training, Burundi. © ILO',
  enterprises_imageSiybAlt: 'A female trainer holding up a drawing of a fruit vendor and a customer.',
  enterprises_imageKabCaption: 'Participants at a KAB training in Syria. © ILO',
  enterprises_imageKabAlt: 'Three male and two female trainees sitting around a desk discussing materials in their binders.',
  enterprises_imageKhouryCaption: 'Clothing designer, Lara Khoury, in her studio in Beirut, Lebanon. © ILO/J. Saad',
  enterprises_imageKhouryAlt: 'A woman drawing on a piece of paper at a table. There is an assortment of fabrics and magazines around her.',
  enterprises_imageMycoopCaption: 'My.Coop training, Mongolia. © ILO',
  enterprises_imageMycoopAlt: 'Four male and one female trainees are sitting around a desk and writing materials to present on a large piece of paper.',
  enterprises_imageScoreCaption: 'Workers of a orange packing factory under ILO SCORE Programme, Peru. © ILO',
  enterprises_imageScoreAlt: 'Four workers in white protective vests and caps sorting oranges on a conveyor belt.',
  enterprises_imageWedCaption: 'Josepha is a participant of the ILO’s Women’s Entrepreneurship Development and Economic Empowerment programme in Tanzania. © ILO/D. Debru',
  enterprises_imageWedAlt: 'A woman speaking to a person on the other side of her desk.',
  enterprises_imageChildhuachipaCaption: 'A child worker in a brick factory, Huachipa, Peru. © ILO/E. Gianotti',
  enterprises_imageChildhuachipaAlt: 'A child with dirty clothes and muddy hands.',
  enterprises_imageWomencoopCaption: 'Women members of a cooperative in Dar Es Salaam, Tanzania. © ILO/M. Crozet',
  enterprises_imageWomencoopAlt: 'Two women in a large chicken coop holding white hens in their hands.',
  enterprises_imageFarmerseedsCaption: 'Farmers buy seeds from a cooperative in Kaolack, Senegal © World Bank/D. Van Leggelo-Padilla',
  enterprises_imageFarmerseedsAlt: 'Two men standing in front of piles of seed bags and talking with paper and a calculator in their hands.',
  enterprises_imagePalestinianCaption: 'Palestinian trainer, Eman Abu Medeen, at the ILO-funded "Children\'s Land" centre in Um Al-Nasser Bedouin Village in the northern Gaza Strip. © S. Jadallah',
  enterprises_imagePalestinianAlt: 'A woman standing next to a display of handcrafted wooden toys and dolls.',
  enterprises_visStartyourbusDetail2: '<p>SIYB has four training packages that respond to all the stages of business development: from generating your business idea to starting, improving and expanding your business. It covers everything from business plan development to stock control, marketing, costing, record-keeping and human resource management.</p>',
  enterprises_visKnowyourbusDetail2: '<p>In the last decade, the KAB programme has provided training to over 14,000 teachers and over 3 million youth on core entrepreneurial skills, such as communication, negotiation and planning. </p>\n\n<p>KAB materials have been used in public and private secondary education, in vocational and technical training institutions, and even in higher education. Introduced in 56 countries across the world, KAB has become part of the national curricula in 18 of these countries. </p>',
  enterprises_visAgriCoopDetail2: '<p>My.COOP addresses the many challenges cooperative managers face. It is based on the idea that better management will result in higher quality and in more efficient and effective cooperative services. It provides guidance on member satisfaction, business opportunities and social considerations.</p>',
  enterprises_visWomenentrepDetail2: '<p>Over the last 15 years, ILO-WED has provided management training to over 1.5 million women, has equipped over 75 service providers to better respond to women\'s needs and has carried out 25 country assessments to help national partners carry out action plans to improve the environment for women\'s entrepreneurship development.</p>',
  enterprises_visResponsibleDetail2: '<p>Since its inception, SCORE Training has been delivered to over 1,400 small and medium-sized enterprises globally. This represents over 300,000 workers who have benefited from enterprise improvements, including efforts to develop a culture of respect, trust and communication in the workplace.</p>\n\n<p>In addition, SCORE Training has improved productivity by up to 50% in participating enterprises and boasts an 88% satisfaction rate.</p>',
  enterprises_imageTokyoCaption: 'Guy Ryder, ILO Director-General, with Toshiro Muto, Tokyo 2020 Olympic and Paralympic Games CEO. © Uta Mukuo',
  enterprises_detailInclusive2: '<p>In Pakistan, the ILO forged a partnership with government, employers’ and workers’ organizations to establish sustainable business practices in order to eliminate child labour from the soccer ball industry in Sialkot, which provides goods for global brands.</p>',
  enterprises_visWhyrcoopsDetail3: '<p>Cooperative banks were a key driver of the economic recovery after the global financial crisis, with Europe’s cooperative banks serving more than 215 million customers and having an average market share of about 20%.</p>\n\n<p>Since the adoption of Recommendation No. 193, almost 100 countries have reviewed, revised or adopted policies or laws dealing with cooperatives. This has also contributed to a change in the perception of cooperatives as a viable business form.</p>',
  conditions_heading: 'Working conditions',
  conditions_chapter: 'chapter 7',
  conditions_title: 'How was your day at work?',
  conditions_date: 'Discover this chapter on 27 February 2019',
  conditions_visTimeTitle: 'Working time around the world',
  conditions_visTimeSelect: 'Search country',
  conditions_visTimeSharesLabel: 'Share of employees (%)',
  conditions_visTimeColour1: 'Working <strong>less than 35 hours</strong> per week',
  conditions_visTimeColour2: 'Working <strong>35-48 hours</strong> per week',
  conditions_visTimeColour3: 'Working <strong>more than 48 hours</strong> per week',
  conditions_visTimeNote: 'Data from 2018 or latest available. Source: ILOSTAT.',
  conditions_infoWhenwego: '<p>When we go to work we expect to earn enough to be able to have a good life, to be able to buy food, pay our bills, and cover eventualities such as illness or a new baby. We expect to have enough time off to be able to relax and spend time with our families and even to have enough money left over to enjoy life’s small pleasures.</p> \n\n<p>When we arrive in the workplace, we expect to be safe, not to die there or suffer from a life-changing injury or illness that leaves us unable to make ends meet or even plunges us into poverty.</p>\n\n<p>In other words, we expect decent working conditions.</p>',
  conditions_calloutTheseshouldnot: 'These should not be simply expectations. They are rights.',
  conditions_calloutManymillions: 'Many millions of us do enjoy these rights, thanks to a number of ILO Conventions that have been adopted over the years by member States, which cover occupational safety and health, working time, minimum wages and maternity protection, among others. But many millions of people do not.',
  conditions_infoSafetyinworkplace: '<h3>Safety in the workplace</h3>\n\n<p>According to ILO statistics, every year, 2.78 million people die as the result of exposure to safety and health hazards at work. That’s more than five people dying every minute. </p>\n\n<p>A further 374 million workers suffer non-fatal occupational accidents every year.</p>\n\n<p>In addition to the suffering caused by accidents at work, there is a significant economic cost for workers and their families, employers and economies as a whole. It’s been calculated that fatal and non-fatal work-related injuries, illness and disease cost the global economy around 4% of GDP.</p>',
  conditions_infoIloflagship: '<p>The ILO flagship programme Global Action for Prevention on Occupational Safety and Health (OSH-GAP) helps member States develop the capabilities needed to improve workplace safety and health in their countries.</p>\n\n<p>We work in over 140 countries to:</p>\n\n<ul>\n<li><mark id="conditions_infoIloflagship">Encourage public and private investment in the prevention of work-related fatalities, injuries and illnesses.</mark></li>\n<li>Train labour inspectors and share best practices with employers and workers to improve safety and health in their workplaces.</li>\n<li>Develop effective laws and create systems to collect data on safety and health.</li>\n<li>Integrate safety and health into education and skills’ training for young workers.</li> \n</ul>',
  conditions_infoHowfaryourmoney: '<h3>How far does your money go?</h3> \n\n<p>For many millions of people, it’s a struggle to make ends meet. Being paid a minimum wage can prevent them from plunging into poverty. Minimum wages can also help overcome poverty and reduce inequality between men and women. They supplement and reinforce policies such as collective bargaining, which is used to set terms of employment and working conditions.</p>\n\n<p>Today more than 90% of ILO member States have some form of minimum wage. The ILO works with governments to ensure that minimum wages are set at a level that takes into account the needs of workers and their families as well as the economy as a whole.</p>\n\n<p>These are core areas of the ILO’s work, which are enshrined in conventions such as the Minimum Wage Fixing Convention, 1970 (No. 131). </p>',
  conditions_detailVisionzero1: '<h3>The Vision Zero Fund</h3> \n\n<p>In 2013, 1,137 workers were killed in Bangladesh, at a garment factory in the Rana Plaza complex. In the wake of that disaster, G7 leaders agreed to establish a new global fund, called the “Vision Zero Fund”, to support efforts to  improve occupational safety and health in the world’s global supply chains.</p>',
  conditions_infoLimitinghours: '<h3>Limiting excessive working hours</h3> \n \n<p>Concerns about excessive working hours influenced the very first ILO Convention, adopted in 1919, which limited the amount of time workers should spend at their jobs each day and during the week.</p>\n\n<p>Today ILO standards on working time are at the heart of the employment relationship.</p> \n\n<p>These standards cover issues such as the number of hours we should work, the rest periods required, and even annual leave.</p>\n\n<p>In addition, ILO standards help shape our work-life balance and play a key role in encouraging enterprises to maintain healthy and productive workforces.</p>',
  conditions_vis1994: 'in 1994',
  conditions_infoJobparents: '<h3>Job security for parents</h3>\n\n<p>When workers have children, often their job security can be threatened.</p> \n\n<p>The ILO recognizes maternity protection as a fundamental labour right, essential to promoting the health and well-being of mothers and their children. It also helps achieve gender equality at work and advances decent work for both women and men.</p>\n\n<p>Virtually every country has adopted laws concerning maternity protection at work. Measures to support workers with family responsibilities, including fathers, are increasing around the world.</p>',
  conditions_vis38Percent: '<p class="bubble-percent">38%</p>',
  conditions_visCountriesmaternity: 'of countries provided at least 14 weeks of maternity leave',
  conditions_vis2013: 'in 2013',
  conditions_vis51Percent: '<p class="bubble-percent">51%</p>',
  conditions_calloutHoweverimprove: 'However, if we are to improve working conditions for all workers, more countries need to ratify ILO Conventions on maternity protection',
  conditions_vis34: '34',
  conditions_visRatified183: 'countries ratified the ILO Maternity Protection Convention as of 1 January 2019',
  conditions_visSeeratifications: 'See ratifications',
  conditions_infoNewways: '<h3>New ways of working</h3> \n\n<p>Today we’re seeing new ways of working due to technological innovation and changes in the traditional structure of work.</p>\n\n<p>These include part-time, temporary, agency and what’s become known as “gig” work - when people work on short-term projects but are not in a traditional employee-employer relationship. They are called “non-standard” jobs and raise issues about conditions of work.</p>',
  conditions_carouselIsyourjob: 'Is your job non-standard?',
  conditions_carouselYesBody1: 'Yes, if you’re engaged for only a finite length of time',
  conditions_carouselYesCaption1: 'This is called temporary employment and it is a non-standard job: it includes fixed-term, project or task-based contracts, seasonal work and casual work, including day labour.',
  conditions_carouselYesBody2: 'Yes, if you’re working fewer hours than people in comparable full-time employment',
  conditions_carouselYesCaption2: 'This is part-time employment and it is a non-standard job. It can allow some workers to combine work with child rearing, studies or training. However, working hours can sometimes be very short and unpredictable, as in the case of zero-hours contracts, where the employer is not obliged to provide any minimum hours.',
  conditions_carouselYesBody3: 'Yes, if you’re paid by one company but work for another',
  conditions_carouselYesCaption3: 'This is called multi-party employment and is a non-standard job. It often happens when a worker is paid by one company – a temporary work agency or subcontractor – but performs work for another company at its place of business. There is usually no employment relationship between the worker and the company to which he or she provides their services.',
  conditions_carouselYesBody4: 'Yes, if you’re hired as an independent contractor but you’re supervised as though you were an employee',
  conditions_carouselYesCaption4: 'This is called disguised employment and is a non-standard job. When workers are monitored by their supervisors as though they were employees, despite being hired as independent contractors, this hides the real nature of the work relationship to bypass labour regulations.',
  conditions_carouselYesBody5: 'Yes, if you are legally independent, but economically dependent',
  conditions_carouselYesCaption5: 'This is called dependent self-employment and is a grey area. It’s when workers perform services for a business under a commercial contract, but depend heavily on a small number of clients for their income.',
  conditions_calloutNonstdemp: 'Non-standard employment is widespread in both industrialized and developing countries, across most economic sectors and occupations.',
  conditions_callout150Countries: 'In over 150 countries, 11% of private sector workers are temporary employees. While women make up less than 40% of total employees, 57% of part-time employees are women.',
  conditions_infoNonstdjobs: '<p>Non-standard jobs may provide an easier access to the labour market, and potentially allow better work-life balance. However, workers in non-standard jobs may be excluded from social protection and social security and have less access to training and career development opportunities.</p>\n\n<p>They also participate less in collective bargaining and are at a higher risk of discrimination.</p>\n\n<p>For many workers, fixed-term contracts, project or seasonal work, temporary and on-call work are associated with greater insecurity, which disproportionately affects women.</p>\n\n<p>However, regardless of whether you are in a standard or non-standard job, all jobs should be decent.</p>',
  conditions_infoSourcing: '<h3>Sourcing globally, selling globally</h3> \n \n<p>Globalization has made the world of business and trade ever more complex. Most companies today either source globally, sell globally, or compete with a company that does.</p>\n\n<p>It means that enterprises now deal with a network of suppliers, manufacturers, warehouses, distribution centres, wholesalers and retailers.</p> \n\n<p>It’s known as a global supply chain and it’s one of the most significant new developments in the world of work in the past 50 years.</p>  \n\n<p>One of the key issues arising from the emergence of these global supply chains is that of working conditions along the chain.</p>',
  conditions_infoInmanycountries: '<p>In many countries, global supply chains create employment and opportunities for economic and social development.</p>\n\n<p>Where employers respect labour regulations and international labour standards, this enhances decent work, improves productivity and growth and enables workers to get jobs requiring higher skills, with better pay and conditions.</p>',
  conditions_calloutButpoor: 'But poor working conditions and employment relations within some global supply chains can have disastrous consequences.',
  conditions_infoCollapseranaplaza: '<p>The collapse of the Rana Plaza building in 2013 and factory fires in Pakistan and Bangladesh in 2012 took the lives of over 1,500 people and prompted a renewed call for global action to achieve decent working conditions in global supply chains.</p> \n\n<p><mark id="conditions_infoCollapseranaplaza">The ILO is at the forefront of that action.</mark></p>\n\n<p>Following the Rana Plaza disaster, more than 800,000 Bangladeshi workers were trained on essential safety and health measures, and injured victims were given support. Almost 300 survivors were trained in livelihood skills, more than 3,000 received career counselling, and 66 were provided with psychosocial counselling.</p>',
  conditions_detailBetterwork1: '<h3>Better Work</h3>     \n\n<p>Better Work is a collaboration between the ILO and the International Finance Corporation (IFC), a member of the World Bank Group. Its aim is to improve working conditions in the garment industry and promote respect for workers’ labour rights, while boosting the competitiveness of apparel businesses. Better Work does this by bringing together all stakeholders in the garment industry.</p>',
  conditions_infoChallengesarise: '<p>Challenges arise when firms make decisions that affect working conditions, without being directly responsible for those doing the work.</p>   \n\n<p>Subcontractors may not comply with labour regulations, in some cases resulting in informal employment, poor working conditions, even forced and child labour.</p> \n\n<p>Because the cross-border sourcing of goods and services makes enforcement difficult, not all governments have been able to cope with the rapid transformation brought about by exposure to the global economy and global supply chains. </p>',
  conditions_calloutSolution: 'The solution: promoting international labour standards, better enforcement of existing laws and strengthening social dialogue.',
  conditions_calloutThroughitswork: 'Through its work in all these areas, the ILO is at the forefront of helping to achieve decent work in global supply chains.',
  conditions_infoTheilohelps: '<p>The ILO helps member States improve workplace safety and health, implement minimum wages and develop laws to improve conditions of work.</p> \n\n<p>Through training, sharing of knowledge and working with public and private-sector partners, the ILO has shown that it is possible to improve the lives of millions working in global supply chains.</p>',
  conditions_calloutPromotingsafe: 'Promoting safe and productive working conditions have been central to ILO work over the past century.',
  conditions_calloutFromthefactories: 'From the factories of the industrial revolution, to the challenges created by the gig economy and global supply chains, ensuring decent working conditions continues to be a priority and a path towards the sustainable enterprises of the future.',
  conditions_captionVietnam: 'A young construction worker without proper safety gear, Hanoi, Viet Nam. © ILO/M. Fossat',
  conditions_imageConstructionAlt: 'Legs of a worker with sandals standing on bars of a scaffolding high above the ground.',
  conditions_imageFactoryCaption: 'An employee of a cheese manufacturing factory. Montevideo, Uruguay. © ILO/M. Fossat',
  conditions_imageFactoryAlt: 'A man wearing white sanitary suit, mask, gloves and an industrial apron holding a hose to clean the floor.',
  conditions_imageMadagascarCaption: 'Workers demand higher salaries and better working conditions during a wild cat strike at a garment factory in Antananarivo, Madagascar. © ILO/M. Fossat',
  conditions_imageMadagascarAlt: 'A factory corridor crowded with women workers. One of them is holding up a cardboard sign that says “raise our salaries.”',
  conditions_imagePlatformCaption: 'A woman working from home with her child. © iStock',
  conditions_imagePlatformAlt: 'A woman with glasses sitting at a kitchen table and using a computer. A baby is sitting in a tall chair next to her.',
  conditions_imageCateringCaption: 'A woman works in catering services, United States.  © J. Maida',
  conditions_imageCateringAlt: 'A woman dressed professionally holding a large tray full of finger foods.',
  conditions_captionGarmentHaiti: 'Garment factory workers in Haiti. © ILO',
  conditions_imageHaitiAlt: 'An aerial view of a factory full of garment machinery and fabrics in different colors.',
  conditions_captionGarmentBang: 'Garment factory workers in Bangladesh. © ILO',
  conditions_imageBangladeshAlt: 'Women in green hijabs and sanitary masks sitting at a long table and operating sewing machines.',
  conditions_captionMyanmarSafety: 'A young construction worker works barefoot in Yangon, Myanmar. © ILO/M. Fossat',
  conditions_imageMyanmarAlt: 'A construction worker working on a scaffolding. A banner that says “safety first” is on the fence.',
  conditions_imageVisionCaption: 'The Vision Zero Fund project in Myanmar is working to improve occupational safety and health. © ILO',
  conditions_imageVisionAlt: 'A woman in a traditional sun hat sitting in the field next to a pile of ginger.',
  conditions_imageBetterworkCaption: 'Garment worker in a Better Work factory in Vietnam. Better Work factories have received bigger order sizes, greater access to buyers, and higher prices from principal buyers over time as a result of participation in the programme. © ILO',
  conditions_imageBetterworkAlt: 'A garment factory worker in Vietnam smiling as she works.',
  conditions_detailBetterwork2: '<p>As a result of their participation with Better Work, factories have steadily improved working conditions as well as their productivity and profitability.</p>\n\n<p>Currently, the programme is active in 1,450 factories in seven countries, employing more than 1.9 million workers. As well as advising factories, Better Work collaborates with governments to improve labour laws, and with international brands to ensure progress is sustained.</p>\n\n<p>Its vision is a global garment industry that lifts millions of people out of poverty by providing decent work, empowering women, driving business competitiveness and promoting inclusive economic growth.</p>',
  conditions_detailVisionzero2: '<p>The ILO plays a leading role in the implementation of the Vision Zero Fund, administering the fund and applying its breadth of expertise and experience to develop knowledge, strategies and tools for use by governments, employers and workers to improve safety and health in global supply chains.</p> \n\n<p>In Myanmar, the ILO provided ginger farmers with access to better information on the safe use of agrochemicals, and helped provide market-based solutions for improving their working conditions.</p>',
  future_heading: 'The future of work',
  future_chapter: 'chapter 9',
  future_title: 'Looking towards the future',
  future_date: 'Discover this chapter on 29 March 2019',
  future_infoLookingtowards: '<p>The world of work is undergoing a profound transformation. Globalization and technological change are creating new paths to prosperity but are also disrupting existing work arrangements.</p>',
  future_visIceberg1: '<p>Digital and technological advances – including information and communication technologies – create new opportunities for workers and enterprises.</p>\n\n<p style="font-weight: 600;">But that’s just the tip of the iceberg.</p>',
  future_visIceberg2: '<p>Climate change, shifting demographics, migration and changes in the organization of work will affect all societies, organizations, workers and enterprises. </p>\n\n<p>The demand for some jobs will change, other jobs will disappear and many may not resemble what they used to.</p>',
  future_visIceberg3: '<p>The direction of these changes and the effects they will have on work will depend on our policies and actions, especially if we seek to shape the future of work that we want.</p>',
  future_visQuestions1: 'Where will the <span class="emphasis">jobs of the future</span> come from and what will they look like?',
  future_visQuestions2: 'What’s in store for <span class="emphasis">young people</span> and what skills will they need?',
  future_visQuestions3: 'How can we ensure that all working arrangements are <span class="emphasis">decent</span>?',
  future_visQuestions4: 'How can we ensure that technology enhances <span class="emphasis">human capabilities</span> rather than diminish them?',
  future_calloutIloidentified: 'The ILO has identified some key sectors that are likely to generate the jobs of the future.',
  future_calloutExplorethem: 'Explore them here to discover emerging opportunities:',
  future_visGreenjobsHeading: 'Green jobs',
  future_visGreenjobsDetail1: '<h3>Green jobs</h3>\n\n<p>Green jobs are one of the newest forms of work to emerge in the past 20 years. They aim to contribute to preserve and restore the environment.</p>\n\n<p>They can be found in sectors as varied as manufacturing and construction, fashion, renewable energy, electronics and waste management.</p>',
  future_visGreenjobsDetail2: '<p>Green jobs help to:</p>\n\n<ul>\n<li>Improve energy efficiency</li>\n<li>Limit greenhouse gas emissions</li>\n<li>Minimize waste and pollution</li>\n<li>Protect and restore ecosystems</li>\n<li>Support adaptation to climate change</li>\n</ul>\n\n<p>The ILO estimates that the transition to a green economy could yield up to 60 million additional jobs over the next 15 years.</p>',
  future_visCareeconomyHeading: 'Care economy',
  future_visCareeconomyDetail1: '<h3>The care economy</h3>\n\n<p>Care work includes health-care services, childcare, early childhood education, disability and long-term care, elder care and much more.</p>\n\n<p>With demographic change around the world and ageing populations in much of the developed world, the care economy holds some of the highest potential for creating the jobs of the future.</p>',
  future_visGigeconomyHeading: 'The "gig economy"',
  future_visGigeconomyDetail1: '<h3>The “gig economy”</h3> \n\n<p>Technological innovations open up new opportunities to work remotely and online. “Gig economy” jobs, where workers have multiple short-term jobs or contracts with multiple employers online, rather than the traditional, more permanent single employer-employee relationship, provide opportunities for workers to earn income and for enterprises to organize work in new ways.</p>',
  future_visGigeconomyDetail2: '<p>Workers who might normally be excluded from the labour market - for example, on account of disability, care responsibilities or illness - have more opportunities to work. At the same time, ILO research has shown that many platform workers earn below the minimum wage.</p>\n\n<p>There are also concerns about the insecurity faced by these workers, as well as their working conditions. The ILO is leading research into many of the issues and questions raised by these new forms of working:</p>\n\n<ul>\n<li>What is the employment status of “crowdworkers’ and “on-demand” workers, who compete to accomplish tasks for employers on an ad-hoc basis?</li>\n</ul',
  future_visRuraleconomyHeading: 'Rural economy',
  future_visRuraleconomyDetail1: '<h3>The rural economy</h3>\n\n<p>Rural development has been on the ILO’s agenda from the beginning. Since 1919, the ILO has adopted over 30 international labour standards that are of direct relevance to agriculture and rural development, covering rights at work, employment, social protection and social dialogue.</p>\n\n<p>The rural economy holds great potential for creating decent and productive jobs and contributes to sustainable development and economic growth.</p>',
  future_visRuraleconomyDetail2: '<p>Moreover, with nearly 80% of the world’s poor living in rural areas, any changes that improve the livelihoods of rural populations will have an enormous impact on poverty alleviation by 2030 - the target date for the achievement of the UN Sustainable Development Goals.</p>\n\n<p>Agriculture will remain the largest sector in many rural areas. Rural populations are also likely to benefit from a vibrant tourism and recreation economy.</p>',
  future_visGlobalsupplyHeading: 'Global supply chains',
  future_visGlobalsupplyDetail1: '<h3>Global supply chains</h3>\n\n<p>The production and distribution of goods and services have become ever more complex. In the past, companies concentrated their output in specific countries or regions. These days, it is spread across transnational global networks that aim to maximize profit and minimize waste. </p>\n\n<p>Global supply chains have transformed the world economy in the last three decades. They have been an engine of growth and job creation, especially in the developing world.</p>',
  future_visGlobalsupplyDetail2: '<p>Jobs in global supply chains are as varied as the countries in which they are found and they have enabled more and more people to find employment.</p>\n\n<p>While a large number of these jobs can be replaced by machines, especially in light manufacturing, ILO research shows that, for now, this may not make economic sense in developing regions due to the comparatively high cost of machinery, the low cost of labour and the limited capacity to absorb new technology. But what about the future?</p>',
  future_visRobotageHeading: 'The robot age',
  future_visRobotageDetail1: '<h3>The robot age</h3>\n\n<p>Advances in the specialization and sophistication of technology are accelerating the rate of automation at work and the demand for industrial robots. With automation ranging from 10% to 60% in some workplaces, there will inevitably be some job losses. </p>\n\n<p>However, there is a more hopeful perspective on this gloomy outlook - one that focuses on job transformation and the reorientation of workers’ skills.</p>',
  future_visRobotageDetail2: '<p>Robotic systems need to be networked, maintained and upgraded. This is likely to create new jobs, especially for those with backgrounds in science, technology, engineering and mathematics (STEM).</p>\n\n<p>In addition, software developers and coding-related jobs hold the potential to be the blue-collar jobs of our future. In today’s developed economies, there are 14 robots per 1,000 employees in manufacturing, all of which require regular servicing. This number is set to rise.</p>',
  future_calloutBrighterfuture: 'A brighter future',
  future_infoSkillsweneed: '<h3>The skills we will need</h3>\n\n<p>The evolution of the world of work will require new skill sets and an emphasis on lifelong learning. Automation and robotization, for example, will increase the demand for technical skills in problem-solving and innovation.</p>\n\n<p>There will also be an increasing demand for “soft skills” such as the ability to communicate effectively, to work with teams, to find creative solutions to problems, to socialize well and to adapt to changing situations. These skills will be rewarded at a premium. As a result:</p>\n\n<ul>\n<li>Education and training systems will need to equip the workforce with the skills and competencies required in the future.</li>\n<li>Policy measures will be needed to enhance links between training institutions and enterprises.</li>\n<li>We will need to strike a balance between ensuring quality of training and meeting the demand for more flexible and shorter training programmes.</li>\n</ul>',
  future_infoOpportunity: '<h3>An opportunity to close the gender gap</h3>\n\n<p>The evolution of the world of work is an opportunity to remove structural barriers to gender equality and tackle inequality in the workplace and in our societies.</p>\n\n<p>Women provide the vast majority of unpaid care and household work the world over, often hindering their participation in the workforce.</p>\n\n<p>Formalizing care work therefore represents a great opportunity to ensure more women can enter the labour force. The ILO is working in over 80 countries to make this happen.</p>\n\n<p>Alongside these efforts, a range of ILO projects are helping women access quality education and skills training to improve their chances of finding decent work.</p>',
  future_visWomen: 'are outside the labour force because they are doing unpaid care work',
  future_visMen: 'are outside the labour force because they are doing unpaid care work',
  future_quoteKofiBody: '“Gender equality is more than a goal in itself. It is a precondition for meeting the challenge of reducing poverty, promoting sustainable development and building good governance.”',
  future_quoteKofiCaption: 'Kofi Annan, former UN Secretary-General and Nobel Peace Prize laureate',
  future_infoNewforms: '<h3>New forms of business</h3>\n\n<p>Businesses are the engine of our economies, the generators of prosperity and the creators of jobs. Still, there are growing concerns that the focus on rewarding investors and creditors comes at the expense of the environment and workers’ well-being. </p>\n\n<p>The growth of alternative forms of business shows that they can play a key role in achieving the UN Sustainable Development Goals by helping to lift millions of people out of poverty.</p>',
  future_infoThesebusinesses: '<p>These types of businesses include worker cooperatives, mutual benefit societies and social enterprises, which respond well to the needs of citizen groups and local communities. According to ILO research:</p>\n\n<ul>\n<li>These enterprises are less likely to delocalize or offshore production activities, both creating and preserving employment.</li>\n<li>They are often at the forefront of efforts to regenerate local communities and rescue businesses at risk of bankruptcy.</li>\n<li>They are effective at pooling resources for micro-enterprises or independent self-employed workers.</li>\n</ul>\n\n<p>ILO Promotion of Cooperatives Recommendation (No. 193) provides guidelines for their development.</p>',
  future_infoModernizing: '<h3>Modernizing social protection systems</h3>\n\n<p>The demands on social protection systems are likely to increase in the light of increasing levels of inequality and the changing role of work in people’s lives.</p>\n\n<p>Job losses will lead to a greater need for unemployment insurance schemes. The mismatch between the skills workers have and those the economy will need in the future will place pressure on public employment programmes. Increases in life expectancy will place greater pressure on old-age pension schemes.</p> \n\n<p>To address these challenges, certain recent innovations in both developing and developed countries show that social protection systems can adapt and move closer to achieving universal coverage.</p>\n\n<p>A number of countries, for instance, have introduced mechanisms to ensure social protection for self-employed and informal workers, including those on digital platforms.</p> \n\n<p>Digital communications and automated procedures are being used to ensure that administrative procedures are more effective and cost-effective.</p>',
  future_infoHumancentred: '<h3>A human-centred agenda</h3>\n\n<p>To prepare for the next 100 years, the ILO established the Global Commission on the Future of Work, made up of Heads of State, prominent academics and representatives from major workers’ and employers’ organizations.</p>\n\n<p>The Commission undertook an in-depth examination of the future of work, to provide the analytical basis for the delivery of social justice in the 21st century. It calls for a human-centred agenda that strengthens the social contract by placing people and the work they do at the core of economic and social policy and business practice.</p>\n\n<p>This agenda consists of three pillars of action, which in combination would drive growth, equity and sustainability for present and future generations:</p>\n\n<ul>\n<li>Increase investment in people’s capabilities</li>\n<li>Increase investment in the institutions of work</li>\n<li>Increase investment in decent and sustainable work</li>\n</ul>',
  future_infoFuturewewant: '<h3>The future we want</h3>\n\n<p>For the past century, ever since the founding of the ILO, its international labour standards have been the bedrock of decent work and social justice.</p>\n\n<p>Social dialogue and tripartism have helped underpin the greatest expansion of economic growth in human history, and looking forward, are more relevant than ever.</p>\n\n<p>It’s up to us all to ensure that with all the changes ahead, we remain committed to working together and finding solutions to the challenges likely in the future.</p>',
  future_calloutParadox: 'A paradox in the world today is that even in the places with the most unemployment, a great deal of work still needs to be done.',
  future_calloutSickelderly: 'The sick and the elderly need care workers. Children and youth need teachers and skills training. Buildings need renovating and cities need greening. The environment needs to be taken care of and climate change tackled.',
  future_infoFulfilling: '<h3>Fulfilling the four pillars of Decent Work</h3>\n\n<p>In other words, there will still be a demand for work and for workers, in the jobs where human interaction matters most, where  our creativity can be given free reign and our aspirations fulfilled.</p>\n\n<p>Therefore, the four pillars of Decent Work - employment creation, social protection, rights at work and social dialogue - are all as relevant as they were 100 years ago, and they hold the keys to a future we can all be proud of.</p>',
  future_infoNext100Years: '<h3>The next 100 years</h3>\n\n<p>A century ago, who could have predicted the world in which we live today? A world where millions have been lifted out of poverty and where limitless information is available at the touch of a button. A world where billions of people have heard of human rights, and where the word “equality” has new meaning for countless women and men around the world.</p>\n\n<p>Yet there is still so much to be done if we are to achieve  our dream of a world with universal and lasting peace, based on social justice. </p>',
  future_calloutHistory: 'History has proven that the challenges we face can only be overcome when we work together. When all of us put the common good ahead of narrow interests, there is nothing we cannot achieve.',
  future_calloutInthisspirit: 'It is in this spirit of hope that the ILO looks towards its second century. We invite you to join us in shaping a future we all want: a world of  social justice and decent work for all.',
  future_imageIndustrobotsCaption: 'Industrial robots at the Gestamp Automocion factory in Abrera, Spain. © AFP/P. Barrena',
  future_imageIndustrobotsAlt: 'Blue and white industrial robots set up in a circle to work on different parts of a product spinning in the middle.',
  future_imageAerialCaption: 'Aerial view when landing at Yangon airport, Myanmar. © ILO/M. Fossat',
  future_imageAerialAlt: 'A patchwork of fields, roads and a river, as seen from an aerial perspective.',
  future_imageChildrenCaption: 'Children play at a kindergarten in Phu Tho province, Viet Nam. © ILO',
  future_imageChildrenAlt: 'A little girl and a little boy play with colourful, plastic building blocks.',
  future_imageMarineCaption: 'A marine research officer maps coral reefs in the Seychelles. © UN Women/Ryan Brown',
  future_imageMarineAlt: 'A woman snorkeling and writing on her notepad under water.',
  future_imageNurseCaption: 'Nurse vaccinates a child against polio at the Howard Karagheusian primary health care centre, in Beirut, Lebanon. © World Bank/Dominic Chavez',
  future_imageNurseAlt: 'In an examination room, a nurse is holding a child’s face in one hand and a medicine bottle in her other hand.',
  future_imageManworkshomeCaption: 'A man works from home. The emergence of online digital labour platforms has led to one of the biggest  transformations in the world of work over the past decade. © iStock',
  future_imageManworkshomeAlt: 'A man in  a kitchen sitting at a table with a computer and a pile of documents.',
  future_imageTwofarmersCaption: 'Two farmers work in the fields in Viet Nam. The promotion of decent work in the rural economy is key to eradicating poverty. © ILO/M. Fossat',
  future_imageTwofarmersAlt: 'Two farmers wearing Vietnamese sun hats working the field full of plants using farming tools.',
  future_imageGarmentCaption: 'Garment factory workers in Jordan - one of the countries covered by the Better Work Programme, which aims to improve working conditions and boost the competitiveness of apparel businesses. © ILO',
  future_imageGarmentAlt: 'Workers sitting in parallel lines and operating sewing machines. Piles of red fabric are in the middle of each table.',
  future_imageRobotsiranCaption: 'Robots work on a car at the Iran Khodro auto plant, west of Tehran, 2008. © AFP/Behrouz Mehri',
  future_imageRobotsiranAlt: 'Sparks fly as orange robotic machines work on the silver metal frame of a car in production.',
  future_imageChildrencodeCaption: 'Children aged 7 to 13 learn how to code.  Antananarivo, Madagascar. © AFP/Rijasolo',
  future_imageChildrencodeAlt: 'A row of boys look at laptop screens with a teacher in the background, on a converted bus.',
  future_imageSchoolgirlsCaption: 'Schoolchildren, West Java, Indonesia. © ILO/A. Mizra',
  future_imageSchoolgirlsAlt: 'Four girls standing next to each other and reading books.',
  future_imageDomesticCaption: 'Domestic worker, Philippines. Domestic workers comprise a significant part of the global workforce in informal employment and are among the most vulnerable groups of workers. © ILO/J. Aliling',
  future_imageDomesticAlt: 'A woman sitting at a table and playing with a baby sitting across her in a high chair.',
  future_imageKofiCaption: 'Kofi Annan, former secretary-general of the UN, at the Conference of African Women and Economic Development, Addis Ababa, 1998. © ILO',
  future_imageEveldoCaption: 'Eveldo, from the indigenous Bribri community, is a member of Association of Small Producers of Talamanca, one of the biggest cooperatives in Costa Rica. © AFP/H. Retamal',
  future_imageEveldoAlt: 'A man  at a plant farm sits at a small table to take care of a plant in a pot.',
  future_imageWomencoopCaption: 'Members of a women’s cooperative, Tanzania. In many countries, women’s participation in cooperatives is much higher than their overall labour force participation. © ILO/M. Crozet',
  future_imageWomencoopAlt: 'Three women sit in a circle and write in a notebook. Other women are sitting around them.',
  future_imageFlowersellerCaption: 'A flower seller at Cuenca Flower market, Ecuador. The majority of people in the informal economy lack social protection and rights at work. © ILO/P. Virot',
  future_imageFlowersellerAlt: 'An old woman sitting outside with pots of flowers she is selling next to her.',
  future_imageOfficialCaption: 'Official presentation of the Global Commission Report on the Future of Work to ILO constituents and representatives of the diplomatic community, January 2019. © ILO',
  future_imageOfficialAlt: 'Cyril Ramaphosa, President of the Republic of South Africa; Guy Ryder, ILO Director-General and Deborah Greenfield, ILO Deputy Director-General for Policy seated at front of meeting room with “Global Commission on the Future of Work” banner behind them.',
  future_imageSyrianCaption: 'A Syrian refugee worker in Jordan. The ILO helps workers access jobs in agriculture as part of the agency’s response to the refugee crisis. © ILO/L. Abu Sha\'ireh',
  future_imageSyrianAlt: 'A woman in a field of tall plants holding a styrofoam box full of harvested beans.',
  future_visOfWomen: 'of women',
  future_visOfMen: 'of men',
  future_visCareeconomyDetail2: '<p>According to ILO statistics, an estimated 269 million new jobs could be created if investment in education, health and social work doubled by 2030.</p>',
  future_visGigeconomyDetail3: '<ul>\n<li>How can the fundamental rights of platform economy workers be guaranteed?</li>\n<li>What is needed to ensure these rights are respected?</li>\n<li>How can platform economy workers have their interests collectively represented so that they can bargain for better pay and working conditions?</li>\n<li>How can minimum conditions of employment (such as the minimum wage) be regulated?</li>\n<li>How can platform economy workers have access to adequate social protection?</li>\n<li>How does the growth of  global crowd work affect efforts to ensure decent work?</li>\n</ul>',
  future_visRuraleconomyDetail3: '<p>In addition, changes in the demographic structures of rural areas (young people moving to cities for work and education, while older people and families move out to rural areas) will have implications for the delivery of public services.</p>\n\n<p>Rural populations will age more rapidly than urban ones, leading to a likely increase in demand for care jobs outside of metropolitan areas. The care sector has great potential for creating the jobs of the future.</p>\n\n<p>Decent work in the rural economy was identified as a priority issue in 2014 and will remain so as the ILO moves into its next century.</p>',
  future_visGlobalsupplyDetail3: '<p>The costs of machines decrease over time, making it viable to relocate much of this production. This could lead to the reconfiguration of global supply chains as developing regions lose jobs to machines in developed regions. The jobs lost and competition for those that remain may put downward pressure on wages and working conditions.</p>\n\n<p>The ILO therefore sees an increasing need for:</p>\n\n<ul>\n<li>Labour market policies that address these changes</li>\n<li>Economic policies that lead to diversification into new economic sectors</li>\n<li>Labour inspection to ensure good working conditions</li>\n</ul>',
  future_visGlobalsupplyDetail4: '<p>In 2017, the International Labour Conference adopted a report on decent work in global supply chains, which led to the development of a plan of action to ensure they adhere to standards of decent work.</p>' };
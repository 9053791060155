module.exports = { interface_loading: '加载中',
  interface_headphones: '使用耳机效果更好',
  interface_title: '我们的故事，你的故事',
  interface_share: '分享',
  interface_fb: '脸书',
  interface_twitter: '推特',
  interface_youtube: 'YouTube',
  interface_on: '打开',
  interface_off: '关闭',
  interface_start: '长按空格键开始',
  interface_scrollDown: '向下滚动',
  interface_description: '国际劳工组织庆祝推进社会正义和促进体面劳动的一百年。',
  interface_news: '新闻&活动',
  interface_fraudAlert: '违法信息举报',
  interface_disclaimer: '免责声明',
  interface_privacyPolicy: '隐私保护',
  interface_copyright: '版权和许可',
  interface_ilo: '国际劳工组织',
  interface_ilo100: '国际劳工组织百年',
  interface_ourStoryDescription: '国际劳工组织是历史最悠久的联合国专门机构，自1919年起，就致力于推进社会正义和促进体面劳动。开始互动旅程，探索这一组织的过去、现在和未来。',
  interface_about: '关于',
  interface_credits: '致谢',
  interface_mobileTitle: '我们的故事，<br>你的故事',
  interface_mobileStart: '点击开始',
  interface_html5VideoError: '您的浏览器不支持HTML 5格式视频',
  interface_previous: '上一页',
  interface_next: '下一页',
  interface_seePhoto: '查看这张照片',
  interface_seeVideo: '观看这段视频',
  interface_nextChapter: '下一章',
  interface_discoverChapter: '发现这一章',
  interface_noOptions: '没有结果',
  interface_learnMore: '更多',
  home_title: '国际劳工组织百年纪念',
  home_subtitle: '推进社会正义，促进体面劳动',
  home_news: '新闻和活动',
  home_ourStory: '我们的故事，你的故事',
  home_newsSubtitle: '了解我们如何纪念一百周年',
  home_ourStorySubtitle: '历史与使命',
  home_ourStoryCta: '点击查看',
  home_newsCta: '了解更多',
  home_description: '2019年，国际劳工组织将迈向100周年。这是一个展现劳工组织百年来卓越成就，并重申其在劳动世界是有公信力的国际组织的绝佳机会。',
  home_socialMedia: '2019年，国际劳工组织将迎来百年华诞。100年，推进社会正义，促进体面劳动。#国际劳工组织百年# https://www.ilo.org/100/zh',
  home_pageTitle: '国际劳工组织百年纪念',
  home_ourStoryCta2: '探索',
  home_ilcTitle: '',
  intro_heading: '2019年，国际劳工组织将迎来一百周年。开启图文互动旅程，探索劳工组织的过去、现在和未来。',
  intro_title: '我们的故事，你的故事',
  intro_buttonStart: '开启发现之旅',
  intro_buttonDates: '了解上线时间',
  intro_tableCaption: '了解“我们的故事，你的故事”各章节内容上线时间',
  intro_chapters: '章节',
  intro_launchDate: '上线时间',
  intro_aboutIntro: '<p>2019年，国际劳工组织将庆祝其推进社会正义和促进体面劳动一百周年.</p>\n\n<p>在其准备迎接明天的挑战之际，本网站将带领您通过一段图文互动旅程，了解国际劳工组织的过去，现在和未来。</p>\n\n<p>了解更多国际劳工组织庆祝一百周年纪念活动，请登录 <a href="https://www.ilo.org/100/news/zh">国际劳工组织百年纪念新闻与活动页面</a>.</p>',
  intro_aboutTitle: '关于',
  intro_aboutDescription: '国际劳工组织百年纪念网站用来纪念其推进社会正义，促进体面劳动的百年历史，网站是在其三方伙伴和其他合作伙伴的大力支持下完成的。',
  intro_aboutResourcesTitle: '更多资源',
  intro_aboutCreditsTitle: '鸣谢',
  intro_aboutResTripartism1: '<a href="https://www.ilo.org">国际劳工组织官方网站</a>',
  intro_aboutResFuture1: '<a href="https://www.ilo.org/global/topics/future-of-work/WCMS_569528/lang--en/index.htm">劳动世界的未来全球委员会报告</a>',
  intro_aboutResTripartism2: '<a href="https://www.ilo.org/global/about-the-ilo/history/lang--en/index.htm">History of the ILO</a>',
  intro_aboutCreditsH1: '故事，照片和视频',
  intro_aboutCreditsP1: '国际劳工组织通讯与公共信息部门',
  intro_aboutCreditsH2: '其他照片和视频',
  intro_aboutCreditsH3: '创意方向与设计',
  intro_aboutCreditsH4: '网站开发',
  intro_aboutCreditsH5: '音乐与声音设计',
  intro_aboutCreditsH6: '特别感谢',
  intro_aboutCreditsP2: '法新社 • 罗斯福图书馆 • 杰西卡·梅达 • 丽萨·克里斯汀• 美国国会图书馆 • 马克西姆·福萨• 壁纸分享公司(Unsplash) • 维基共享',
  intro_aboutCreditsP3: '国际劳工组织通讯与公共信息部门<br>埃尔卡诺数据公司（ElkanoData）',
  intro_aboutCreditsP4: '国际劳工组织通讯与公共信息部门<br>方球数字公司（Squareball Digital）',
  intro_aboutCreditsP5: '埃尔卡诺数据公司（ElkanoData）<br>\n极端音乐（Extreme Music）(唱片公司)',
  intro_aboutCreditsP6: '国际劳工组织百年纪念网站用来纪念其推进社会正义，促进体面劳动的百年历史，网站是在三方伙伴和其他合作伙伴的大力支持下完成的。\n\n<br><br>\n\n伯尼斯•安托万 (特立尼达和多巴哥青年活动家)  •   瓦伦蒂娜   奥内姆（酒店接待员）•   米歇尔    巴切莱特（联合国人权事务高级专员）•   斯雷拉    达斯    古普塔（多元化和包容性专家）• 卡林    杜姆洛特（阿埃塔人原住民领袖）•  扎伊纳布    埃库巴（家政工人）•  阿波洛尼娅·丰特斯 (领取养老金者)  •  乌尔夫    古斯塔夫松（矿工和工会会员）•  艾莉森  哈特（互联网自由职业者）•  近藤真一（日本坚鲁医院院长）•  日下幸则（福井大学名誉教授）•  道·瑟·伊 (失业工人)  •  齐纳布    马哈茂德（企业家）•    玛丽娜    梅西亚（企业家）• 辛·马尔·敏 (服装厂工人)  •  菅沼成文（日本高知医学院教授）•  弗朗西斯    菲里（手语老师）•  维沙尼•拉戈贝尔 (圭亚那青年活动家)  •  尼古拉    葛利亚    桑切斯（学生）•  劳雷尔斯    普拉格（联合国艾滋病规划署社区特别顾问）•  阿方索·耶稣·维达尔 (领取养老金者)  •   张艳艳（中国企业联合会）\n\n<br><br>\n\n特别感谢国际劳工组织所有帮助创建本网站的同事。',
  intro_dateChap1: '1月9日',
  intro_dateChap2: '1月16 日',
  intro_dateChap3: '1月30 日',
  intro_dateChap4: '2月6日',
  intro_dateChap5: '2月13 日',
  intro_dateChap6: '2月21 日',
  intro_dateChap7: '2月27日',
  intro_dateChap8: '3月13日',
  intro_dateChap9: '4月1日',
  intro_post: '开始一段互动之旅了解@ILO的过去、现在和未来——庆祝推进社会正义和促进体面工作100周年。',
  intro_aboutResTripartism3: '<a href="https://www.ilo.org/actemp/lang--en/index.htm">Bureau for Employers\' Activities (ACTEMP)</a>',
  intro_aboutResRights1: '<a href="https://www.ilo.org/global/standards/introduction-to-international-labour-standards/conventions-and-recommendations/lang--en/index.htm">What are International Labour Standards, Conventions and Recommendations?</a>',
  intro_aboutResRights2: '<a href="https://www.alliance87.org/">Alliance 8.7</a>',
  intro_aboutResRights3: '<a href="https://www.ilo.org/global/about-the-ilo/how-the-ilo-works/departments-and-offices/governance/fprw/lang--en/index.htm">The ILO Fundamental Principles and Rights at Work Branch</a>',
  intro_aboutResTripartism4: '<a href="https://www.ilo.org/actrav/lang--en/index.htm">Bureau for Workers’ Activities (ACTRAV)</a>',
  intro_aboutResEquality1: '<a href="https://www.equalpayinternationalcoalition.org/">EPIC: the Equal Pay International Coalition</a>',
  intro_aboutResEquality2: '<a href="https://www.ilo.org/global/topics/indigenous-tribal/lang--en/index.htm">Indigenous and tribal peoples ILO topic portal</a>',
  intro_aboutResEquality3: '<a href="https://www.ilo.org/global/topics/hiv-aids/lang--en/index.htm">HIV and AIDS ILO topic portal</a>',
  intro_aboutResEmployment1: '<a href="https://www.decentjobsforyouth.org/">The Global Initiative on Decent Jobs for Youth</a>',
  intro_aboutResEmployment2: '<a href="https://www.skillsforemployment.org/KSP/en/index.htm">Skills for Employment Knowledge Sharing Platform</a>',
  intro_aboutResEmployment3: '<a href="https://www.ilo.org/ilostat/faces/wcnav_defaultSelection;ILOSTATCOOKIE=R-wfbFBlo0oFiXPV-X-uScWMUKkEUyQ18RILGxzS2kO8HhTHCNYu!1595421183?_afrLoop=284774499866132&_afrWindowMode=0&_afrWindowId=null">ILOSTAT: Labour statistics</a>',
  intro_aboutResProtection1: '<a href="https://www.social-protection.org/gimi/gess/Wspr.action">World Social Protection Report Data 2017-2019</a>',
  intro_aboutResWork1: '<a href="https://www.ilo.org/global/topics/labour-law/lang--en/index.htm">Labour law ILO topic portal</a>',
  intro_aboutResConditions1: '<a href="https://betterwork.org/">Better Work</a>',
  intro_aboutResConditions2: '<a href="https://www.ilo.org/wcmsp5/groups/public/---ed_dialogue/---lab_admin/documents/projectdocumentation/wcms_572474.pdf">Vision Zero Fund</a>',
  intro_aboutResConditions3: '<a href="https://www.ilo.org/global/topics/wages/minimum-wages/lang--en/index.htm">Minimum Wage Policy Guide</a>',
  intro_aboutResEnterprises1: '<a href="https://www.ilo.org/empent/areas/business-helpdesk/lang--en/index.htm">ILO Helpdesk for Business on International Labour Standards</a>',
  intro_aboutResEnterprises2: '<a href="https://www.ilo.org/empent/units/boosting-employment-through-small-enterprise-development/eese/lang--en/index.htm">Enabling Environment for Sustainable Enterprises (EESE)</a>',
  intro_aboutResEnterprises3: '<a href="http://www.knowaboutbusiness.org/">Know About Business (KAB)</a>',
  intro_aboutResEnterprises4: '<a href="http://mmw.itcilo.org/en/home">Making Microfinance Work!</a>',
  intro_aboutResEnterprises5: '<a href="https://www.ilo.org/empent/areas/start-and-improve-your-business/lang--en/index.htm">Start and Improve Your Business Programme (SIYB)</a>',
  intro_aboutResEnterprises6: '<a href="https://www.itcilo.org/en/areas-of-expertise/rural-development/my-coop-manging-your-agricultural-cooperative">My.COOP</a>',
  intro_aboutResEnterprises7: '<a href="https://www.ilo.org/empent/Projects/score/lang--en/index.htm">Sustaining Competitive and Responsible Enterprises (SCORE)</a>',
  intro_aboutResEnterprises8: '<a href="https://www.ilo.org/empent/areas/womens-entrepreneurship-development-wed/lang--en/index.htm">Women\'s Entrepreneurship Development Programme (WED)\n</a>',
  intro_aboutResEnterprises9: '<a href="https://www.ilo.org/empent/areas/mne-declaration/lang--en/index.htm">Tripartite Declaration of Principles concerning Multinational Enterprises and Social Policy (MNE Declaration)</a>',
  intro_aboutResFuture2: '<a href="https://www.ilo.org/global/topics/sdg-2030/lang--en/index.htm">Decent work and the 2030 Agenda for sustainable development</a>',
  intro_aboutResEquality4: '<a href="https://www.ilo.org/global/topics/disability-and-work/lang--en/index.htm">Disability and work ILO topic page</a>',
  intro_aboutResEquality5: '<a href=" http://www.businessanddisability.org/">ILO Global Business and Disability Network</a>',
  tripartism_title: '为社会正义奠定基础',
  tripartism_heading: '三方机制与社会对话',
  tripartism_chapter: '第一章',
  tripartism_ilo: '国际劳工组织',
  tripartism_infoboxIlo: '<p>国际劳工组织诞生于动荡年代，也是科技和社会巨变的年代。</p> \n<p>当时，整个世界都在努力从战争的废墟中重建、恢复，社会正义是实现世界持久和平的关键，国际劳工组织正是植根于这种信念而生。</p>',
  tripartism_socialJustice: '社会正义',
  tripartism_keysQuote: '“只有当三把钥匙共同转动，也就是政府、雇主和工人三方集聚一堂时，我们才能打开大门，推进社会正义。”',
  tripartism_guyRyder: '——国际劳工组织总干事盖·莱德',
  tripartism_calloutWorld1919: '1919年的世界',
  tripartism_infoWw1: '<p>第一次世界大战使各国满目疮痍，人民饱受创伤，国家经济遭受严重打击。世界版图被重新划分，后方夜以继日劳作的人们高声呼喊，希望他们的声音能够被听到。</p>\n\n<p>欧洲和美国在战前的一百年发生了深刻的经济和社会变革。工业化带来了欧洲国家经济前所未有的发展，国家间的竞争也日益激烈，一部分原因就来自于劳动世界发生的泯灭人性的变革。</p>',
  tripartism_calloutVersailles: '凡尔赛和约',
  tripartism_infoVersailles: '<p>一战的余波中，巨大的不平等和恶劣的工作条件带来了人们的觉醒，欧洲的革命一触即发。</p>\n\n<p>工人们要求，和平解决方案必须包括确保更公平工作条件的措施，并通过国际劳工立法和工会权利得到进一步推进。</p>',
  tripartism_whatIsSocialJustice: '什么是社会正义？',
  tripartism_infoUnrest: '<h3>“如此动荡不安，世界和平与和谐因此遭受危害”</h3>\n\n<p>这些要求如此坚决，社会形式势如破竹，巴黎和会的主要关切之一，就是“......如此动荡不安，世界和平与和谐因此遭受危害......”。</p>\n\n<p>这一宣言掀开了《国际劳工组织章程》序言，明确地赋予了国际劳工组织在维护和平中的重要作用。</p>',
  tripartism_calloutIloCreated: '因此，汇集了政府、工人和雇主三方代表的国际劳工组织，根据《凡尔赛和约》第十三章的规定，在1919年正式成立，代表了一战的终结。',
  tripartism_quoteWildDreamBody: '“我清楚的记得那些日子里，国际劳工组织还只是一个梦想。对很多人来说，这是个疯狂的梦想。有谁听说过各国政府会联合起来，在国际航班上讨论提高劳工标准呢？”<br><br>“更疯狂的设想是，那些直接受到影响的人们——各国的工人和雇主——应该和政府联手确定这些劳工标准”。',
  tripartism_quoteWildDreamCaption: '1934年，富兰克林•德拉诺•罗斯福担任总统时，美国加入国际劳工组织。',
  tripartism_calloutWildDream: '对很多人来说，这是个疯狂的梦想',
  tripartism_timeline1969Heading: '国际劳工组织成立50周年，被授予诺贝尔和平奖',
  tripartism_timeline1969Para: '“几乎没有组织能像国际劳工组织一样，成功地将其所依据的基本道德理念转化为行动。国际劳工组织通过认真和不懈的努力，成功地进行了改革，消除了大多数国家最为严重的不公正”——艾塞•莱昂纳斯夫人，挪威议会诺贝尔委员会主席诺贝尔和平奖',
  tripartism_timeline1919Heading: '《国际劳工组织章程》的起草',
  tripartism_timeline1919Para: '从1月到4月，巴黎和会成立的劳工委员会在巴黎召开了会议，并在凡尔赛宫开始起草《国际劳工组织章程》。\n\n第一届国际劳工大会于1919年10月至11月在华盛顿召开，共有40个国家的代表参会。',
  tripartism_timeline1920Heading: '国际劳工局在日内瓦成立',
  tripartism_timeline1920Para: '1920年夏天，国际劳工组织在瑞士日内瓦成立，法国人阿尔伯特·托马斯任国际劳工局第一任局长。国际劳工局成为国际劳工组织永久秘书处。',
  tripartism_timeline1936Heading: '国际劳工组织第一届区域会议在智利圣地亚哥召开',
  tripartism_timeline1944Heading: '《费城宣言》',
  tripartism_timeline1946Heading: '国际劳工组织加入联合国',
  tripartism_timeline1946Para: '国际劳工组织成为刚刚成立的联合国的首个专门机构。这使得国际劳工组织成为冷战期间论战的中心舞台之一。',
  tripartism_timeline1947Heading: '国际劳工组织第一届亚洲区域会议在印度新德里召开',
  tripartism_timeline1947Para: '在亚洲发生翻天覆地变化之时，来自18个国家和地区的代表共同商讨如何加强国际劳工组织在亚洲的活动。',
  tripartism_timeline1964Heading: '《反对种族隔离宣言》',
  tripartism_timeline1964Para: '国际劳工大会全票通过《反对种族隔离宣言》，谴责南非政府的种族政策。1990年，纳尔逊·曼德拉参加了国际劳工大会，并赞扬国际劳工组织为反对种族隔离而作出的贡献。',
  tripartism_timeline2014Heading: '国际劳工组织国际培训中心在意大利都灵成立',
  tripartism_timeline1982Heading: '国际劳工组织在东欧促进工会自由',
  tripartism_timeline1982Para: '国际劳工组织全力支持波兰团结工会的合法性，莱赫•瓦文萨领导的罢工标志着东欧集团首个独立、自治工会的成立，打开了重返民主的大门。',
  tripartism_timeline1998Heading: '《国际劳工组织关于工作中的基本原则与权利宣言》',
  tripartism_timeline1998Para: '国际劳工组织成员国承诺尊重并促进结社自由、强迫劳动、童工和歧视相关的权利和原则，无论该国是否批准了相关公约。',
  tripartism_timeline1999Heading: '启动体面劳动议程',
  tripartism_timeline1999Para: '体面劳动概括了人们在劳动生活中的愿望，议程的四个支柱分别为促进就业和企业、扩大社会保护、促进社会对话和保障工作中的权利。男女机会和待遇平等是这四大支柱统一的基础。',
  tripartism_timeline2008Heading: '《争取公平全球化的社会正义宣言》',
  tripartism_timeline2008Para: '《宣言》通过综合手段，旨在实现四个战略目标促进体面劳动：创造就业、社会保护、社会对话以及工作中的基本原则和权利。宣言将社会对话和三方机制作为经济发展和社会进步互相转化的最适当方法，不断促进其发展。\n\n同年，全球金融危机开始，成为自大萧条以来最严重的经济衰退。',
  tripartism_timeline2009Heading: '全球就业契约',
  tripartism_timeline2009Para: '面对长期失业、贫穷和不平等的前景，国际劳工大会通过了《全球就业契约》，旨在刺激经济复苏、创造就业机会并为劳动者及其家庭提供保护。',
  tripartism_timeline2015Heading: '可持续发展目标将体面劳动纳入中心',
  tripartism_timeline2015Para: '2015年9月联合国大会期间，体面劳动议程的四个支柱——工作中的标准和权利、创造就业和企业发展、社会保护和社会对话——成为2030年可持续发展议程的组成部分。',
  tripartism_timeline2019Heading: '国际劳工组织百年纪念：塑造有利于所有人的未来',
  tripartism_timeline2019Para: '作为联合国历史最悠久的专门机构，国际劳工组织开展百年纪念活动。',
  tripartism_infoLifeIndustry: '<h3>工业化国家当时的生活与现今大有不同</h3>\n\n<p>当时，贫困、不平等和歧视的程度和范围更广，在工厂、田间劳作的儿童随处可见。工作场所的事故和伤亡更是司空见惯。</p>',
  tripartism_timeline1926Heading: '国际劳工组织公约监督机制',
  tripartism_timeline1926Para: '实施公约与建议书专家委员会成立，监督各国对其批准的国际劳工组织公约负责。',
  tripartism_timeline1929Heading: '大萧条开始',
  tripartism_timeline1929Para: '国际劳工组织的工作直接关系到大萧条的解决方案，这也是当时最重要的问题。',
  tripartism_timeline1936Para: '美洲成员国的首次劳工会议重申了普适性的概念——无论各国的社会或经济体制如何，国际劳工组织的行动必须回应全世界人民的需求。',
  tripartism_timeline1939Heading: '第二次世界大战开始',
  tripartism_timeline1939Para: '由于瑞士被轴心国军队包围，国际劳工组织总部于1940年8月临时迁往加拿大蒙特利尔。',
  tripartism_timeline1944Para: '国际劳工组织通过《费城宣言》，重申劳动不是商品，言论自由和结社自由是不断进步的必要条件，任何地方的贫困对一切地方的繁荣构成威胁。这使得国际劳工组织的工作与战后所面临的挑战息息相关。',
  tripartism_timeline1948Heading: '通过国际劳工组织关于结社自由的核心公约',
  tripartism_timeline1948Para: '第87号结社自由和保护组织权利公约（1948年）规定了工人和雇主有权不经事先批准建立和参加他们自己选择的组织。',
  tripartism_timeline1949Heading: '通过国际劳工组织关于集体谈判的核心公约',
  tripartism_timeline1949Para: '第98号组织权利和集体谈判权利公约（1949年）指出，工人应享有保护，防止发生任何排斥工会的歧视行为，并规定工人享有集体谈判的权利。',
  tripartism_timeline1952Heading: '安第斯印第安项目',
  tripartism_timeline1952Para: '第一项大型技术合作项目开始实施，项目由国际劳工组织领导并与联合国其他机构合作。该项目覆盖七个安第斯山脉国家，旨在改善原住民的社会经济条件，一直持续到1972年。',
  tripartism_timeline1955Heading: '第一届国际劳工组织欧洲区域会议在日内瓦召开',
  tripartism_timeline1955Para: '“本次会议的重要性在于，它表明欧洲国家已经做好了准备，解决欧洲大陆日益完善的经济一体化所带来的社会问题。”——戴维·莫尔斯，国际劳工组织时任总干事',
  tripartism_timeline1960Heading: '第一届国际劳工组织非洲区域会议在尼日利亚拉各斯召开',
  tripartism_timeline1960Para: '此次会议为国际劳工组织有史以来举办的规模最大的区域会议，本次会议召开的背景十分特殊，许多新非洲国家的独立，使得会议在国际上获得了极大的共鸣。',
  tripartism_timeline2014Para: '国际培训中心为政府、雇主、工人组织以及支持体面劳动的其他国际合作伙伴提供培训、学习和能力开发服务。自1964年成立以来，都灵培训中心已经对来自172个国家的8万人展开了培训。',
  tripartism_timeline1976Heading: '通过国际劳工组织关于三方机制和劳工标准公约',
  tripartism_timeline1976Para: '第144号三方协商促进履行国际劳工标准公约（1976年）规定，雇主和工人应以平等地位参加从事协商的任何机构，且每年至少开展一次协商。',
  tripartism_timeline1977Heading: '改善工作条件和工作环境的新项目',
  tripartism_timeline1977Para: '改善工作条件和工作环境国际项目（PIACT）旨在加强并支持相关的行动，“使劳动更加人道”。项目希望通过预防工伤和职业病，“更加关注技术转移中的人性因素”，提升工作质量。',
  tripartism_timeline1992Heading: '消除童工劳动的新项目',
  tripartism_timeline1992Para: '国际劳工组织启动了消除童工劳动国际计划（IPEC），这是有史以来影响最为深远的消除童工劳动项目，根据国际劳工组织统计数据，这一项目让全世界8600万儿童摆脱了童工劳动。',
  tripartism_timeline2002Heading: '成立全球化的社会影响世界委员会',
  tripartism_timeline2002Para: '26位重要人物——包括国家元首、商业领袖、工会领导人和诺贝尔奖获得者——通过普通人的视角审视全球化进程。这是我们首次尝试在这个时代最具争议和分歧的话题中找到共同点。',
  tripartism_quoteSocJusBody1: '“战胜贫穷不是一种慈善行为，而是一种追求正义的行动。”',
  tripartism_quoteSocJusBody2: '“只有最广大的民众对各自的福祉充满责任感，社会正义才能得以实现。”',
  tripartism_quoteSocJusCaption1: '纳尔逊•曼德拉，南非前总统，1993年诺贝尔和平奖得主',
  tripartism_quoteSocJusCaption2: '——海伦·凯勒，美国作家、政治活动家',
  tripartism_quoteSocJusBody3: '“当人们的权利被剥夺，无法有尊严的生活，无法期待更好的未来，无法掌控自己的生活时，剩下的就只有为获得这些权利的斗争。”',
  tripartism_quoteSocJusCaption3: '——拉尼娅·阿卜杜拉，约旦王后',
  tripartism_quoteSocJusBody4: '“实现世界持久和平的另一个关键因素就是社会正义。”',
  tripartism_quoteSocJusCaption4: '——阿瑟·亨德森，英国工党前领导人，1934年诺贝尔和平奖获得者',
  tripartism_quoteSocJusBody5: '“和平离不开正义，正义离不开公平，公平离不开发展，发展离不开民主，民主离不开对文化和各人种身份认同与价值的尊重。”',
  tripartism_quoteSocJusCaption5: '里格伯塔•门楚，危地马拉人权活动家，1992年诺贝尔和平奖得主',
  tripartism_quoteSocJusBody6: '“教育是赋权和可持续发展的关键，教育权是实现生活中其他所有权利的关键。教育是社会正义的关键。”',
  tripartism_quoteSocJusCaption6: '——凯拉什·萨蒂亚尔希，印度儿童权利活动家，2014年诺贝尔和平奖获得者',
  tripartism_infoFounders: '<h3>国际劳工组织创始成员国</h3>\n\n<p>......坚信，在国际和平与社会正义之间存在着必然的联系。这种联系十分重要，因此必须成立处理劳工事务的组织，从而促进并保卫世界和平。</p>',
  tripartism_visSocJustTitle: '社会正义的关键因素',
  tripartism_visSocJustHeading1: '国际劳工组织如何实现社会正义',
  tripartism_visSocJustBody1: '<p>国际劳工组织是唯一一个政府没有绝对投票权的国际组织。在制定标准和政策时，雇主和工人代表与政府有着平等的发声权，参与决策过程。这就是“三方机制”的概念。</p>\n\n<p>在1919年，给予“非政府”代表参与国际会议的权利完全是一项很激进的建议。实际上，在国际劳工组织成立很多年甚至几十年之后，大多数国家才开始在国家层面建立起三方机制。</p>\n\n<p>这一独特的安排使得国际劳工组织拥有了得天独厚的优势，可以将“真实世界”中关于就业和劳工的知识与其工作相结合。</p>\n\n<p>基于这一成功的先例，国际劳工组织85%的成员国建立了三方机构，处理劳工相关的事宜。</p>',
  tripartism_visSocJustHeading2: '社会对话',
  tripartism_visSocJustBody2: '<p>受到劳动世界决定所影响的人们应该有权利在决策和实施过程中发声，这一民主进程就是社会对话。</p>\n\n<p>社会对话包括工人、雇主和政府之间的信息交流、协商和谈判等多种形式。这一切都是基于结社自由权，使得工人和雇主能够自愿组建并加入相关组织，同时也基于集体谈判工资和其他就业条件的权利。建立在透明、诚信、相互尊重和理解基础上的社会对话会取得最佳效果。</p>\n\n<p>在工作场所，社会对话有助于改善工作环境，提高经济效益，也是稳定、公平、生产力和可持续增长的强大推动力。</p>\n\n<p>在国家层面，社会对话和三方机制是参与式民主的守护者。他们共同保障并促进实现工作中的基本原则和权利，确保工人能够公平地从经济发展中受益，确保工作场所的安全，确保全球化惠及每一个个体。社会对话和三方机制对于可持续发展、社会正义与和平十分关键。</p>',
  tripartism_visSocJustHeading3: '工人和雇主组织',
  tripartism_visSocJustBody3: '<p>如何建立良好的劳资关系对世界各地的工人和雇主组织来说是一个共同且艰巨的挑战。</p>\n\n<p>由于社会对话是其工作的关键，国际劳工组织协助提升这些组织的效力，使其更好地支持并代表其成员。</p>\n\n<p>因此，国际劳工组织与工人雇主代表紧密合作，使他们与政府代表一同参与国际劳工组织的理事会，并制定相关议程。</p>\n\n<p>通过在工作场所的合作，国际劳工组织在劳动世界及其面临的不断变化的挑战中发挥着重要的作用，包括引进新技术、推动创新并确保安全的工作环境。</p>',
  tripartism_calloutDistinctHeading: '国际劳工组织与其他政府间组织的不同还体现在另外一个重要方面，即其通过的国际劳工标准以及监督实施方式。',
  tripartism_calloutDistinctBody: '这些国际公约规定了批约国应采取的行动和应遵守的原则。它们是国际劳工组织在劳动世界采取行动和产生影响力的主要手段，并有助于创造国家、企业和工人的公平竞争环境。',
  tripartism_timeline1919Caption: '首届国际劳工大会于1919年在美国首都华盛顿召开。 © 国际劳工组织',
  tripartism_timeline1920Caption: '国际劳工组织首任总干事阿尔伯特•托马斯。© 国际劳工组织',
  tripartism_timeline1926Caption: '1926年，国际劳工组织理事会批准成立一个技术委员会，即后来的公约与建议书实施专家委员会（专家委员会），来监督国际劳工标准实施情况。© 国际劳工组织',
  tripartism_timeline1929Caption: '大萧条期间，失业工人排成长队。美国纽约，1930年。© 美国国会图书馆',
  tripartism_timeline1936Caption: '首届国际劳工组织美洲成员国会议于1936年在智利圣地亚哥召开。© 国际劳工组织',
  tripartism_timeline1939Caption: '1940年9月，国际劳工组织在战乱期间暂移至加拿大，国际劳工组织官员及其家属在葡萄牙里斯本港口等待上船。© 国际劳工组织',
  tripartism_timeline1944Caption: '1944年5月17日，在美国总统富兰克林•罗斯福（坐，左）的见证下，国际劳工组织总干事爱德华• 费兰（坐，右）在白宫签署了具有历史意义的《费城宣言》。© 国际劳工组织',
  tripartism_timeline1946Caption: '国际劳工组织总干事爱德华• 费兰（左）与联合国秘书长特里格夫•赖伊（右）签署协议，国际劳工组织成为新成立的联合国的首个专门机构。© 国际劳工组织',
  tripartism_timeline1947Caption: '1947年，国际劳工组织首届亚洲区域会议在印度新德里召开。© 国际劳工组织',
  tripartism_timeline1948Caption: '1948年，国际劳工组织职员在美国旧金山参加国际劳工大会。© 国际劳工组织',
  tripartism_timeline1949Caption: '国际劳工组织第四任总干事爱德华• 费兰（左）与第五任总干事戴维•莫尔斯（右），瑞士日内瓦，1949年。© 国际劳工组织',
  tripartism_timeline1952Caption: '安第斯印第安人计划，秘鲁。© 国际劳工组织',
  tripartism_timeline1955Caption: '瑞士联邦主席马克斯•珀蒂皮埃尔与第五任国际劳工组织总干事戴维•莫尔斯（左）及国际劳工组织理事会主席罗伯特•艾戈（右）共同宣布国际劳工组织首届欧洲区域会议开幕。© 国际劳工组织',
  tripartism_timeline1960Caption: '第五任国际劳工组织总干事戴维•莫尔斯到访尼日利亚拉各斯，出席国际劳工组织首届非洲区域会议。© 国际劳工组织',
  tripartism_timeline1964Caption: '国际劳工组织是首批就种族隔离政策对南非实施制裁的国际组织。三十余年后，在1990年第77届国际劳工大会上，时任南非总统纳尔逊•曼德拉感谢国际劳工组织为反对种族隔离做出的贡献。© 国际劳工组织',
  tripartism_timeline2014Caption: '每年，来自世界各地的人们都会来到位于都灵的国际劳工组织（ILO）国际培训中心参与研讨会、讲习班和课程学习。©大卫·贝卢卡',
  tripartism_timeline1969Caption: '1969年12月10日，国际劳工组织总干事戴维•莫尔斯在奥斯陆大学代表国际劳工组织从挪威国会诺贝尔委员会主席艾塞•莱昂纳斯手中接过诺贝尔和平奖奖杯。© 国际劳工组织',
  tripartism_timeline1976Caption: '1976年，瑞士日内瓦联合国大会会场参加第61届国际劳工大会的代表。© 国际劳工组织',
  tripartism_timeline1977Caption: '毛里塔尼亚职业安全与卫生宣传海报。© 国际劳工组织',
  tripartism_timeline1982Caption: '国际劳工组织对波兰独立工会——团结工会给予支持，团结工会领导者莱赫•瓦文萨后来当选波兰总统。© 国际劳工组织',
  tripartism_timeline1992Caption: '国际劳工组织制定消除童工劳动国际计划（IPEC）。© 国际劳工组织/E.贾诺蒂',
  tripartism_timeline1998Caption: '第86届国际劳工大会通过《国际劳工组织关于工作中的基本原则与权利宣言》，瑞士日内瓦。© 国际劳工组织',
  tripartism_timeline1999Caption: '国际劳工组织推出体面劳动议程。图为2001年6月7日，失业的津巴布韦工人正在首都哈拉雷求职。© 法新社/亚历山大•乔',
  tripartism_timeline2002Caption: '全球化的社会影响世界委员会。从左至右分别为，坦桑尼亚总统本杰明•威廉•姆卡帕、芬兰总统塔里娅•哈洛宁、国际劳工组织总干事胡安•索马维亚。© 国际劳工组织/M.克罗泽特',
  tripartism_timeline2008Caption: '2008年召开的第97届国际劳工大会通过了《争取公平全球化的社会正义宣言》，瑞士日内瓦。©国际劳工组织',
  tripartism_timeline2009Caption: '2009年6月15日，时任国际劳工组织总干事胡安•索马维亚在国际劳工组织全球就业危机峰会开幕式致辞，瑞士日内瓦。 © 国际劳工组织',
  tripartism_timeline2015Caption: '联合国第八任秘书长潘基文在国际劳工大会上致辞。© 国际劳工组织',
  tripartism_timeline2017Caption: '2017年8月21日，国际劳工组织总干事盖•莱德在劳动世界的未来全球委员会成立仪式上致辞，瑞士日内瓦。© 国际劳工组织/M.克罗泽特',
  tripartism_timeline2019Caption: '国际劳工组织亚太地区局工作人员庆祝即将到来的国际劳工组织百年纪念。© 国际劳工组织',
  tripartism_visSocJustTripartism: '三方机制',
  tripartism_visSocJustGovernments: '政府',
  tripartism_visSocJustEmployers: '雇主',
  tripartism_visSocJustWorkers: '工人',
  tripartism_imageThreekeysCaption: '国际劳工组织位于瑞士日内瓦的新总部大楼于1926年正式起用。代表政府、雇主和工人的三把钥匙打开了新总部大楼的大门。© 国际劳工组织',
  tripartism_imageThreekeysAlt: '瑞士日内瓦拉帕德别墅大门上的三把钥匙。',
  tripartism_imageGirlcottonCaption: '1913年，一个年轻女孩在美国德克萨斯州的一家纺织厂纺织棉花。© 美国国会图书馆',
  tripartism_imageFactoriesCaption: '二十世纪的工业 © 国际劳工组织',
  tripartism_imageProtestCaption: '欧洲街头的游行，1919 年。© 英国百代新闻电影资料库',
  tripartism_imageCommissionCaption: '在1919年2-3月召开的巴黎和会期间，国际劳工立法委员会召开会议。会议起草了《国际劳工组织章程》，并被写入后来的《凡尔赛和约》。 © 国际劳工组织',
  tripartism_imageCommissionAlt: '参会代表站在一栋建筑门前的台阶上。为首的是国际劳工组织立法委员会主席塞缪尔•龚帕斯。',
  tripartism_imageInaugurationCaption: '国际劳工组织总部于1926年在瑞士日内瓦正式落成。 © 国际劳工组织',
  tripartism_imageRooseveltCaption: '富兰克林•罗斯福是第32任美国总统。在他任期内，美国正式加入国际劳工组织。 ©法新社（音频来源：美国国家档案）',
  tripartism_imageFirstilcCaption: '首届国际劳工大会于1919在美国首都华盛顿召开。 © 国际劳工组织',
  tripartism_imageAlbertthomasCaption: '国际劳工组织首任总干事阿尔伯特•托马斯。© 国际劳工组织',
  tripartism_imageGbtwentysixCaption: '1926年国际劳工组织理事会批准成立一个技术委员会，即后来的公约与建议书实施专家委员会（专家委员会），来监督国际劳工标准实施情况。© 国际劳工组织',
  tripartism_imageGbtwentysixAlt: '代表们齐聚会议室。',
  tripartism_imageUnemployedCaption: '大萧条期间，失业工人排成长队。美国纽约，1930年。© 美国国会图书馆',
  tripartism_imageFirstchileCaption: '首届国际劳工组织美洲成员国会议于1936年在智利圣地亚哥召开。© 国际劳工组织',
  tripartism_imageFirstchileAlt: '代表们走出大楼，为首的是国际劳工组织第二任总干事哈罗德•巴特勒。',
  tripartism_imageShiplisbonCaption: '1940年9月，国际劳工组织在战乱期间暂移至加拿大，国际劳工组织官员及其家属在葡萄牙里斯本港口等待上船。© 国际劳工组织',
  tripartism_imageShiplisbonAlt: '镜头前的三男三女站在一艘插着美国国旗的大船前。',
  tripartism_imagePhelandofpCaption: '1944年5月17日，在美国总统富兰克林•罗斯福（坐，左）的见证下，国际劳工组织总干事爱德华• 费兰（坐，右）在白宫签署了具有历史意义的《费城宣言》。© 国际劳工组织',
  tripartism_imagePhelandofpAlt: '包括罗斯福、费兰在内的三人围桌而坐。后排站立着二男一女，女性是美国历史上第一位女性内阁成员弗朗西斯•帕金斯。',
  tripartism_imagePhelanlieCaption: '国际劳工组织总干事爱德华• 费兰（左）与联合国秘书长特里格夫•赖伊（右）签署协议，国际劳工组织成为新成立的联合国的首个专门机构。© 国际劳工组织',
  tripartism_imagePhelanlieAlt: '他们并肩而坐，共同签署协议。',
  tripartism_imageFirstasiaconfCaption: '1947年，国际劳工组织首届亚洲区域会议在印度新德里召开。© 国际劳工组织',
  tripartism_imageFirstasiaconfAlt: '出席区域会议的代表及印度开国总理贾瓦哈拉尔•尼赫鲁，站在房间里。',
  tripartism_imageIlcsanfranCaption: '1948年，国际劳工组织职员在美国旧金山参加国际劳工大会。© 国际劳工组织',
  tripartism_imageIlcsanfranAlt: '图中的男性和女性站在挂着“国际劳工大会”横幅的建筑前。',
  tripartism_imagePhelanmorseCaption: '国际劳工组织第四任总干事爱德华• 费兰（左）与第五任总干事戴维•莫尔斯（右），瑞士日内瓦，1949年。© 国际劳工组织',
  tripartism_imagePhelanmorseAlt: '他们并肩而立，友好对视。',
  tripartism_imageAndeanCaption: '安第斯印第安人计划，秘鲁。© 国际劳工组织',
  tripartism_imageAndeanAlt: '当地工人与国际劳工组织专家在秘鲁丘奎图的一处建筑工地上。',
  tripartism_imagePetitpierreCaption: '瑞士联邦主席马克斯•珀蒂皮埃尔与第五任国际劳工组织总干事戴维•莫尔斯（左）及国际劳工组织理事会主席罗伯特•艾戈（右）共同宣布国际劳工组织首届欧洲区域会议开幕。© 国际劳工组织',
  tripartism_imagePetitpierreAlt: '两人坐在桌前，桌上放着麦克风。中间一人起立发言。',
  tripartism_imageMorsenigeriaCaption: '第五任国际劳工组织总干事戴维•莫尔斯到访尼日利亚拉各斯，出席国际劳工组织首届非洲区域会议。© 国际劳工组织',
  tripartism_imageMorsenigeriaAlt: '戴维•莫尔斯与代表走下舷梯。',
  tripartism_imageMandelaCaption: '国际劳工组织是首批就种族隔离政策对南非实施制裁的国际组织。三十余年后，在1990年第77届国际劳工大会上，时任南非总统纳尔逊•曼德拉感谢国际劳工组织为反对种族隔离做出的贡献。© 国际劳工组织',
  tripartism_imageItcturinCaption: '国际劳工组织意大利都灵培训中心开展培训。© 国际劳工组织',
  tripartism_imageItcturinAlt: '在装有广角镜的大会议室里，几十人围坐在桌旁，倾听大屏幕旁讲授者的讲解。',
  tripartism_imageNobelprizeCaption: '1969年12月10日，国际劳工组织总干事戴维•莫尔斯在奥斯陆大学代表国际劳工组织从挪威国会诺贝尔委员会主席艾塞•莱昂纳斯手中接过诺贝尔和平奖奖杯。© 国际劳工组织',
  tripartism_imageIlcseventysixCaption: '1976年，瑞士日内瓦联合国大会会场参加第61届国际劳工大会的代表。© 国际劳工组织',
  tripartism_imageIlcseventysixAlt: '男性与女性参会代表在大会会场。',
  tripartism_imagePosteroshCaption: '毛里塔尼亚职业安全与卫生宣传海报。© 国际劳工组织',
  tripartism_imagePosteroshAlt: '两人站在写着“工伤事故”的海报前。',
  tripartism_imageLechCaption: '国际劳工组织对波兰独立工会——团结工会给予支持，团结工会领导者莱赫•瓦文萨后来当选波兰总统。© 国际劳工组织',
  tripartism_imageIpecCaption: '国际劳工组织制定消除童工劳动国际计划（IPEC）。© 国际劳工组织/E.贾诺蒂',
  tripartism_imageIpecAlt: '一个带着头巾的女孩坐在织毯机后面。',
  tripartism_imageAdoptfprwCaption: '第86届国际劳工大会通过《国际劳工组织关于工作中的基本原则与权利宣言》，瑞士日内瓦。© 国际劳工组织',
  tripartism_imageAdoptfprwAlt: '第八任总干事米歇尔•汉森和主席台的其他代表为宣言通过而鼓掌。',
  tripartism_imageZimbabweCaption: '国际劳工组织推出体面劳动议程。图为2001年6月7日，失业的津巴布韦工人正在首都哈拉雷求职。© 法新社/亚历山大•乔',
  tripartism_imageZimbabweAlt: '六名男子站在街边电线杆旁，其中三人正在阅读报纸。',
  tripartism_imageWorldcomCaption: '全球化的社会影响世界委员会。从左至右分别为，坦桑尼亚总统本杰明•威廉•姆卡帕、芬兰总统塔里娅•哈洛宁、国际劳工组织总干事胡安•索马维亚。© 国际劳工组织/M.克罗泽特',
  tripartism_imageWorldcomAlt: '三人并排站在条幅前，面带微笑。',
  tripartism_imageDeclarationsjCaption: '2008年召开的第97届国际劳工大会通过了《争取公平全球化的社会正义宣言》，瑞士日内瓦。©国际劳工组织',
  tripartism_imageDeclarationsjAlt: '联合国大会厅坐满了代表。',
  tripartism_imageSomaviajobsCaption: '2009年6月15日，时任国际劳工组织总干事胡安•索马维亚在国际劳工组织全球就业危机峰会开幕式致辞，瑞士日内瓦。 © 国际劳工组织',
  tripartism_imageSomaviajobsAlt: '一名男子在联合国大会厅发表演说，演讲台上带有国际劳工组织的标志。',
  tripartism_imageBankimoonCaption: '联合国第八任秘书长潘基文在国际劳工大会上致辞。© 国际劳工组织',
  tripartism_imageBankimoonAlt: '一名男子在演讲台发表演说。在他身后，是一面带有国际劳工组织标志的旗帜。',
  tripartism_imageRyderfowCaption: '2017年8月21日，国际劳工组织总干事盖•莱德在劳动世界的未来全球委员会成立仪式上致辞，瑞士日内瓦。© 国际劳工组织/M.克罗泽特',
  tripartism_imageRyderfowAlt: '一名男子在演讲台发表演说，讲台上写着“劳动世界的未来全球委员会”字样。',
  tripartism_imageAsiaofficeCaption: '国际劳工组织亚太地区局工作人员庆祝即将到来的国际劳工组织百年纪念。© 国际劳工组织',
  tripartism_imageAsiaofficeAlt: '一群男女身着和国际劳工组织标志相同蓝色的T恤，站在前排的人们手持带有国际劳工组织百年标志的标语。',
  rights_heading: '权利与劳工标准',
  rights_chapter: '第二章',
  rights_title: '你在工作中的权利',
  rights_calloutRightsTitle: '同样的故事在全世界每个国家都有可能上演，它们的共同点在于涉及对我们权利的侵犯。',
  rights_calloutRightsBody: '儿童受教育的权利、参加工会的权利、设立企业的权利、享有安全的工作环境的权利，以及被尊重的权利——这些权利都是国际劳工组织国际劳工标准的重要部分。',
  rights_infoPathtodw: '<h3>通往体面劳动之路</h3>\n\n<p>劳动并不是了无生气的商品，无法用最高利润或最低定价来衡量。</p>\n\n<p>我们所说的体面劳动，内化于人的自尊、福祉和发展。国际劳工标准使我们踏上通往体面劳动之路。</p>\n\n<p>要使经济发展更有意义，就需要创造就业，保证人们能享有自由、平等、安全和有尊严的工作条件。</p>\n\n<p>国际劳工标准旨在确保发展过程中让每一个人生活得更好。</p>',
  rights_infoLevelplayBody1: '<h3>公平竞争环境</h3>\n\n<p>在经济全球化背景下实现体面劳动的目标需要全球层面的努力。国际劳工组织自创立以来，通过由政府、雇主和工人共同支持的法律体系，为这一目标不断努力。</p>\n\n<p>国际劳工组织法律体系中包含国际劳工公约，即在一国政府通过或批约后具有法律约束力的国际条约，或不具有法律约束力的建议书。</p>\n\n<p>国际劳工公约与建议书设立了童工、强迫劳动、性别平等等与具体劳工事务相关的最低限度的保护标准。</p>\n\n<p>他们不仅针对不断变化的劳动世界中工人面临的新情况和新挑战，更致力于为不同国家和不同产业创造公平的竞争环境。在你所在的国家，确保工作场所提供良好工作条件的法律很有可能是依据国际劳工标准制定的。</p>',
  rights_calloutDevstandards: '在国际劳工组织制定国际劳工标准是一项需要全球各国政府、工人和雇主组织共同努力的独特过程。',
  rights_infoRoadtolsBody1: '<h3>制定劳工标准之路</h3>\n\n<p>在确定劳动世界的一项问题后，国际劳工组织会形成相应的报告，并征求成员国政府、工人和雇主组织的意见。在此基础上，国际劳工组织理事会将同意在国际劳工大会上就这一议题进行讨论。</p>\n\n<p>根据讨论结果，国际劳工组织将制定新的劳工标准，在后续会议上进行讨论，做必要修改后提交通过。每一项国际劳工标准需保证有三分之二的多数表决后通过。</p>',
  rights_calloutSohowprobTitle: '劳动世界中的重要问题是如何确定的？',
  rights_calloutSohowprobBody: '政府、工人和雇主组织以及公民社会团体参与确定制定新的国际劳工标准的需求。',
  rights_infoGlobalmarchBody1: '<p>1998年，印度儿童权利活动家凯拉什•萨蒂亚尔希发起了一场名为“全球反童工游行”的草根运动，强烈呼吁消除童工。非政府组织、教师和儿童纷纷参与。</p>\n\n<p>游行活动遍布非洲、亚洲、北美洲、拉丁美洲和欧洲，最终来到了日内瓦国际劳工大会现场。</p>\n\n<p>这一游行具有重要意义。当时的国际劳工组织正在讨论提出针对最恶劣形式的童工的新公约。</p>\n\n<p>这一游行活动影响力巨大，并促成了国际劳工组织史上通过最快的一个公约。</p>',
  rights_calloutUniversalvalues: '我们的普世价值',
  rights_infoGbfourissues: '<p>国际劳工组织确立了四项工作场所核心原则与权利。它们受八个“核心”公约和相关建议书及议定书的保护。</p>',
  rights_listFprw1: '结社自由与集体谈判权',
  rights_listFprw2: '消除一切形式的强迫劳动',
  rights_listFprw3: '有效消除童工',
  rights_listFprw4: '消除就业歧视',
  rights_infoBasisfprw: '<p>这些原则构成了1998年通过的《国际劳工组织关于工作中基本原则和权利宣言》的基础。《宣言》规定，不管成员国是否批准相关公约，都应当尊重并推动以上四大基本原则与权利。</p>',
  rights_visConventionsTitle: '国际劳工组织核心公约',
  rights_infoSupervisoryBody: '<h3>国际劳工组织监督体系</h3>\n\n<p>只有推动落实国际劳工标准，才能使之行之有效。为确保国际劳工标准有效性，国际劳工组织在全球层面拥有<mark id="rights_infoSupervisoryBody">独一无二的监督体系</mark>，确保批约国落实公约。</p>',
  rights_calloutYourrights: '你所享有的劳工权利',
  rights_infoAdoptionils: '<p>通过制定国际劳工标准，国际劳工组织致力于应对并将继续应对全球经济发展过程中工人、雇主和政府面临的新挑战。</p>',
  rights_calloutManyideas: '很多当今人们认为理所当然的理念，不管是性别平等还是反对就业歧视，都是由国际劳工组织最先在国际层面引导讨论的。',
  rights_imageChildmechanicCaption: '玻利维亚拉巴斯的一位儿童修车工。© 国际劳工组织/M.克罗泽特',
  rights_imageDomesticCaption: '印度本加尔的一名家庭工人在筛稻谷。© 国际劳工组织/S.J.穆哈默德',
  rights_imageDomesticAlt: '身着彩色服装的女性手握木棍，在场地里筛稻谷。',
  rights_imageChildmechanicAlt: '一张背光照片，照片上的轿车被千斤顶抬起来，一名小男孩手握工具在车底进行修理工作。',
  rights_imageIlc1998Caption: '1998年国际劳工大会，瑞士日内瓦。© 国际劳工组织',
  rights_imageIlc1998Alt: '鱼眼镜头下的联合国大会厅坐满参加国际劳工大会的代表。',
  rights_imageGlobalmarchCaption: '1998年，全球反童工游行到达瑞士日内瓦万国宫。© 国际劳工组织',
  rights_imageAdoptfprwCaption: '1998年，第86届国际劳工大会通过《国际劳工组织关于工作中的基本原则和权利宣言》。© 国际劳工组织',
  rights_imageAdoptfprwAlt: '第八任总干事米歇尔•汉森和主席台的其他代表为宣言通过而鼓掌。',
  rights_imageC182Caption: '在码头工作的儿童。© 国际劳工组织/P.希恩',
  rights_imageChilddockAlt: '一个男孩背着一摞砖走在码头和小船之间的木桥上。',
  rights_imageC105Caption: '巴西帕拉州解救出的被奴役劳工在排队等待身份认证。© 国际劳工组织/J.雷佩尔',
  rights_imageRescuedAlt: '一只手拿着被解救人员的照片，背景是排队的人。',
  rights_imageC29Caption: '在尼泊尔砖厂工作的工人。© 丽莎•克里斯汀',
  rights_imageBrickyardAlt: '一个工人头顶四块砖块，正在往上摞另外两块。工人们被笼罩在砖块造成的橘红色烟雾中。',
  rights_imageC111Caption: '巴基斯坦信德省的女性农业工人在国际妇女节集会，呼吁自身的权利。© 国际劳工组织',
  rights_imageSindhAlt: '身着五彩服饰的妇女坐在大型帐篷中，手拿“妇女节，零歧视”的标语。',
  rights_imageC100Caption: '2001年，在巴黎参加五一劳动节工人游行的妇女，呼吁同工同酬。© 法新社/M.玛蒂娜/法新社',
  rights_imageEqualpayAlt: '参加游行的妇女，手持法语标语“现在就要男女同工同酬”。',
  rights_imageC138Caption: '一名女童正在搬运一大捆树枝。©国际劳工组织/A.卡恩',
  rights_imageC98Caption: '反对养老金改革游行中的工人和学生，法国安纳西。©国际劳工组织/M.克罗泽特',
  rights_imagePensionreformAlt: '街上行走的人群，两名身着蓝色背心的男子手持CFTC（法国基督教工人联合会）的蓝色旗帜。',
  rights_imageC87Caption: '日本的工会会议。©国际劳工组织',
  rights_imageUnionmeetAlt: '一名男子高举左臂，背后一群人坐在看台上。',
  rights_imageCasCaption: '2017年6月，106届国际劳工大会标准实施委员会，日内瓦。©国际劳工组织',
  rights_imageCasAlt: '坐满代表的会议室，屏幕上显示这是标准实施委员会的会议。',
  rights_imageEbelingCaption: '1950年，在国际劳工组织第33届一般性会议上，L.E.艾柏林介绍了美国雇主对女性同酬问题的看法。这次讨论促成了国际劳工组织通过第100号对男女工人同等价值的工作付予同等报酬公约（1951年）。©国际劳工组织',
  rights_imageEbelingAlt: '一名女性表情严肃，手拿笔记面向观众演说。',
  rights_detailSupervisory1: '<h3>国际劳工组织如何对标准实施进行监督</h3>\n\n<p>国际劳工组织通过多种途径确保国际劳工公约与建议书的实施。</p>\n\n<p>如成员国在标准实施方面遇到困难，国际劳工组织将通过技术援助等方式帮助成员国解决问题。</p>',
  rights_detailSupervisory2Title: '公约和建议书实施专家委员会',
  rights_detailSupervisory2: '<h3>公约和建议书实施专家委员会</h3>\n\n<p>一国批准国际劳工公约后，有义务定期就公约实施相关举措提交报告。政府必须与国家级雇主和工人组织代表分享报告内容，并听取社会伙伴意见。</p>',
  rights_detailSupervisory3Title: '标准实施委员会',
  rights_detailSupervisory3: '<p>公约和建议书实施专家委员会设立于1926年，旨在对国际劳工标准在各国际劳工组织成员国实施情况做出公正的技术评估。</p>\n\n<p>公约和建议书实施专家委员会现由理事会任命的20名杰出法律专家组成，每届任期三年。法律专家具有不同地域、文化和法律体系背景。</p>',
  rights_ilsShortNameC29: '强迫劳动公约',
  rights_ilsShortNameC182: '最恶劣形式童工劳动公约',
  rights_ilsShortNameC105: '废除强迫劳动公约',
  rights_ilsShortNameC111: '就业和职业歧视公约',
  rights_ilsShortNameC100: '同酬公约',
  rights_ilsShortNameC138: '准予就业最低年龄公约',
  rights_ilsShortNameC98: '组织权利和集体谈判权利',
  rights_ilsShortNameC87: '结社自由和保护组织权公约',
  rights_ilsBlurbC29: '凡批准本公约的会员国承诺在可能的最短期限内禁止使用一切形式的强迫或强制劳动，包括人口贩卖，并确保受害者得到有效补偿。',
  rights_ilsBlurbC182: '凡批准本公约的会员国应立即采取有效的措施，以保证将禁止和消除最恶劣形式的童工劳动作为一项紧迫事务，包括出售和贩卖儿童、强迫或强制招募儿童用于武装冲突、使用、招收或提供儿童卖淫、使用、招收或提供儿童从事非法活动，特别是生产和贩卖有关国际条约中界定的毒品，以及在可能对儿童健康、安全或道德有伤害性的环境中工作。',
  rights_ilsBlurbC105: '凡批准本公约的国际劳工组织成员承担制止和不利用任何方式的强迫或强制劳动：作为政治压迫或政治教育的工具或作为对持有或发表政见或意识形态上与现存政治、社会或经济制度相反的意见的惩罚；作为经济发展目的动员和使用劳工的方法；作为劳动纪律的工具；作为对参加罢工的惩罚；作为实行种族、社会、民族或宗教歧视的工具。',
  rights_ilsBlurbC111: '凡本公约生效的会员国，承诺宣布和遵循一项旨在以符合国家条件和惯例的方法促进就业和职业机会均等和待遇平等的国家政策，以消除基于种族、肤色、性别、宗教、政治见解、民族血统或社会出身等原因，具有取消或损害就业或职业机会均等或待遇平等作用的任何区别、排斥或优惠',
  rights_ilsBlurbC100: '男女工人同工同酬的原则对一切工人适用。',
  rights_ilsBlurbC138: '凡本公约对其生效的会员国，承诺执行一项国家政策，以保证有效地废除童工并将准予就业或工作的最低年龄逐步提高到符合年轻人身心最充分发展的水平。最低年龄应不低于完成义务教育的年龄。准予从事按其性质或其工作环境很可能有害年轻人健康、安全或道德的任何职业或工作类别，其最低年龄不得少于十八岁。',
  rights_ilsBlurbC98: '工人应享有充分的保护，以防止在就业方面发生任何排斥工会的歧视行为。鼓励和推动在雇主或雇主组织同工人组织之间最广泛地发展与使用集体协议的自愿谈判程序，以便通过这种方式确定就业条款和条件。',
  rights_ilsBlurbC87: '工人或雇主没有任何区别，应有权建立和仅根据有关组织的规则加入各自选择的组织，且不须事先批准。会员国承诺采取所有必要和适当措施，以确保工人和雇主可以自由地行使组织权利。',
  rights_conventionsCountries: '国家',
  rights_visConventionsSee: '了解各国通过的公约',
  rights_visConventionsUpdate: '最近更新：',
  rights_visConventionsSelect: '选择一个国家',
  rights_visConventionsCountries: '国家',
  rights_visConventionsRatified: '批准（公约）的国家',
  rights_visConventionsNotRatified: '未批准（公约）的国家',
  rights_visConventionsRatifs: '批准此公约',
  rights_date: '本章节将于2019年1月16日上线，敬请期待。',
  rights_visLatestUpdate: '最近更新',
  rights_visConventionsFundratif: '批准核心公约',
  rights_calloutManyIdeasSub: '也正是因此，才能造福今天数以百万计的女性和男性。',
  rights_calloutConclusion2: '在不断变化的环境下，国际劳工组织通过为工人和雇主争取新的权利和保护，为创造一个平等、无歧视的世界而不懈努力。',
  rights_visConventionsC182: '第182号公约',
  rights_visConventionsC29: '第29号公约',
  rights_visConventionsP29: '第29号议定书',
  rights_visConventionsC105: '第105号公约',
  rights_visConventionsC111: '第111号公约',
  rights_visConventionsC100: '第100号公约',
  rights_visConventionsC138: '第138号公约',
  rights_visConventionsC98: '第98号公约',
  rights_visConventionsC87: '第87号公约',
  rights_calloutConclusion1: '人人享有更美好的劳动世界未来是国际劳工组织的核心使命。这也要求国际劳工组织能够理解与预测改变劳动世界的因素。',
  rights_infoSuperTest: '',
  rights_detailSupervisory4: '<h3>标准实施委员会</h3>\n\n<p>专家委员会年度报告将提交国际劳工大会，并由标准实施委员会进行审议。</p>\n\n<p>标准实施委员会成员包括政府、雇主和工人代表，他们将从专家委员会报告中选取案例进行讨论。</p>',
  rights_detailSupervisory5: '<p>案例所涉国政府应在委员会会议上做出回应，并据情提供相关信息。</p>\n\n\n<p>在多数情况下，委员会采纳政府建议，制定具体措施来纠正问题，并由国际劳工组织为政府提供支持。</p>\n\n\n<p>国际劳工组织通常会提供长期技术支持，来确保劳工权利不受侵犯，以及工人得以享受基本权利和保护。</p>',
  employment_heading: '就业',
  employment_chapter: '第四章',
  employment_title: '不仅仅是普通的劳动',
  employment_infoEmppromotBody: '<p>促进就业是国际劳工组织的核心宗旨，也是体面劳动议程的重要支柱。</p>\n\n<p>自国际劳工组织成立之初，推动充分和生产性就业就是其不可分割的一部分。这是由于体面劳动——并非任何劳动——是和平、社会正义、社会包容、经济发展和个人发展的重要基石。</p>',
  employment_quoteSantosBody: '“实现社会正义意味着创造更多就业，减少贫困和不公平。体面劳动是实现社会正义的支柱，劳动相关政策以及更公平的经济发展至关重要。”',
  employment_quoteSantosCaption: '——诺贝尔和平奖获得者、前哥伦比亚总统胡安•曼努埃尔•桑托斯在2018年第107届国际劳工大会上的发言',
  employment_infoEndpovertyTitle: '贫困的终结？',
  employment_infoEndpoverty: '<h3>贫困的终结？</h3>\n\n<p>贫困是当今世界面临的诸多问题的根本原因。收入不平等、失业和物质匮乏会引发不稳定、冲突和暴力极端主义。</p>\n\n<p>反之，更高质量的就业能增加人们的收入，使社会更具有凝聚力、更加公平。更充分更高质量的就业对创造一个宜居的世界至关重要。</p>',
  employment_infoEndpovertyBody2: '反之，更高质量的就业能增加人们的收入，使社会更具有凝聚力、更加公平。更充分更高质量的就业对创造一个宜居的世界至关重要。',
  employment_calloutWhatstandsTitle: '是什么阻碍了人们获得体面劳动？',
  employment_calloutWhatstandsBody: '全球男性和女性在求职过程中面临着哪些挑战？',
  employment_detailInequalityTitle: '不平等',
  employment_detailInequalityBody1: '<h3>不平等</h3>\n\n<p>劳动力市场中的不平等会导致贫困。人们没有求职渠道，或缺乏岗位所需技能而无法就业，或者由于他们工作中的权利无法得到保障，而无法供养自己和家庭，这些都是不公正的表现。</p>\n\n<p>这些不平等会带来不公正，克服这一问题意味着人们能够充分发挥潜能来实现自给自足并脱离贫困。国际劳工组织通过工资补助项目等方式，帮助世界各国消除劳动力市场中的不平等现象，寻找可持续消除贫困的方式。</p>',
  employment_detailInequalityBody2: '<p>在俄罗斯，国际劳工组织与一家名为LUKOIL的公司建立公私伙伴关系，为当地雇主提供工资补助，帮助青年人就业。</p>\n\n<p>通过这种方式，为青年人提供了新的机会，使他们获得工作经验，同时向雇主证明青年人是公司宝贵的财富。</p>',
  employment_detailConflictTitle: '冲突与灾害',
  employment_detailConflictBody1: '<h3>冲突与灾害</h3>\n\n<p>全球约有15亿人生活在武装暴力等脆弱环境中；约2亿人受灾害影响。冲突与灾害会摧毁人们的生活，带来体面劳动赤字。它们也会引发贫困、不平等和社会动荡等问题。</p>\n\n<p>国际劳工组织支持各国为受冲突与灾害影响人群创造体面劳动机会的项目，以此来加强相关人群的复原能力，并帮助相关国家向和平与稳定过渡。</p>',
  employment_detailConflictBody2: '<p>在经历了近半个世纪武装斗争的哥伦比亚，国际劳工组织帮助当地社区发展旅游业，为青年和女性等脆弱群体创造就业机会。</p>\n\n<p>当农村家庭能够自己创造收入，冲突的根本原因——缺少工作和发展机会——就得到了解决，取而代之的是稳定和持久的和平。</p>',
  employment_detailYouthempTitle: '青年就业',
  employment_detailYouthempBody1: '<h3>青年就业</h3>\n\n<p>根据国际劳工组织统计数据，尽管过去数十年教育大幅发展，全球仍有6600万失业青年，超过1.45亿青年工人生活在贫困线以下。</p>\n\n<p>未来，尽管青年人有工作的能力和意愿，但是体面劳动的机会却非常有限。</p>',
  employment_detailYouthempBody2: '<p>投资青年就业意味着共同努力，坚持创造就业优先。同时，我们必须帮助青年克服歧视和技能与岗位要求不匹配等困难。</p>\n\n<p>国际劳工组织帮助各国设计、实施和评估就业政策和项目，确保青年人有更多机会获得体面劳动。</p>',
  employment_detailRuralexodusTitle: '农村劳动力外流',
  employment_detailRuralexBody1: '<h3>农村劳动力外流</h3>\n\n<p>到2050年，预计全球有68%的人口生活在城市，上百万人将到城市寻找工作。这会使外流农村人口面临岗位和机会减少的风险。</p>\n\n<p>加强农村女性和青年的技能开发和创业开发是一个保证他们享有体面劳动的途径。</p>\n\n<p>国际劳工组织致力于在农村和中小企业推动技能开发。</p>',
  employment_detailRuralexBody2: '<p>在肯尼亚、坦桑尼亚联合共和国和乌干达等国，国际劳工组织为超过2900名女性企业家开展过创业培训。这不仅大大提升了她们的自信，后续调查还显示每两个企业家中就有一个成功创业。</p>\n\n<p>通过国际劳工组织的支持，埃塞俄比亚有6300名女性和7个由女性主导的企业得到了信贷和企业管理方面的培训，这不仅使她们的收入有所增长，还创造了新的就业。</p>',
  employment_detailInformalTitle: '非正规工作',
  employment_detailInformalBody1: '<h3>非正规工作</h3>\n\n<p>全球非正规经济领域工人超过一半。这意味着他们从事非正式工作，不受劳动法保护。他们的权利得不到保障，无法为自己发声，也没有足够收入来避免陷入贫困。</p>\n\n<p>非正规企业生产率通常较低，无法为雇员提供体面劳动的条件。这也限制了企业自身的发展，意味着他们无法获取主流资源。</p>',
  employment_detailInformalBody2: '<p>国际劳工组织通过提供知识和技能，帮助工人和企业从非正规向正规转变。</p>\n\n\n<p>2015年尼泊尔地震后，国际劳工组织为当地导游和为重建旅游业服务的小企业提供培训，帮助企业更好为客户服务，也确保他们能创造更优质的就业岗位。</p>',
  employment_detailMigrationTitle: '劳务移民',
  employment_detailMigrationBody1: '<h3>劳务移民</h3>\n\n<p>根据国际劳工组织最新预测，劳务移民在全球2.32亿移民中占1.5亿。</p>\n\n<p>他们为接收国的经济、社会和文化发展做出了贡献。同时，他们给家庭的赡养款和在其他国家所获取的技能对本国也具有很大的价值。但他们的贡献往往被人们忽视。</p>',
  employment_detailMigrationBody2: '<p>事实上，很多移民工人面临剥削和虐待的风险。全球仍有2100万人身陷强迫劳动，其中44%在国内移民或国际移民后被困或被贩卖。</p>\n\n<p>国际劳工组织提供政策开发方面的援助，为劳务移民和其接收国争取权益最大化。</p>',
  employment_calloutDecentjobsTitle: '人人享有体面劳动',
  employment_calloutDecentjobsBody: '多年来，国际劳工组织在上百个国家开展研究以及援助项目，为推动及创造体面劳动的政策出台作出了贡献。',
  employment_infoRightskillsTitle: '必要的技能',
  employment_infoRightskills: '<h3>必要的技能</h3>\n\n<p>技术、生产性质、就业和气候变化等因素使劳动世界变化日新月异。在这一背景下，有效开展技能培训显得尤为重要。</p>\n\n<p>国际劳工组织在预测和适应未来就业能力的技能政策与体系开发方面走在前列。</p>',
  employment_infoRightskillsBody2: '更重要的是，国际劳工组织旨在通过技能开发，加强弱势群体的社会融入。',
  employment_infoInvestmentsTitle: '创造就业岗位的投资',
  employment_infoInvestments: '<h3>创造就业岗位的投资</h3>\n\n<p>全球仍有上百万人生活在缺少道路、桥梁、水源、电力或网络等关键基础设施的地区。他们中的许多人无法得到水源、健康和教育等基本服务。</p>\n\n<p>基础设施投资能极大改善生活条件，给人们生活质量带来直接影响。</p>\n\n<p>利用当地劳工和资源来建设亟需的基础设施，并让社区参与规划设计，能达到创造就业、减少开支和支持当地产业发展的效果。当地机构的能力和效率也会大大提高。</p>\n\n<p>这样一来，农村与城市贫困问题会得到改善，个人和社区也能得到更好的经济和社会发展。</p>',
  employment_infoInvestmentsBody2: '这样一来，农村与城市贫困问题会得到改善，个人和社区也能得到更好的经济和社会发展。',
  employment_infoStronginstTitle: '强有力的制度保障',
  employment_infoStronginstBody: '<h3>强有力的制度保障</h3>\n\n<p>在当今全球化、竞争更加激烈的世界，只有治理机制能够使人人享有体面收入以及社会和劳工保护，使企业有繁荣发展的空间，才能保证劳动力市场的良好运行。</p>\n\n<p>而这需要强有力的政策框架和机制保障。</p>',
  employment_calloutThisiswhy: '这也正是为什么国际劳工组织致力于通过在政府、雇主和工人代表间开展社会对话，帮助各国制定和落实相关机制和政策。',
  employment_infoNepalBody1: '<p>以尼泊尔为例，国际劳工组织劳动力市场信息和就业服务项目（LIfE） 为该国公共就业服务提供支持，在职业匹配、提高就业能力、解决技能不匹配问题和在雇主和工人间建立良好连接等方面发挥了重要作用。</p>\n\n<p>项目的一个主要优势在于参与者不仅通过获取技能和人际社交能力，还通过工作场所的社会化进程提高自身专业素质。对雇主来说，工作场所培训能够缩小技能差距，有助于提高生产力。</p>',
  employment_infoNepalBody2: '项目的一个主要优势在于参与者不仅通过获取技能和人际社交能力，还通过工作场所的社会化进程提高自身专业素质。对雇主来说，工作场所培训能够缩小技能差距，有助于提高生产力。',
  employment_calloutChallengesdwTitle: '体面劳动面临复杂的挑战，应对方式也同样复杂。',
  employment_calloutChallengesdwBody: '凭借近百年的经验，国际劳工组织将在推动劳动世界未来体面劳动这一核心领域发挥更大的领导作用。',
  employment_imageLocalyouthsCaption: '马达加斯加迭戈苏亚雷斯当地青年正在参加培训班，该培训项目由国际劳工组织支持，致力于通过帮助青年人学习商业贸易，脱离犯罪生活，从而打击城市暴力。©国际劳工组织马达加斯加局',
  employment_imageLocalyouthsAlt: '三名男子和一名妇女身着蓝色制服、头戴黄色头盔，站在木桌旁，桌上放着打开的工具箱。',
  employment_imageWastepickCaption: '菲律宾马尼拉一处垃圾场的拾荒人。奎松市3000名垃圾处理工人成立了工会，他们的废物管理模式，可以循环使用近40%的生活垃圾。©国际劳工组织/M.里曼多',
  employment_imageWastepickAlt: '一大群男性、女性和儿童在垃圾填埋场拾拣垃圾',
  employment_imageSlumsCaption: '利比里亚蒙罗维亚西点地区的贫民窟。七万五千人在这里居住，却仅享有四个公共厕所。肺结核等传染病在这里流行。©联合国利比里亚特派团/A.G.法兰',
  employment_imageSlumsAlt: '航拍海岸边屋顶生锈的低矮房屋。',
  employment_imageTaclobanCaption: '2013年11月，强台风“海燕”过境后的菲律宾塔克洛班市。大约90%的城市被摧毁。幸存者把这次灾难描述为“世界末日”。©国际劳工组织/M。克罗泽特',
  employment_imageTaclobanAlt: '一架飞机飞过一片充满瓦砾、损毁车辆和折断树木的区域。',
  employment_imageMigrantaddisCaption: '在国际劳工组织举办的招聘会上，一名归国移民与雇主商谈工作机会，埃塞俄比亚亚的斯亚贝巴。©国际劳工组织/M. 塔德赛',
  employment_imageMigrantaddisAlt: '两名男子站在桌子两端互相交谈，桌上放着电脑和宣传页。',
  employment_imageFarmersCaption: '马达加斯加的农民。马达加斯加大部分人口从事农业生产，然而当地农具缺乏，基础设施薄弱，农民只能勉强自给自足。©国际劳工组织，马达加斯加',
  employment_imageFarmersAlt: '两个女性农民在稻田里弯腰拾稻穗。',
  employment_imageHandicraftCaption: '尼泊尔加德满都的手工业者。国际劳工组织在当地帮助女性创业者，与她们分享商业案例。这不仅有利于社会保障，使她们能供养家庭，还改变了当地长期对性别和女性社会地位的固有观念。©国际劳工组织/M.克罗泽特',
  employment_imageHandicraftAlt: '一名妇女挎着黄色背包，手拿蓝绿相见的孔雀玩偶。',
  employment_imageMigrantdatesCaption: '巴林的移民工人正在收获椰枣。巴林是世界上劳务移民占当地工人比例最高的国家之一。©国际劳工组织',
  employment_imageMigrantdatesAlt: '一名男子站在树枝间，从满是果实的枝条上摘椰枣。',
  employment_imageApprenticeCaption: '国际劳工组织学徒在坦桑尼亚桑给巴尔的一家酒店就业。©国际劳工组织，坦桑尼亚',
  employment_imageApprenticeAlt: '一名头戴白纱的妇女坐在前台看电脑屏幕。',
  employment_imageConstructionCaption: '玻利维亚拉巴斯一个建筑工地的工人。根据经合组织的数据，建筑业约占全球劳动力的7%，预计到2020年将占GDP的13%。©国际劳工组织',
  employment_imageConstructionAlt: '建筑工地的三名男性建筑工人，头戴头盔和保护眼镜。',
  employment_imageTraineesCaption: '学员参加国际劳工组织LIfE尼泊尔项目工作场所学习的培训。©尼泊尔手工艺品协会联合会（FHAN）',
  employment_imageTraineesAlt: '坐在地板上的青年男女正在雕刻木雕。',
  employment_date: '2019年2月6日发现这一章',
  employment_detailConflictBody3: '<p>事实上，最终达成的和平协议特别引用了国际劳工标准，以此来确保体面劳动和农村工人权利的保护——而这两者是防止社会动荡的关键。</p>',
  employment_detailMigrationBody3: '<p>在约旦、尼泊尔、菲律宾和突尼斯等国，国际劳工组织与其他联合国机构密切合作，为劳务移民提供可靠信息，防止他们受到剥削。</p>\n\n<p>同时，国际劳工组织还通过当地和国际媒体宣传关于公平招聘的信息，提高劳务移民和潜在雇主的意识。</p>',
  protection_heading: '社会保护',
  protection_chapter: '第五章',
  protection_title: '你并不是孤立无援',
  protection_calloutWhathappensTitle: '疾病、残疾、怀孕、衰老、收入不足或因为无法工作而没有收入等情况会引发哪些问题？',
  protection_calloutWhathappensBody: '我们如何保护个人和其家庭避免此类风险，帮助他们脱离贫困？',
  protection_infoFacesofspBody: '<h3>社会保护</h3>\n\n<p>社会保护是一项人权。社会保护保证每一个人得到医疗和收入保障。在疾病、失业、受伤、怀孕或超出适合工作年龄等情况下，社会保护会为个人和家庭提供保障。</p>\n\n<p>在意外发生时，它能够为您的家人提供支持。社会保护让您安心，为更美好的未来保驾护航。</p>',
  protection_calloutEveryoneright: '人人都有权享有社会保护',
  protection_quoteEveryonerightBody: '“人人有权享受为维持他本人和家属的健康和福利所需的生活水准，包括食物、衣着、住房、医疗和必要的社会服务；在遭到失业、疾病、残障、孤寡、衰老或在其他不能控制的情况下丧失谋生能力时，有权享受保障。”',
  protection_quoteEveryonerightCaption: '《世界人权宣言》第二十五条第一款',
  protection_infoYourspBody: '<h3>你享有的社会保护</h3>\n\n<p>社会保护是旨在减少和消除贫困与弱势的一系列措施和项目。社会保护确保你能享有：</p>\n\n<ul>\n<li>儿童与家庭福利</li>\n<li>生育保护</li>\n<li>失业保险</li>\n<li>工伤保险</li>\n<li>疾病保险</li>\n<li>医疗保险</li> \n<li>养老金</li>\n<li>残疾人福利</li>\n<li>生存者抚恤金</li>\n</ul>',
  protection_calloutWhyneedsp: '我们为什么需要社会保护？',
  protection_infoEndpovertyBody: '<h3>消除贫困</h3>\n\n<p>根据国际劳工组织统计数据，当今世界，仍有40亿人缺少社会保护。全世界仅有29%的人口享有充分社会保障，55%的人口完全未被覆盖。</p>',
  protection_calloutUniversalsp: '全民社会保护覆盖是实现联合国可持续发展目标的关键。否则，我们就无法消除贫困、减少不平等，以及实现性别平等。',
  protection_infoTimeforactionBody: '<h3>覆盖全民的社会保护：行动刻不容缓</h3>\n\n<p>使社会保护覆盖全球40亿缺乏社会保护的人群，是有可能的。国际劳工组织致力于帮助各国建立与改进国家社会保护体系。</p>\n\n<p>国际劳工组织的标准和专业支持对引导各国发展国际社会保护计划至关重要。</p>',
  protection_calloutPasttenyears: '过去十余年间，国际劳工组织为136个国家的社会保护发展提供支持：',
  protection_listSpfloors1: '国家社会保护战略',
  protection_listSpfloors2: '医疗保险',
  protection_listSpfloors3: '儿童福利',
  protection_listSpfloors4: '生育保护',
  protection_listSpfloors5: '失业保险体系',
  protection_listSpfloors6: '公共就业项目',
  protection_listSpfloors7: '养老金',
  protection_vizColombiaTitle: '哥伦比亚',
  protection_vizColombiaBody: '确定了其全国社会保护战略',
  protection_vizPeruTitle: '秘鲁',
  protection_vizPeruBody: '建立了非供款养老金计划',
  protection_vizTogoTitle: '多哥',
  protection_vizTogoBody: '建立了覆盖40万劳动者的全国医疗保险体系',
  protection_vizCyprusTitle: '塞浦路斯',
  protection_vizCyprusBody: '改革了其社会援助计划',
  protection_vizKazakhstanTitle: '哈萨克斯坦',
  protection_vizKazakhstanBody: '批准了国际劳工组织第183号生育保护公约',
  protection_vizIndiaTitle: '印度',
  protection_vizIndiaBody: '国家健康保险计划（RSBY）被延长',
  protection_vizCambodiaTitle: '柬埔寨',
  protection_vizCambodiaBody: '建立了全国性社会健康保险计划',
  protection_vizThailandTitle: '泰国',
  protection_vizThailandBody: '实施了全民保健计划',
  protection_vizVietnamTitle: '越南',
  protection_vizVietnamBody: '扩大了非供款养老金的覆盖范围',
  protection_vizMozambiqueTitle: '莫桑比克',
  protection_vizMozambiqueBody: '确定了其国家社会保护战略',
  protection_vizJordanTitle: '约旦、巴林和沙特阿拉伯',
  protection_vizJordanBody: '实施了失业保险计划',
  protection_vizSouthafricaTitle: '南非',
  protection_vizSouthafricaBody: '扩大了其扩建公共建设工程计划',
  protection_infoRoadaheadBody: '<h3>通往未来之路</h3>\n\n<p>尽管各国在完善社会保护方面取得了可喜的进展，全世界仍然有数十亿人无法获得充分的社会保护。</p>\n\n<p>缺乏社会保护会加剧人的生命周期中贫困、不平等和社会排斥的风险。解决这些问题的关键就在于全民社会保护。</p>',
  protection_calloutOnlywhen: '只有当这一理想变成现实，我们才能够向实现人人享有可持续发展的目标迈出坚实的步伐。',
  protection_imageGarmentmedCaption: '约旦一家服装厂的医务中心。©国际劳工组织',
  protection_imageGarmentmedAlt: '窗子上的标志写着“医务中心”。透过窗子可以看到患者和医务人员。',
  protection_imagePregnantCaption: '一名怀孕的妇女在中国杭州的汽车零件加工厂工作。©国际劳工组织',
  protection_imagePregnantAlt: '一名怀孕女性站在走廊的桌子后，在一叠纸上盖章。',
  protection_imageNurseCaption: '一名护士在伊拉克巴格达的医务中心为一名儿童治疗。©国际劳工组织/APEX影像',
  protection_imageNurseAlt: '一名儿童躺在床上，一名女护士正抬起他的右腿，另外一名女性握住儿童的左腿。',
  protection_imageHomelessCaption: '美国旧金山的一个流浪汉正向垃圾桶内张望。©国际劳工组织',
  protection_imageHomelessAlt: '一名男子正弯腰将手伸入街边的垃圾桶，背后一辆电车驶过。',
  protection_imageVendorCaption: '厄瓜多尔昆卡的一名卖花人。©国际劳工组织',
  protection_imageVendorAlt: '一名妇女坐在她贩卖的花束前。',
  protection_imageNursesnewCaption: '位于塞拉利昂弗里敦的克里斯蒂安王子母婴医院的一名护士正在看护新生儿。©世界银行/D.查韦斯',
  protection_imageNursesnewAlt: '两名护士身着粉色制服，望着摇篮里包裹在橘色毯子中熟睡的三个新生儿。',
  protection_date: '2019年2月13日发现这一章',
  protection_countries: '国家',
  protection_vizDominicanrepTitle: '多米尼加共和国',
  protection_vizDominicanrepBody: '批准了国际劳工组织第102号社会保障公约（最低标准）',
  protection_vizMoldovaTitle: '摩尔多瓦共和国',
  protection_vizMoldovaBody: '创建了双边社会保障协定，涵盖摩尔多瓦在各国的流动工人',
  protection_vizRwandaTitle: '卢旺达',
  protection_vizRwandaBody: '实施了产假福利计划',
  protection_vizTimorlesteTitle: '东帝汶',
  protection_vizTimorlesteBody: '创建了其首个社会保障计划',
  protection_country: '',
  protection_countries1: '国家',
  work_heading: '劳动与治理',
  work_chapter: '第六章',
  work_title: '劳动的规则',
  work_infoMostofusBody: '<p>无论是办公室，市场，工厂还是船舶，许多人可能会认为他们的工作场所只是在他们劳作的地方。</p>\n\n<p>在日常工作中，可能很少有人会停下来思考那些对于确保工作安全和确保所有人的体面劳动条件至关重要的法律，条例和法规。</p>\n\n<p>劳动世界正在发生诸多变化，如新技术，短期合同和在线工作等，那些支撑劳动世界的法律，法规，政策和制度需要跟上步伐，以便继续支持和保护体面劳动。</p>',
  work_calloutRoleofilo: '国际劳工组织在这其中的作用是什么？这与您有何关联？',
  work_infoLabourstdsBody: '<p>过去一个世纪，各国政府批准的国际劳工组织的国际劳工标准已经发展成为一套完整的国际条约体系，在国家法律和政策中、公共和私人机构的劳动中得以体现。</p>\n\n<p>这些标准促进体面且具有生产力的工作的发展，确保无论你在哪里工作，都能够享有自由、平等、安全和有尊严的环境。</p>\n\n<p>国际劳工组织与政府、雇主和工人组织紧密合作，确保影响您劳动生活的法律和法规能够与时俱进且得到实施。</p>\n\n<p>国际劳工组织也帮助公共机构开展劳动监察，确保劳工法在大家的工作场所得以实施。</p>',
  work_infoKeepingyouBody: '<h3>确保工作时的安全</h3>\n\n<p>职业安全与卫生（OSH）是任何工作场所中最关键的问题之一。这一问题意味着保护并支持工人的安全、卫生和福利。</p>\n\n<p>职业安全与卫生的发展要追溯到十九世纪的工业革命，这一概念始于对在工作中受伤并丧生的男性、女性和儿童的忧虑。</p>\n\n<p>到了国际劳工组织成立的1919年，发展能够保护工人安全与健康的国际劳工标准成为了首要任务。国际劳工组织制定的前十个建议书中，有四个是为了解决职业安全与卫生问题。</p>\n\n<p>如今，国际劳工组织有40余个公约和建议书是专门针对职业安全和卫生而制定的。187号公约是最近通过的公约之一，其目标是建立一种全球预防文化，从而真正减少与工作有关的死亡、伤害和疾病。</p>',
  work_calloutLawscompliedTitle: '政府确保工作场所的劳动法得到遵守，方法之一就是有效执法。',
  work_calloutLawscompliedBody: '有效执法包括通过劳动监察员对办公室、工厂、建筑工地和其他工作场所进行监察，以确保这些工作场所的劳动条件符合劳动法律和政策的要求。',
  work_infoFrontlineBody: '<h3>在前线</h3> \n\n<p>然而，政府用于执法的预算并不能跟上工作场所数量的增长和工作领域的加速变化。劳动监察员愈加难以检查每个工作场所，并针对他们检查出的工作条件提出解决方案。</p>\n\n<p>国际劳工组织一贯支持各国政府发展劳动监察体系，帮助各国对劳动监察计划和执行进行更新，以应对足以影响工作场所每一个人的迅速变化。</p>',
  work_calloutFourareas: '国际劳工组织还在其他四个相关领域开展工作，以确保劳动法得到恰当执行，并确保公共和私营劳动机构有效运作。',
  work_listFitforpurpose1Title: '有的放矢',
  work_listFitforpurpose1Body: '使劳动管理体制发挥作用',
  work_listLawmatters2Title: '有法可依',
  work_listLawmatters2Body: '协助劳动法改革',
  work_listWorkingtog3Title: '合作共赢',
  work_listWorkingtog3Body: '三方机制、社会对话和劳资关系',
  work_listReaching4Title: '达成共识',
  work_listReaching4Body: '如何预防和解决劳动争议',
  work_detailFitforpurpose1: '<h3>使劳动管理体制发挥作用</h3>\n\n<p>从国家劳动部门到招聘机构，制定和执行国家劳动法的公共和私营机构组织应确保严密、专业和高效，这样才能对劳动力市场进行恰当管理。 </p>',
  work_detailLawmatters1: '<h3>协助劳动法改革</h3> \n\n<p>劳动法需要跟上正在改变工作场所的快速变革，需要尊重国际劳工组织标准并满足体面劳动的目标。</p>\n\n<p>为了帮助成员国评估、设计和修订其劳动法并履行国际劳工组织公约，国际劳工组织会为成员国提供技术专家和援助。</p>',
  work_detailWorkingtog1: '<h3>三方机制、社会对话和劳资关系</h3>  \n\n<p>当政府、工人和雇主在健全的劳动关系体系中共同处理问题时，将有助于改善经济绩效，并实现利润和财富更公平的分配。这一切是通过以下方式实现的：</p>\n\n<ul>\n<li> 协商、咨询、信息交流</li>\n<li> 集体谈判</li>\n<li> 预防和解决争端</li>\n<li> 其他社会对话工具，包括国际劳工标准</li>\n</ul>',
  work_detailReaching1: '<h3>如何预防和解决劳动争议</h3>  \n\n<p>在雇佣关系中，投诉和冲突是不可避免的。</p>\n\n<p>但在进入上诉阶段之前，依然有办法通过协商一致来预防和解决劳资纠纷。</p>',
  work_infoBrokenrulesBody: '<h3>支离破碎的法规</h3> \n\n<p>即使立法已经到位，实现法治依然存在着挑战。</p> \n\n<p>例如，187个成员国中的182个已经批准了《最恶劣形式的童工劳动公约》，这意味着该公约将成为首个实现全部批准的公约。尽管这些年童工数量有所下降，全球依然存在着1亿5200万童工。</p> \n\n<p>187个成员国中，有178个国家批准了《强迫劳动公约》，然而根据国际劳工组织统计数据，2500万人口依然处于强迫劳动的状态下。这一产业每年将产生1500亿美元的非法营利。</p> \n\n<p>国际劳工组织在所有的部门领域都面临着类似的挑战，为应对这些挑战，国际劳工组织为各国政府提供一系列项目和方案，支持各国政府执行劳动法，也包括在必要时更新公约。</p> \n\n<p>例如，在2014年，为了应对新形式的现代奴隶制的出现，国际劳工大会通过了《强迫劳动议定书》——1930年《强迫劳动公约》的附录——旨在加强力度，消除包括贩卖人口在内的所有类型的强迫劳动。</p>',
  work_infoTacklingBody: '<h3>解决中介问题</h3> \n\n<p>在当今充满挑战的经济中，劳动者们正努力寻找工作机会，招聘服务比以往任何时候都重要。</p>\n\n<p>如果公共和私营部门的就业机构能够得到适当的管控，它们将发挥重要的作用，将工作需求与符合资格的工人相匹配。</p>\n\n<p>目前全球预计有2.44亿国际移民和7.4亿国内移民，大多数移民是为了寻找体面的就业和更好的生计。</p>\n\n<p>然而，一些弱势群体，特别是移民工人和低技能工人，成为了那些肆无忌惮的就业机构和非正式劳动中介的牺牲品，这些机构可能诱使这些群体被贩卖，或使他们深陷于强迫劳动之中。</p>',
  work_calloutTrappedflTitle: '全球有2500万人口身陷强迫劳动，其中有44%的人是国内或国际劳务移民。',
  work_calloutTrappedflBody: '从第三方贷款的移民工人面临强迫劳动的风险不断增加。',
  work_infoInresponseBody: '<p>为了应对这些挑战，国际劳工组织与各国政府、雇主和工人组织、私营部门和其他国际组织密切合作，以便：</p>\n\n<ul>\n<li>帮助防止人口贩卖和强迫劳动。</li>\n<li>保护包括移民工人在内的工人，在招聘过程中免受虐待和欺诈行为的侵害。</li>\n<li>降低劳动力迁徙的成本，提高移民工人及其家庭、原籍国和目的地国的发展成果。</li>\n</ul>',
  work_calloutIloaimstoTitle: '国际劳工组织旨在提高关于有效招聘做法的知识，改进法律、政策和执行机制，提倡公平的商业做法，并赋予劳动者权力和保护劳动者。',
  work_calloutIloaimstoBody: '国际劳工组织将利用上述劳动世界中的治理工具，努力支持世界各地改善劳动条件，加强为所有人争取体面劳动的斗争。',
  work_imageNightshiftCaption: '马达加斯加塔马塔夫一家荔枝工厂的夜班工人。©国际劳工组织/M.福萨',
  work_imageNightshiftAlt: '工厂的工人身着白色工服，带着卫生口罩、帽子和蓝色手套，坐在长椅上整理传送带上的荔枝。',
  work_imageFishersCaption: '在国际劳工组织-澳大利亚国际开发署就业项目上，渔民们正在学习环境友好的渔业技能，菲律宾。©国际劳工组织/M.克罗泽特',
  work_imageFishersAlt: '两名男性渔夫站在木筏上，试图撒出绿色的大渔网。',
  work_imageWomenwarCaption: '1918年5月，在英国伍尔维奇兵工厂3号皇家炮弹厂工作的女工。©帝国战争博物馆',
  work_imageWomenwarAlt: '一群女工使用不同的工具修理桌上的炮弹壳。',
  work_imageLabourinspCaption: '劳动监察员在约旦安曼开展职业安全卫生检查。©国际劳工组织/G.梅杰范',
  work_imageLabourinspAlt: '一名女性劳动监察员手握遥控杆，与坐在机器前的男性工人交谈。',
  work_imageChildcarpetCaption: '巴基斯坦拉合尔的一名织毯童工。©国际劳工组织/M.克罗泽特',
  work_imageChildcarpetAlt: '一名女童坐在织毯机后，用手抓住面前的几缕线，露出了眼睛。',
  work_imageMigrantCaption: '卡塔尔的一名移民工人。©Apex影像',
  work_imageMigrantAlt: '一名身着黄衣的男子正在向地上撒干草，背景是高楼大厦。',
  work_imageSyrianCaption: '约旦的一名叙利亚难民建筑工人。©国际劳工组织/A. 奥苏克尼',
  work_imageSyrianAlt: '一名男子站在脚手架上，用黄色油漆涂刷天花板上的精美装饰。',
  work_date: '2019年2月20日发现这一章',
  work_detailFitforpurpose2: '<p>国际劳工组织就劳动管理制度的改革提供技术咨询和援助，并对负责劳动监察、就业服务、社会保护福利、企业发展、争端解决、劳动政策和法律的制定、数据收集和分析、以及社会对话等工作的官员进行培训。 </p>',
  work_detailWorkingtog2: '<p>这种社会对话对于实现和维持体面劳动至关重要。</p>\n\n<p>国际劳工组织促进政府、雇主和工人代表之间的社会对话，并帮助成员国在本国建立良好的劳资关系，以便有效地管理劳动领域。</p>\n\n<p>国际劳工组织通过研究、教育和培训项目以及信息和技能共享平台支持这项工作。</p>',
  work_detailReaching2: '<p>在调解与协调中，第三方在谈判期间提供协助，以帮助当事各方达成协议，特别是在当事各方陷入僵局的情况下。</p>\n\n<p>仲裁是指被授权的第三方（不作为法院）作出解决争端的决定。</p>\n\n<p>专门劳动审判是指普通法院或者专门劳动法院解决权利义务争议的案件。</p>',
  work_imageConsensusCaption: '金属加工领域的社会伙伴在突尼斯市的巴德∙尔卡∙德拉的劳动和调解总监察办公室协商，达成避免冲突的协议。 ©国际劳工组织',
  work_imageWorkingtoCaption: '威德∙布扎努伊，突尼斯工业、贸易和手工业联合会（UTICA）主席，突尼斯全国四方对话成员。四方对话在2015年获得诺贝尔和平奖，“因为它在2011年茉莉花革命之后为突尼斯建立多元民主做出了决定性的贡献”。©国际劳工组织',
  work_imageFitforpurposeCaption: '一个位于中国天津市的人力资源开发和就业中心。类似的的就业服务中心有助于培养劳动者的知识和技能，以提高竞争力和生产力，同时促进社会包容和体面劳动。 ©国际劳工组织',
  work_imageLawmattersCaption: '在意大利热那亚港检查“乔利∙蓝”号船。根据国际劳工组织海事劳工公约（2006年）的规定，保罗∙里昂中尉检查船舶是否符合船舶安全，海洋污染预防以及海员体面劳动和生活条件的国际标准。©国际劳工组织',
  equality_heading: '平等和非歧视',
  equality_title: '自由地做自己',
  equality_chapter: '第三章',
  equality_calloutHundreds: '数以百万计的人们在工作中遭受歧视。这不仅是对基本人权的侵犯，并有着广泛的社会和经济影响。',
  equality_infoConsequencesTitle: '歧视带来的后果',
  equality_infoConsequencesBody1: '<h3>歧视带来的后果</h3>\n\n<p>全世界各个国家、各行各业都存在歧视。这不仅扼杀了个人的机会，浪费了人类的智慧，使经济发展变慢，还加剧社会矛盾，扩大不平等差距。</p>\n\n<p>消除歧视是国际劳工组织体面劳动议程的重要部分。这一领域所取得的成功不仅惠及工作场所，还惠及家庭和学校，甚至是街头。</p>',
  equality_infoConsequencesBody2: '消除歧视是国际劳工组织体面劳动议程的重要部分。这一领域所取得的成功不仅惠及工作场所，还惠及家庭和学校，甚至是街头。',
  equality_infoDiscriminationTitle: '歧视影响着我们每一个人',
  equality_infoDiscrimination: '<h3>歧视影响着我们每一个人</h3>\n\n<p>歧视可基于多重缘由，如种族、性别、性向、是否携带HIV病毒、是否残障、年龄和政治倾向等。原住民、少数民族、宗教群体、女性和移民等通常是最容易受歧视的群体。在劳动世界中，歧视也与针对女性和男性的工作场所暴力和骚扰有关。</p>\n\n<p>性骚扰十分普遍。它加深了对女性能力和理想的偏见，会迫使女性离开职场，摧毁她们的职业生涯，甚至对她们生活各个方面造成持久的负面影响。</p>',
  equality_infoDiscriminationBody2: '性骚扰十分普遍。它加深了对女性能力和理想的偏见，会迫使女性离开职场，摧毁她们的职业生涯，甚至对她们生活各个方面造成持久的负面影响。',
  equality_infoResearchnineBody: '<p>国际劳工组织在9个国家开展的、时间跨越2013-2016年的一项研究表明，对女同性恋者、男同性恋者、双性恋者与跨性别者的偏见在职场中依然存在。由于在多个层面受到歧视，包括暴力和骚扰，他们通常不得不隐藏自己的性取向。</p>',
  equality_infoDomino: '<h3>歧视带来的多米诺骨牌效应</h3>\n\n<p>歧视，不管是隐性歧视还是显性歧视，都会影响人的精神健康。多年研究表明，如果长时间受到不平等待遇或在不良环境中工作，人出现焦虑、抑郁等与压力相关的病症的风险会大大提高。当一个人的精神健康受到影响，就可能产生多米诺骨牌效应，影响到其伴侣、孩子和同事。</p>\n\n<p>歧视会阻碍人们实现梦想，追求事业，限制他们的潜力，还会在全球范围内损失超过4000亿美元的GDP增长。</p>',
  equality_infoDominoBody1: '歧视，不管是隐性歧视还是显性歧视，都会影响人的精神健康。多年研究表明，如果长时间受到不平等待遇或在不良环境中工作，人出现焦虑、抑郁等与压力相关的病症的风险会大大提高。当一个人的精神健康受到影响，就可能产生多米诺骨牌效应，影响到其伴侣、孩子和同事。',
  equality_infoDominoBody2: '歧视会阻碍人们实现梦想，追求事业，限制他们的潜力，还会在全球范围内损失超过4000亿美元的GDP增长。',
  equality_calloutPortraitsTitle: '不平等现象',
  equality_calloutPortraitsBody: '歧视不仅在道德上是错误的，还是对人权的侵犯，对经济造成负面影响。',
  equality_infoBreakingexpectTitle: '打破性别固见',
  equality_infoBreakingexpectBody1: '<h3>打破性别固见</h3>\n\n<p>国际劳工组织与盖洛普咨询公司(Gallup)联合出版的《女性和工作更美好的未来：女性和男性发声》中首次揭示了全球男性和女性对于女性与工作的态度和想法。研究结果表明，在很多情况下，男性的观点与女性非常相似，使女性拥有与男性同等的工作机会是全球共识。</p>\n\n<p>来自于142个国家和地区的14.9万成年人参加了此调查，调查结果代表了全球超过99%成年人的观点。</p>',
  equality_infoBreakingexpectBody2: '来自于142个国家和地区的14.9万成年人参加了此调查，调查结果代表了全球超过99%成年人的观点。',
  equality_calloutDespiteprog: '尽管过去一个世纪取得了长足的进展，女性劳动参与率仍远低于男性。',
  equality_calloutAbetterlife: '人人享有更好的工作',
  equality_infoIlsdiscrimination: '<p>国际劳工组织针对反歧视出台的国际劳工标准和指南得到了世界各国政府、工人和雇主的认可。</p>\n\n<p>随着越来越多的国家出台反歧视和推动平等的法律，国际劳工组织的专家为在这一领域立法提供建议等方面发挥了至关重要的作用。</p>',
  equality_calloutConclusion1: '我们的世界有望消除歧视，人人都有责任共同实现这一目标。',
  equality_imageMigrantreturnCaption: '一名参与国际劳工组织重返社会项目的回国移民工人，在埃塞俄比亚首都亚的斯亚贝巴接受心理支持的剪影。移民工人经常受到不同形式的歧视，暴力和骚扰。 ©国际劳工组织/A.穆卢耐',
  equality_imageMigrantreturnAlt: '窗前是一名女性的头部剪影，由于是背光照片，她的脸部没有显示。',
  equality_imageDisabledgarmentCaption: '服装工厂工人阮氏成孝，越南同奈。©国际劳工组织/A.阮',
  equality_imageDisabledgarmentAlt: '一名驼背女性坐在工厂的缝纫机前，手拿一小片布。',
  equality_imageTranswomenCaption: '巴西的跨性别女性在参加厨房助理课程，该课程由阿根廷厨师保拉∙卡洛塞拉为男/女同性恋、双性恋、变性人和双性人（LGBTI）教授。国际劳工组织促进将LGBTI人员纳入正规就业市场。©国际劳工组织巴西局',
  equality_imageTranswomenAlt: '两名女厨师正在烹饪，厨房的不锈钢桌上摆满了原料。',
  equality_visGenderBody: '<h3>性别</h3>\n\n<p>性别平等会给经济发展带来积极影响。麦肯锡全球研究院报告显示，推动女性平权有可能会使全球GDP在2025年增加12万亿美元。</p>\n\n<p>推动性别平等体现在国际劳工组织工作的各个方面，植根于国际劳工组织章程和核心公约。</p>',
  equality_visLgbtBody: '<h3>女同性恋者、男同性恋者、双性恋者与跨性别者群体</h3>\n\n<p>世界银行近期在印度所做的一项研究显示，对女同性恋者、男同性恋者、双性恋者与跨性别者的歧视不仅会使这一群体受到伤害，还有可能使印度GDP每年下降1.7%。</p>\n\n<p>国际劳工组织关于性别认同和性取向的主张：国际劳工组织“推动劳动世界权利、多样性和平等”项目对全球女同性恋者、男同性恋者、双性恋者与跨性别者群体所受歧视情况做了研究，并强调促进他们更好融入工作场所的良好实践。包括阿根廷、哥斯达黎加、法国、匈牙利、印度、印度尼西亚、黑山共和国、南非和泰国在内的国家对这一项目表示支持。</p>',
  equality_visHivBody: '<h3>HIV病毒和艾滋病</h3>\n\n<p>制定有关HIV病毒的工作场所政策，普及相关教育，以及鼓励自愿咨询和检测HIV病毒，能有效减少工作场所偏见，使员工尽早知晓自身状况，以及在检测结果呈阳性时尽早治疗。通过治疗，员工健康状况能够得到改善，请假时间变少，工作效率提高。这些措施能帮助HIV病毒携带者继续工作，供养家庭。</p>\n\n<p>国际劳工组织的自愿咨询和检测倡议（VCT@WORK）已惠及超过600万劳动者，帮助他们更好地了解自身病毒感染状况，并在必要时及时就医。</p>\n\n\n<p>类似倡议能帮助减少因感染HIV病毒而无法继续工作的人数。2005年，35万人因携带HIV病毒无法继续工作。预计到2020年，这一数字会减少到4万人，其中男性减少85%，女性减少93%。</p>',
  equality_visDisabilityBody: '<h3>残障</h3>\n\n<p>全球约10亿人，即全世界人口的七分之一，有残障。其中，约8亿正处于工作年龄，但是他们中的大多数却被工作场所排除在外。这不仅阻碍了他们参与社会活动，也导致全球GDP减少3%-7%。</p> \n\n<p>国际劳工组织全球商业与残障网络涵盖多个跨国企业，总雇员数超过420万人。残障网络分享相关知识和信息，帮助企业通过多种途径招聘和留住残障员工。</p>',
  equality_visIndigenousBody: '<h3>原住民</h3>\n\n<p>原住民约占全球总人口的5%，却占全球贫困人口的15%。他们通常已被剥夺了土地，无法获得高质量的教育，他们的声音也不被政府所重视。原住民更多地参加正规工作，可以使数以百万计原住民，特别是女性原住民，脱贫</p>    \n\n<p>国际劳工组织自1920年代起就开始关注原住民和部落民族问题。国际劳工组织第169号原住民和部落民族公约（1989年），是唯一一个专门针对原住民权利保护的国际公约。</p>',
  equality_visEthnicityBody: '<h3>种族</h3>\n\n<p>如果移民后代能拥有更强的经济实力，政府能通过收入和税收获利。例如，研究表明，如果法国能消除对北非后裔的歧视，将为政府带来2%的额外收入，并使政府支出减少0.5%。</p>\n\n<p>自1919年国际劳工组织成立之初，消除种族歧视就一直是其核心工作，这也体现在第111号就业和职业歧视公约（1958年）中。1998年通过的《国际劳工组织工作中的基本原则和权利宣言》重申了所有成员国有义务尊重，促进并实现这一原则。</p>',
  equality_visMigrantBody: '<h3>移民工人</h3> \n\n<p>移民工人是最容易受到经济下行影响和最易被歧视的群体之一。然而，经济学家普遍认为，移民对工资增长和促进就业有微弱但积极的影响，因为移民会带来经济增长。</p>\n\n<p>国际劳工组织公平招聘倡议帮助为移民工人创造公平招聘的机会。同时，移民工人招聘顾问平台通过为工人提供招聘机构的用户评价，保护移民工人免受不公平就业。</p>',
  equality_visDomesticBody: '<h3>家政工人</h3>\n\n<p>根据国际劳工组织统计，全球共有6700万家政工人。他们主要负责照顾家庭，做家务、做饭等，是看护经济中的基本组成部分。但其中只有10%的家政工人享受和其他劳动者同等的劳动保护。他们的收入通常不到平均收入的一半，这也是造成劳动力底层市场性别工资差距的主要原因。许多家政工人工作条件恶劣的根本原因在于不同形式的歧视：家政工作通常不被认为是正式工作，而是女性与生俱来的责任。</p>\n\n<p>第189号家庭工人体面劳动公约（2011年）是唯一被国际认可的旨在保护和提高全球家政工人工作和生活条件的国际劳工标准。</p>',
  equality_visAgeBody: '<h3>年龄</h3>\n\n<p>老年劳动力参与率低会对经济造成负面影响。以澳大利亚为例，如果3%的年龄在55周岁或以上的劳动者工作，GDP有望增加330亿美元，或增加大约1.6%的国民收入。如果这一群体参与有偿工作的人数增加5%，GDP将增加480亿美元，或增加2.4%的国民收入。</p>\n\n<p>国际劳工组织第162号老年劳动者建议书（1980年）特别关注老年劳动者问题。建议书为各国提供使老年劳动者享有体面的和更有利的工作条件的建设性框架。</p>',
  equality_visWomen1Body: '女性观点',
  equality_visWomen2Body: '男性观点',
  equality_visWomen3Body: '<p>受调查人群中，70%的女性和 66%的男性认为女性应从事有偿工作——是认为女性应当做家庭主妇的比例的两倍多。</p>',
  equality_visWomen4Body: '<p>根据报告结果，全球女性倾向于从事有偿工作（29%）或能够兼顾工作与家庭（41%）。</p>',
  equality_visWomen5Body: '<p>仅27%的女性倾向于全职在家，29%的男性持相同观点。</p>',
  equality_visWomen6Body: '<p>70%倾向于从事有偿工作的女性中，很大一部分女性目前并未在职场中。</p>',
  equality_visWomen7Body: '<p>值得注意的是，全球各地情况皆如此，包括阿拉伯国家等女性劳动者参与率向来较低的国家和地区。</p>',
  equality_visWomen8Body: '倾向于女性从事有偿工作',
  equality_visWomen9Body: '倾向于女性兼顾工作与家庭',
  equality_visWomen10Body: '倾向于女性全职在家做家务和照顾家庭',
  equality_visWomen11Body: '不清楚或拒绝回答',
  equality_date: '2019年1月30日发现这一章',
  equality_visGapTitle: '劳动力性别差异',
  equality_visGapSelect: '选择国家',
  equality_visGapRatesLabel: '劳动力参与率',
  equality_visGapWomen: '劳动力女性占比',
  equality_visGapMen: '劳动力男性占比',
  equality_visGapGap: '差异',
  equality_visGapNote: '2019年数据/最新数据。来源：ILOSTAT（国际劳工组织数据库）',
  equality_visGapDefinition: '劳动力指劳动年龄人口的总和，包括就业人群和失业人群。劳动力参与率指劳动力人口占劳动年龄人口的比例。',
  equality_calloutPortraitsSub: '不平等的代价不仅仅是几十亿美元的损失，更会造成一些国家财富和人才的流失。如果人人都能享有平等的机会，我们的社会会更加美好。',
  equality_calloutDespiteProgSub: '当前全球女性劳动参与率约为49%，而男性劳动参与率约为75%。',
  equality_calloutConclusion2: '平等是为所有男性和女性创造体面劳动的重要途径，也是所有国际劳工组织就业项目的基石。',
  equality_infoWomenBody1: '<p>女性常常从事低质量、低薪工作，她们的价值往往会被低估。</p>\n\n<p>全球各地，女性长时间工作， 包括有偿工作和无偿工作。全球无偿家务工作中75%由女性承担。根据国际劳工组织估算，每天全球无偿家务工作时间为164亿个小时，相当于全球GDP的9%。\n</p>',
  equality_infoWomenBody2: '为了解决这一问题，国际劳工组织、联合国妇女署和经合组织成立同酬国际联盟，旨在与政府、私营部门、公民社会和学术圈共同采取具体措施，推动缩小性别薪酬差距，争取在2030年前实现同薪同酬。',
  equality_calloutPortraitsSubtitle: '了解更多劳动世界的情况。',
  equality_portrait0Detail1: '<h3>卡林，原住民领导人</h3>\n\n<p>老卡林•杜姆洛特是阿埃塔人，这是一个生活在菲律宾的原住民族群。在卡林的一生中，他看到即便是生活在祖祖辈辈留下来的土地上，他的文化和人民的需求也在被边缘化和被忽视。</p>\n\n<p>作为一名原住民领导人，他的奋斗目标是获得必需的认可，帮助人民摆脱贫困，并在那些会影响阿埃塔人日常生活的决定中获得发言权。</p>',
  equality_portrait0Detail2: '<h3>反对歧视土著人民</h3>\n\n<p>土著人民约占世界人口的5%，却占贫穷人口的15%。他们常常被剥夺土地，得不到高质量的教育，他们的声音在政府中得不到倾听。增加她们在正式就业部门的参与，可以使数百万人，特别是妇女摆脱贫困。</p>\n\n<p>劳工组织自20世纪20年代以来一直在处理土著和部落人民的问题。它负责《1989年土著和部落人民公约》(第169号)，这是唯一专门处理他们权利的国际条约。</p>',
  equality_portrait0Caption: '老卡林•杜姆洛特，阿埃塔人的原住民领导人，他与国际劳工组织一起，努力提高人们对菲律宾和世界各地原住民所面临的歧视的认识。©国际劳工组织',
  equality_portrait1Detail1: '<h3>斯雷拉，多元化与包容性专家</h3>\n\n<p>15年前，斯雷拉•达斯•古普塔的整个人生都改变了，因为她被诊断出患有自身免疫性疾病，不得不坐在轮椅上生活。残障生活改变了人们对她的看法和对她工作的期望。</p>',
  equality_portrait1Detail2: '<p>斯雷拉认为自己很幸运，因为她的雇主愿意与她一起发现，她如何才能最好地为工作作出贡献，这在当今世界的许多地方仍然很少见。</p>\n\n<p>因此，赋能他人已成为她生活的一股导向力，20多年来，她一直在残障融合、人权、女性赋能和母婴健康等领域努力支持他人。</p>',
  equality_portrait1Caption: '斯雷拉•达斯•古普塔，是一位帮助企业构建更多元、对残障人士更包容的工作场所的专家。©国际劳工组织',
  equality_portrait2Detail1: '<h3>阿贝尔，移民工人</h3>\n\n<p>像阿贝尔•托雷斯•加西亚这样的人们对支持工业化国家的粮食供应和经济发展至关重要。</p>\n\n<p>年复一年，他在烈日下辛苦劳作，收获的红薯、西红柿和蔬菜摆上了人们的餐桌，却只得到极其微薄的报酬，更谈不上被认可。尽管他从事着当地人不愿意做的工作，却几乎每天都会遭受歧视。</p>',
  equality_portrait2Detail2: '<h3>反对歧视移民工人</h3>\n\n<p>移民工人是受经济衰退影响最大的群体之一，也是最先受到歧视的群体。然而，经济学家的共识是，移民工人对工资和就业的影响虽小，但却是积极的，因为移民推动了经济增长。</p>',
  equality_portrait2Caption: '阿贝尔•托雷斯•加西亚是美国北卡罗来纳州的一名墨西哥移民农场工人，尽管他在这个为州经济贡献约840亿美元的行业里合法工作，却每天都面临着被歧视。©马克•麦克安德鲁斯',
  equality_portrait3Detail1: '<h3>布兰卡，老年工人</h3>\n\n<p>77岁的布兰卡•埃斯特•卡斯蒂略的整个人生都面对着贫困和被排斥。尽管要照顾14个孩子和11个孙子女，但年龄越大，她在找工作时受到的歧视就越大。她是数以百万计的为家庭提供主要经济来源的普通人中的一员，但随着年龄的增长，继续供养家庭变得十分艰难。</p>',
  equality_portrait3Detail2: '<h3>反对歧视老年人</h3>\n\n<p>老年人的低劳动力参与率带来了相当大的经济成本。在澳大利亚，如果55岁及55岁以上的劳动者中再增加3%的人加入劳动大军，将为GDP带来330亿美元的增长，或使国民收入增加1.6%左右。</p>',
  equality_portrait3Caption: '77 岁的布兰卡•埃斯特•卡斯蒂略是一名刚刚退休的老人。她在找工作时倍受歧视，几乎没有雇主愿意雇佣她这一年龄的女性。©国际劳工组织',
  equality_visGapWorld: '世界',
  equality_portrait4Detail1: '<h3>彩，获奖导演</h3>\n\n<p>谷村彩是一位拍摄音乐录影、电视和电影的获奖导演。凭借她对各大品牌、公司和艺术家如凯蒂•佩里等极富创意和美感的理念，她的作品在互联网上累计被浏览超过10亿次。</p>',
  equality_portrait4Detail2: '<p>然而，谷村认为女性在电影电视制作行业所面临的歧视和障碍限制了她能做的工作。在她担任导演的片场，她甚至被其他人误认为是助理。</p>\n\n<p>在2018年票房收入前100位的电影中，只有4%是由女性导演的。</p>',
  equality_portrait4Caption: '谷村彩，日裔澳大利亚电影导演，因为性别在工作场所遭遇歧视。© 劳伦•克鲁',
  equality_portrait5Detail1: '<h3>艾玛，女同性恋权利活动家</h3>\n\n<p>艾玛•查克 A. 是职场骚扰的幸存者。2014年，当她被发现是一名同性恋权利活动家时，她在工作中的职责在毫无预兆的情况下被减少了，她不得不忍受来自同事的恐同言论。</p>\n\n<p>后来，在违背她本人意愿的情况下，被强行调到另一个地方工作。</p>',
  equality_portrait5Detail2: '<p>然而，她在工作中的遭遇让她开始质疑自己，质疑自己的工作，质疑自己的价值。</p>\n\n<p>她花了几年时间才慢慢恢复过来，并再次感受到工作中的认可、赏识和重视。今天，她自豪地支持那些经历过类似情况的人，帮助他们克服困难。</p>',
  equality_portrait5Caption: '艾玛•查克 A. ，一名职场骚扰和歧视的幸存者，她被歧视是因为她为女同性恋群体争取权益而采取的行动。©艾玛•查克 A.',
  equality_portrait6Detail1: '<h3>劳蕾尔，艾滋病和人权活动家</h3>\n\n<p>1991年，年仅20岁的劳蕾尔∙斯普拉格被诊断感染艾滋病毒。她很快就病倒了，很多朋友和家人也相继离她而去。</p>\n\n<p>她几乎马上就面对了艾滋病毒感染污名化和歧视。那时，她无法阻止发生在自己身上的事情，但她可以做很多事情来帮助与她曾经处于同样境况的人。</p>\n\n<p>今天，她是消除与艾滋病相关的污名化的领导者，同时也在致力于消除艾滋病毒携带者所遭受的歧视、罪犯化和侵犯人权的行为。</p>',
  equality_portrait6Detail2: '<h3>反对歧视艾滋病毒携带者</h3>\n\n<p>工作场所的艾滋病毒政策和教育，以及艾滋病毒自愿检测和咨询的宣传，在工作场所减少了艾滋病污名化，使员工能够及早了解自己的艾滋病毒状况，并在检测呈阳性时接受治疗。</p>\n\n<p>通过治疗，劳动者的健康状况得以改善。他们的请假减少了，工作效率更高。这确保了艾滋病毒携带者能够继续工作并照顾他们的家人。</p>',
  equality_portrait6Caption: '劳蕾尔∙斯普拉格，一个致力于解决艾滋病相关污名化的领导者，一个为消除艾滋病携带者所遭受的歧视、罪犯化和侵犯人权行为的活动家©国际劳工组织',
  equality_portrait7Detail1: '<h3>扎伊纳布，家政工人</h3>\n\n<p>扎伊纳布∙埃库巴被骚扰了好几年。 “他们就把我当成一个物体，” 她说，“我的雇主会把我的衣服撩起来，或者当我睡觉时，他们会过来把毯子扯下来，然后大笑。</p>\n\n<p>”家政工人很容易受到骚扰，因为他们在其他人看不到的地方闭门工作，当扎伊纳布告诉外人她的遭遇时，很少有人相信她。</p>',
  equality_portrait7Detail2: '<h3>反对歧视家政工人</h3>\n\n<p>根据国际劳工组织统计，全球共有6700万家政工人。他们主要负责照顾家庭，做家务、煮饭等，是看护经济中的基本组成部分。但其中只有10%的家政工人享受和其他劳动者同等的劳动保护。</p>\n\n<p>他们的收入通常不到平均收入的一半，这也是造成劳动力底层市场性别工资差距的主要原因。</p>',
  equality_portrait7Caption: '扎伊纳布∙埃库巴是一名家政工人，多年来，她在某些雇主家中遭受骚扰、暴力和歧视。©扎伊纳布∙埃库巴',
  equality_portrait8Detail1: '<h3>Leroy，足球教练</h3>\n\n<p>Leroy Rosenior是上世纪八九十年代的职业足球运动员。“种族歧视是可怕的，”他说。“你知道那句话:‘棍棒和石头可以打断我的骨头，但言语永远不会伤害我。’嗯，我想我的骨头断了一半，但身体上的疼痛很快就过去了。然而，这些话一直伴随着我。”</p>\n\n<p>近年来，勒罗伊一直在与“出示种族主义红牌”合作，向人们宣传种族主义在足球和整个社会中的危险。2019年3月，他将获得英国君主颁发的一项享有盛誉的MBE奖项。他希望MBE将突出本组织的工作，并促进解决足球和所有工作场所种族歧视的持续需要。</p>',
  equality_portrait8Detail2: '<h3>反对种族歧视</h3>\n\n<p>如果那些遭受种族歧视的男女有更多的经济权力，政府将从收入和公司税的形式的额外收入中获益。</p>\n\n<p>例如，研究表明，在法国结束对北非后裔的歧视将导致额外收入增加2.0%，政府支出减少0.5%。</p>',
  equality_portrait8Caption: 'Leroy Rosenior，上世纪八九十年代的职业足球运动员，在球场内外都遭受着种族歧视。©向种族主义出示红牌',
  equality_portrait1Detail3: '<h3>反对歧视残障人士</h3>\n\n<p>大约10亿人，亦即全球总人口的七分之一有某种程度的残障。其中约有8亿人处于工作年龄，但许多人被排除在工作场所之外。</p>',
  equality_portrait1Detail4: '<p>这不仅阻碍了他们充分参与社会，而且导致了全球GDP的3%到7%的损失。</p>\n\n<p>国际劳工组织全球商业与残障网络由雇员总数超过420万的跨国企业组成，它分享知识，并为企业和雇主雇佣和留用残障人士提供工具和方法。.</p>',
  equality_portrait2Detail3: '<p>劳工组织公平招聘倡议有助于为移民工人创造公平的招聘选择。</p>\n\n<p>“移民工人招聘顾问平台”通过用户对招聘机构的评论，帮助移民工人免受滥用就业的侵害。</p>',
  equality_portrait3Detail3: '<p>这一群体的有偿就业每增加5%，GDP就会增加480亿美元，占国民收入的2.4%。</p>\n\n<p>国际劳工组织在第162号老年工人建议书(1980年)中特别提到老年工人的情况。这为各国提供了一个可供遵循的建设性框架，如何为老年工人提供体面和富有成效的工作条件。</p>',
  equality_portrait4Detail3: '<h3>反对性别歧视</h3>\n\n<p>性别平等促进经济，持续推动经济增长。例如，麦肯锡全球研究所的一份报告发现，到2025年，通过促进女性平等，全球GDP将增加12万亿美元。</p>\n\n<p>推动性别平等反映在国际劳工组织工作的方方面面，并庄严载入其《章程》和各项基本公约。</p>',
  equality_portrait5Detail3: '<h3>反对歧视LGBTI人群</h3>\n\n<p>世界上每一个国家都将从更好地捍卫女同性恋、男同性恋、双性恋或变性人（LGBTI）的权利中获益。</p>\n\n<p>例如，世界银行最近对印度的一项研究估计，除了LGBTI群体因歧视而遭受的痛苦外，该国因恐同症而遭受的经济损失可能高达每年GDP的1.7%。</p>',
  equality_portrait5Detail4: '<p>国际劳工组织的“性别认同与性取向：促进工作世界的权利、多样性和平等”项目研究了世界各地对LGBT员工的歧视，并强调了促进他们融入职场的良好做法。</p>\n\n<p>支持的国家包括阿根廷、哥斯达黎加、法国、匈牙利、印度、印度尼西亚、黑山、南非和泰国。</p>',
  equality_portrait6Detail3: '<p>国际劳工组织的自愿咨询和检测倡议(VCT@WORK)已惠及600多万工人，帮助他们了解自己的艾滋病毒状况，并在必要时寻求治疗。</p>\n\n<p>这些举措有助于减少因艾滋病毒阳性而无法工作的人数。2005年，35万人因为携带艾滋病毒而无法工作。到2020年，这一数字预计将下降到4万人，其中男性下降85%，女性下降93%。</p>',
  equality_portrait7Detail3: '<p>许多家政工人工作条件恶劣的根本原因在于不同形式的歧视：家政工作通常不被认为是正式工作，而是女性与生俱来的责任。</p>\n\n<p>第189号家庭工人体面劳动公约（2011年）是唯一被国际认可的旨在保护和提高全球家政工人工作和生活条件的国际劳工标准。</p>',
  equality_portrait8Detail3: '<p>消除基于种族的歧视自1919年以来一直是劳工组织工作的核心，并载入1958年《歧视(就业和职业)公约》(第111号)。</p>\n\n<p>1998年通过的《劳工组织工作基本原则和权利宣言》重申了所有会员国尊重、促进和实现这一原则的义务。</p>',
  enterprises_heading: '可持续发展企业',
  enterprises_chapter: '第八章',
  enterprises_title: '促进人类与环境发展的企业',
  enterprises_date: '2019年3月6日发现这一章',
  enterprises_infoAroundtheworld: '<p>全球范围内，对于不只受利益驱动的企业的需求日益增加。企业和消费者都希望能够获得对社区、环境和社会有益的产品与服务。</p>\n\n<p>可持续企业包括小型、中型、大型和多国企业、合作社、“绿色”企业和社会企业，这些企业会平衡自身与员工以及社会的需求和利益。</p> \n\n<p>推动类似企业的发展，是国际劳工组织体面劳动议程的核心工作，包括创造就业、工作中的权利、社会保护和社会对话。同时，这也与联合国的全球目标，即可持续发展目标紧密相连，体面就业也是经济政策制定与发展的核心。这种发展不会对环境带来伤害，在当下和未来会惠及每一个个体。</p>',
  enterprises_infoTherightenviro: '<h3>为适当的企业创造适当的环境</h3>\n\n<p>可持续企业并不是存在于真空中。推动创新和经济增长，从而实现更多、更好地就业，这需要在区域、国家和地方层面创造适当的环境。国际劳工组织参与了这一进程的每一步。</p>\n\n<p>从个人到企业再到国家机构，国际劳工组织协助利益相关方为所有类型、所有规模的企业创造适当的环境，通过可持续企业营商环境项目实现可持续发展目标。</p>',
  enterprises_infoInclusive: '<h3>包容、负责、可持续的工作环境</h3>\n\n<p>国际劳工组织工作的核心是三方机制，这一机制聚集了政府、雇主和工人代表，以达成共识。创建有利于人类和环境发展的企业，关键在于三方机制。</p>\n\n<p>国际劳工组织《多国企业和社会政策的三方原则宣言》，即MNE宣言，是唯一一个为企业在社会政策以及包容、负责、可持续的工作环境实践方面提供具体指导的全球性法律文书。</p>\n\n<p>宣言由世界各国的政府、雇主和工人组织共同制定并通过，旨在鼓励多国企业为经济和社会进步做出更多积极贡献，实现人人享有体面劳动。宣言也尝试解决以及最小化企业在这一进程中可能遇到的问题。</p>\n\n<p><mark id="enterprises_infoInclusive">国际劳工组织也在该宣言的指导下，对政府、企业和企业家提供支持。</mark></p>',
  enterprises_detailInclusive1: '<h3>体面劳动和奥运会</h3>\n\n<p>国际劳工组织与2020年东京奥运会组委会、残奥组委会建立起特别伙伴关系，根据宣言的指导，在为2020年奥运会做出贡献的企业中，推广对社会负责的劳工实践。</p>',
  enterprises_infoEntrepreneurship: '<h3>追寻成功的创业工具</h3>\n\n<p>国际劳工组织协助个人和组织获得、改善并保持建设可持续企业所需的技能和知识。</p>\n\n<p>了解其中两个项目：</p>\n\n<ul>\n<li>创办和改善你的企业（SIYB）</li>\n<li>了解企业（KAB）</li>\n</ul>',
  enterprises_visStartyourbusHeading: '创办你的企业',
  enterprises_visKnowyourbusHeading: '了解企业',
  enterprises_visKnowyourbusBody: '关于企业，你想了解什么？',
  enterprises_visStartyourbusBody: '全球最大的企业管理培训项目之一',
  enterprises_visStartyourbusDetail1: '<h3>全球最大的企业管理培训项目之一</h3>\n\n<p>创办和改善你的企业培训项目（SIYB）是目前全球最大型的创业培训项目之一，其网络遍及100余个国家，共有65000余名培训师，参与培训的企业家超过1500万人。</p>',
  enterprises_visKnowyourbusDetail1: '<h3>关于商业，你想了解什么？</h3>\n\n<p>了解企业项目（KAB）鼓励青年人创业，帮助15至24岁的青年人更好地了解企业领域。项目提升了青年人的意识，使其了解创业和自雇形式就业的机会与风险。</p>',
  enterprises_infoHelpingbus: '<h3>协助企业取得成功</h3>\n\n<p>国际劳工组织提供培训和建议，旨在协助现有的企业能够在企业层面应用负责、可持续和包容的工作实践。</p>\n\n<p>国际劳工组织的项目涵盖方方面面，从咨询到完善产品和工作条件，再到为希望创办自己的企业的女性提供培训和支持服务。这些项目包括：</p>\n\n<ul>\n<li>My. COOP项目</li>\n<li>企业可持续发展项目</li>\n<li>女性创业能力发展项目</li>\n</ul>',
  enterprises_visAgriCoopHeading: '农业合作社',
  enterprises_visAgriCoopDetail1: '<h3>My.COOP项目</h3>\n\n<p>My. COOP：在农业合作社，农民将资源聚集到特定活动区域，以降低成本，从规模经济中受益。My. COOP是国际劳工组织为农业合作社现任和未来管理人员定制的培训项目。</p>',
  enterprises_visResponsibleHeading: '负责任的企业',
  enterprises_visResponsibleDetail1: '<h3>企业可持续发展项目 （SCORE）</h3>\n\n<p>针对全球和本地供应链新兴市场的中小企业，这一培训及实地咨询有助于提高生产力和工作条件。SCORE项目囊括了工作场所合作、质量管理和职业安全卫生等问题。</p>',
  enterprises_visWomenentrepHeading: '女性创业精神',
  enterprises_visWomenentrepDetail1: '<h3>女性创业能力发展（ILO-WED）项目</h3>\n<p>女性创业能力发展项目为女性提供培训和支持服务，通过性别视角服务于女性赋权。该项目通过与政府、雇主和工人组织以及服务提供方共同合作，完善服务，将服务向更多的女性拓展，实现其目标。</p>',
  enterprises_infoAddresslegal: '<h3>解决法律挑战</h3>\n\n<p>即使是最遵纪守法的公司，也可能苦于如何使其运营符合国际劳工标准，并建立良好的劳资关系。 </p>\n\n<p>这时就体现出国际劳工组织企业国际劳工标准服务台的作用。对于公司管理者和劳动者来说，这一秘密法律工具，提供了大量劳工议题相关的信息和建议，所有这些议题都源自多国企业宣言。</p>\n\n<p>如今，企业在打击童工和强迫劳动、解决工作场所的歧视和不平等、改善职业安全卫生等方面获得支持。 </p>',
  enterprises_infoRethinking: '<h3>重新思考企业运营方式</h3>\n\n<p>如今，包括失业、排外、不平等、经济震荡、贫困和全球变暖在内的各类挑战迫使我们重新思考企业运营的方式。</p>\n\n<p>结果之一就是社会企业的出现——这类企业具有明确的经济和社会目标，经常与环境相关，并成为不断发展的社会团结经济的一部分。</p>\n\n<p>与传统的营利性企业不同，它们通常由基层协会和合作社、公平贸易协会和非政府组织组成。许多企业为自愿的性质，并且受诸如妇女权利等特定事业的驱动，这使它们在世界各地的公共政策制定中发挥越来越大的影响力。</p>',
  enterprises_calloutIntheeu: '在欧盟，有10%的企业和6%的就业属于社会团结经济，大多数为合作社。',
  enterprises_visWhyrcoops: '为什么合作社如此重要？',
  enterprises_visWhyrcoopsDetail1: '<h3>为了体面劳动而合作</h3>\n\n<p>合作社是社会团结经济的主要支柱。它们在可持续企业中是独一无二的，因为它们由合作社成员共同拥有和民主控制。 </p>\n\n<p>合作社存在于所有经济部门，在世界范围内提供了至少2亿7900万个工作岗位。</p>',
  enterprises_visWhyrcoopsDetail2: '<p>正如国际劳工组织2002 年《促进合作社建议书》(第 193 号)所认可，由成员拥有、民主控制的合作社有助于创造优质就业、调动资源和产生投资，包括为弱势群体创造的机会。 </p>\n\n<p>联合国预计，由于合作社提供的食品、社会保护、能源、卫生和金融服务，30亿人的生计更加安全。</p>\n\n<p>如今，合作社占全球GDP的3%到3.5%，分布在56个国家的2000家最大的合作社的总营业额超过2.5万亿美元。</p>',
  enterprises_calloutCooperatives: '合作社通过生产性就业、社会保护和尊重权利，促进国际劳工组织创造人人享有体面劳动的总体目标。合作社也提供了一个平台，人们可以表达关切，组织并参与影响其生活的决定。',
  enterprises_calloutTheilosupports: '国际劳工组织通过宣传、伙伴关系、培训与政策建议，支持社会团结经济的发展。',
  enterprises_calloutPositiveimpact: '国际劳工组织着眼劳动世界的未来时，可持续企业的积极影响将成为一个核心考虑因素。',
  enterprises_calloutAfuture: '一个我们期冀在此生活的未来。',
  enterprises_imageNuragroCaption: '哥伦比亚努拉格罗协会的工人正在接受国际劳工组织培训。©国际劳工组织',
  enterprises_imageNuragroAlt: '三名工人在植物农场搬运植物。',
  enterprises_imageLakumbaCaption: '哥伦比亚昆巴协会的工人正接受国际劳工组织培训。©国际劳工组织',
  enterprises_imageLakumbaAlt: '一名女性在温室里手持一篮草莓。',
  enterprises_imageTechnicianCaption: '埃及开罗一家多国洗涤剂工厂生产线上的技师。©国际劳工组织/M.克罗泽特',
  enterprises_imageTechnicianAlt: '摆满机器的工厂里，一名身着蓝色制服的男子带着保护面罩和眼镜。',
  enterprises_imageSiybCaption: '“创办和改善你的企业”项目培训，布隆迪。©国际劳工组织',
  enterprises_imageSiybAlt: '一名女性培训师手持一张图片，图片上是一名水果商贩和一名顾客。',
  enterprises_imageKabCaption: '在叙利亚举办的“了解企业”项目培训的参与者。©国际劳工组织',
  enterprises_imageKabAlt: '三名男性和两名女性被培训者坐在桌旁，讨论文件夹中的内容。',
  enterprises_imageKhouryCaption: '时装设计师劳拉•库里在其位于黎巴嫩贝鲁特的工作室。©国际劳工组织/J.赛德',
  enterprises_imageKhouryAlt: '一名女性坐在桌边在一张纸上绘画，身边围绕着纺织品和杂志。',
  enterprises_imageMycoopCaption: 'My.Coop培训，蒙古。©国际劳工组织',
  enterprises_imageMycoopAlt: '四名男性和一名女性被培训者围坐在桌旁，在一大张纸上书写将要进行发言的内容。',
  enterprises_imageScoreCaption: '参加国际劳工组织企业可持续发展项目的橙子包装工厂的工人，秘鲁。©国际劳工组织',
  enterprises_imageScoreAlt: '四名穿着白色防护背心和帽子的工人在分拣传送带上的橙子。',
  enterprises_imageWedCaption: '约瑟法，坦桑尼亚女性创业能力发展及经济赋权项目的参与者。©国际劳工组织/D.德布吕',
  enterprises_imageWedAlt: '一名妇女在对桌子另一边的人说话。',
  enterprises_imageChildhuachipaCaption: '秘鲁瓦奇帕地区砖厂的童工。©国际劳工组织/E.贾诺蒂',
  enterprises_imageChildhuachipaAlt: '一名身着脏衣服的儿童，双手沾满了泥。',
  enterprises_imageWomencoopCaption: '坦桑尼亚达累斯萨拉姆一家合作社的女性成员。©国际劳工组织/M.克罗泽特',
  enterprises_imageWomencoopAlt: '两名女性在大型养鸡合作社，手里抓着白色的母鸡。',
  enterprises_imageFarmerseedsCaption: '塞内加尔考拉克的农民正在合作社购买种子。©世界银行/D.范莱格罗-帕迪拉',
  enterprises_imageFarmerseedsAlt: '两名男子站在成堆的种子袋前，手里拿着纸张和计算器聊天。',
  enterprises_imagePalestinianCaption: '巴勒斯坦籍培训师艾曼•阿布•麦迪恩在国际劳工组织资助建立的位于加沙地带北部纳赛尔贝都因村的“儿童天地”中心。 ©S.贾达拉',
  enterprises_imagePalestinianAlt: '一名女性站在手工木质玩具和玩偶的展示台前。',
  enterprises_visStartyourbusDetail2: '<p>SIYB共有四套培训课程，对应企业发展的各个阶段：从产生商业想法到创办企业，到完善与扩大企业。培训项目覆盖了从商业计划开发到库存管理、市场营销、成本核算、档案记录和人力资源管理等方方面面。</p>',
  enterprises_visKnowyourbusDetail2: '<p>过去十年，KAB项目已经为14000余名老师和超过300万的青年人提供培训，培训内容包括沟通、谈判和规划等核心创业技能。</p>\n\n<p>KAB相关材料已被用于公立和私立中等教育、职业和技术培训机构，甚至高等教育中。世界上56个国家引入了KAB项目，并被18个国家纳入国家课程体系。</p>',
  enterprises_visAgriCoopDetail2: '<p>My.COOP 旨在解决合作社管理者面临的众多挑战。其理念在于，更好的管理会带来更高的质量和更高效、更有效的合作服务。它为会员满意度、商业机会和社会考量提供指导。 </p>',
  enterprises_visWomenentrepDetail2: '<p>过去15年间，该项目已向超过150万名女性提供管理培训，配备超过75家服务提供商，以更好地回应女性的需求。项目已展开25次国别评估，协助国家伙伴出台行动计划，改善女性创业发展的环境。</p>',
  enterprises_visResponsibleDetail2: '<p>自实施起，SCORE项目已经向全球超过1400家中小规模企业提供培训。也就是说，超过30万名工人已经从企业的改善中受益，这些改善包括培育工作场所尊重、信任和沟通的文化。</p>\n\n<p>除此之外，参加SCORE培训的企业生产率最高提升了50%，实现了88%的满意率。</p>',
  enterprises_imageTokyoCaption: '盖∙莱德，国际劳工组织总干事与武藤敏郎，2020年东京奥运会和残奥会组委会主席',
  enterprises_detailInclusive2: '<p>国际劳工组织在巴基斯坦与政府、雇主和工人组织建立起伙伴关系，旨在开展可持续企业实践，从为全球品牌提供产品的锡亚尔科特足球产业中消除童工劳动。</p>',
  enterprises_visWhyrcoopsDetail3: '<p>全球金融危机之后，合作银行是经济复苏的关键动力，欧洲的合作银行服务了超过2.15亿客户，平均市场份额约为20%。</p> \n\n<p>自第193号建议书通过以来，有近100个国家审查、修订或通过了有关合作社的政策或法律。这也改变了合作社仅仅是一种可行的商业形式的看法。</p>',
  conditions_heading: '工作条件',
  conditions_chapter: '第七章',
  conditions_title: '你今天的工作怎么样？',
  conditions_date: '2019年2月27日发现这一章',
  conditions_visTimeTitle: '世界各地的工作时间？',
  conditions_visTimeSelect: '选择一个国家',
  conditions_visTimeSharesLabel: '雇员比例',
  conditions_visTimeColour1: '每周工作35个小时以下',
  conditions_visTimeColour2: '每周工作35至48小时',
  conditions_visTimeColour3: '每周工作超过48个小时',
  conditions_visTimeNote: '2018年以来或最近期的数据。来源：ILOSTAT',
  conditions_infoWhenwego: '<p>每当出门工作时，我们都期望能获得足够的报酬，过上好的生活，有能力购买食物、支付生活开销并应对包括疾病和新生命诞生在内的各类事件。我们期望有充足的休假时间，用来放松身心、与家人欢聚，更希望能够有余钱享受生命的小喜悦。</p> \n\n<p>到达工作场所后，我们希望能够安全地工作，不会在工作场所遭受伤害或疾病，没有死亡的危险，导致入不敷出、堕入贫困。</p>\n\n<p>总而言之，我们都希望有体面的工作条件。</p>',
  conditions_calloutTheseshouldnot: '以上不应该仅仅是期望，而是权利。',
  conditions_calloutManymillions: '数以百万计的人的确享有了这些权利，这要归功于国际劳工组织成员国历年来通过的各类公约，包括职业安全卫生、工作时间、最低工资和生育保护等等，但依然有数以百万计的人享受不到这些权利。',
  conditions_infoSafetyinworkplace: '<h3>工作场所的安全</h3> \n\n<p>根据国际劳工组织统计数据，每年有278万人死于工作场所中的安全卫生风险，相当于每分钟就有5个人面临死亡。</p>\n\n<p>每年，有3亿7400万工人遭受非致命性职业伤害。</p>\n\n<p>工作事故除了产生痛苦之外，劳动者及其家庭、雇主及整个经济还要付出巨大的经济代价。据计算，致命的和非致命的工作相关的伤害、病痛和疾病花费占据全球经济的4%左右。 </p>',
  conditions_infoIloflagship: '<p>全球职业安全与卫生预防行动（OSH-GAP）是国际劳工组织的旗舰项目之一，该项目协助成员国开发并改善本国工作场所安全与卫生所需的能力。</p>\n\n<p>我们在140多个国家开展工作：</p>\n\n<ul>\n<li><mark id="conditions_infoIloflagship">鼓励公共和私人投资，预防与工作有关的死亡、受伤和疾病。</mark></li>\n<li>培训劳动监察员，与雇主和工人分享最佳做法，改善工作场所的安全和卫生状况。</li>\n<li>制定有效法律，建立系统以收集安全卫生数据。</li>\n<li>将安全与卫生纳入青年工人的教育和技能培训中。</li> \n</ul>',
  conditions_infoHowfaryourmoney: '<h3>你的钱能用多久？</h3> \n\n<p>数以百万计的人依然很难实现收支平衡。最低工资可以防止他们陷入贫困，也有助于克服贫困并减少男女之间的不平等。最低工资补充并加强了集体谈判等用于确定就业条件和工作条件的政策。</p>\n\n<p>如今，超过90%的国际劳工组织成员国都拥有某种形式的最低工资。国际劳工组织与各国政府合作，确保最低工资水平能够考虑到劳动者及其家庭以及经济整体的需要。</p>\n\n<p>这些是国际劳工组织工作的核心领域，这些工作也体现在包括第131号确定最低工资公约（1970年）在内的劳工公约中。</p>',
  conditions_detailVisionzero1: '<h3>零伤亡愿景基金</h3> \n\n<p>2013年，位于孟加拉拉纳广场的一家服装厂的1137名工人因事故丧生。这场灾害之后，七国集团领导人一致同意建立一个新的国际基金，并将其命名为“零伤亡愿景基金”，为改善全球供应链中的职业安全卫生提供支持。</p>',
  conditions_infoLimitinghours: '<h3>限制超时工作时间</h3> \n \n<p>对超时工作的关切影响了1919年初通过的第一个国际劳工组织公约，该公约对劳动者每天和每周的工作时长进行了限制。</p>\n\n<p>如今，国际劳工组织关于工作时间的标准处于雇佣关系的核心。</p> \n\n<p>这些标准涵盖了工作小时数、休息时间甚至年假等问题。</p>\n\n<p>此外，国际劳工标准有助于实现工作生活的平衡，这些劳工标准对于激励企业维持健康和有生产力的劳动力发挥着重要的作用。</p>',
  conditions_vis1994: '1994年',
  conditions_infoJobparents: '<h3>父母的工作保障</h3>\n\n<p>当劳动者有了孩子，常常无法保障工作的稳定。</p> \n\n<p>国际劳工组织承认生育保护是一项基本劳动权利，对促进母亲及其子女的健康与福利至关重要，它还有助于在工作中实现两性平等，促进男性和女性的体面劳动。</p>\n\n<p>几乎每个国家都通过了工作场所生育保护的法律。世界各地关于支持劳动者承担家庭责任（包括作为父亲）的措施也不断出台。</p>',
  conditions_vis38Percent: '<p class="bubble-percent">38%</p>',
  conditions_visCountriesmaternity: '实行至少14周产假的国家',
  conditions_vis2013: '2013年',
  conditions_vis51Percent: '<p class="bubble-percent">51%</p>',
  conditions_calloutHoweverimprove: '然而，如果要提升所有劳动者的工作条件，就需要更多的国家批准国际劳工组织关于生育保护的公约。',
  conditions_vis34: '34',
  conditions_visRatified183: '至2019年1月1日，批准国际劳工组织生育保护公约的国家',
  conditions_visSeeratifications: '查看批约情况',
  conditions_infoNewways: '<h3>新的工作方式</h3> \n\n<p>如今，由于技术创新和传统劳动结构的变化，出现了新的工作方式。</p>\n\n<p>这些工作包括兼职、临时、代理和所谓的“零工”工作，也就是短期工作项目，而不是传统的员工—雇主关系的工作。这些工作形式被称为“非标准”工作，相关的工作条件问题随之出现。</p>',
  conditions_carouselIsyourjob: '你在从事非标准工作吗？',
  conditions_carouselYesBody1: '是的，如果你的工作只持续一定的时长',
  conditions_carouselYesCaption1: '这类工作被称为临时就业，非标准工作的一种。包括定期工、基于项目或任务的合同工、季节性工作和临时工作，包括散工。',
  conditions_carouselYesBody2: '是的，如果你的工作时间少于全职工作时间',
  conditions_carouselYesCaption2: '这类工作被称为兼职就业，非标准工作的一种。它使一些劳动者能够将把工作和抚养子女、学习或培训结合起来。然而，工作时间有时可能非常短，且不可预测，如零工时合同，雇主没有义务限制最低工作时间。',
  conditions_carouselYesBody3: '是的，如果你由一家公司支付工资，却为另一家公司工作',
  conditions_carouselYesCaption3: '这类工作被称为多方雇佣关系，非标准工作的一种。劳动者由一家公司（临时介绍所或分包商）支付工资，但在其营业地为另一家公司工作。劳动者与其提供服务的公司之间通常没有雇佣关系。',
  conditions_carouselYesBody4: '是的，如果你作为劳务派遣工被雇佣，但却像雇员一样受到上级领导',
  conditions_carouselYesCaption4: '这类工作被称为隐蔽性雇佣，非标准工作的一种。劳务派遣工有时会受到上级的监督，就像他们是正式雇员一样，这就掩盖了劳动关系的真实性质，从而绕过了劳动法规的监管。',
  conditions_carouselYesBody5: '是的，如果你在法律上是独立的，但在经济上是非独立的',
  conditions_carouselYesCaption5: '这类工作被称为依赖性自雇就业，是一个灰色地带。指劳动者根据商业合同为企业提供服务，但收入在很大程度上依赖于少数客户。',
  conditions_calloutNonstdemp: '非标准就业普遍存在于工业化国家和发展中国家的大多数经济部门和职业中。',
  conditions_callout150Countries: '在150余个国家中，11%的私营部门劳动者是临时雇员。虽然女性占雇员总数的比例不到40%，但57%的兼职雇员是女性。',
  conditions_infoNonstdjobs: '<p>通过非标准工作，可能更容易进入劳动力市场，并实现工作与生活更好的平衡。然而，从事非标准工作的劳动者可能被排除在社会保护和社会保障之外，并且较少获得培训和职业发展机会。</p>\n\n<p>他们也较少参与集体谈判，并且面临更高的被歧视风险。</p>\n\n<p>对许多劳动者来说，固定期限合同、项目或季节性工作、临时工作和待命工作都是不具有保障性的，尤其对女性产生严重影响。</p>\n\n<p>然而，无论你从事的是标准工作还是非标准工作，所有的工作都应该是体面的。</p>',
  conditions_infoSourcing: '<h3>全球采购、全球销售</h3> \n \n<p>全球化使商业和贸易变得前所未有的复杂。如今，大多数企业或者选择在全球采购，或者选择在全球销售，或者选择与以上两类企业竞争。</p>\n\n<p>这意味着企业必须与供应商、制造商、仓库、配送中心、批发商和零售商打交道。</p> \n\n<p>这就是全球供应链，过去50年劳动世界最重要的新发展之一。</p>  \n\n<p>全球供应链产生的关键问题之一，是供应链条上的工作条件问题。</p>',
  conditions_infoInmanycountries: '<p>在许多国家，全球供应链创造了就业和社会经济发展的机会。</p>\n\n<p>如果雇主能够尊重劳动法规和国际劳工标准，将促进体面劳动，提高生产力和经济发展，并使劳动者能够获得对技能要求更高、工资和条件更好的工作。</p>',
  conditions_calloutButpoor: '但是，在一些全球供应链中，恶劣的工作条件和就业关系可能产生灾难性的后果。',
  conditions_infoCollapseranaplaza: '<p>2013年拉纳广场大楼倒塌和2012年巴基斯坦和孟加拉国的工厂火灾，导致1500多人丧生。这促使人们再次呼吁采取全球行动，在全球供应链上实现体面的工作条件。</p>\n\n<p><mark id="conditions_infoCollapseranaplaza">国际劳工组织正处在这一行动的最前线。</mark></p>\n\n<p>在拉纳广场灾难之后，80多万孟加拉国工人接受了基本安全和卫生措施的培训，伤员得到了妥善支持，大约300名幸存者接受了生计技能培训，3000多人接受了职业咨询，66人接受了心理咨询。</p>',
  conditions_detailBetterwork1: '<h3>更好的工作（Better Work）</h3> \n\n<p>“更好的工作”是国际劳工组织与世界银行集团成员国际金融公司（IFC）之间开展的合作项目，其目的是改善服装行业的工作条件，提升对劳动者劳动权利的尊重，同时提高服装企业的竞争力。更好的工作项目通过聚集服装行业的所有利益相关者，实现其目的。</p>',
  conditions_infoChallengesarise: '<p>当公司做出影响工作条件、却不对从事这些工作的劳动者负责的决定时，挑战随之产生。</p>\n\n<p>分包商可能会不遵守劳动法规，在某些情况下就会导致非正规就业、恶劣的工作条件，甚至强迫劳动和使用童工。</p>\n\n<p>由于跨境采购商品和服务给执法带来了困难，并非所有政府都能够应对全球经济和全球供应链带来的快速转型。</p>',
  conditions_calloutSolution: '解决方式：促进国际劳工标准的实施，更好地执行现有法律，加强社会对话。',
  conditions_calloutThroughitswork: '通过在这些领域的工作，国际劳工组织正工作在最前线，帮助在全球供应链中实现体面劳动。',
  conditions_infoTheilohelps: '<p>国际劳工组织帮助成员国改善工作场所的安全和卫生、实施最低工资，并制定法律以改善工作条件。</p>\n\n<p>通过培训、知识分享以及与公共和私营部门伙伴合作，国际劳工组织已经证明，改善全球供应链中数百万人的生活并非不可能。</p>',
  conditions_calloutPromotingsafe: '在过去一百年间，推动安全和有利生产的工作条件一直是国际劳工组织的工作核心。',
  conditions_calloutFromthefactories: '从工业革命的工厂，到零工经济和全球供应链带来的挑战，确保体面的工作条件将持续成为国际劳工组织的优先事项，也是实现未来可持续企业的必经之路。',
  conditions_captionVietnam: '没有适当的安全装备的一名年轻建筑工人，越南河内。 ©国际劳工组织/ M∙福萨',
  conditions_imageConstructionAlt: '穿着凉鞋的劳动者站在高高的脚手架上的腿。',
  conditions_imageFactoryCaption: '乌拉圭蒙得维的亚奶酪工厂的一名员工。©国际劳工组织/M.福萨',
  conditions_imageFactoryAlt: '一名男子身着白色卫生制服、口罩、手套和围裙，手拿龙头在清洗地板。',
  conditions_imageMadagascarCaption: '马达加斯加塔那那利佛一家服装厂的游行工人，要求更高的薪水和更好的工作条件。 ©国际劳工组织/ M∙福萨',
  conditions_imageMadagascarAlt: '挤满女工的工厂走廊，其中一人手持纸板，上面写着“给我们加薪”。',
  conditions_imagePlatformCaption: '一位女性在家中带着孩子共同工作。©iStock',
  conditions_imagePlatformAlt: '一名带着眼镜的女性坐在厨房桌旁操作电脑，身旁的宝宝椅上坐着一个婴儿。',
  conditions_imageCateringCaption: '在餐饮服务业工作的一名女性，美国。 ©J∙梅达',
  conditions_imageCateringAlt: '一名穿着职业的女性手持摆满了小食的托盘。',
  conditions_captionGarmentHaiti: '海地的服装厂工人。©国际劳工组织',
  conditions_imageHaitiAlt: '服装厂俯视图，工厂里摆满了机器和五颜六色的纺织品。',
  conditions_captionGarmentBang: '孟加拉国的服装厂工人。 ©国际劳工组织',
  conditions_imageBangladeshAlt: '头戴绿色头巾和卫生口罩的妇女坐在长桌旁操作缝纫机。',
  conditions_captionMyanmarSafety: '缅甸仰光，一名赤脚工作的建筑工人。©国际劳工组织/M.福萨',
  conditions_imageMyanmarAlt: '在脚手架上工作的建筑工人，横梁上挂着“安全第一”的横幅。',
  conditions_imageVisionCaption: '缅甸的零愿景基金旨在提升职业安全与卫生水平。©国际劳工组织',
  conditions_imageVisionAlt: '一名女子头戴传统太阳帽坐在地上，身旁是一摞姜。',
  conditions_imageBetterworkCaption: '越南工人，在一个参加了“更好的工作”项目的服装厂工作。 由于参加更好的工作项目，工厂获得了更大的订单，得以接触到更多的购买者，主要购买者的采购价格也随着时间提高。 ©国际劳工组织',
  conditions_imageBetterworkAlt: '一名女子正微笑着操作桌上的缝纫机。',
  conditions_detailBetterwork2: '<p>通过不断参与更好的工作项目，工厂的工作条件、生产力和盈利能力得到了稳步改善。</p>\n\n<p>目前，更好的工作项目已经在7个国家的1450家工厂开展，雇佣了190多万工人。除了为工厂提供咨询外，该项目还与政府合作，改善劳动法，并与国际品牌合作，以确保持续取得成果。</p>\n\n<p>该项目的愿景是让全球服装业通过提供体面劳动，赋能女性，提高企业竞争力和促进包容性经济增长，使数百万人摆脱贫困。</p>',
  conditions_detailVisionzero2: '<p>国际劳工组织在零伤亡愿景基金的实施中起到了领导作用，负责基金的管理，并利用其广泛的专家资源和实践经验，为政府、雇主和工人开发知识、战略和工具，从而改善全球供应链中的职业安全卫生。</p> \n\n<p>在缅甸，国际劳工组织为姜农提供了渠道，使其更好地了解农药的安全使用方法，并协助提供市场化的解决方式，提升工作条件。</p>',
  future_heading: '劳动世界的未来',
  future_chapter: '第九章',
  future_title: '面向未来',
  future_date: '2019年3月29日发现这一章',
  future_infoLookingtowards: '<p>劳动世界正在经历深刻的转型。全球化和技术变革创造了通往繁荣的新路径，但同时也在扰乱目前的劳动状况。</p>',
  future_visIceberg1: '<p>数字和技术进步——包括信息和通信技术——为工人和企业创造了新机会。</p>\n\n<p style="font-weight: 600;">但这仅是冰山一角。</p>',
  future_visIceberg2: '<p>气候变化、人口结构转型、移民和劳动形式的变化会影响整个社会、组织、工人和企业。</p>\n\n<p>某些工作岗位的需求也会发生变化，有些工作会消失，还有很多其他的工作将不再和往常一样。</p>',
  future_visIceberg3: '<p>这些变革的方向及其对劳动世界的影响取决于我们的政策和行动，尤其是我们正试图塑造我们期望的劳动世界的未来。</p>',
  future_visQuestions1: '<span class="emphasis">未来的工作</span>从何而来？会是什么样子？',
  future_visQuestions2: '未来为青年人准备了什么？他们需要什么技能？',
  future_visQuestions3: '如何确保所有的工作安排都是体面的？',
  future_visQuestions4: '如何确保技术能够提升人的能力，而不是弱化人类？',
  future_calloutIloidentified: '国际劳工组织识别了一些能在未来提供工作岗位的重点领域。',
  future_calloutExplorethem: '浏览以下领域，探索新机会：',
  future_visGreenjobsHeading: '绿色就业',
  future_visGreenjobsDetail1: '<h3>绿色就业</h3>\n\n<p>绿色就业是过去20年间出现的最新劳动形式之一，旨在服务、保护和恢复环境。</p>\n\n<p>在不同的领域都可以看到绿色就业岗位，包括制造业、建筑业、时尚业、再生能源、电子和废品管理领域等等。</p>',
  future_visGreenjobsDetail2: '<p>绿色就业能够协助：</p>\n<ul>\n<li>提升能源效率</li>\n<li>限制温室气体排放</li>\n<li>将垃圾和污染最小化</li>\n<li>保护、恢复生态系统</li>\n<li>支持适应气候变化</li>\n</ul>\n\n<p>国际劳工组织预计，在接下来15年，向绿色经济的转变将会产生最多6000万个工作岗位。</p>',
  future_visCareeconomyHeading: '照护经济',
  future_visCareeconomyDetail1: '<h3>照护经济</h3>\n\n<p>照护工作包括保健服务、儿童护理、幼儿早期教育、残障人和长期护理、老年护理等等。</p>\n\n<p>随着世界人口结构的变化，以及许多发达国家的人口老龄化，照护经济在创造未来就业机会方面拥有一定的潜力。</p>',
  future_visGigeconomyHeading: '零工经济',
  future_visGigeconomyDetail1: '<h3>零工经济</h3> \n\n<p>技术创新为远程和在线工作开辟了新的机会。“零工经济”工作为工人提供赚取收入的机会，并为企业提供组织工作的新方式。工人在网上可以与多个雇主签订多个短期工作或合同，而不是传统、持久的单一雇主-员工关系。</p>',
  future_visGigeconomyDetail2: '<p>那些通常被排除在劳动力市场之外的工人——例如，有残障、承担照顾责任或身患疾病的工人——将获得更多的工作机会。同时，国际劳工组织的研究显示，许多在平台经济工作的工人工资要低于最低工资。</p>\n\n<p>关于这些工人所面临的不安全感以及他们的工作条件存在很多担忧。国际劳工组织正在牵头对这些新工作形式带来的许多问题进行研究：</p>\n\n<ul>\n<li>“众包工人”和“按需工人”主要通过竞争完成雇主的临时任务，他们的就业状况如何？</li>\n</ul>',
  future_visRuraleconomyHeading: '农村经济',
  future_visRuraleconomyDetail1: '<h3>农村经济</h3>\n\n<p>农村发展从最初就列入国际劳工组织的议程。自1919年以来，国际劳工组织通过了30多项与农业和农村发展直接相关的国际劳工标准，包括工作权利、就业、社会保护和社会对话等。</p>\n\n<p>农村经济具有创造体面和生产性就业的巨大潜力，有助于可持续发展和经济增长。</p>',
  future_visRuraleconomyDetail2: '<p>此外，由于世界上将近80%的穷人都生活在农村地区，任何改善农村人口生计的变革都将对在2030年——实现联合国可持续发展目标的时间——完成减贫产生巨大影响。</p>\n\n<p>农业依然将是许多农村地区最大的部门。农村人口也可以从旅游和娱乐经济中受益。</p>',
  future_visGlobalsupplyHeading: '全球供应链',
  future_visGlobalsupplyDetail1: '<h3>全球供应链</h3>\n\n<p>商品和服务的生产与分配变得日益复杂。过去，企业把生产集中在特定的国家或地区。如今，生产则遍布跨国的全球网络，目的是将利润最大化，并且减少浪费。</p>\n\n<p>过去的三十年间，全球供应链改变了世界经济，成为了就业增长和创造的引擎，在发展中国家更是如此。</p>',
  future_visGlobalsupplyDetail2: '<p>全球供应链中的就业岗位与其所在的国家一样多种多样，使得更多的人群能够实现就业。</p>\n\n<p>虽然这些工作中的大部分都可以被机器所取代，特别是在轻型制造业领域。但国际劳工组织的研究表明，就目前而言，由于机器成本相对较高、劳动力成本较低、新技术转化的能力有限，在发展中国家使用机器可能没有实际的经济意义。但是在未来呢？</p>',
  future_visRobotageHeading: '机器人时代',
  future_visRobotageDetail1: '<h3>机器人时代</h3>\n\n<p>技术的专业化和复杂化正在加速劳动自动化，也增加了和对工业机器人的需求。在一些工作场所，自动化程度从10%到60%不等，那么就不可避免地会产生一些就业岗位的流失。</p>\n\n<p>然而，面临这种黯淡的前景，我们也看到了希望，那就是关注就业转型与工人技能的重新定向。</p>',
  future_visRobotageDetail2: '<p>机器人系统需要联网、维护和升级，这就会创造新的就业机会，尤其是面向那些有科学、技术、工程和数学背景的人群。</p>\n\n<p>此外，软件开发人员和与编码相关的工作未来将成为蓝领工作。如今，发达经济体的制造业领域，每1000名员工配备14名机器人，所有这些机器人都需要定期维修，而这一数字将持续上升。</p>',
  future_calloutBrighterfuture: '更美好的未来',
  future_infoSkillsweneed: '<h3>我们需要的技能</h3>\n\n<p>劳动世界的发展需要新的技能，并需要强调终身学习。例如，自动化和机器人将增加问题解决和创新过程中对技术技能的需求。</p>\n\n<p>同时，对于“软技能”的需求也越来越大，例如有效沟通、团队合作、寻找创造性解决方案、良好社交以及适应环境变化的能力。这些技能都将会获得额外回报。产生的结果：</p>\n<ul>\n<li>教育和培训体系需要让劳动力拥有未来所需的技能和能力。</li>\n<li>需要采取政策措施，加强培训机构和企业之间的联系。</li>\n<li>我们应确保培训质量，还应满足对更灵活、更短期的培训方案的需求，应该取得以上二者间的平衡。</li>\n</ul>',
  future_infoOpportunity: '<h3>缩小性别差距的机会</h3>\n\n<p>劳动世界的发展为我们提供了机会，消除性别平等的结构性障碍，解决工作场所和社会中的不平等。</p>\n\n<p>在世界范围内，妇女提供了绝大部分的无报酬照护和家务劳动，这常常妨碍她们的劳动参与。</p>\n\n<p>因此，照护工作的正规化是确保更多妇女能够参与劳动的大好机会。国际劳工组织正在80多个国家开展工作，以实现这一目标。</p>\n\n<p>除了这些工作之外，国际劳工组织的系列项目正在帮助妇女获得优质教育和技能培训，以提高她们体面就业的机会。</p>',
  future_visWomen: '在劳动力市场之外，因为她们正在承担照护工作',
  future_visMen: '在劳动力市场之外，因为他们正在承担照护工作',
  future_quoteKofiBody: '“性别平等不只是一个目标，而是应对减贫、促进可持续发展、实现善治等挑战的前提。”',
  future_quoteKofiCaption: '科菲•安南，联合国前任秘书长，诺贝尔和平奖获得者',
  future_infoNewforms: '<h3>新型商业</h3>\n\n<p>企业是经济发展的重要引擎，是繁荣的发动机、是就业的创造者。然而，人们越来越担心，对于投资者和债权人回报的关注，是以牺牲环境和工人福利为代价的。</p>\n\n\n<p>其他商业形式的出现表明，通过帮助数百万人摆脱贫困，企业可以在实现联合国可持续发展目标方面发挥关键作用。</p>',
  future_infoThesebusinesses: '<p>这些企业包括工人合作社、互利社团和社会企业，它们很好地满足了公民团体和地方社区的需要。根据国际劳工组织的研究：</p>\n\n<ul>\n<li>这些企业不太可能在本地或国土之外进行生产活动，这样就能够创造并且保留就业。</li>\n<li>这些企业常常奋斗在前线，重建当地社区、拯救面临破产风险的企业。</li>\n<li>这些企业能够有效地为微型企业或独立自雇就业者汇集资源。</li>\n</ul>\n\n<p>国际劳工组织《合作社促进建议书（第193号）》为合作社的发展提供了指导方针。</p>',
  future_infoModernizing: '<h3>社会保护体系现代化</h3>\n\n<p>由于不平等程度的日益加剧，以及劳动在人们生活中的角色不断变化，对社会保护系统的需求可能有所增加。</p>\n\n<p>失业将导致对失业保险的更大需求。工人所拥有的技能与未来经济需要的技能之间的不匹配，将给公共就业计划带来压力。预期寿命的增加将给养老金计划带来更大的压力。</p>\n\n<p>为应对这些挑战，最近在发展中国家和发达国家出现了某些创新，这些创新表明，社会保护系统可以适应并正逐渐实现全民覆盖。</p>\n\n<p>例如，一些国家已经引进了相关机制，确保对自雇就业者和非正式工人的社会保护，保护对象也包括数字平台的工人。</p>\n\n<p>数字通信和自动化程序也正用来确保行政程序更加有效，实现成本效益。</p>',
  future_infoHumancentred: '<h3>以人为本的议程</h3>\n\n<p>为规划未来一百年的工作，国际劳工组织成立了由各国元首、著名学者和主要工人和雇主组织代表组成的全球未来工作委员会。</p>\n\n<p>全球未来工作委员会针对未来的工作进行了深入调查，为21世纪社会正义的实现提供了分析基础。它呼吁以人为本的议程，通过将人们和其所做的工作置于经济和社会政策以及商业实践的核心中，加强社会契约。</p>\n\n<p>该议程由三大行动支柱组成，这三大行动支柱将共同推动当前和未来世世代代的增长、公平和可持续性：</p>\n\n<ul>\n<li>增加对人们能力的投资</li>\n\n<li>增加对工作机构的投资</li>\n\n<li>增加对体面工作和可持续工作的投资</li>\n</ul>',
  future_infoFuturewewant: '<h3>我们所期望的未来</h3>\n\n<p>在过去的一个世纪里，自国际劳工组织成立以来，国际劳工标准一直是体面劳动和社会正义的基础。</p>\n\n<p>社会对话和三方机制为人类历史上最强劲的经济增长奠定了基础，而在未来，也将比以往任何时候都更加重要。</p>\n\n<p>我们所有人都有责任确保，无论今后发生怎样的变革，我们仍然致力于共同努力，找到应对未来挑战的解决办法。</p>',
  future_calloutParadox: '当今世界的一个悖论是，即使在失业率最高的地方，仍然需要大量的劳动。',
  future_calloutSickelderly: '病人和老年人需要护理工作者，儿童和青少年需要教师和技能培训。建筑需要翻新、城市需要绿化、环境需要保护、气候变化需要应对。',
  future_infoFulfilling: '<h3>实现体面劳动的四大支柱</h3>\n\n<p>换言之，对劳动和工人的需求仍然存在，在劳动中，人与人之间的互动最为重要，人们可以自由支配创造力，实现各自的愿望。</p>\n\n<p>因此，体面工作的四大支柱——创造就业、社会保护、劳动权利和社会对话——都与100年前一样重要，它们掌握着通往我们都将引以为豪的未来的钥匙。</p>',
  future_infoNext100Years: '<h3>下一个百年</h3>\n\n<p>一个世纪以前，有谁能预测到我们今天生活的世界？在这个世界上，数百万人摆脱了贫困，只要按一下按钮，就能得到无限的信息。在这个世界上，数以亿计的人听说过人权，“平等”这个词汇对世界各地无数的男性和女性来说，都有新的含义。</p>\n\n<p>然而，如果我们仍然想要实现最初的梦想，去建立一个以社会正义为基础，拥有普遍而持久和平的世界，还有很多工作要做。</p>',
  future_calloutHistory: '历史已证明，只有我们一起努力，才能克服面临的挑战。当所有人都把共同利益置于狭隘的利益之上，我们就没有什么不能实现。',
  future_calloutInthisspirit: '正是本着这种希望的精神，国际劳工组织期待着它的下一个百年。我们邀请您与我们一道，塑造一个拥有社会正义、人人享有体面劳动的世界。',
  future_imageIndustrobotsCaption: '西班牙阿夫雷拉地区海斯坦普汽车组件公司的工业机器人。©法新社/P.巴雷纳',
  future_imageIndustrobotsAlt: '白色工业机器人将一件产品围绕在中间，随着产品的旋转，机器人可以在不同的部分进行操作。',
  future_imageAerialCaption: '缅甸仰光机场着陆时的鸟瞰图©国际劳工组织/M.福萨',
  future_imageAerialAlt: '空中俯瞰田野、道路、河流穿插交织',
  future_imageChildrenCaption: '越南富寿省的一所幼儿园里孩子们在玩耍©国际劳工组织',
  future_imageChildrenAlt: '小女孩和小男孩在玩彩色塑料积木',
  future_imageMarineCaption: '海洋研究人员在绘制塞舌尔珊瑚礁图©联合国妇女署/瑞安•布朗',
  future_imageMarineAlt: '一名潜水的女性正在水下记笔记。',
  future_imageNurseCaption: '黎巴嫩贝鲁特霍华德•卡拉格豪锡安健康中心，护士范妮•博亚吉安在为一名儿童注射脊髓灰质炎疫苗。©世界银行/多米尼克•查韦斯',
  future_imageNurseAlt: '在检查室里，一名护士一手捧着一名儿童的脸，另一手拿着药瓶。',
  future_imageManworkshomeCaption: '一位男士在家工作。在线数字劳动力平台的出现引发过去十年来世界上最大的变革之一。©在线存储（iStock）',
  future_imageManworkshomeAlt: '一名男性坐在厨房的桌旁，桌上是电脑和一摞文件。',
  future_imageTwofarmersCaption: '越南两位农民在干农活。农村经济中，促进体面工作是消除贫困的关键©国际劳工组织/M.福萨',
  future_imageTwofarmersAlt: '两个头戴越南斗笠的农民手握农具在农田劳作。',
  future_imageGarmentCaption: '约旦的服装厂工人——这里是“改善工作计划”涵盖的国家之一，该计划旨在改善工作条件，提高服装企业的竞争力©国际劳工组织',
  future_imageGarmentAlt: '成排的工人正在操作缝纫机，每个桌子的中间都堆着红色布料。',
  future_imageRobotsiranCaption: '2008年，在德黑兰西部的伊朗霍德罗汽车厂里，机器人在车上工作。法新社/贝鲁兹•梅里',
  future_imageRobotsiranAlt: '橙色的自动机器人参与汽车银色金属框架生产流程，火花四溅。',
  future_imageChildrencodeCaption: '马达加斯加安塔那那利佛7至13岁的儿童学习如何编码。法新社/里扎罗洛',
  future_imageChildrencodeAlt: '在一辆改装的公共汽车上，一群男孩看着笔记本电脑屏幕，屏幕中一位老师在讲课。',
  future_imageSchoolgirlsCaption: '印尼西爪哇岛的学龄儿童。©国际劳工组织/A.米斯拉',
  future_imageSchoolgirlsAlt: '四个女童坐成一排，正在读书。',
  future_imageDomesticCaption: '菲律宾当地工人。当地工人是全球非正规就业劳动力的重要组成部分，是最脆弱的工人群体之一©国际劳工组织/J•阿利林（J.Aliling）',
  future_imageDomesticAlt: '一名女性坐在桌边，逗弄对面坐在宝宝椅里面的婴儿。',
  future_imageKofiCaption: '1998年，联合国前任秘书长科菲•安南参加在亚的斯亚贝巴举办的非洲妇女和经济发展大会。©国际劳工组织',
  future_imageEveldoCaption: '埃瓦尔多来自哥斯达黎加土著布里布里人社区，他是塔拉曼卡小生产者协会的成员，该协会是哥斯达黎加最大规模的合作社之一。©法新社/H.雷塔马尔',
  future_imageEveldoAlt: '植物农场里的一名男子正在侍弄桌上瓶子中的植物。',
  future_imageWomencoopCaption: '坦桑尼亚妇女合作社成员。在许多国家，妇女参加合作社的人数远远高于她们全部劳动参与人数©国际劳工组织/M•克罗泽特（M. Crozet）',
  future_imageWomencoopAlt: '三名女性围坐桌旁，在笔记本上记录。其他的女性围坐在她们身边。',
  future_imageFlowersellerCaption: '厄瓜多尔花卉市场的一名卖花人。非正规经济中多数人缺乏社会保护和工作权利©国际劳工组织/ P•维罗特（P. Virot）',
  future_imageFlowersellerAlt: '一名女性身着蓝色制服、头戴白色安全帽，正在操作黄色推土机。',
  future_imageOfficialCaption: '2019年1月，向国际劳工组织成员和外交界代表正式提交全球委员会关于工作前景的报告©国际劳工组织',
  future_imageOfficialAlt: '2019年1月，向国际劳工组织成员和外交界代表正式提交全球委员会关于工作前景的报告©国际劳工组织',
  future_imageSyrianCaption: '约旦的叙利亚难民工人。国际劳工组织应对难民危机的部分工作就是帮助工人获得农业就业机会©国际劳工组织/ L•阿布•沙伊雷（L.Abu Sha\'ireh）',
  future_imageSyrianAlt: '一名女性在高高的作物中间，手中的泡沫箱装满了刚收获的豆子。',
  future_visOfWomen: '的女性',
  future_visOfMen: '的男性',
  future_visCareeconomyDetail2: '<p>根据国际劳工组织统计数据，若到2030年，教育、卫生和社会工作方面的投资能够翻番，估计将创造约2.69亿个新工作岗位。</p>',
  future_visGigeconomyDetail3: '<ul>\n<li>如何保障平台经济工人的基本权利？</li>\n<li>为了保障这些权利，都需要哪些支持？</li>\n<li>平台经济工人如何通过集体代表他们的利益，从而很好使他们能够进行工资和劳动条件方面的谈判？</li>\n<li>如何规范最低就业条件（如最低工资）？</li>\n<li>如何使平台经济工作者获得充分的社会保护？</li>\n<li>全球众包工作数量的增加如何影响体面劳动的保障工作？</li>\n</ul>',
  future_visRuraleconomyDetail3: '<p>此外，农村人口结构的变化（年轻人移居城市工作和接受教育，而老年人和家庭移居农村地区）将对公共服务的供给产生影响。</p>\n\n<p>农村人口的老龄化速度将比城市人口更快，导致大城市以外地区对照护工作的需求增加。照护领域在创造未来就业方面有很大的潜力。</p>\n\n<p>2014年，农村经济中的体面劳动被确定为优先事项，并将随着国际劳工组织进入新的百年而持续成为重点领域工作。</p>',
  future_visGlobalsupplyDetail3: '<p>机器的成本随着时间的流逝而降低，这使得这种生产模式的迁移成为可能。这或许将导致全球供应链的重新配置，因为发展中区域的很多就业机会都流失给了发达区域的机器。这种失业以及剩余劳动力的竞争可能会给工资和工作条件带来下行压力。</p>\n\n<p>因此，国际劳工组织认为不断需要：</p>\n<ul>\n<li>针对这些变革的劳动力市场政策</li>\n<li>实现多样化经济发展的经济政策</li>\n<li>确保良好劳动条件的劳动监察</li>\n</ul>',
  future_visGlobalsupplyDetail4: '<p>2017年，国际劳工大会通过了一份关于全球供应链中体面劳动的报告，该报告促成了一项行动计划，以确保各国遵守体面劳动的标准。</p>' };
/* eslint-disable react/no-did-mount-set-state, global-require, import/no-dynamic-require, no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { IntlProvider, addLocaleData } from 'react-intl';
import { localeData } from './locales';

addLocaleData(localeData);

export default ComposedComponent => {
  class withIntl extends Component {
    static propTypes = {
      pageContext: PropTypes.shape({
        pageContext: PropTypes.shape({})
      })
    };

    static childContextTypes = {
      language: PropTypes.object
    };

    static defaultProps = {
      pageContext: {
        pageContext: {}
      }
    };

    constructor(props) {
      super();
      const { pageContext: pageContext = {} } = props;
      const { locale = 'en', languages, originalPath } = pageContext;

      this.state = {
        language: {
          locale,
          languages,
          originalPath
        },
        messages: {}
      };
    }

    getChildContext() {
      const { language } = this.state;
      return {
        language
      };
    }

    componentDidMount() {
      this.checkForNewMessages();
    }

    componentWillUnmount() {
      this.signal.cancel('Api is being canceled');
    }

    getMessages = locale => {
      const { messages } = this.state;
      if (isEmpty(messages)) {
        return require(`./locales/${locale}.js`);
      }
      return messages;
    };

    signal = axios.CancelToken.source();

    checkForNewMessages = async () => {
      const root = process.env.API_URL;
      const { locale } = this.state.language || 'en';
      try {
        const request = await axios.get(`${root}/api/content/${locale}`, {
          cancelToken: this.signal.token
        });
        const content = request.data;
        const messages = content.themes.reduce(
          (msgs, themes) => ({
            ...msgs,
            ...themes.entries
          }),
          {}
        );
        this.setState({ messages });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error('Error: ', error.message);
        }
        console.error(error);
      }
    };

    render() {
      const { language } = this.state;
      const locale = language.locale || 'en';
      const messages = this.getMessages(locale);
      return (
        <IntlProvider locale={locale} messages={messages}>
          <ComposedComponent {...this.props} />
        </IntlProvider>
      );
    }
  }
  return withIntl;
};

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Helmet from 'react-helmet';
import favicon from 'assets/images/ilo-fav.ico';
import CloudinaryImage from 'components/Image';

const Head = ({
  title,
  description,
  heading,
  shareImg,
  intl: { formatMessage, messages, locale }
}) => (
  <Helmet>
    {/* Basic meta data */}
    <html lang={locale} />
    <title>
      {formatMessage({
        id: heading,
        defaultMessage: messages.interface_ilo100
      })}{' '}
      |{' '}
      {formatMessage({
        id: title,
        defaultMessage: messages.interface_title
      })}
    </title>
    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    <meta
      name="description"
      content={formatMessage({
        id: description,
        defaultMessage: messages.home_description
      })}
    />

    {/* Facebook Open Graph */}
    <meta property="og:url" content={`https://www.ilo.org/100/${locale}`} />
    <meta
      property="og:title"
      content={formatMessage({
        id: title,
        defaultMessage: messages.interface_title
      })}
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:description"
      content={formatMessage({
        id: description,
        defaultMessage: messages.home_description
      })}
    />
    <meta
      property="og:image"
      content={CloudinaryImage.getImageUrlById(`${shareImg}-${locale}`)}
    />
    {/* Twitter Cards */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@ilo" />
    <meta
      name="twitter:title"
      content={formatMessage({
        id: title,
        defaultMessage: messages.home_title
      })}
    />
    <meta
      name="twitter:description"
      content={formatMessage({
        id: description,
        defaultMessage: messages.home_description
      })}
    />
    <meta
      name="twitter:image"
      content={CloudinaryImage.getImageUrlById(`${shareImg}-${locale}`)}
    />
  </Helmet>
);

const { shape, func, string } = PropTypes;
Head.propTypes = {
  intl: shape({
    formatMessage: func,
    messages: shape({}),
    intl: string
  }),
  title: string,
  description: string,
  heading: string,
  shareImg: string
};

Head.defaultProps = {
  intl: {
    formatMessage: () => {},
    messages: {},
    intl: 'en'
  },
  title: 'titlenotfound',
  description: 'descriptionnotfound',
  heading: 'headingnotfound',
  shareImg: 'home/homepage-share'
};

export default injectIntl(Head);

import React from 'react';
import PropTypes from 'prop-types';

/**
 * A helper component for using Images on cloudinary.
 * Provide an id with location of the image on Cloudinary.
 * Eg. folder/image-name.jpg. Includes default transofmrs for:
 *
 * Quality: 70%
 * Width: quto
 * Format: auto
 * Quality: auto
 * DPR: auto
 *
 * Additional paramaters can be added as props. Prop names should follow
 * the API transformation reference found here:
 * https://cloudinary.com/documentation/image_transformation_reference
 */

const cloudinaryname = process.env.GATSBY_CLOUDINARY_CLOUD_NAME;
const cloudinaryFolder =
  process.env.GATSBY_CLOUDINARY_FOLDER === '/'
    ? 'ilo-staging'
    : process.env.GATSBY_CLOUDINARY_FOLDER;

const allParams = (baseParams, additionalParams = {}) => {
  const keys = Object.keys(additionalParams);
  if (keys.length > 0) {
    return `${baseParams},`.concat(
      keys.map(key => `${key}_${additionalParams[key]}`).join()
    );
  }
  return baseParams;
};

const getBaseUrl = () =>
  `https://res.cloudinary.com/${cloudinaryname}/image/upload`;

class CloudinaryImage extends React.PureComponent {
  static getImageUrlById = (
    id,
    width = 'auto',
    quality = 80,
    format = 'auto',
    ...rest
  ) => {
    const baseParams = `w_${width},f_${format},q_${quality},dpr_auto`;
    return `${getBaseUrl()}/${allParams(
      baseParams,
      ...rest
    )}/v1/${cloudinaryFolder}/${id}`;
  };

  render() {
    const {
      id,
      width,
      quality,
      format,
      alt,
      onLoad,
      style,
      className,
      ...rest
    } = this.props;
    const src = CloudinaryImage.getImageUrlById(
      id,
      width,
      quality,
      format,
      rest
    );

    return (
      <img
        src={src}
        key={id}
        alt={alt}
        onLoad={onLoad}
        style={style}
        className={className}
      />
    );
  }
}

CloudinaryImage.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.string,
  onLoad: PropTypes.func,
  quality: PropTypes.number,
  format: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string
};

CloudinaryImage.defaultProps = {
  width: 'auto',
  format: 'auto',
  quality: 80,
  className: '',
  style: {},
  onLoad: () => {},
  alt: ''
};

export default CloudinaryImage;

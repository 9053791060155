import CrimsonTextBoldTTF from './CrimsonText-Bold.ttf';
import CrimsonTextBoldWOFF from './CrimsonText-Bold.woff';
import CrimsonTextBoldWOFF2 from './CrimsonText-Bold.woff2';
import CrimsonTextItalicTTF from './CrimsonText-Italic.ttf';
import CrimsonTextItalicWOFF from './CrimsonText-Italic.woff';
import CrimsonTextItalicWOFF2 from './CrimsonText-Italic.woff2';
import CrimsonTextRegularWOFF from './CrimsonText-Regular.woff';
import CrimsonTextRegularWOFF2 from './CrimsonText-Regular.woff2';
import NotoKufiArabicBoldTTF from './NotoKufiArabic-Bold.ttf';
import NotoKufiArabicBoldWOFF from './NotoKufiArabic-Bold.woff';
import NotoKufiArabicBoldWOFF2 from './NotoKufiArabic-Bold.woff2';
import NotoKufiArabicWOFF from './NotoKufiArabic.woff';
import NotoKufiArabicWOFF2 from './NotoKufiArabic.woff2';
import OpenSansSemiBoldTTF from './OpenSans-SemiBold.ttf';
import OpenSansSemiBoldWOFF from './OpenSans-SemiBold.woff';
import OpenSansSemiBoldWOFF2 from './OpenSans-SemiBold.woff2';
import OpenSansSemiBoldItalicTTF from './OpenSans-SemiBoldItalic.ttf';
import OpenSansSemiBoldItalicWOFF from './OpenSans-SemiBoldItalic.woff';
import OpenSansSemiBoldItalicWOFF2 from './OpenSans-SemiBoldItalic.woff2';
import OpenSansBoldTTF from './OpenSans-Bold.ttf';
import OpenSansBoldWOFF from './OpenSans-Bold.woff';
import OpenSansBoldWOFF2 from './OpenSans-Bold.woff2';
import OpenSansBoldItalicTTF from './OpenSans-BoldItalic.ttf';
import OpenSansBoldItalicWOFF from './OpenSans-BoldItalic.woff';
import OpenSansBoldItalicWOFF2 from './OpenSans-BoldItalic.woff2';
import OpenSansItalicTTF from './OpenSans-Italic.ttf';
import OpenSansItalicWOFF from './OpenSans-Italic.woff';
import OpenSansItalicWOFF2 from './OpenSans-Italic.woff2';
import OpenSansRegularTTF from './OpenSans-Regular.ttf';
import OpenSansRegularWOFF from './OpenSans-Regular.woff';
import OpenSansRegularWOFF2 from './OpenSans-Regular.woff2';
import OswaldBoldTTF from './Oswald-Bold.ttf';
import OswaldBoldWOFF from './Oswald-Bold.woff';
import OswaldBoldWOFF2 from './Oswald-Bold.woff2';
import OswaldRegularTTF from './Oswald-Regular.ttf';
import OswaldRegularWOFF from './Oswald-Regular.woff';
import OswaldRegularWOFF2 from './Oswald-Regular.woff2';
import KarlaRegularTTF from './Karla-Regular.ttf';
import KarlaBoldTTF from './Karla-Bold.ttf';
import KarlaBoldItalicTTF from './Karla-BoldItalic.ttf';
import KarlaItalicTTF from './Karla-Italic.ttf';

export default {
  CrimsonTextBoldTTF,
  CrimsonTextBoldWOFF,
  CrimsonTextBoldWOFF2,
  CrimsonTextItalicTTF,
  CrimsonTextItalicWOFF,
  CrimsonTextItalicWOFF2,
  CrimsonTextRegularWOFF,
  CrimsonTextRegularWOFF2,
  NotoKufiArabicBoldTTF,
  NotoKufiArabicBoldWOFF,
  NotoKufiArabicBoldWOFF2,
  NotoKufiArabicWOFF,
  NotoKufiArabicWOFF2,
  OpenSansSemiBoldTTF,
  OpenSansSemiBoldWOFF,
  OpenSansSemiBoldWOFF2,
  OpenSansSemiBoldItalicTTF,
  OpenSansSemiBoldItalicWOFF,
  OpenSansSemiBoldItalicWOFF2,
  OpenSansBoldTTF,
  OpenSansBoldWOFF,
  OpenSansBoldWOFF2,
  OpenSansBoldItalicTTF,
  OpenSansBoldItalicWOFF,
  OpenSansBoldItalicWOFF2,
  OpenSansItalicTTF,
  OpenSansItalicWOFF,
  OpenSansItalicWOFF2,
  OpenSansRegularTTF,
  OpenSansRegularWOFF,
  OpenSansRegularWOFF2,
  OswaldBoldTTF,
  OswaldBoldWOFF,
  OswaldBoldWOFF2,
  OswaldRegularTTF,
  OswaldRegularWOFF,
  OswaldRegularWOFF2,
  KarlaRegularTTF,
  KarlaBoldTTF,
  KarlaBoldItalicTTF,
  KarlaItalicTTF,
};

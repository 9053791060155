/* eslint-disable global-require */

const localeData = [
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/fr'),
  ...require('react-intl/locale-data/es'),
  ...require('react-intl/locale-data/zh'),
  ...require('react-intl/locale-data/ru'),
  ...require('react-intl/locale-data/ar')
];

module.exports = {
  localeData,
  languages: [
    { value: 'en', text: 'english' },
    { value: 'fr', text: 'français' },
    { value: 'es', text: 'español' },
    { value: 'zh', text: '中文' },
    { value: 'ru', text: 'русский' },
    { value: 'ar', text: 'عربى' }
  ]
};

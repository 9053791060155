import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const I18nLink = ({ to, children, external, ...rest }, { language }) => {
  const { locale } = language;
  const toWithLang = locale ? `/${language.locale}${to}` : `${to}`;
  const internal = /^\/(?!\/)/.test(to) && !external;

  if (internal) {
    return (
      <Link to={toWithLang} {...rest} style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    );
  }
  return (
    <a href={to} {...rest}>
      {children}
    </a>
  );
};

I18nLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool
};

I18nLink.defaultProps = {
  external: false
}

I18nLink.contextTypes = {
  language: PropTypes.object,
};

export default I18nLink;

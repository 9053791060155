// Provides Global styles component loaded in Layout

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    &.overflow {
      overflow: hidden;
    }
  }

  h1, h2, h3, h4, p, figcaption, .list {
    direction: ${props => (props.locale === 'ar' ? 'rtl' : 'ltr')};
  }

  /* target IE to respect aspect ratios and sizes for img tags containing SVG files */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
      width: 100%;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
  }

  .slide__left {
    .carousel-enter {
      transform: translate3d(-100%,0,0);
    }

    .carousel-enter.carousel-enter-active {
      transform: translate3d(0, 0, 0);
      transition: transform 200ms ease-in;
    }

    .carousel-exit {
      transform: translate3d(0,0,0);
    }

    .carousel-exit.carousel-exit-active {
       transform: translate3d(100%,0,0);
       transition: transform 200ms ease-in;
    }
  }

  .slide__right {

    .carousel-enter {
      transform: translate3d(100%,0,0);
    }

    .carousel-enter.carousel-enter-active {
      transform: translate3d(0,0,0);
      transition: transform 200ms ease-in;
    }

    .carousel-exit {
      transform: translate3d(0,0,0);
    }

    .carousel-exit.carousel-exit-active {
      transform: translate3d(-100%,0,0);
      transition: transform 200ms ease-in;
    }
  }

  em {
    font-style: normal;
  }

  @media only screen and (max-width: 480px) {
    ::cue {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-device-width: 1024px) {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  }
`;

export default GlobalStyle;
